import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Header, Segment, Portal, Input, Button, List, Icon } from 'semantic-ui-react'

import Select from "react-select";
import ColorPicker from './ColorPicker'

function stringToHexColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return hslToHex(h, s, l);
}

function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

const NewSubFactors = ({
    surveyStruc, setSurveyStruc,
}) => {


    const [listName, setListName] = useState('')
    const [updated, setUpdated] = useState(false)
    const [optionHead, setOptionHead] = useState([])
    const [selectedOption, setSelectedOption] = useState(false)
    const [subFactor, setSubFactor] = useState({})
    const [color, setColor] = useState('#BCBCBC')

    useEffect(() => {
        const PriorityList = []
        if (surveyStruc?.options) {
            surveyStruc?.options.map((st, key) => {
                return PriorityList.push({ value: key, label: st.name })
            })
        }
        setOptionHead(PriorityList)
    }, [surveyStruc?.options]);

    function AddToList() {
        const _survey = subFactor
        const _options = _survey?.options ? _survey?.options : []
        if (listName.length > 0) {
            if (!_options.includes(listName)) {
                _options.push({
                    id: uuidv4(),
                    name: listName,
                    color: color
                })
            }
        }
        _survey.options = _options
        setSubFactor(_survey)
        setListName('')

        const _subFactorRows = []
        surveyStruc.subfactors.filter(st => st.id !== _survey?.id).map(sl => {
            return _subFactorRows.push({id: uuidv4(), ...sl})
        })
        _subFactorRows.push(_survey)
        const _surveyStruc = surveyStruc
        _surveyStruc.subfactors = _subFactorRows
        setSurveyStruc(_surveyStruc)

        setUpdated(Math.random())
    }

    function RemoveFromList(key) {
        const _survey = subFactor
        _survey.options = subFactor?.options.splice(0, key)
        setSubFactor(_survey)

        const _subFactorRows = []
        surveyStruc.subfactors.filter(st => st.id !== _survey?.id).map(sl => {
            return _subFactorRows.push(sl)
        })
        _subFactorRows.push(_survey)
        const _surveyStruc = surveyStruc
        _surveyStruc.subfactors = _subFactorRows
        setSurveyStruc(_surveyStruc)

        setUpdated(Math.random())
    }

    function SelectedOption(e) {
        setSelectedOption(e)
        const SurveyStru = surveyStruc?.subfactors?.find(({ option }) => option === e)
        if (SurveyStru) {
            setSubFactor(SurveyStru)
        } else {
            setSubFactor({
                id: uuidv4(),
                option: e,
                options: [],
                parent: 0,
            })
        }
    }

    function UpdatedOptionList(e) {
        setListName(e.target.value)
        setColor(stringToHexColor(e.target.value, 50, 60))
    }

    return (
        <>
            <div>
                <Half>
                    <FormContainerTop>
                        <Label>Option</Label>
                        <Select
                            placeholder="Select Option"
                            onChange={(e) => SelectedOption(e.label)}
                            value={optionHead.find(({ label }) => label === selectedOption)}
                            options={optionHead}
                        />
                    </FormContainerTop>
                    {selectedOption &&
                        <>
                            <FormContainer>
                                <Label>Options</Label>
                                <List divided verticalAlign='middle'>
                                    {subFactor?.options?.length > 0 &&
                                        subFactor?.options.map((item, key) => {
                                            return (
                                                <List.Item key={key}>
                                                    <RowList>
                                                        <Icon
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => RemoveFromList(key)}
                                                            name='minus circle' />

                                                        <ColorBlock color={item?.color} />
                                                        <List.Content>{item?.name}</List.Content>
                                                    </RowList>
                                                </List.Item>
                                            )
                                        })}
                                </List>

                                <Input fluid type='text' placeholder='Option Heading' action>
                                    <input name="options" value={listName}
                                        onChange={(e) => UpdatedOptionList(e)} />
                                    <ColorPicker
                                        color={color}
                                        setColor={(c) => setColor(c.hex)}
                                    />
                                    <Button onClick={AddToList}>Add + </Button>
                                </Input>
                            </FormContainer>
                        </>
                    }
                </Half>
            </div>


        </>
    )
}

export default NewSubFactors


const StyledButton = styled.button`
  background-color: #6a00ff;
  color: white;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 7px 7px 18px;
  border: none;
  outline: none;
//   margin-top:30px;
 
  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;

const Half = styled.div`
	width:100%;
	padding:30px 30px;
`

const RowList = styled.div`
    display: flex;
    align-items: stretch; 
    justify-content: flex-start;
`

const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-bottom:30px;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
    width: 100%;
	margin-top:20px;
`

const FormContainerTop = styled.div`
    width: 100%;
	margin-top:0px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;

`
