import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Loader, Select } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Chart from "react-apexcharts";
// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import useGetUserSettings from "hooks/useGetUserSettings";
import { useSearchParams, useLocation } from "react-router-dom";

import CategorizedDropdown from "../DataScience/CategorizedDropdown";

import ChartLoading from "reports/Audit/ChartLoading";
import noData from "assets/images/empty/empty.png";
import Model from "assets/images/nav/fa6-solid_layer-group.svg";
import { erf } from "mathjs";

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};
const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const create_option_list = (
  categories,
  questions,
  type,
  records,
  showPersonality
) => {
  let options = [];

  categories.categories.map((item) => {
    if (type === 1) {
      options.push({ text: item.name, value: item });
    }
    if (type === 2) {
      item.options.forEach((o) => {
        options.push({
          text: o.name,
          value: { text: o.name, type: item.name, id: o.id, qId: item.id },
        });
      });
    }
  });

  let personality_factors = [
    "Extraversion",
    "Conscientiousness",
    "Agreeableness",
    "Neuroticism",
    "Openess",
  ];
  if (type === 2) {
    if (showPersonality) {
      personality_factors.forEach((f, i) => {
        options.push({
          text: f,
          value: { type: "personality", id: i, text: f },
        });
      });
    }

    records.forEach((item, i) => {
      options.push({
        text: item.name,
        value: { type: "records", id: item.id, text: item.name },
      });
    });
  }

  return options;
};

let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

const retrieveCategoryAverages = (
  responseData,
  vertical,
  variable,
  breakdown
) => {
  let values = [];

  const optionResponses = responseData.filter((d) => {
    return d.categories.find(
      (cr) => cr.id === variable?.qId && cr.response === variable?.id
    );
  });

  vertical?.options.forEach((option) => {
    if (variable.type === "personality") {
      const optionData = responseData.filter((rd) => {
        return (
          rd?.survey_personality &&
          rd.categories.find(
            (cr) => cr.id === vertical.id && option.id === cr.response
          )
        );
      });

      const breakdownResponses =
        breakdown &&
        breakdown.options.map((op) => {
          const breakdownMatches = optionData.filter((mr) => {
            return mr?.categories?.find((cr) => cr.response === op.id);
          });

          const matchingResponses = breakdownMatches?.map((od) => {
            return calculate_personality(od.survey_personality, variable.id);
          });

          let sum = 0;
          matchingResponses.forEach((r) => {
            sum += r;
          });

          return {
            id: op.id,
            name: op.name,
            percent:
              (matchingResponses.length > 0 &&
                Math.round(sum / matchingResponses.length)) ||
              0,
          };
        });

      const matchingResponses = optionData?.map((od) => {
        return calculate_personality(od.survey_personality, variable.id);
      });

      let sum = 0;
      matchingResponses.forEach((r) => {
        sum += r;
      });

      values.push({
        name: option.name,
        id: option.id,
        seriesData: breakdownResponses,
        percent:
          (matchingResponses.length > 0 &&
            Math.round(sum / matchingResponses.length)) ||
          0,
      });
    } else if (variable.type === "records") {
      const optionData = responseData.filter((rd) => {
        return (
          rd?.employee_records &&
          rd.categories.find(
            (cr) => cr.id === vertical.id && option.id === cr.response
          )
        );
      });

      const breakdownResponses =
        breakdown &&
        breakdown.options.map((op) => {
          const numericalRegex = /[$|€|£]?(\d*\s?[\,?\d{3,}]*\.?\d+)/;
          let hasRecords = false;

          const breakdownMatches = optionData.filter((mr) => {
            return mr?.categories?.find((cr) => cr.response === op.id);
          });

          breakdownMatches.forEach((od) => {
            if (od.employee_records.length > 0) {
              hasRecords = true;
            }
          });

          let sum = 0;
          let matchingResponses = [];
          if (hasRecords) {
            matchingResponses = breakdownMatches.map((or) => {
              let recordVal;
              or.employee_records.forEach((recordObj) => {
                if (recordObj?.[variable.text]) {
                  recordVal = recordObj?.[variable.text];
                }
              });
              let cleanedVal = recordVal?.toString().match(numericalRegex);
              if (cleanedVal) {
                if (cleanedVal.input.match(/€/)) {
                  cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
                  cleanedVal = Number(cleanedVal) * 1.35;
                } else {
                  cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
                  cleanedVal = cleanedVal.replace(/,/g, "");
                }
                return Number(cleanedVal);
              }
            });

            matchingResponses.forEach((r) => {
              if (r) {
                sum += r;
              }
            });
          }

          return {
            id: op.id,
            name: op.name,
            percent:
              (matchingResponses?.length > 0 &&
                Math.round(sum / matchingResponses?.length)) ||
              0,
          };
        });
      const numericalRegex = /[$|€|£]?(\d*\s?[\,?\d{3,}]*\.?\d+)/;
      let hasRecords = false;
      optionData.forEach((od) => {
        if (od.employee_records.length > 0) {
          hasRecords = true;
        }
      });
      if (hasRecords) {
        const matchingResponses = optionData.map((or) => {
          let recordVal;
          or.employee_records.forEach((recordObj) => {
            if (recordObj?.[variable.text]) {
              recordVal = recordObj?.[variable.text];
            }
          });
          let cleanedVal = recordVal?.toString().match(numericalRegex);
          if (cleanedVal) {
            if (cleanedVal.input.match(/€/)) {
              cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
              cleanedVal = Number(cleanedVal) * 1.35;
            } else {
              cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
              cleanedVal = cleanedVal.replace(/,/g, "");
            }
            return Number(cleanedVal);
          }
        });

        let sum = 0;
        matchingResponses.forEach((r) => {
          if (r) {
            sum += r;
          }
        });

        values.push({
          name: option.name,
          id: option.id,
          seriesData: breakdownResponses,
          percent:
            (matchingResponses.length > 0 &&
              Math.round(sum / matchingResponses.length)) ||
            0,
        });
      }
    } else {
      const matchingResponses = optionResponses.filter((or) => {
        return or.categories.find((cr) => cr.response === option.id);
      });
      const breakdownResponses =
        breakdown &&
        breakdown.options.map((op) => {
          const breakdownMatches = matchingResponses.filter((mr) => {
            return mr?.categories?.find((cr) => cr.response === op.id);
          });

          return {
            id: op.id,
            name: op.name,
            percent:
              (breakdownMatches.length / optionResponses.length) * 100 || 0,
          };
        });

      values.push({
        name: option.name,
        id: option.id,
        seriesData: breakdownResponses,
        percent:
          (matchingResponses.length > 0 &&
            Math.round(
              (matchingResponses.length / optionResponses.length) * 100
            )) ||
          0,
      });
    }

    values.sort((a, b) => a.percent > b.percent && -1);
    return values;
  });

  values.sort((a, b) => a.percent > b.percent && -1);
  return values;
};

const Comparison = ({ data, records, showPersonality }) => {
  const dispatch = useDispatch();
  const [horizontal, setHorizontal] = useState();
  const [vertical, setVertical] = useState();
  const [breakdown, setBreakdown] = useState();
  const [seriesData, setSeriesData] = useState();
  const [anchor_list, setAnchorList] = useState();
  const [anchor_list2, setAnchorList2] = useState();
  const [breakdownList, setBreakdownList] = useState();
  const [loading, setLoading] = React.useState(false);
  const { filteredCategories, isAdmin } = useGetUserSettings();
  const location = useLocation();
  const isDEI = location.pathname.includes("edi");

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({
    surveyType: isDEI ? 7 : 6,
    auditTypeId: isDEI ? 4 : 3,
    filteredCategories,
    isAdmin,
  });

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    surveyType: isDEI ? 7 : 6,
    get_employee,
    filteredCategories,
    isAdmin,
  });

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });

  useEffect(() => {
    if (vertical) {
      const breakdownListFiltered = [...anchor_list].filter((listItem) => {
        return listItem.text !== vertical.name;
      });

      setBreakdownList(breakdownListFiltered);
    }
  }, [vertical]);

  useEffect(() => {
    if (data.length > 0 && horizontal && vertical) {
      const calculated = retrieveCategoryAverages(
        data,
        vertical,
        horizontal,
        breakdown
      );
      setLoading(true);
      let sum = 0;
      calculated.forEach((c) => (sum += c.percent));
      if (calculated.length > 0 && sum > 0) {
        let seriesArr = [];
        if (breakdown) {
          seriesArr = breakdown?.options?.map((bo) => {
            return {
              name: bo.name,
              data: calculated.map((group) => {
                const groupPercent =
                  group.seriesData.find((sd) => sd.id === bo.id)?.percent || 0;
                return Math.floor(groupPercent);
              }),
            };
          });
        } else {
          seriesArr = [
            {
              name: "Scores",
              data: calculated.map((group) => group.percent),
            },
          ];
        }
        const series = {
          series: [...seriesArr],
          options: {
            chart: {
              type: "bar",
              fontFamily: "Poppins, sans-serif",
              foreColor: "#7E7E7E",
              toolbar: {
                show: false,
              },
              height: 400,
              redrawOnWindowResize: true,
              stacked: false,
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "70%",
                endingShape: "rounded",
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#66D686", "#476DFA", "#E66579", "#EE7C35"],

            xaxis: {
              labels: {
                rotate: -45,
                rotateAlways: true,
                offsetY: 5,
              },

              categories: calculated.map((calc) => calc.name),
              tickPlacement: "between",
            },
            yaxis: {
              decimalsInFloat: 0,
              title: {
                text: horizontal.text,
                style: {
                  fontWeight: 400,
                },
              },
            },
          },
        };

        setSeriesData(series);
      }

      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  }, [data, horizontal, vertical, breakdown]);

  useEffect(() => {
    if (get_survey_structure[0] && questionStructure) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1
      );

      setAnchorList(anchor_list);
      setBreakdownList(anchor_list);

      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2,
        records,
        showPersonality
      );

      setAnchorList2(anchor_list2);
      if (anchor_list) {
        setHorizontal(
          anchor_list.find((f) => f.value.priority == "secondary").value
        );
        setVertical(
          anchor_list.find((f) => f.value.priority == "primary").value
        );
      }
    }
  }, [get_survey_structure[0], questionStructure]);

  if (!get_survey_structure[0]) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>Group Comparison</Title>
      <OptionArea>
        <div style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
          <Label>Category</Label>
          <StyledSelect
            placeholder="Select Survey Type"
            options={anchor_list}
            value={vertical}
            id="vertical"
            onChange={(e, t) => setVertical(t.value)}
          />
        </div>

        <div style={{ marginLeft: 30, display: "flex", alignItems: "center" }}>
          <Label>Variable</Label>
          <CategorizedDropdown
            placeholder="Select a Variable"
            filterOptions={anchor_list2}
            value={horizontal}
            id="variable"
            onSelectLabel={(t) => setHorizontal(t.value)}
          />
        </div>
      </OptionArea>
      <OptionArea>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BreakdownLabel>Breakdown</BreakdownLabel>
          <StyledSelect
            placeholder="Select a Breakdown"
            options={breakdownList}
            value={breakdown || null}
            id="breakdown"
            onChange={(e, t) => {
              setBreakdown(t.value);
            }}
          />
        </div>
        {breakdown && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setBreakdown()}
          >
            <ResetText>Remove Breakdown</ResetText>
          </div>
        )}
      </OptionArea>
      {(!horizontal || !vertical || !seriesData || !seriesData?.options) &&
      !loading ? (
        <NoDataContainer>
          <ImageContainer src={noData} />
          <Text>No data for this group</Text>
        </NoDataContainer>
      ) : loading && horizontal && vertical ? (
        <ChartLoading />
      ) : (
        <Card>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Team comparison by {vertical?.name} ({horizontal?.text})
          </TableTitle>
          <Sub></Sub>
          {seriesData && (
            <Chart
              options={seriesData.options}
              series={seriesData.series}
              type="bar"
              height={400}
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default Comparison;

const ResetText = styled.div`
  font-size: 14px;
  font-family: "Poppins";
  padding-left: 15px;
  color: #476dfa;
  cursor: pointer;
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins";
  margin-top: 10px;
`;

const ImageContainer = styled.img`
  height: 140px;
  margin-top: 50px;
`;

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
  margin-bottom: 30px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const BreakdownLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 38px;
`;

const Card = styled.div`
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 40px 20px 20px;
  margin-right: 20px;
`;

const TableTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-left: 33px;
`;

const Image = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [19.5, 18.3, 16.4, 16.3, 16.2, 13, 12],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: "100%",
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        colors: {
          backgroundBarOpacity: 1,
        },
      },
    },
    colors: ["#476DFA"],
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: true,
        offsetY: 5,
        minHeight: 110,
      },
      categories: [
        "Team A",
        "Team B",
        "Team C",
        "Team D",
        "Team E",
        "Team F",
        "Team G",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      decimalsInFloat: 0,
      title: {
        text: "Performance Review Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
