import {
  PENDING_EMPLOYEE,
  GET_EMPLOYEES,
  SEARCH_EMPLOYEES,
  FETCH_EMPLOYEE,
  FETCH_USER_EMPLOYEE,
  EDIT_EMPLOYEE_PIC,
  DELETE_EMPLOYEE,
  RESET_EMPLOYEE,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  VALIDATE_EMPLOYEE,
  VALIDATE_ORG_EMP_EMAIL,
  SIGNUP_TOKEN,
  CLEAR_EMPLOYEE,
  CLEAR_ALL,
  GET_SAFE_EMPLOYEE_LIST,
  INACTIVE_EMPLOYEES,
  EMPLOYEES_LIST,

} from "constants/actions";

const initialState = {
  employees: [],
  search: false,
  employee_pics: [],
  pending: false,
  created: [],
  updated: false,
  token: [],
  userEmp: false,
  validateEmail: [],
  allEmailList: [],
  inactive: [],
  quicklist: [],
  employeesListSafe: [],
};

export default function Employee(state = initialState, action) {
  switch (action.type) {
    case PENDING_EMPLOYEE:
      return {
        ...state,
        pending: true,
        created: [],
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
        updated: false,
        pending: false,
      };

    case INACTIVE_EMPLOYEES:
      return {
        ...state,
        inactive: action.payload,
        pending: false,
      };
    // only active emps listed here
    case GET_SAFE_EMPLOYEE_LIST:
      return {
        ...state,
        employeesListSafe: action.payload,
        pending: false,
      };
    // including inactive
    case EMPLOYEES_LIST:
      return {
        ...state,
        quicklist: action.payload,
        pending: false,
      };
    case SEARCH_EMPLOYEES:
      return {
        ...state,
        search: action.payload,
        pending: false,
        updated: false,
      };
    case VALIDATE_EMPLOYEE:
      return {
        ...state,
        validateEmail: action.payload,
        pending: false,
        updated: false,
      };

    case VALIDATE_ORG_EMP_EMAIL:
      return {
        ...state,
        allEmailList: action.payload,
        pending: false,
        updated: false,
      };

    case EDIT_EMPLOYEE_PIC:
      return {
        ...state,
        employee_pics: action.payload,
        [action.payload.id]: {
          ...state[action.payload.id],
          picture: action.payload.picture,
        },
        pending: false,
        updated: action.payload,
      };

    case FETCH_EMPLOYEE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        // pending: false,
        // created: []
      };
    case FETCH_USER_EMPLOYEE:
      return {
        ...state,
        userEmp: action.payload,
        pending: false,
        // created: []
      };
    // case EDIT_EMPLOYEE:

    //   return { ...state,
    //     [action.payload.id]: action.payload,
    //     pending: false };

    case EDIT_EMPLOYEE:
      if (state.employees.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          employees: state.employees.filter(
            (emp) => emp.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        [action.payload.id]: action.payload,
        employees: [...state.employees, action.payload],
        pending: false,
        updated: action.payload,
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.filter((row) => row.id !== action.payload),
        pending: false,
        updated: true,
        created: [],
      };

    case ADD_EMPLOYEE:
      return {
        ...state,
        created: action.payload,
        employees: [...state.employees, action.payload],
        pending: false,
        updated: action.payload,
      };
    case SIGNUP_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case RESET_EMPLOYEE:
      return {
        ...state,
        created: [],
        updated: false,
        pending: false,
      };
    case CLEAR_EMPLOYEE:
      return {
        ...state,
        employees: [],
        search: false,
        employee_pics: [],
        pending: false,
        created: [],
        token: [],
        updated: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        employees: [],
        search: false,
        employee_pics: [],
        pending: false,
        created: [],
        token: [],
        updated: false,
      };



    default:
      return state;
  }
}
