import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import AddMultipleSchedule from "components/CultureAudit/MultipleScheduler";
import AddSchedule from "components/CultureAudit/SingleScheduler";
import CalendarList from "components/CultureAudit/CalendarList";
import StatusList from "components/CultureAudit/StatusList";

import { BasicErrorMessage } from "utilities/ErrorMessages";
import { Message } from "semantic-ui-react";
import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_PDF_TEMPLATES,
  SG_CREATE_PDF_TEMPLATE,
  SG_FETCH_USER_EMPLOYEE,
} from "constants/actions";

import {
  SURVEY_TYPE,
  SURVEY_INFO,
  SURVEY_QUESTION_TYPE,
} from "constants/surveys";

const EdiSurvey = () => {
  const dispatch = useDispatch();

  const [fetchAgain, setFetchAgain] = useState(0);
  const [organizationId, setOrganizationId] = useState(false);

  const [open, setOpen] = useState(false);
  const [briefId, setBriefId] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [subfactorFound, setSubfactorFound] = useState(false);

  const [userAdmin, setUserAdmin] = useState(false);
  const [userEmployee, setUserEmployee] = useState(false);
  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);
  const [pdfTemplate, setPdfTemplate] = useState(false);

  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [tab, setTab] = useState(0);
  const [primarySecondaryName, setPrimarySecondaryName] = useState({
    primary: "Type",
    secondary: "Role",
  });
  const [alertMessage, setAlertMessage] = useState({
    no: 0,
    text: null,
  });

  const EmployeeId = useSelector(
    (state) => Number(state.auth?.employee_id),
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );
  const pdfConfig = useSelector(
    (state) => state.pdfGenerator.pdfConfig,
    shallowEqual
  );

  const {
    get_auth,
    get_organizations,
    get_employee,
    get_survey_structure,
    get_employee_categories,
    get_survey_questions,
    get_selectedOrg,
    get_debrief_schedules,
  } = useSelector(
    (state) => ({
      get_errors: state.errors,
      get_auth: state.auth,
      get_organizations: state.organizations,
      get_employee: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_categories: state.employee_category.employee_category,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_debrief_schedules: state.debrief_schedule,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (EmployeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${EmployeeId}`,
      });
    }
  }, [dispatch, EmployeeId]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${SURVEY_QUESTION_TYPE.EDI}&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    dispatch({
      type: SG_FETCH_USER_EMPLOYEE,
      payload: {
        id: Number(get_auth?.employee_id),
      },
    });
  }, [dispatch, get_auth]);

  useEffect(() => {
    setUserAdmin(false);
    if (get_employee) {
      setUserEmployee(get_employee.userEmp);
      // check for admin
      if (get_employee?.userEmp?.account_type === 5) {
        setUserAdmin(true);
      }
    }
  }, [get_employee, get_auth]);

  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_TEMPLATES,
    });
  }, [dispatch]);

  // call the post api after state update false to []
  // if empty it would return [] or empty array
  useEffect(() => {
    setPdfTemplate(pdfConfig);
  }, [pdfConfig]);

  useEffect(() => {
    if (pdfTemplate.length === 0) {
      dispatch({
        type: SG_CREATE_PDF_TEMPLATE,
        payload: { name: "ver 1.0", survey_type: SURVEY_INFO.edi.type },
      });
    }
  }, [pdfTemplate, dispatch]);

  useEffect(() => {
    const _Category = get_employee_categories.filter(
      (ec) => ec.employee === Number(get_auth?.employee_id)
    )?.[0]?.category;

    setUserEmployeeCategoryList(
      _Category?.filter((ct) => ct.priority === "primary").map((ct) => ct.value)
    );
  }, [get_employee_categories, get_auth]);

  useEffect(() => {
    setPrimarySecondaryName({
      secondary: get_survey_structure?.[0]?.categories?.find(
        (f) => f.priority === "secondary"
      )?.name,
      primary: get_survey_structure?.[0]?.categories?.find(
        (f) => f.priority === "primary"
      )?.name,
    });
  }, [get_survey_structure]);

  useEffect(() => {
    const _primaryList = [];

    if (get_survey_structure?.[0]?.categories?.length > 0) {
      get_survey_structure?.[0]?.categories.map((st) => {
        if (st?.priority === "primary") {
          // no subfactors
          st.options.map((str) => {
            if (str?.subfactors?.length > 0) {
              // subfactors found
              setSubfactorFound(true);
              // console.log(str)
              str?.subfactors.map((opf) => {
                // check if user is not admin
                // for eligibility
                if (userAdmin) {
                  _primaryList.push({
                    value: opf.name,
                    name: opf?.name,
                    name_id: opf?.id,
                    label: opf?.name,
                    group: str?.name,
                    mainGroup: st?.name,
                    type_of: st?.priority,
                    subtitle: st?.subtitle,
                    priority: st?.priority,
                  });
                } else {
                  if (userEmployeeCategoryList?.includes(opf.name)) {
                    _primaryList.push({
                      value: opf.name,
                      name: opf?.name,
                      name_id: opf?.id,
                      label: opf?.name,
                      group: str?.name,
                      mainGroup: st?.name,
                      type_of: st?.priority,
                      subtitle: st?.subtitle,
                      priority: st?.priority,
                    });
                  }
                }
                return null;
              });
            } else {
              // check if user is not admin
              // for eligibility
              if (userAdmin) {
                _primaryList.push({
                  value: str?.name,
                  label: str?.name,
                  name: str?.name,
                  name_id: str?.id,
                  type_of: st?.priority,
                  subtitle: st?.subtitle,
                  priority: st?.priority,
                });
              } else {
                if (userEmployeeCategoryList?.includes(str.name)) {
                  _primaryList.push({
                    value: str?.name,
                    label: str?.name,
                    name: str?.name,
                    name_id: str?.id,
                    type_of: st?.priority,
                    subtitle: st?.subtitle,
                    priority: st?.priority,
                  });
                }
              }
            }
            return null;
          });
          return null;
        }

        setPrimaryCategory(_primaryList);
        return null;
      });
    }
  }, [get_survey_structure, userAdmin, userEmployeeCategoryList]);

  useEffect(() => {
    if (alertMessage?.no !== 0) {
      setTab(1);
    }
  }, [alertMessage]);

  const getTabs = () => {
    if (userAdmin === true || userEmployee?.survey_schedule === 1) {
      return ["Calendar", "History/Pending"];
    }
    return ["Calendar"];
  };

  useEffect(() => {
    if (get_debrief_schedules?.updated_debrief) {
      if (get_debrief_schedules?.created) {
        setAlertMessage({ no: 1, text: "Successfully Added New Schedule" });
        return;
      } else {
        setAlertMessage({ no: 1, text: "Successfully Updated Schedule" });
        return;
      }
    }
  }, [get_debrief_schedules, setAlertMessage]);

  return (
    <Container>
      <Title>Assessment Schedule</Title>
      {alertMessage?.text && (
        <Message
          onDismiss={() => setAlertMessage({ no: 0, text: null })}
          color={Number(alertMessage?.no === 2) ? "red" : "green"}
        >
          {alertMessage?.text}
        </Message>
      )}
      {showMenu && (
        <NavContainer>
          <div style={{ display: "flex" }}>
            {getTabs().map((item, i) => {
              return (
                <Tab onClick={() => setTab(i)} selected={i === tab} key={i}>
                  {item}
                </Tab>
              );
            })}
          </div>

          {/* {subfactorFound ? */}
          {userAdmin === true || userEmployee?.survey_schedule === 1 ? (
            briefId ? (
              <AddSchedule
                setFetchAgain={setFetchAgain}
                briefId={briefId}
                organizationId={organizationId}
                trigger={<StyledButton>Schedule</StyledButton>}
                open={open}
                setOpen={setOpen}
                employee_category={get_employee_categories}
                primaryCategory={primaryCategory}
                surveyQuestions={get_survey_questions}
                surveyStructure={get_survey_structure}
                primarySecondaryName={primarySecondaryName}
                surveyType={SURVEY_TYPE.EDI}
                surveyInfo={SURVEY_INFO.edi}
                setAlertMessage={setAlertMessage}
              />
            ) : (
              <AddMultipleSchedule
                setFetchAgain={setFetchAgain}
                briefId={briefId || 0}
                organizationId={organizationId}
                trigger={<StyledButton>Schedule</StyledButton>}
                open={open}
                setOpen={setOpen}
                employee_category={get_employee_categories}
                primaryCategory={primaryCategory}
                surveyQuestions={get_survey_questions}
                surveyStructure={get_survey_structure}
                primarySecondaryName={primarySecondaryName}
                surveyType={SURVEY_TYPE.EDI}
                surveyInfo={SURVEY_INFO.edi}
                setAlertMessage={setAlertMessage}
              />
            )
          ) : (
            ""
          )}
        </NavContainer>
      )}

      <BasicErrorMessage />
      {tab === 0 ? (
        <CalendarList
          survey_structure={get_survey_structure[0]}
          organizationId={organizationId}
          employee_category={get_employee_categories}
          primaryCategory={primaryCategory}
          subfactorFound={subfactorFound}
          surveyType={SURVEY_TYPE.EDI}
          userAdmin={userAdmin}
          userEmployeeCategoryList={userEmployeeCategoryList}
        />
      ) : (
        <StatusList
          organizationId={organizationId}
          fetchAgain={fetchAgain}
          setFetchAgain={setFetchAgain}
          setBriefId={setBriefId}
          briefId={briefId}
          setOpen={setOpen}
          setAlertMessage={setAlertMessage}
          surveyType={SURVEY_TYPE.EDI}
          setShowMenu={setShowMenu}
          pdfConfig={pdfConfig}
        />
      )}
    </Container>
  );
};

/******** Styles ****************************************************/

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  font-family: "Poppins", sans-serif;
  padding-left: 58px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-right: 20px;
`;

const StyledButton = styled.button`
  background-color: #e3b01f;
  color: black;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 300;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  outline: none;
  margin-top: 10px;
  margin-right: 10px;
  font-family: "Barlow", sans-serif;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;

const Tab = styled.div`
  font-size: 14px;

  padding: 6px 16px 6px 16px;
  color: ${(props) => (props.selected ? "white" : "#7f7f7f")};
  border: ${(props) =>
    props.selected ? "1px solid #2d50e2" : "1px solid #7f7f7f"};
  background-color: ${(props) => (props.selected ? " #2d50e2" : "white")};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.selected ? "#2d50e2" : "#7f7f7f")};
    color: white;
  }
`;

const NavContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3dbdb;
`;

export default EdiSurvey;
