import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
// import Curiosity from "assets/images/curiosity.svg";
// import Enjoyment from "assets/images/enjoyment.svg";
// import Motivation from "assets/images/motivation.svg";
// import Discontent from "assets/images/discontent.svg";
// import Stress from "assets/images/stress.svg";
// import Anger from "assets/images/anger.svg";
import { Progress, Loader, Icon } from 'semantic-ui-react'
import Chart from "react-apexcharts";
import EmotionComment from './EmotionComment'

import format from "date-fns/format";

const Loading = () => {

  return (
    <div style={{ height: 350, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

    </div>
  )
}


const EQ = ({ empMoodData, selEmp, pastDates }) => {

  const [chartData, setChartData] = useState([0, 0]);
  const [selected, setSelected] = React.useState(pastDates?.length - 1)
const [lastDate, setLastDate] = useState(format(new Date(), 'MMM do yyyy'))
const [dateList,setDates] = useState([])


  const data = {

    series: [{
      name: 'Mood score',
      data: [31, 40]
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false
        }
      },
      grid: {
        padding: {
          right: 50
        }
      },
      dataLabels: {
        enabled: false
      },
      annotations: {
        points:
          [
            {
              x: new Date(pastDates?.[selected]).getTime(),
              y: 100,
              marker: {
                size: 8,
              },
              label: {
                text: '25%',
                borderWidth: 0,
                style: {
                  fontSize: 24,
                  fontWeight: 'bold'
                }
              }
            }
          ]
      },
      stroke: {
        curve: 'smooth',
        colors: ['#6a00ff']
      },
      fill: {
        colors: ['#6a00ff']
      },
      xaxis: {
        type: 'date',
        categories: pastDates
      },
      yaxis:{
        min:0,
        max:100
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },


  };

  const [options, setOptions] = React.useState(data.options)
  const [loading, setLoading] = React.useState(false)
  const [chartComments, setChartComments] = useState([]);


  const setData = (idx) => {
    setLoading(true)
    let _options = options
    setSelected(idx)
    let _data = chartData[idx]

    _options.annotations.points = [
      {
        x: dateList[idx],
        y: _data,
        marker: {
          size: 8,
        },
        label: {
          text: _data + '%',
          borderWidth: 0,
          style: {
            fontSize: 24,
            fontWeight: 'bold'
          }
        }
      }
    ]
    setOptions(_options)
    setTimeout(function () {
      setLoading(false)
    }, 20);

  }

  useEffect(() => {
     setLoading(true)
    const _score = []
    const _comments = []
    const _dates = []

    let _options = options
    empMoodData.map(md => {
      if(md.responses.length>0){
        _score.push(md.responses[0].mood_score)
         _comments.push(md.responses[0].comments)
      }else{
        _score.push(0)
        _comments.push([])

      }
      _dates.push(format(
          new Date(md?.date.toLocaleString()),
          "MMM do yyyy"
        ))


    }
    )

    _dates.reverse()
    _options.xaxis.categories = _dates

    _score.reverse()
    let idx = _score.length - 1
    let currentScore = _score[idx]

     _options.annotations.points = [
      {
        x: _dates[idx],
        y: currentScore,
        marker: {
          size: 8,
        },
        label: {
          text: currentScore + '%',
          borderWidth: 0,
          style: {
            fontSize: 24,
            fontWeight: 'bold'
          }
        }
      }
    ]

    const checkNan = (d) =>{
      if(d===0){
        return true
      }
      if(isNaN(d)){
        return false
      }
      if(d){
        return true
      }

      return false

    }


    setChartData(_score.map(e=>(checkNan(e)?e:0)))
    setChartComments(_comments.reverse())
    setDates(_dates)
    setSelected(idx)
    setLastDate(_dates[_dates.length-1])
    setOptions(_options)
    setTimeout(function () {
      setLoading(false)
    }, 1);

  }, [empMoodData, options]);

  const move = (i) => {
    if (i > 0 && selected === chartData.length - 1) {
      return
    }
    if (i < 0 && selected === 0) {
      return
    }
    setLoading(true)
    let _options = options
    let idx = selected + i
    setSelected(idx)
    let _data = chartData[idx]
    setLastDate(dateList[idx])

    _options.annotations.points = [
      {
        x: dateList[idx],
        y: _data,
        marker: {
          size: 8,
        },
        label: {
          text: _data + '%',
          borderWidth: 0,
          style: {
            fontSize: 24,
            fontWeight: 'bold'
          }
        }
      }
    ]

    setOptions(_options)
    setTimeout(function () {
      setLoading(false)
    }, 1);

  }

  return (
    <Container>
      <Title>Emotional Health Metrics</Title>
      <DateContainer>
        <DateText>Report date: {lastDate}</DateText>
        <div style={{ marginRight: 40 }}>
          <Icon
            name="chevron left"
            onClick={() => move(-1)}
            size={"small"}
            style={{ fontSize: 22, cursor: "pointer", zIndex: 2 }}
          />
          <Icon
            name="chevron right"
            onClick={() => move(1)}
            size={"small"}
            style={{ fontSize: 22, cursor: "pointer", zIndex: 2 }}
          />
        </div>
      </DateContainer>
      {!loading ? <Chart options={options} series={[{
        name: 'Mood score',
        data: chartData
      }]} type="area" height={350} /> : <Loading />}
      <EmotionContainer>
        {chartComments[selected]?.map((p, k) => {
          return <EmotionComment key={k} mood={p.name} comment={p.comment} on_date={pastDates[selected]} />
        }
        )}

        {/* <EmotionComment mood={"Optimistic"} comment={""} on_date={""} /> */}
        {/* <EmotionComment mood={"Concerned"} comment={""} on_date={""} /> */}
      </EmotionContainer>
    </Container>
  )
}


export default EQ


const DateContainer = styled.div`
  display:flex;
  justify-content:space-between;
`

const Container = styled.div`
	width:100%;
 	padding:30px 20px;
 	margin-top:20px;
 	position:relative;
 	margin-bottom:50px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:30px;
	color:#4a4a4a;
`

const DateText = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:5px;
  margin-bottom:30px;
  margin-left:3px;
`

const EmotionContainer = styled.div`
  display:flex;

  @media(max-width:1100px){
    flex-wrap:wrap;
  }
`