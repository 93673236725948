import {
  ADD_ACTIVITY_ITEM,
  DELETE_ACTIVITY_ITEM,
  GET_ACTION_PLAN_ACTIVITY,
  GET_ALL_ACTION_PLANS,
  CREATE_ACTION_PLAN,
  FETCH_ACTION_PLAN,
  UPDATE_ACTION_PLAN,
  UPDATE_ACTIVITY_ITEM,
  DELETE_ACTION_PLAN,
  GET_ACTION_PLAN_ACTIVITIES,
} from "constants/actions";

const initialState = {
  plans: [],
  planActivity: [],
  planActivities: [],
};

export default function ActionPlans(state = initialState, action) {
  switch (action.type) {
    case ADD_ACTIVITY_ITEM:
      return {
        ...state,
        planActivity: [action.payload, ...state.planActivity],
      };

    case DELETE_ACTIVITY_ITEM:
      const planActivityCopy = [...state.planActivity];
      const filteredActivities = planActivityCopy.filter(
        (event) => event.id !== action.payload
      );

      return {
        ...state,
        planActivity: [...filteredActivities],
      };

    case GET_ACTION_PLAN_ACTIVITY:
      return {
        ...state,
        planActivity: [...action.payload.results],
      };

    case GET_ALL_ACTION_PLANS:
      return {
        ...state,
        plans: [...action.payload],
      };

    case CREATE_ACTION_PLAN:
      return {
        ...state,
        plans: [...state.plans, action.payload],
      };

    case FETCH_ACTION_PLAN:
      return {
        ...state,
        plans: [...action.payload],
      };

    case UPDATE_ACTION_PLAN:
      const plansCopy = [...state.plans];
      const foundPlan = plansCopy.findIndex(
        (plan) => plan.id === action.payload.id
      );

      plansCopy[foundPlan] = action.payload;
      return {
        ...state,
        plans: [...plansCopy],
      };

    case UPDATE_ACTIVITY_ITEM:
      const activityHistoryCopy = [...state.planActivity];
      const foundActivity = activityHistoryCopy.findIndex(
        (activity) => activity.id === action.payload.id
      );

      activityHistoryCopy[foundActivity].description =
        action.payload.description;

      return {
        ...state,
        planActivity: [...activityHistoryCopy],
      };

    case DELETE_ACTION_PLAN:
      const actionPlanCopyDelete = [...state.plans];
      const foundDeletePlan = state.plans.findIndex(
        (event) => event.id === action.payload
      );
      if (foundDeletePlan) {
        actionPlanCopyDelete.splice(foundDeletePlan, 1);
      }

      return {
        ...state,
        plans: [...actionPlanCopyDelete],
      };

    case GET_ACTION_PLAN_ACTIVITIES:
      return {
        ...state,
        planActivities: [...action.payload],
      };

    default:
      return state;
  }
}
