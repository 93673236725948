import {
  GET_EMPLOYEE_CATEGORIES,
  DELETE_EMPLOYEE_CATEGORY,
  FETCH_EMPLOYEE_CATEGORY,
  EDIT_EMPLOYEE_CATEGORY,
  ADD_EMPLOYEE_CATEGORY,
  CLEAR_EMPLOYEE_CATEGORY,
  CLEAR_ALL,
  GET_EMPLOYEE_ORG_CATEGORIES,
  GET_EMPLOYEE_USER_CATEGORIES,
  CLEAR_EMPLOYEE_ORG_CATEGORY
} from "constants/actions";

const initialState = {
  employee_category: [],
  employee_org_category: [],
  employee_user_category: [],
  pending: false,
  created: false,
  fetched: false,
};

export default function EmployeeCategories(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_CATEGORIES:
      return {
        ...state,
        employee_category: action.payload,
        pending: false,
        created: false,
      };

    case FETCH_EMPLOYEE_CATEGORY:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
        created: false,
      };

    case EDIT_EMPLOYEE_CATEGORY:
      if (
        state.employee_category.filter((an) => an.id === action.payload.id)[0]
      ) {
        state = {
          ...state,
          employee_category: state.employee_category.filter(
            (rw) => rw.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        created: false,
        employee_category: [...state.employee_category, action.payload],
      };

    case GET_EMPLOYEE_ORG_CATEGORIES:
      return {
        ...state,
        employee_org_category: action.payload,
        pending: false,
        created: false,
        fetched: true,
      };
    
    case CLEAR_EMPLOYEE_ORG_CATEGORY:
      return {
        ...state,
        employee_org_category: [],
        created: false,
      };

    case GET_EMPLOYEE_USER_CATEGORIES:
      return {
        ...state,
        employee_user_category: action.payload,
        pending: false,
        created: false,
      };

    case DELETE_EMPLOYEE_CATEGORY:
      // console.log(action.payload)
      return {
        ...state,
        employee_category: state.employee_category.filter(
          (row) => row.id !== action.payload.id
        ),
      };

    case ADD_EMPLOYEE_CATEGORY:
      // const newState = state.employee_category
      // newState.push(action.payload)
      return {
        ...state,
        created: action.payload,
        employee_category: [...state.employee_category, action.payload],
      };

    case CLEAR_EMPLOYEE_CATEGORY:
      return {
        ...state,
        employee_category: [],
        created: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        employee_category: [],
        employee_org_category: [],
        employee_user_category: [],
        created: false,
      };

    default:
      return state;
  }
}
