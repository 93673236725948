import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  PENDING_EMPLOYEE,
  GET_EMPLOYEES,
  LOAD_EMPLOYEES,
  SEARCH_EMPLOYEES,
  SG_SEARCH_EMPLOYEES,
  DELETE_EMPLOYEE,
  RESET_EMPLOYEE,
  REMOVE_EMPLOYEE,
  PULL_EMPLOYEE,
  FETCH_EMPLOYEE,
  FETCH_USER_EMPLOYEE,
  SG_FETCH_USER_EMPLOYEE,
  UPDATE_EMPLOYEE,
  SG_UPDATE_EMPLOYEE_AUDIT,
  EDIT_EMPLOYEE,
  UPDATE_EMPLOYEE_PIC,
  EDIT_EMPLOYEE_PIC,
  SAVE_EMPLOYEE,
  ADD_EMPLOYEE,
  REGISTER_EMPLOYEE,
  SG_REGISTER_EMPLOYEE_CULTURE,
  CLEAR_EMPLOYEE,
  VALIDATE_EMPLOYEE,
  VALIDATE_EMAIL,
  SG_VALIDATE_EMPLOYEE,
  VALIDATE_ORG_EMP_EMAIL,
  SG_VALIDATE_ORG_EMP_EMAIL,
  SIGNUP_TOKEN,
  SG_SIGNUP_TOKEN,
  CLEAR_VALIDATE_EMPLOYEE,
  GET_SAFE_EMPLOYEE_LIST,
  SG_GET_SAFE_EMPLOYEE_LIST,
  INACTIVE_EMPLOYEES,
  SG_INACTIVE_EMPLOYEES,
  EMPLOYEES_LIST,
  SG_EMPLOYEES_LIST,
  ALL_ERRORS,
} from "constants/actions";



function* loademployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.GET_EMPLOYEES, action.payload);

    yield put({ type: GET_EMPLOYEES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeLoad() {
  yield takeLatest(LOAD_EMPLOYEES, loademployees);
}

function* validateEmployee(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.VALIDATE_EMPLOYEE, action.payload);
    yield put({ type: VALIDATE_EMPLOYEE, payload: json.data });
    yield put({ type: VALIDATE_EMAIL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeValidate() {
  yield takeLatest(SG_VALIDATE_EMPLOYEE, validateEmployee);
}

function* validateEmpOrgValidList(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.VALIDATE_ORG_EMP_EMAIL, action.payload);
    yield put({ type: VALIDATE_ORG_EMP_EMAIL, payload: json.data });
 
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeOrgValidList() {
  yield takeLatest(SG_VALIDATE_ORG_EMP_EMAIL, validateEmpOrgValidList);
}


function* searchEmployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.GET_EMPLOYEES, action.payload);
    yield put({ type: SEARCH_EMPLOYEES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSearch() {
  yield takeLatest(SG_SEARCH_EMPLOYEES, searchEmployees);
}

function* signupToken(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.SIGNUP_TOKEN, action.payload);
    yield put({ type: SIGNUP_TOKEN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSignUpToken() {
  yield takeLatest(SG_SIGNUP_TOKEN, signupToken);
}

function* fetchemployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.FETCH_EMPLOYEE, action.payload);
    yield put({ type: FETCH_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFetch() {
  yield takeLatest(PULL_EMPLOYEE, fetchemployees);
}

function* fetchUsremployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.FETCH_EMPLOYEE, action.payload);
    yield put({ type: FETCH_USER_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeUsrFetch() {
  yield takeLatest(SG_FETCH_USER_EMPLOYEE, fetchUsremployees);
}

function* updateemployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.EDIT_EMPLOYEE, action.payload);
    yield put({ type: EDIT_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeUpdate() {
  yield takeLatest(UPDATE_EMPLOYEE, updateemployees);
}

function* updateEmployeeAudit(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.EDIT_EMPLOYEE_AUDIT, action.payload);
    yield put({ type: EDIT_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeAuditUpdate() {
  yield takeLatest(SG_UPDATE_EMPLOYEE_AUDIT, updateEmployeeAudit);
}

function* updateemployeepic(action) {
  try {
    const json = yield call(api.ADD_EMPLOYEE_PIC, action.payload);
    yield put({ type: EDIT_EMPLOYEE_PIC, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeePicUpdate() {
  yield takeLatest(UPDATE_EMPLOYEE_PIC, updateemployeepic);
}

function* addemployees(action) {
  yield put({ type: RESET_EMPLOYEE });
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.ADD_EMPLOYEE, action.payload);
    yield put({ type: ADD_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeAdd() {
  yield takeLatest(SAVE_EMPLOYEE, addemployees);
}

function* registeremployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.REGISTER_EMPLOYEE, action.payload);
    yield put({ type: ADD_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeRegister() {
  yield takeLatest(REGISTER_EMPLOYEE, registeremployees);
}

function* registerCultureemployee(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.REGISTER_EMPLOYEE_CULTURE, action.payload);
    yield put({ type: ADD_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeCultureRegister() {
  yield takeLatest(SG_REGISTER_EMPLOYEE_CULTURE, registerCultureemployee);
}

function* deleteemployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    yield call(api.DELETE_EMPLOYEE, action.payload);
    yield put({ type: DELETE_EMPLOYEE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeDelete() {
  yield takeLatest(REMOVE_EMPLOYEE, deleteemployees);
}

function* getEmployeeSafeList(action) {
  try {
    const json = yield call(api.GET_SAFE_EMPLOYEE_LIST, action.payload);
    yield put({ type: GET_SAFE_EMPLOYEE_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getEmployeeSafeListLoad() {
  yield takeLatest(SG_GET_SAFE_EMPLOYEE_LIST, getEmployeeSafeList);
}

function* getEmployeeQuickList(action) {
  try {
    const json = yield call(api.GET_EMPLOYEE_SELECT_LIST, action.payload);
    yield put({ type: EMPLOYEES_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getEmployeeQuickListLoad() {
  yield takeLatest(SG_EMPLOYEES_LIST, getEmployeeQuickList);
}

function* getEmployeeInactiveList(action) {
  try {
    const json = yield call(api.GET_INACTIVE_EMPLOYEES, action.payload);
    yield put({ type: INACTIVE_EMPLOYEES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getEmployeeInactiveListLoad() {
  yield takeLatest(SG_INACTIVE_EMPLOYEES, getEmployeeInactiveList);
}

export default function* index() {
  yield all([
    employeeLoad(),
    employeeSearch(),
    employeeSignUpToken(),
    employeeFetch(),
    employeeUsrFetch(),
    employeeUpdate(),
    employeeAuditUpdate(),
    employeePicUpdate(),
    employeeAdd(),
    employeeRegister(),
    employeeCultureRegister(),
    employeeDelete(),
    employeeValidate(),
    employeeOrgValidList(),
    getEmployeeSafeListLoad(),
    getEmployeeQuickListLoad(),
    getEmployeeInactiveListLoad(),

  ]);
}
