import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import DatePick from "../DatePicker";
import useGetOrgData from "hooks/useGetOrgData";

const Phase3 = ({ data, setData }) => {
  const setMember = (d) => {
    const _data = Object.assign({}, data);
    _data.member = d;
    setData(_data);
  };

  const setStatus = (d) => {
    const _data = Object.assign({}, data);
    _data.status = d;
    setData(_data);
  };

  const { get_employees, isOTP, employeeCategories } = useGetOrgData({
    orgCategories: true,
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [employees, setEmployees] = useState();

  useEffect(() => {
    if (get_employees && get_employees?.employees.length > 0 && !employees) {
      let employeeOptions = get_employees?.employees.map((employee) => {
        if (employee.id) {
          const displayText = employee.first_name
            ? `${employee.first_name} ${employee.last_name}`
            : employee.email;

          return {
            key: employee.id,
            text: displayText,
            value: {
              id: employee.id,
              name: displayText,
              member: displayText,
            },
          };
        }
      });

      if (employeeOptions) {
        if (employeeCategories?.length > 1) {
          const filteredEmps = employeeOptions.filter((f) => {
            const empCats = employeeCategories.find(
              (c) => c.employee === f.key
            );

            const validEmployee = empCats?.category?.find(
              (cat) =>
                cat?.name_id === data.primary || cat?.value_id === data.primary
            );
            return !!validEmployee;
          });

          setEmployees(filteredEmps);
        }
      } else {
        setEmployees(employeeOptions);
      }
    }
  }, [employees, get_employees, employeeCategories]);

  const handleDatesSelected = (date, startDate = false) => {
    const _data = Object.assign({}, data);

    if (startDate) {
      setStartDate(date);
      _data.startDate = date;
    } else {
      setEndDate(date);
      _data.endDate = date;
    }

    setData(_data);
  };

  useEffect(() => {
    const _data = Object.assign({}, data);
    _data.startDate = startDate;
    _data.endDate = endDate;

    setData(_data);
  }, []);

  return (
    <Container>
      <Title>Plan details</Title>

      <Label>Timeline</Label>
      <Description>
        Select a timeline for the implementation of this plan? ie. is it a long
        term or short term plan?
      </Description>
      <DatePick
        startDate={startDate}
        endDate={endDate}
        handleDateChange={(date, isStartDate) =>
          handleDatesSelected(date, isStartDate)
        }
      />

      <Label>Who is accountable for this plan?</Label>
      <Description>Tag team members to include them on the plan</Description>
      {employees && (
        <Dropdown
          placeholder="Tag team members"
          fluid
          selection
          search
          multiple
          options={employees}
          value={data.member}
          onChange={(e, t) => setMember(t.value)}
          style={{
            marginRight: 20,
            borderRadius: 100,
            justifyContent: "center",
            paddingLeft: 15,
          }}
        />
      )}

      <Label>Current plan status</Label>
      <Description>What is the status of this plan as of today?</Description>
      <Dropdown
        placeholder="Set a status"
        fluid
        selection
        options={planStatus}
        value={data.status}
        onChange={(e, t) => setStatus(t.value)}
        style={{
          marginRight: 20,
          borderRadius: 100,
          justifyContent: "center",
        }}
      />
    </Container>
  );
};

export default Phase3;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 55px;
  padding-left: 55px;
  padding-bottom: 150px;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;

  margin-top: 20px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  color: #7e7e7e;
`;

const templateOptions = [
  {
    key: "Michael Bawol",
    text: "Michael Bawol",
    value: { id: 0, name: "Michael Bawol" },
  },
];

const statusOptions = [
  {
    key: "Planned",
    text: "Planned",
    value: 0,
  },
  {
    key: "In Progress",
    text: "In Progress",
    value: 1,
  },
  {
    key: "Action Required",
    text: "Action Required",
    value: 2,
  },
  {
    key: "On Hold",
    text: "On Hold",
    value: 3,
  },
  {
    key: "Completed",
    text: "Completed",
    value: 4,
  },
];

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  // {
  //   value: 4,
  //   text: "On Hold",
  //   key: "On Hold",
  // },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
  {
    value: 11,
    text: "Archived",
    key: "Archived",
  },
  {
    value: 99,
    text: "For Deletion",
    key: "For Deletion",
  },
];
