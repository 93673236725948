import React from "react";
import styled from "styled-components";

import {
  Icon,
  Table,
} from "semantic-ui-react";

import { Link } from 'react-router-dom';

const TableList = ({members, employee_categories}) => {
// {data.team_name ? data.team_name : data.department_name}
// const [primaryList, setPrimaryList] = useState([]);

// useEffect(() => {
//   setPrimaryList([]);
//   if (employeeCategory?.id) {
//     if (employeeCategory?.category?.length > 0) {
//       setPrimaryList(
//         employeeCategory?.category
//           .filter((ec) => ec.priority === "primary")
//           .map((fl) => fl.value)
//       );
//     }
//   }
// }, [employeeCategory]);
  return (
    <>
      <div
        style={{
          marginTop: 30,
        }}
      >
        {members && members.length > 0 && (
          <Table singleLine selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Updated</Table.HeaderCell>
                <Table.HeaderCell>More</Table.HeaderCell>
              </Table.Row>

            </Table.Header>
            <Table.Body>
              {members &&
                members.map((item, i) => {
                  if (item.email) {
                    return (<Table.Row key={i}>
                      <Table.Cell>{item?.first_name
                        ? item?.first_name + " " + item?.last_name
                        : " "}</Table.Cell>
                      <Table.Cell>{item?.email}</Table.Cell>
                      <Table.Cell>{item?.updated_at}</Table.Cell>
                      <Table.Cell>
                      <Link to={`/core/members/team/member/${item.id}`}>
                         <Linked>
                            <Icon name="angle double right" />
                          </Linked>
                      </Link>
                         
                        
                        </Table.Cell>
                    </Table.Row>
                    )
                  } else {
                    return <Table.Row key={i}>
                      <Table.Cell>NA</Table.Cell>
                      <Table.Cell>NA</Table.Cell>
                      <Table.Cell>NA</Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>;
                  }
                })}
            </Table.Body>
          </Table>
        )}
      </div>

      
    </>
  );
};

export default TableList;

const Linked = styled.span`
  text-decoration: none;
  cursor: pointer;
`;
