import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Select } from "semantic-ui-react";
import Chart from "react-apexcharts";
import Plan from "./Plan";

const Report = ({ back, data }) => {
	return (
		<Container>
			<Back onClick={() => back()}>
				<Icon name="chevron left" />
				Back
			</Back>
			<Title>Report</Title>
			<div style={{ display: "flex" }}>
				<div style={{ width: "50%", padding: "0 30px 0 0" }}>
					<Factor>
						{data.metric.type}: {data.metric.title}
					</Factor>
					<Question>{data.question}</Question>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<SectionTitle>
							Outcome measures by school year
						</SectionTitle>
						<Select
							placeholder="Select timeframe"
							options={anchor_list}
							defaultValue={anchor_list[0].value}
						/>
					</div>
					<ChartArea>
						<Chart
							options={data3.options}
							series={data3.series}
							type="bar"
							width={"100%"}
						/>
					</ChartArea>

					<Change>
						<div>
							<SectionTitle>Selected time frame</SectionTitle>
							<Description>
								The time frame you selected for a change in this
								area.{" "}
							</Description>
						</div>
						<Label color={"#E13C40"}>{data.time}</Label>
					</Change>

					<Change>
						<div>
							<SectionTitle>Change over time frame</SectionTitle>
							<Description>
								The overall change in scores in this area was
								15%. This is a very large difference.
							</Description>
						</div>
						<Label color={"#E13C40"}>+15%</Label>
					</Change>

					<Change>
						<div>
							<SectionTitle>Group differences</SectionTitle>
							<Description>
								There was a significant group difference between
								1st years and 4th years in response to this
								particular intervention
							</Description>
						</div>
						<Label color={"#E13C40"}>Large</Label>
					</Change>
				</div>

				<Plan />
			</div>
		</Container>
	);
};

export default Report;

const Container = styled.div`
	width: 100%;
	padding: 20px;
	margin-top: 10px;
	font-family: "Barlow", sans-serif;
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 50px;
`;

const ChartArea = styled.div`
	width: 100%;
	height: 400px;
`;

const Factor = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 5px;
`;

const Question = styled.div`
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 30px;
`;

const SectionTitle = styled.div`
	font-size: 14px;
	font-weight: bold;
`;

const Change = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;

const Description = styled.div`
	width: 80%;
`;

const Label = styled.div`
	background-color: ${(props) => props.color};
	padding: 0px 20px;
	border-radius: 10px;
	font-size: 12px;
	color: white;
	margin-left: 5px;
`;

const Back = styled.div`
	cursor: pointer;
	margin-bottom: 10px;
`;

const data3 = {
	series: [
		{
			name: "Before",
			data: [8.2, 8.5, 9, 7.6],
		},
		{
			name: "After",
			data: [8.6, 8.0, 7.3, 8.5],
		},
	],
	options: {
		chart: {
			type: "line",
			toolbar: {
				show: false,
			},
			sparkline: {
				enabled: false,
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["orange","grey"],
		fill: {
			type: ["solid", "pattern"],
			opacity: 0.6,
			pattern: {
				style: [
					"verticalLines",
					"slantedLines",
					"verticalLines",
					"horizontalLines",
				], // string or array of strings
			},
		},
		grid: {
			padding: {
				left: 10,
				right: 10,
				bottom: 20,
				top: 10,
			},
		},

		dataLabels: {
			enabled: true,
			style: {
				colors: ["white", "black"],
			},
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			categories: [
				"1st year",
				"2nd year",
				"3rd year",
				"4th year",
				"4+ year",
			],
		},
		yaxis: {
			title: {
				text: "Score",
			},
			min: 0,
			max: 10,
			decimalsInFloat: 0,
		},
	},
};

const anchor_list = [
	{ key: 0, value: "School year", text: "School year" },
	{ key: 1, value: "Position", text: "Position" },
];
