import React, { useState } from "react";
import { Header, Message, Modal } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled, { keyframes } from "styled-components";

import {
  SG_ADD_PERSONALITY_PARTICIPANT,
  REMOVE_EMPLOYEE,
} from "constants/actions";

const CancelButton = styled.div`
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
`;
const P = styled.p`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;

const Button = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-left: 10px;
`;

const DisabledButton = styled(Button)`
  height: 45px;
  border-radius: 8px;
  background-color: #d0d0d0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-left: 10px;
`;

const SubText = styled.div`
  font-size: 13px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  margin-bottom: 30px;
`;

function AddMember(props) {
  const apiDispatch = useDispatch();
  const {  organization } = props;

  const [open, setOpen] = useState(false);

  const memberList = useSelector((state) => state.employees.employees).map(
    (item, i) => ({
      key: item.id,
      text: item.first_name
        ? item.first_name + " " + item.last_name
        : item.email,
      value: item.id,
    }),
    shallowEqual
  );

  const [selectedMembers, setSelectedMembers] = useState([]);
  const { get_personality_participants } = useSelector(
    (state) => ({
      get_personality_participants: state.personalityparticipants.personality_participants,
    }),
    shallowEqual
  );

  const _ParticipantList = [];
  get_personality_participants.map((p) => _ParticipantList.push(p.employee));

  const _ParticipantNonTeamList = [];
  if (get_personality_participants) {
    memberList
      .filter((o1) => !_ParticipantList.includes(o1.key))
      .map((p) => _ParticipantNonTeamList.push(p));
  }

  const save = () => {
    selectedMembers.map((team) =>
      apiDispatch({
        type: SG_ADD_PERSONALITY_PARTICIPANT,
        payload: {
          organization: organization,
          employee: team,
        },
      })
    );

    setSelectedMembers([]);
    setOpen(false);
  };

  const close = () => {
    setOpen(false);
  };

  const onChange = (e, data) => {
    setSelectedMembers(data.value);
  };

  return (
    <Modal
      onClose={() => close()}
      onOpen={() => setOpen(true)}
      open={open}
      size={"tiny"}
      closeIcon
      trigger={props.trigger}
    >
      <Modal.Header>
        Invite Members to the Personality Assessment Tool
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <P>
            Select one or multiple people to use nuLogic's personality assessment.
          </P>

          <Dropdown
            placeholder="Select Member(s)"
            fluid
            multiple
            search
            selection
            defaultValue={selectedMembers}
            onChange={onChange}
            options={_ParticipantNonTeamList}
            style={{ marginBottom: 30 }}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>

          {selectedMembers.length > 0 ? (
            <Button onClick={save}>Invite</Button>
          ) : (
            <DisabledButton>Invite</DisabledButton>
          )}
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default AddMember;
