import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";

import Insights from "./Insights/Top3";
import LoadingSpinner from "../../components/LoadingSpinner";
import Empty from "./Empty";
import { SURVEY_INFO } from "constants/surveys";

import csv from "./generate_csv";
import { Data_Generator } from "./Analytics/calculations";
import { GET_CULTURE_AUDIT_REPORTS } from "constants/actions";
import Heatmap from "./Heatmap";
import NavBar2 from "reports/Audit/NavBar2";

import Grades from "./Grades";

import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
} from "constants/actions";
import useGetSurveyData from "hooks/useGetSurveyData";
import Filter from "./Analytics/Filter";

const Overview = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  let testing = searchParams.get("testing");
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState();
  const [recentResponses, setRecentResponses] = useState();
  const [categories, setCategories] = useState();
  const [questions, setQuestions] = useState();
  const [EDIQues, setEDIQues] = useState(0);
  const [category_access, setAccess] = useState([]);
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

  const { nav_state } = useSelector(
    (state) => ({
      nav_state: state.audit.nav,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selected === "") {
      setSelected(null);
    }
  }, [selected]);

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const {
    auth,
    surveyStructure,
    selectedOrg,
    ediResponses,
    surveyQuestions,
    get_organizations,
    get_employee,
    get_employee_categories,
  } = useSelector(
    (state) => ({
      errors: state.errors,
      auth: state.auth,
      surveyQuestions: state.surveyquestions.survey_questions,
      surveyStructure: state.surveystructure.survey_structure,
      selectedOrg: state.selectedOrg,
      ediResponses: state.debrief_schedule.culture_audit_reports,
      get_organizations: state.organizations,
      get_employee: state.employees,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_employee_categories.length > 0) {
      setAccess(
        get_employee_categories.find(
          (f) => f.employee === Number(auth.employee_id)
        ).category
      );
    }
  }, [auth, get_employee_categories]);

  useEffect(() => {
    //Determine which survey is being used in the org data
    if (get_organizations && organizationId) {
      const organization = get_organizations[organizationId];
      const ediQues =
        (organization?.services_enabled &&
          organization?.services_enabled.filter((sc) => {
            return sc?.name === SURVEY_INFO.edi?.name;
          })?.[0]?.question_sort_order) ||
        0;
      if (ediQues) {
        setEDIQues(ediQues);
      }
    }
  }, [get_organizations, organizationId]);

  useEffect(() => {
    if (
      !recentResponses &&
      ediResponses.response &&
      ediResponses.response.length > 0
    ) {
      let responseList = [];

      const dataSet = ediResponses.response.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });

      dataSet.forEach((responseSet) => {
        if (responseSet.responses.length > 0) {
          const exists = responseList.findIndex(
            (response) => response.name === responseSet.name
          );
          if (exists === -1) {
            responseList.push(responseSet);
          }
          if (responseList.length === 0) {
            responseList.push(responseSet);
          }
        }
      });
      setRecentResponses(responseList);
      // This generates a csv of the raw data
      // window.open(csv(responseList,surveyStructure))
      setLoading(false);
    }

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [ediResponses, recentResponses]);

  const generate_test_data_set = () => {
    setLoading(true);
    const DataGenerated = Data_Generator(100, 5, surveyStructure[0], questions);
    setRecentResponses(null);

    dispatch({
      type: GET_CULTURE_AUDIT_REPORTS,
      payload: { response: DataGenerated },
    });

    setTimeout(() => setLoading(false), 100);
  };

  useEffect(() => {
    if (surveyStructure[0] && surveyQuestions[0]) {
      if (EDIQues > 0) {
        const SurveyQues = surveyQuestions.find((sq) => {
          return sq.sort_order === EDIQues;
        });

        setQuestions(SurveyQues.questions);
        setCategories(surveyStructure[0]?.categories);
      } else {
        setQuestions(surveyQuestions[0].questions);
        setCategories(surveyStructure[0]?.categories);
      }
    }
  }, [surveyStructure, surveyQuestions]);

  useEffect(() => {
    if (organizationId && !ediResponses[0] && !recentResponses) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=7&status=2`,
      });

      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: "audit-type-id=4",
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (Number(ambassadorId) > 0 && selectedOrg) {
      setOrganizationId(selectedOrg.organization?.id);
    } else {
      setOrganizationId(Number(auth.organization_id));
    }
  }, [selectedOrg, ambassadorId, auth]);

  /**
   *
   * @param {string} factor
   * returns the index for the given factor
   */
  const getFactorIndex = (factor) => {
    if (questions) {
      return questions?.factors?.findIndex((factorObj) => {
        return factorObj.title.toLowerCase() === factor.toLowerCase();
      });
    } else if (surveyQuestions[0]) {
      return surveyQuestions[0]?.questions?.factors?.findIndex((factorObj) => {
        return factorObj.title.toLowerCase() === factor.toLowerCase();
      });
    }
  };

  const reverseScoring = {
    0: 10,
    1: 9,
    2: 8,
    3: 7,
    4: 6,
    5: 5,
    6: 4,
    7: 3,
    8: 2,
    9: 1,
    10: 0,
  };

  const getGrade = (score) => {
    if (score > 96) {
      return "A+";
    }
    if (score > 92) {
      return "A";
    }
    if (score > 89) {
      return "A-";
    }
    if (score > 86) {
      return "B+";
    }
    if (score > 82) {
      return "B";
    }
    if (score > 79) {
      return "B-";
    }
    if (score > 76) {
      return "C+";
    }
    if (score > 72) {
      return "C";
    }
    if (score > 69) {
      return "C-";
    }
    if (score > 66) {
      return "D+";
    }
    if (score > 60) {
      return "D";
    }

    return "F";
  };

  const getAggregatedScore = (responseObj, factor) => {
    const questionObj = questions?.factors[factor]?.questions.find(
      (question) => question.id === responseObj.ques_order
    );

    return questionObj?.reverse
      ? reverseScoring[responseObj.response]
      : responseObj.response;
  };

  /**
   * Gets the avg for the specified factor.
   * This can be extended to get previous responses (for change)
   * @param {number} factor current factor to get avg.
   *
   * @returns the factor average (for all questions) used on factor averages.
   */
  const getFactorAverage = (factorIndex) => {
    const dataSet = recentResponses;
    if (dataSet) {
      let currentScores = [];
      dataSet.forEach((responseGroup) => {
        responseGroup.responses?.forEach((responseSet) => {
          responseSet.response.questions.forEach((questionResponses) => {
            if (factorIndex === questionResponses.factor) {
              const score = getAggregatedScore(questionResponses, factorIndex);
              currentScores.push(score);
            }
          });
        });
      });

      if (currentScores.length > 0) {
        return getAvg(currentScores);
      }
    }
    return 0;
  };

  const getAvg = (currentScores) => {
    const sum = currentScores.reduce((a, b) => a + b, 0);
    const avg = sum / currentScores.length || 0;

    return avg * 10;
  };

  const navigateTo = (l) => {
    navigate(`?name=${l}`);
  };

  // TODO: Look at using useMemo, or useCallback for expensive calculations.
  const getDeiAvg = (sections) => {
    let factorAvg = [];
    sections.forEach((section) => {
      factorAvg.push(getFactorAverage(getFactorIndex(section.title)));
    });

    return (getAvg(factorAvg) / 10).toFixed(1);
  };

  // TODO: get formula for label
  const getScoreLabel = (deiAvg) => {
    if (deiAvg > 80) {
      return "Great";
    }
    if (deiAvg > 70) {
      return "Good";
    }
    if (deiAvg > 60) {
      return "Fair";
    }
    return "Poor";
  };

  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );

  // This should eventually be pulled from API questions (factors)
  const sections =
    (questions &&
      questions.factors.map((f) => {
        return {
          title: f.title,
          icon: () => <Icon name={f.icon} style={{ marginRight: 10 }} />,
          navLink: f.title,
        };
      }, [])) ||
    surveyQuestions[0]?.questions.factors.map((f) => {
      return {
        title: f.title,
        icon: () => <Icon name={f.icon} style={{ marginRight: 10 }} />,
        navLink: f.title,
      };
    }, []);

  if (
    loading ||
    !surveyStructure[0] ||
    !surveyQuestions[0] ||
    !sections ||
    !categories
  ) {
    return <LoadingSpinner />;
  }

  const hasResponses = (responses) => {
    const hasResponse = responses.response?.filter(
      (responseGroup) => responseGroup.responses.length > 0
    );

    return hasResponse?.length > 0;
  };

  if (
    (ediResponses?.response && ediResponses?.response?.length === 0) ||
    !hasResponses(ediResponses)
  ) {
    return (
      <>
        {testing ? (
          <div>
            <Test onClick={() => generate_test_data_set()} id={"test"}>
              Generate Data
            </Test>
          </div>
        ) : (
          <Empty />
        )}
      </>
    );
  }

  return (
    <>
      {testing ? (
        <div>
          <Test onClick={() => generate_test_data_set()} id={"test"}>
            Generate Data
          </Test>
        </div>
      ) : (
        ""
      )}
      <Container>
        <Contain>
          <Card>
            <H3>nuLogic Score</H3>
            <div style={{ position: "relative" }}>
              <Chart
                options={data.options}
                series={[getDeiAvg(sections)]}
                type="radialBar"
                height={250}
              />
              <Values>
                <Stat>{getDeiAvg(sections)}%</Stat>
              </Values>
            </div>
            <TextArea>
              <Text>
                Your nuLogic grade is{" "}
                <Change> {getGrade(getDeiAvg(sections))}</Change>
              </Text>
            </TextArea>
          </Card>

          <Grid>
            {sections.map((section) => {
              return (
                <Area>
                  {section.icon()}
                  <div>
                    <Title>{section.title}</Title>
                    <Score>
                      Grade:{" "}
                      {getGrade(getFactorAverage(getFactorIndex(section.title)))}{" "}
                      (
                      {Math.floor(
                        getFactorAverage(getFactorIndex(section.title))
                      )}
                      %)
                    </Score>
                    <Linked onClick={() => navigateTo(`${section.navLink}`)}>
                      See Analytics
                    </Linked>
                  </div>
                </Area>
              );
            })}
          </Grid>
        </Contain>
        <MapContainer>
          {/* 
          <div
            style={{
              width: 200,
              marginLeft: 30,
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <Filter
              structure={surveyStructure}
              selected={selected}
              ignoreString
              setSelected={(v) => setSelected(v)}
            />
          </div> */}
          
          <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Heatmap
              questions={questions}
              categories={surveyStructure[0]}
              rule={WhiteLabel?.rule}
              data2={ediResponses?.response}
              anchor={selected}
              color={WhiteLabel?.highlight}
            />
          </div>
        </MapContainer>
      </Container>
    </>
  );
};

export default Overview;

const MapContainer = styled.div`
  width:100%;
  padding:20px;
`;

const NavBarWrapper = styled.div`
  padding-top: 50px;
`;

const Container = styled.div`
  font-family: "Barlow", sans-serif;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Contain = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width:100%;
  display:flex;
   align-items: center;
  flex-wrap: wrap;
  margin-top:20px;
`

const Grid = styled.div`
  width: calc(100% - 300px);
  display: flex;
  flex-wrap: wrap;
  height: 200px;
`;

const Area = styled.div`
  width: 33%;
  height: 50px;
  display: flex;
`;

const Score = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

const LoadingWrapper = styled.div`
  padding-top: 30px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Title2 = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const H3 = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const Change = styled.span`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  color: #ab710a;
  margin-left: 3px;
`;

const Card = styled.div`
  padding: 30px 10px;
  position: relative;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
`;

const Test = styled.button`
  margin-top: 10px;
`;

const Values = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Stat = styled.div`
  font-size: 36px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
`;

const TextArea = styled.div`
  width: 100%;
  padding: 10px;
`;
const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Linked = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const data = {
  series: [86],
  options: {
    chart: {
      height: 250,
      type: "radialBar",
    },
    grid: {
      padding: {
        right: 0,
        top: 0,
        left: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "65%",
        },
        track: {
          background: "white",
        },
        dataLabels: {
          show: false,
          name: { show: false },
          value: { show: false },
        },
      },
    },
    fill: {
      colors: ["#2d50e2"],
    },
  },
};
