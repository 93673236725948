import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Loader,
  Checkbox,
  Message,
  Dimmer,
  Icon,
  Divider,
} from "semantic-ui-react";

import { useSearchParams } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  SG_REGISTER_USER_TOKEN,
  SG_VALIDATE_TOKEN,
  CLEAR_PASSWORD_DATA,
  CLEAR_ERRORS,
} from "constants/actions";

import UserErrorMessages from "./UserErrorMessages";

import styled from "styled-components";
import Input from "./Input";
import GoogleAuth from "./GoogleAuth";

import { DomainContext } from "layouts/LayoutContext";

import MandatoryLinks from "./MandatoryLinks";

const RegisterToken = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Domain = useContext(DomainContext);

  let [searchParams, setSearchParams] = useSearchParams();
  let logo = searchParams.get("org");

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [message, setMessage] = useState(false);

  const [msgResp, setMsgResp] = useState(false);
  const [success, setSuccess] = useState(false);

  const [validNextSteps, setValidNextSteps] = useState(true);

  const [valueChanged, setValueChanged] = useState(0);
  const [formStep, setFormStep] = useState(0);
  const [formValue, setFormValue] = useState({});

  const [valueChanged2, setValueChanged2] = useState(0);
  const [formErrors2, setFormErrors2] = useState({});
  const [formValue2, setFormValue2] = useState({});
  const [pristine2, setPristine2] = useState(true);

  const { token } = useParams();

  const { get_auth, get_token_validation } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_token_validation: state.validateEmail.validateToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_auth?.token) {
      navigate("/app/dashboard");
    }
    // }, 5000);
    // return () => clearTimeout(timer1);
  }, [navigate, get_auth]);

  const onSubmit = () => {
    if (formErrors2?.errors > 0) {
      setPristine2(false);
      return;
    }

    dispatch({
      type: CLEAR_ERRORS,
    });

    dispatch({
      type: SG_REGISTER_USER_TOKEN,
      payload: {
        first_name: formValue2?.first_name,
        last_name: formValue2?.last_name,
        password: formValue2?.password,
        password2: formValue2?.password2,
        token: token,
      },
    });
    setPristine(true);
  };

  useEffect(() => {
    // get_token_validation
    const _formValue2 = {};
    _formValue2["first_name"] = get_token_validation?.first_name;
    _formValue2["last_name"] = get_token_validation?.last_name;
    setFormValue2(_formValue2);
  }, [get_token_validation]);

  useEffect(() => {
    setMsgResp(false);

    if (get_auth.register_token?.message) {
      setMessage(false);
      setMsgResp(get_auth.register_token?.message);
    }
  }, [get_auth]);

  useEffect(() => {
    setMsgResp(false);
    setMessage(false);
    setValidNextSteps(true);
    if (get_token_validation?.message) {
      setMessage(get_token_validation?.message);
    }
    if (get_token_validation?.next_steps < 0) {
      setValidNextSteps(false);
    }
  }, [get_token_validation]);

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;
    if (!token) {
      ErrorList["token"] = {
        error: true,
        message: "no token found",
      };
      totalErrors += 1;
      ErrorList["errors"] = totalErrors;
      setFormErrors(ErrorList);
    }
  }, [token]);

  useEffect(() => {
    if (token?.length > 0) {
      dispatch({
        type: SG_VALIDATE_TOKEN,
        payload: { token: token },
      });
    }
  }, [token, dispatch]);

  function ChangeFormValue(name, value) {
    // console.log(name, value)
    const _FormValue = formValue;
    _FormValue[name] = value;
    setFormValue(_FormValue);
    setValueChanged(Math.random);
  }

  function ChangeFormValue2(name, value) {
    // console.log(name, value)
    const _FormValue2 = formValue2;
    _FormValue2[name] = value;
    setFormValue2(_FormValue2);
    setValueChanged2(Math.random);
  }

  const onOrgSubmit = () => {
    if (!valueChanged) {
      setPristine(false);
      return;
    }
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    setFormStep(1);
    // setPristine(true);
  };

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;
    // console.log("error checking form 2")
    if (!formValue2?.first_name) {
      // console.log("error checking form 2 first name")
      ErrorList["first_name"] = {
        error: true,
        message: "Please enter first name",
      };
      totalErrors += 1;
    }
    if (!formValue2?.last_name) {
      ErrorList["last_name"] = {
        error: true,
        message: "Please enter last name",
      };
      totalErrors += 1;
    }

    if (!formValue2?.password) {
      ErrorList["password"] = {
        error: true,
        message: "Please enter password of min 8 characters",
      };
      totalErrors += 1;
    }
    // The min length of password is 8 characters
    if (formValue2?.password) {
      if (formValue2?.password.length < 8) {
        ErrorList["password"] = {
          error: true,
          message: "Please enter password of min 8 characters",
        };
        totalErrors += 1;
      }
    }

    if (!formValue2?.password2) {
      ErrorList["password2"] = {
        error: true,
        message: "Please re enter the same password",
      };
      totalErrors += 1;
    }
    if (formValue2?.password && formValue2?.password2) {
      if (formValue2?.password !== formValue2?.password2) {
        ErrorList["password2"] = {
          error: true,
          message: "Passwords do not match",
        };
        totalErrors += 1;
      }
    }

    ErrorList["errors"] = totalErrors;

    setFormErrors2(ErrorList);
  }, [formValue2, pristine2, valueChanged2]);

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;

    if (!formValue?.terms) {
      ErrorList["terms"] = {
        error: true,
        message: "Please check terms",
      };
      totalErrors += 1;
    }
    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [formValue, pristine, valueChanged]);

  // console.log(get_token_validation);

  return (
    <Container>
      <FormContainer>
        <Box>
          <IMG src={Domain.fullImage} />
          {/* <IMG src={(domain == "nu.innerlogic.com" || domain =="nulogic.ca") ? NuWave : Logo} /> */}

          <Title>Create Login</Title>
          <Description>
            You have been invited to create a login, please create your password
          </Description>
          {/* {registerError && (
              <Message
                error
                onDismiss={() =>  setRegisterError(false)}
                // header={props.message}
                content={registerError}
              />
            )} */}
          <UserErrorMessages />
          {success && <Loader active inline="centered" />}

          {!token && <Error>No Token found</Error>}
          {msgResp && (
            <div>
              <Loader />
              <Message
                success
                // header='Action Forbidden'
                content={msgResp}
              />
            </div>
          )}
          <div>
            {message && (
              <Message
                info
                // header='Action Forbidden'
                content={message}
              />
            )}
          </div>

          {formErrors.length > 0 && !pristine && (
            <Message
              negative
              //   onDismiss={clearErrors}
              header="We need these details!"
              list={formErrors}
            />
          )}
          {validNextSteps ? (
            <EnabledContainer>
              {formStep === 0 && (
                <Box2>
                  {/* close */}

                  {formErrors.terms?.error && !pristine && (
                    <Message
                      error
                      // header={props.message}
                      content={formErrors.terms?.message}
                    />
                  )}

                  <Checkbox
                    onChange={() => ChangeFormValue("terms", !formValue?.terms)}
                    checked={formValue?.terms}
                    name="terms"
                    label={{
                      children: (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"https://portal.nulogic.co/terms.html"}
                        >
                          I agree to the terms and conditions
                        </a>
                      ),
                    }}
                    style={{ borderColor: "black", marginBottom: 20 }}
                  />

                  <Button type="submit" top={true} onClick={onOrgSubmit}>
                    Next
                  </Button>
                </Box2>
              )}
              {formStep === 1 && (
                <Box2>
                  <CloseBox onClick={() => setFormStep(0)}>
                    <Icon name="chevron left" /> Go back
                  </CloseBox>

                  <Button
                    type="submit"
                    top={true}
                    onClick={() => setFormStep(2)}
                  >
                    Register with Email
                  </Button>

                  {Domain.showGoogleSso && (
                    <>
                      <Divider horizontal>Or</Divider>

                      <GoogleAuth signUpToken={token} />
                    </>
                  )}
                </Box2>
              )}
              {formStep === 2 && (
                <>
                  <CloseBox onClick={() => setFormStep(0)}>
                    <Icon name="chevron left" /> Back
                  </CloseBox>

                  <Dimmer.Dimmable blurring dimmed={success ? true : false}>
                    <Input
                      placeholder="First Name"
                      name="first_name"
                      type="text"
                      id="first_name"
                      defaultValue={formValue2?.first_name}
                      onChange={(e) => ChangeFormValue2("first_name", e)}
                    />

                    {formErrors2?.first_name?.error && !pristine2 && (
                      <ErrorBanner>
                        {formErrors2?.first_name?.message}
                      </ErrorBanner>
                    )}

                    <Input
                      placeholder="Last Name"
                      name="last_name"
                      type="text"
                      id="last_name"
                      defaultValue={formValue2?.last_name}
                      onChange={(e) => ChangeFormValue2("last_name", e)}
                    />

                    {formErrors2?.last_name?.error && !pristine2 && (
                      <ErrorBanner>
                        {formErrors2?.last_name?.message}
                      </ErrorBanner>
                    )}

                    <Input
                      placeholder="Password"
                      name="password"
                      type="password"
                      id="password"
                      defaultValue={formValue2?.password}
                      onChange={(e) => ChangeFormValue2("password", e)}
                    />

                    {formErrors2?.password?.error && !pristine2 && (
                      <ErrorBanner>
                        {formErrors2?.password?.message}
                      </ErrorBanner>
                    )}
                    <Input
                      placeholder="Repeat Password"
                      name="password2"
                      type="password"
                      id="password2"
                      defaultValue={formValue2?.password2}
                      onChange={(e) => ChangeFormValue2("password2", e)}
                    />

                    {formErrors2?.password2?.error && !pristine2 && (
                      <ErrorBanner>
                        {formErrors2?.password2?.message}
                      </ErrorBanner>
                    )}

                    {token && (
                      <Button onClick={onSubmit}>Get Started Now</Button>
                    )}
                  </Dimmer.Dimmable>
                </>
              )}
            </EnabledContainer>
          ) : (
            ""
          )}
          {!token && (
            <Message
              error
              // header='Action Forbidden'
              content="Invalid URL"
            />
          )}

          <Bottom>
            <div>
              <Link to="/account/login">Already registered? Sign in</Link>
            </div>
          </Bottom>

          <MandatoryLinks Domain={Domain} />
        </Box>
      </FormContainer>
    </Container>
  );
};

export default RegisterToken;

const Title = styled.span`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 10px;
  font-family: "Barlow", sans-serif;
  margin-top: 30px;
`;
const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;
const IMG = styled.img`
  width: 160px;
`;

const CloseBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-bottom: 20px;
  color: #4634eb;
  font-size: 14px;
`;

const ErrorBanner = styled.div`
  color: red;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 1px;
  width: 100%;
`;

const Error = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: -20px;
  line-height: 1.4;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

const EnabledContainer = styled.div``;
const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Box2 = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Red Hat Display", sans-serif;
  justify-content: space-between;

  a {
    color: #6a00ff;
    font-weight: bold;
  }
`;

const FormContainer = styled.div`
  width: 500px;
  height: 100%;
  padding: 100px 30px 0 30px;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 20px;
  }
`;

const Description = styled.div`
  font-family: "Barlow", sans-serif;
  margin-bottom: 20px;
`;

const RowsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
`;
