import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fetch_data from "./fetch_data";
import Anchor from "reports/Audit/Anchor";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  get_sentiment_by_anchor,
  get_culture_factor_sentiment,
  getStructuredFeedback,
  getStructuredFeedbackFactor,
  getConversations,
  // getConversationsByFactor
} from "./extract_feedback";
import Chart from "react-apexcharts";
import LoadingCard from "./LoadingCard";
import ChartLoading from "./chartLoading";
import FeedbackCard from "./FeedbackCard";
import ChatModal from "./ChatModal";
import LabeledToggle from "components/LabeledToggle";
import LabeledDropdown from "components/LabeledDropdown";
import IconContainer from "./IconContainer";
import { ILIcon, LightningIcon, SummaryIcon, CloseIcon } from "./Icons";
import SearchBar from "components/SearchBar";
import BasicDropdown from "./BasicDropdown";

import { SG_POST_ML_SERVER_V1, SET_DATA_ACNHOR } from "constants/actions";
import Loading from "reports/AI/Loading";

const sortOptions = [
  {
    name: "Feedback Quality (Ascending)",
    value: 0,
  },
  {
    name: "Feedback Quality (Descending)",
    value: 1,
  },
  {
    name: "HR Flags (Ascending)",
    value: 2,
  },
  {
    name: "HR Flags (Descending)",
    value: 3,
  },
  {
    name: "Sentiment (Ascending)",
    value: 4,
  },
  {
    name: "Sentiment (Descending)",
    value: 5,
  },
];

const average = (data) => {
  return data.reduce((a, b) => a + b, 0) / data.length;
};

const get_sentiment_words = (score) => {
  if (score > 0.7) {
    return "Very Positive";
  }
  if (score > 0.5) {
    return "Moderately Positive";
  }
  if (score > 0.2) {
    return "Slightly Positive";
  }
  if (score > -0.2) {
    return "Neutral";
  }
  if (score > -0.5) {
    return "Slightly Negative";
  }
  if (score > -0.7) {
    return "Moderately Negative";
  }

  return "Very Negative";
};

const get_sentiment_labels = (score) => {
  if (score > 0.7) {
    return "Positive";
  }
  if (score > 0.2) {
    return "";
  }
  if (score > -0.2) {
    return "Neutral";
  }
  if (score > -0.7) {
    return "";
  }

  return "Negative";
};

const get_average_sentiment = (data) => {
  let average_sentiment = [];
  if (data.length > 0) {
    data.map((f) => {
      average_sentiment.push(f.conversation_analysis.sentiment[0].compound);
    });
    return average(average_sentiment);
  }
};

const get_total_flags = (data) => {
  let flag_count = 0;
  if (data.length > 0) {
    data.map((f) => {
      flag_count += f.conversation_analysis.hr_flag[0];
    });
    return flag_count;
  }
};

const get_average_quality = (data) => {
  let average_quality = [];
  if (data?.length > 0) {
    data.map((f) => {
      average_quality.push(f.conversation_analysis.quality[0]);
    });
    return average(average_quality);
  }
};

const sortByHrFlag = (direction, data) => {
  if (direction === "asc") {
    return data.sort((a, b) => {
      return (
        Math.round(get_total_flags(a.ml_feedback.feedback)) -
        Math.round(get_total_flags(b.ml_feedback.feedback))
      );
    });
  } else {
    return data.sort((a, b) => {
      return (
        Math.round(get_total_flags(b.ml_feedback.feedback)) -
        Math.round(get_total_flags(a.ml_feedback.feedback))
      );
    });
  }
};

const sortByQuality = (direction, data) => {
  if (direction === "asc") {
    return data.sort((a, b) => {
      return (
        Math.round(get_average_quality(a.ml_feedback.feedback)) -
        Math.round(get_average_quality(b.ml_feedback.feedback))
      );
    });
  } else {
    return data.sort((a, b) => {
      return (
        Math.round(get_average_quality(b.ml_feedback.feedback)) -
        Math.round(get_average_quality(a.ml_feedback.feedback))
      );
    });
  }
};

const sortBySentiment = (direction, data) => {

  if (direction === "asc") {
    return data.sort((a, b) => {
      return (
        get_average_sentiment(a.ml_feedback.feedback) -
        get_average_sentiment(b.ml_feedback.feedback)
      );
    });
  } else {
    return data.sort((a, b) => {
      return (
        get_average_sentiment(b.ml_feedback.feedback) -
        get_average_sentiment(a.ml_feedback.feedback)
      );
    });
  }
};

const Feedback = () => {
  const dispatch = useDispatch();
  const [chart, setChart] = useState(data3.options);
  const [series, setData] = useState(data3.series);
  const [loading, setLoading] = useState(true);
  const [dataSelected, setDataSelected] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState();
  const [selectedOption, setSelectedOption] = useState("Anchor");
  const [measurement, setMeasurement] = useState("Sentiment analysis");
  const [clearClicked, setClearClicked] = useState(false);
  const [primaryOptions, setPrimaryOptions] = useState();
  const [selectedPrimary, setSelectedPrimary] = useState();
  const [gptResponse, setGptResponse] = useState();
  const [selectedAnchor, setSelectedAnchor] = useState();
  const [anchorOptions, setAnchorOptions] = useState();
  const [anchorList, setAnchorList] = useState();
  const [sortedConversations, setSortedConversations] = useState();
  const [selectedPrimaryId, setSelectedPrimaryId] = useState();
  const [selectedSort, setSelectedSort] = useState(sortOptions[0].value);
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const { data, sort_order, get_survey_structure, get_survey_questions } =
    Fetch_data({ dei: false });

  const getPrimarySelectOptions = (structure) => {
    const primary = structure.categories.find(
      (category) => category.priority === "primary"
    );
    const options = primary.options.map((option) => option.name);
    return {
      name: primary.name,
      options,
    };
  };

  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const get_chat_gpt_resp = useSelector(
    (state) => state.chat_gpt.chat_gpt,
    shallowEqual
  );
  const get_chat_gpt = useSelector((state) => state.chat_gpt, shallowEqual);

  const build_state = (is_french) => {
    let _state = get_survey_structure[0]?.categories
      .filter((f) => f.priority.toLowerCase() !== "primary")
      .map((i) => {
        return {
          text: is_french ? (i.fr_name ? i.fr_name : i.name) : i.name,
          value: i.id,
        };
      });
    if (_state) {
      _state.unshift({
        value: get_survey_structure[0].categories.find(
          (f) => f.priority.toLowerCase() === "primary"
        ).id,
        text: is_french ? "Aperçu" : "Overview",
      });
    }

    return _state;
  };

  const get_filtered_data = (data) => {
    if (measurement === "Sentiment analysis") {
      return data
        .filter((f) => f.sentiment)
        .sort((a, b) =>
          sort_order ? a.sentiment - b.sentiment : b.sentiment - a.sentiment
        );
    }

    if (measurement === "HR flags") {
      return data
        .filter((f) => f.hr_flags)
        .sort((a, b) =>
          sort_order ? a.hr_flags - b.hr_flags : b.hr_flags - a.hr_flags
        );
    }

    if (measurement === "Quality") {
      return data
        .filter((f) => f.quality)
        .sort((a, b) =>
          sort_order ? a.quality - b.quality : b.quality - a.quality
        );
    }
  };

  useEffect(() => {
    if (get_survey_structure?.length > 0) {
      const primaryOptions = getPrimarySelectOptions(get_survey_structure[0]);
      setPrimaryOptions(primaryOptions);

      setAnchorList(build_state());
      setAnchorOptions(
        build_state().map((option) => {
          return option.text;
        })
      );
    }
  }, [get_survey_structure]);

  useEffect(() => {
    if (measurement === "Sentiment analysis") {
      // set default sort to measurement.
      setSelectedSort(4);
    }
    if (measurement === "HR flags") {
      // set default sort to measurement.
      setSelectedSort(3);
    }
    if (measurement === "Quality") {
      // set default sort to measurement.
      setSelectedSort(0);
    }
  }, [measurement]);

  useEffect(() => {
    const primaryId = get_survey_structure[0]?.categories
      .find((category) => {
        return category.priority === "primary";
      })
      ?.options.find((op) => op.name === selectedPrimary)?.id;

    if (primaryId) {
      setSelectedPrimaryId(primaryId);
      setDataSelected(null);
    } else {
      setSelectedPrimaryId();
      setDataSelected(null);
    }
  }, [selectedPrimary]);

  const handleSortChange = (sort) => {
    const sortValue = sortOptions.find((f) => f.name === sort).value;
    setSelectedSort(sortValue);
  };

  const getSortName = (sortValue) => {
    const sortName = sortOptions.find((f) => f.value === sortValue).name;
    return sortName;
  };

  useEffect(() => {
    if (conversations && conversations?.length > 0) {
      const feedbackObject = conversations.filter(
        (f) => f?.ml_feedback?.feedback?.length>0
      );

      switch (selectedSort) {
        case 0:
          return setSortedConversations(sortByQuality("asc", feedbackObject));
        case 1:
          return setSortedConversations(sortByQuality("desc", feedbackObject));
        case 2:
          return setSortedConversations(sortByHrFlag("asc", feedbackObject));
        case 3:
          return setSortedConversations(sortByHrFlag("desc", feedbackObject));
        case 4:
          return setSortedConversations(sortBySentiment("asc", feedbackObject));
        case 5:
          return setSortedConversations(
            sortBySentiment("desc", feedbackObject)
          );
        default:
          return setSortedConversations(feedbackObject);
      }
    }
  }, [conversations, selectedSort]);

  const get_selected_data_set = (data) => {
    if (measurement === "Sentiment analysis") {
      return {
        name: "Sentiment",
        data: data.map((item) => {
          return item.sentiment.toFixed(2);
        }),
      };
    }
    if (measurement === "HR flags") {
      return {
        name: "HR Flags",
        data: data.map((item) => {
          return item.hr_flags.toFixed(2);
        }),
      };
    }
    if (measurement === "Quality") {
      return {
        name: "Feedback Quality",
        data: data.map((item) => {
          return item.quality.toFixed(2);
        }),
      };
    }
  };

  const setAnchor = (newAnchor) => {
    if (anchorList && newAnchor) {
      const anchorValue = anchorList.find((f) => f.text === newAnchor).value;

      if (anchorValue !== -1) {
        setSelectedAnchor(newAnchor);
        dispatch({
          type: SET_DATA_ACNHOR,
          payload: anchorValue,
        });
      }
    }
  };

  useEffect(() => {
    setDataSelected(null);
    setConversations([]);
    setSortedConversations([]);
    setSelectedConversation(null);
    setOpen(false);
    setSearchString();
    setGptResponse();
  }, [anchor, selectedPrimary]);

  useEffect(() => {
    const sentiment_data = get_sentiment_by_anchor(
      data,
      anchor,
      get_survey_structure,
      selectedPrimaryId
    );


    const culture_factor_sentiment = get_culture_factor_sentiment(
      data,
      get_survey_questions,
      sort_order,
      selectedPrimaryId
    );

    if (sentiment_data && selectedOption === "Anchor") {
      setLoading(true);
      let filtered_data = get_filtered_data(sentiment_data);
      let series = get_selected_data_set(filtered_data);

      let _chart = chart;
      _chart.xaxis.categories = filtered_data.map((item) => {
        return item.name;
      });

      _chart.colors = [
        function ({ value, seriesIndex, w }) {
          if (value < 0) {
            return "#F15062";
          } else {
            return "#27CDA7";
          }
        },
      ];

      _chart.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          setDataSelected(config.selectedDataPoints[0]);
        },
        mounted: (chartContext, config) => {
          if (dataSelected) {
            chartContext.toggleDataPointSelection(0, dataSelected[0]);
          }
        },
      };

      _chart.yaxis.labels = {formatter: function (value) {
        return value
      }}

      if (measurement === "HR flags") {
        _chart.yaxis.min = 0;
        _chart.yaxis.max = Math.max(
          ...filtered_data.map((item) => {
            return item.hr_flags.toFixed(2);
          })
        );
        _chart.yaxis.title = "HR Flags";

       
      }

      if (measurement === "Quality") {
        _chart.yaxis.min = 0;
        _chart.yaxis.max = 10;
        _chart.yaxis.title = "Feedback Quality";

      }

      if (measurement === "Sentiment analysis") {
        _chart.yaxis.min = -1;
        _chart.yaxis.max = 1;
        _chart.yaxis.title = "Feedback Sentiment";

        _chart.yaxis.labels = {formatter: function (value) {
          return get_sentiment_labels(value)
        }}
      }

      setRawData(filtered_data);
      setChart(_chart);
      setData([series]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    if (culture_factor_sentiment && selectedOption === "Culture Factors") {
      setLoading(true);

      let filtered_data = get_filtered_data(culture_factor_sentiment);
      let series = get_selected_data_set(filtered_data);
      

      let _chart = chart;
      _chart.xaxis.categories = filtered_data.map((item) => {
        return item.name;
      });

      _chart.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          setDataSelected(config.selectedDataPoints[0]);
        },
        mounted: (chartContext, config) => {
          if (dataSelected) {
            chartContext.toggleDataPointSelection(0, dataSelected[0]);
          }
        },
      };

      _chart.yaxis.labels = {formatter: function (value) {
        return value
      }}

      if (measurement === "HR flags") {
        _chart.yaxis.min = 0;
        _chart.yaxis.max = Math.max(
          ...filtered_data.map((item) => {
            return item.hr_flags.toFixed(2);
          })
        );
        _chart.yaxis.title = "HR Flags";
      }

      if (measurement === "Quality") {
        _chart.yaxis.min = 0;
        _chart.yaxis.max = 10;
        _chart.yaxis.title = "Feedback Quality";
      }

      if (measurement === "Sentiment analysis") {
        _chart.yaxis.min = -1;
        _chart.yaxis.max = 1;
        _chart.yaxis.title = "Feedback Sentiment";

        _chart.yaxis.labels = {formatter: function (value) {
          return get_sentiment_labels(value)
        }}
      }

      setRawData(filtered_data);
      setChart(_chart);
      setData([series]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [
    data,
    anchor,
    selectedOption,
    measurement,
    clearClicked,
    selectedPrimaryId,
  ]);

  useEffect(() => {
    if (rawData && dataSelected) {
      let feedback;
      if (selectedOption === "Anchor") {
        feedback = getStructuredFeedback(
          rawData[dataSelected].id,
          anchor,
          data,
          selectedPrimaryId
        );
      }
      if (selectedOption === "Culture Factors") {
        feedback = getStructuredFeedbackFactor(rawData[dataSelected], data);
      }

      const prompt = `Based on the feedback provided above, please summarize the information in a single paragraph and then in point form highlight important actions to consider. 
            Title the paragraph "#Summary" and the point form "#Key Takeaways. Don't mention that this feedback is from a culture survey."`;
      dispatch({
        type: SG_POST_ML_SERVER_V1,
        payload: {
          input_text: feedback,
          prompt: prompt,
        },
      });

      if (!rawData[dataSelected].id) {
        // setConversations(
        //   getConversationsByFactor(anchor, data, rawData[dataSelected])
        // );
      } else {
        setConversations(
          getConversations(
            rawData[dataSelected].id,
            anchor,
            data,
            selectedPrimaryId
          )
        );
      }
    }
  }, [dataSelected]);

  useEffect(() => {
    if (!get_chat_gpt?.pending) {
      setGptResponse(get_chat_gpt?.chat_gpt_ml?.response);
    }
  }, [get_chat_gpt_resp, get_chat_gpt]);

  function getIconType(icon) {
    switch (icon.replace(":", "").toLowerCase()) {
      case "summary":
        return SummaryIcon();
      case "key takeaways":
        return LightningIcon();
      case "conversations":
        return ILIcon();
      default:
        return null;
    }
  }

  function formatElements(rawString) {
    if (!rawString) return null;
    // Splitting the rawString into separate lines
    let lines = rawString.split("\n");

    return lines.map((line, index) => {
      // Handling section headers
      if (line.startsWith("#")) {
        // Removing the '#' and wrapping the text with <T2>
        const header = line.slice(1).trim();
        const iconType = getIconType(header);
        const icon = <IconContainer icon={iconType} />;
        return (
          <HeadingWrapper>
            {icon}
            <T2 key={index}>{line.slice(1).trim().replace(":", "")}</T2>
          </HeadingWrapper>
        );
      }
      // Handling list items
      else if (line.trim().match(/^(\d+\.|-)/)) {
        // Wrapping the text with '<li>'
        return (
          <TakeawaySection>
            <TakeawayItem key={index}>
              {line.trim().slice(2).trim()}
            </TakeawayItem>
          </TakeawaySection>
        );
      }
      // Leaving other lines unchanged
      else {
        if (line.length > 0) {
          return <SummaryParagraph key={index}>{line}</SummaryParagraph>;
        }
      }
    });
  }

  const openModal = (item) => {
    setSelectedConversation(item);
    setOpen(true);
  };

  return (
    <Container>
      <ChatModal open={open} setOpen={setOpen} data={selectedConversation} />
      <Title>Conversation Analytics</Title>
      <Sub>
        Your hub for analyzing all the conversations that have taken place in
        your organization.
      </Sub>

      <Tools>
        <SubTitle>Average sentiment data by Department</SubTitle>
        <Configurations>
          <ToggleWrapper>
            <LabeledToggle
              onSelect={(selectedOption) => {
                setSelectedOption(selectedOption);
                setGptResponse();
                setDataSelected(null);
                setRawData(null);
                setConversations([]);
                setSelectedConversation(null);
                setOpen(false);
                setSearchString();
              }}
              selected={selectedOption}
              options={["Anchor", "Culture Factors"]}
            />
          </ToggleWrapper>
          <DropdownWrapper>
            {primaryOptions && (
              <BasicDropdown
                options={["All", ...primaryOptions.options]}
                selectedOption={selectedPrimary || "All"}
                label={primaryOptions.name}
                handleOptionChange={(newOption) =>
                  setSelectedPrimary(newOption)
                }
              />
            )}
            <BasicDropdown
              options={["Sentiment analysis", "HR flags", "Quality"]}
              selectedOption={measurement}
              label="Measurement"
              handleOptionChange={(newOption) => setMeasurement(newOption)}
            />
            {anchorList && selectedOption !== "Culture Factors" && (
              <BasicDropdown
                options={anchorOptions}
                selectedOption={selectedAnchor || anchorOptions[0]}
                label="Anchor"
                handleOptionChange={(newOption) => {
                  setAnchor(newOption);
                  setDataSelected(null);
                }}
              />
            )}
          </DropdownWrapper>

          <HiddenAnchor>
            <Anchor />
          </HiddenAnchor>
          {dataSelected && (
            <ClearButton
              onClick={() => {
                setClearClicked(!clearClicked);
                setChart(data3.options);
                setData(data3.series);
                setLoading(true);
                setDataSelected(null);
                setRawData(null);
                setConversations([]);
                setSortedConversations([]);
                setSelectedConversation(null);
                setOpen(false);
                setSearchString();
                setSelectedOption("Anchor");
                setMeasurement("Sentiment analysis");
                setGptResponse();
                setTimeout(() => {
                  setClearClicked(false);
                }, 300);
              }}
            >
              {CloseIcon()}
            </ClearButton>
          )}
        </Configurations>
      </Tools>

      {loading ? (
        <ChartLoading />
      ) : (
        <Chart options={chart} series={series} type="bar" height={350} />
      )}

      <Sub2>Conversation Analytics</Sub2>
      <BottomSection>
        {!get_chat_gpt?.pending && gptResponse && (
          <Sub>{formatElements(gptResponse)}</Sub>
        )}

        {get_chat_gpt?.pending && dataSelected && (
          <Sub>
            <LoadingCard loading={loading} />
          </Sub>
        )}
        {sortedConversations && sortedConversations?.length > 0 && (
          <ChatHistory>
            <HeadingWrapper>
              <IconContainer icon={ILIcon("#2D70E2")} />
              <T2>Conversations</T2>
            </HeadingWrapper>
            {/* <SearchBar
              placeholderText="Search by keyword"
              onChange={setSearchString}
              text={searchString}
            /> */}
            <BasicDropdown
              options={sortOptions.map((option) => option.name)}
              selectedOption={getSortName(selectedSort)}
              label="Sort"
              handleOptionChange={(newOption) => handleSortChange(newOption)}
            />
            {sortedConversations.map((item, i) => {
              if (item?.ml_feedback?.feedback) {
                return (
                  <FeedbackCard data={item} onClick={() => openModal(item)} />
                );
              }
            })}
          </ChatHistory>
        )}
      </BottomSection>
    </Container>
  );
};

export default Feedback;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 58px;
  margin-top: 20px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  padding-left: 20px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HiddenAnchor = styled.div`
  visibility: hidden;
  display: none;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChatHistory = styled.div`
  width: 50%;
`;

const SummaryParagraph = styled.p`
  color: #2a3039;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-bottom: 32px;
`;

const TakeawaySection = styled.ul`
  list-style-position: outside;
  margin-left: -20px;
`;

const TakeawayItem = styled.li`
  color: #2a3039;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Title = styled.div`
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  text-transform: capitalize;
  color: #2a3039;
`;

const Sub = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding-right: 40px;
  color: #666d79;
  width: 50%;
  margin-top: 5px;
`;

const Tools = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 50px;
  justify-content: space-between;
`;

const Configurations = styled.div`
  display: flex;
  padding-top: 15px;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const T2 = styled.div`
  color: #2a3039;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-left: 12px;
`;

const SubTitle = styled.div`
  color: #2a3039;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`;

const RadioWrapper = styled.div`
  margin-bottom: 20px;
`;

const ToggleWrapper = styled.div``;

const RadioButton = styled.label`
  margin-right: 20px;
`;
const H3 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2a3039;
`;

const Sub2 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2a3039;
  margin-bottom: 20px;
`;

const ClearButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-right: 35px;
  // width: 70px;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [9, 10, 8],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
      events: {
        dataPointMouseEnter: function(event) {
          event.path[0].style.cursor = "pointer";
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0.4,
        stops: [0, 80, 100],
        colorStops: [],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#3260a8"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [
        "Apples",
        "Oranges",
        "Strawberries",
        "Pineapples",
        "Mangoes",
        "Bananas",
        "Blackberries",
        "Pears",
        "Watermelons",
        "Cherries",
        "Pomegranates",
        "Tangerines",
        "Papayas",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      min: -2,
      max: 2,
      decimalsInFloat: 0,
      tickAmount: 4,
      title: {
        text: "Sentiment",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
