import { Navigate } from "react-router-dom";

import HomeLayout from "layouts/HomeLayout";
import AuthLayout from "layouts/AuthLayout";
// import SimpleDash from "layouts/SimpleLayout"

import ForgotPassword from "account/ForgotPassword";
import UpgradePassword from "account/UpgradePassword";
import Login from "account/Login";
import Validate from "account/Validate";
import Logout from "account/Logout";
import ResetPassword from "account/ResetPassword";
import ChangePassword from "account/ChangePassword";
import SelectOrg from "account/SelectOrg";
import PasswordMessage from "account/PasswordMessage";
//
import Lost from "404";
// import MarketingReport from "marketingReport"
import Health from "health";
import UnPaid from "404/UnPaid";
import UnSubcribed from "404/NotSubscribed";
import NoAccess from "404/NoAccess";

import CreateTeam from "settings/create/index";
import ChatGpt from "reports/ChatGpt"

// import _SurveySettings from "reports/changes/SurveySettings"
//import _SurveySettings from "settings/survey_settings/SurveySettings"
import OrganizationSettings from "settings/organization/index";

import LeaderList from "settings/survey_settings/LeaderList";
// import LeadershipSettings from "settings/survey_settings/Leadership"

import Members from "members/index";
import MemberDirectory from "settings/member_directory/index";
import PersonalitySettings from "settings/personality/index";

import MemberSettings from "settings/user_settings/index";

// import Survey from "survey"

// old sign up
import ImageUpload from "signup/organization/ImageUpload";
//
import Register from "account/Register";
import RegisterToken from "account/RegisterToken";
import AmbassadorToken from "account/AmbassadorRegisterToken";

import Images from "reports/images";
import UserUpload from "settings/bulk//UserUpload";

import ValidateEmail from "validate";
import NoLoginRole from "validate/NonLoginRole";
import LeaderManagement from "settings/member_directory/Teams/TeamLeader";
//

// Dashboard
import Dashboard from "dashboard/index";
import Home from "home/index";
// Ambassador
import Ambassador from "ambassador/index";
//
import RoutesTeamTool from "routes/TeamTool";

import RoutesEdi from "routes/Edi";
// import RoutesOtp from "routes/Otp";

import RoutesStandards from "routes/Standards";

import AmbassadorLogin from 'ambassador/Login'

const routes = [
  {
    path: "app",
    element: <AuthLayout />,
    children: [

      {
        path: "edi/*",
        element: <RoutesEdi />,
      },
      {
        path: "standards/*",
        element: <RoutesStandards />,
      },
      {
        path: "team/*",
        element: <RoutesTeamTool />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: false,
      },
      {
        path: "home",
        element: <Home />,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: false,
      },
      {
        path: "404",
        element: <Lost />,
        role: 21,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "unsubscribed",
        element: <UnSubcribed />,
        role: 21,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "access-denied",
        element: <NoAccess />,
        role: 21,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "organization",
        element: <OrganizationSettings />,
        role: 6,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "upload",
        element: <ImageUpload />,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "createteam",
        element: <CreateTeam />,
        role: 9,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "survey_settings/leaders-list",
        element: <LeaderList />,
        role: 5,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "TeamManagement",
        element: <LeaderManagement />,
        role: 5,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },

      {
        path: "images",
        element: <Images />,
        role: 5,
        visible: true,
        authentication: true,
        team: false,
        paid: false,
      },
      // { path: '*', element: <Navigate to="/404" /> }
    ],
  },
  {
    path: "/settings",
    element: <AuthLayout />,
    children: [
      {
        path: "personality",
        element: <PersonalitySettings />,
        role: 6,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
        role: 11,
        visible: false,
        authentication: true,
        team: false,
        paid: false,
      },
      {
        path: "select-organization",
        element: <SelectOrg />,
        role: 21,
        visible: false,
        authentication: true,
        team: false,
        paid: false,
      },
      // {
      //   path: "profile",
      //   element: <Profile />,
      //   role: 5,
      //   visible: true,
      //   authentication: true,
      //   team: false,
      // },
      {
        path: "member", // profile page
        element: <MemberSettings />,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "user-upload",
        element: <UserUpload />,
        role: 6,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
      {
        path: "personality",
        element: <PersonalitySettings />,
        role: 5,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
    ],
  },
  {
    path: "/ambassador",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Ambassador />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "ambassador-select",
        element: <AmbassadorLogin />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
    ],
  },
  {
    path: "/core",
    element: <AuthLayout />,
    children: [
      {
        path: "management",
        element: <MemberDirectory />,
        role: 9,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },

      {
        path: "members/*",
        element: <Members />,
        role: 9,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
    ],
  },

  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "",
        element: <Login />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "health",
        element: <Health />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      // {
      //   path: "roi-report/:token",
      //   element: <MarketingReport />,
      //   role: 11,
      //   visible: true,
      //   authentication: false,
      //   team: false,
      //   paid: false,
      // },
      // {
      //   path: "roi-report/",
      //   element: <MarketingReport />,
      //   role: 11,
      //   visible: true,
      //   authentication: false,
      //   team: false,
      //   paid: false,
      // },
      {
        path: "404",
        element: <Lost />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "unpaid",
        element: <UnPaid />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },

      {
        path: "ambassador-token/:token",
        element: <AmbassadorToken />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "validate-email",
        element: <ValidateEmail />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "validate-survey-email",
        element: <NoLoginRole />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
       
      
      { path: "/", element: <Navigate to="/account/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/experimental",
    element: <AuthLayout />,
    children: [
      {
        path: "open-ai/*",
        element: <ChatGpt />,
        role: 9,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
    ],
  },
  {
    path: "account",
    element: <HomeLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "upgrade-password",
        element: <UpgradePassword />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "logout",
        element: <Logout />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "register",
        element: <Register />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "register-token/:token",
        element: <RegisterToken />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "password-message",
        element: <PasswordMessage />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },

      {
        path: "validate-account",
        element: <Validate />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      // { path: 'login', element: <LoginView /> },
      // { path: 'register', element: <RegisterView /> },
      // { path: 'logout', element: <Logout /> },
      // { path: 'forgot-password', element: <ForgotPassword /> },
      // { path: 'reset-password', element: <ResetPassword /> },
      // { path: '404', element: <NotFoundView /> },
      // { path: '/', element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
