import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useLocation, Route, Outlet  } from "react-router-dom";

import PageShell from "./PageShell";
import SideBar from "./SideBar";

import MobileNav from "./MobileNav";
import TopBar from "./TopBar";
import Help from "./Help";
import Checklist from './onboarding'

import ErrorWrapper from "error/index";

import { SG_FETCH_USER_EMPLOYEE, CLEAR_PASSWORD_DATA } from "constants/actions";

import { DomainContext } from 'layouts/LayoutContext';
import {DomainList } from "data/live/domain"

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});


const ComplianceCheck = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const [authEmployeeId, setAuthEmployeeId] = useState(false);
  const [authEmployee, setAuthEmployee] = useState(false);
  const [ambassadorId, setAmbassadorId] = useState(false);


  // const [organization, setOrganization] = useState(false);

  const { get_auth, get_employees, get_error, get_organizations } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
      // get_organizations: state.organizations
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_auth?.token) {
      setAuthEmployeeId(get_auth.employee_id);
      // setOrganization(get_organizations[get_auth.organization_id])
    }
  }, [get_auth, get_organizations]);

  // console.log(get_error)

  useEffect(() => {
    if (
      get_error?.status == 401 ||
      get_error?.msg == "Request failed with status code 401" ||
      get_error?.msg?.message == "Request failed with status code 401"
    ) {
      dispatch({
        type: CLEAR_PASSWORD_DATA,
      });
      if (location.pathname !== "/account/login") {
        return navigate("/account/login");
      }
    }
  }, [dispatch, get_error, navigate, location]);

  useEffect(() => {
    if (get_auth?.token) {
      setAmbassadorId(get_auth.ambassador_id);
    }
  }, [get_auth]);

  useEffect(() => {
    // if the response is ok 200
    // console.log("SG_FETCH_USER_EMPLOYEE here", employeeId)
    // console.log(employee)
    if (!authEmployee) {
      if (Number(authEmployeeId) > 0) {
        dispatch({
          type: SG_FETCH_USER_EMPLOYEE,
          payload: { id: authEmployeeId },
        });
      }
    }
  }, [dispatch, authEmployeeId, authEmployee]);

  useEffect(() => {
    if (get_auth?.token) {
      if (authEmployeeId) {
        setAuthEmployee(get_employees.userEmp);
        // props.setEmployee1(get_employees[authEmployeeId])
      }
    }
  }, [dispatch, get_employees, authEmployeeId, props, get_auth]);

  // if (get_auth?.token) {
  //   if (Number(get_auth?.organization_id) === 0) {
      
  //     if (location.pathname !== "/ambassador") {
  //       return navigate("/ambassador");
  //     }
  //   } 
  //  if (employee?.email_status === 9) {
  //     if (location.pathname !== "/app/dashboard") {
  //      return navigate("/app/dashboard");
  //     }
  //   }
  // }

  // if (location.pathname !== '/account/logout') {
  //   navigate("/account/logout")
  // }
  // if (!get_auth?.token){
  //   if (location.pathname !== '/account/login') {
  //     navigate("/account/login")
  //   }
  // }


  return props.children;


  //return get_auth?.token? <WebSite props={props} /> :  <WebSite props={props} />
};
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */


const MobileContainer = () => {

  const [open, setOpen] = useState(false);
  const [help,setHelp] = useState(false)
  
  return  (
    <>
    {/* Desktop View */}
    <Media
      greaterThan="mobile"
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <SideBar open={open} setOpen={(v)=>setOpen(v)}/>
      <TopBar open={open} setOpen={(v)=>setOpen(v)} setHelp={()=>setHelp(!help)}/>
      {/* <Checklist/> */}
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100vw",
            paddingLeft: open?205:50,
            paddingTop: 37,
            paddingRight: 30,
            transition:'all 0.5s'
          }}
        >
         
          <ErrorWrapper>
           
                <Outlet />

          </ErrorWrapper>
        </div>
        {/* <Help open={help} setHelp={()=>setHelp(false)}/> */}
      </div>
    </Media>

    {/* Mobile View */}  
      <Media at="mobile" style={{ position: "relative" }}>
      <MobileNav />
      <div
        style={{
          width: "100%",
          marginTop: "100px",
          maxWidth: "100%",
          overflowX: "hidden",
          padding: 10,
        }}
      >
        <Outlet />
      </div>

    </Media>
    </>
  ) 
};


const ResponsiveContainer = ({ children }) => {
  const domain = window.location.host;
  // console.log(domain)
  return (
    <MediaContextProvider>
      <DomainContext.Provider value={
        DomainList?.[domain]? DomainList?.[domain] : Object.values(DomainList)[0]}>
        <ComplianceCheck>
    
          <MobileContainer>{children}</MobileContainer>
        </ComplianceCheck>
      </DomainContext.Provider>
    </MediaContextProvider>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;