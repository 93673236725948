import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { CSSTransitionGroup } from 'react-transition-group';
import './analytics.css'
import { Link, useParams } from "react-router-dom"
import { Icon } from 'semantic-ui-react'
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Trust from '../../../assets/images/Dependability.svg'
import Clarity from '../../../assets/images/Clarity.svg'
import Communication from '../../../assets/images/Communication.svg'
import Alignment from '../../../assets/images/Direction.svg'
import Resilience from '../../../assets/images/Resilience.svg'
import Impact from '../../../assets/images/Impact.svg'

import culture_definitions from '../../../data/culture_definitions.json'
import culture_analytics from '../../../data/culture_analytics.json'
import MobileChart from './mobiletrends'
import { SG_GET_CULTURE_DATA } from "constants/actions";
import { useNavigate } from "react-router-dom";
import EmployeeAvatar from "components/EmpAvatar";

import Comments from './Comments'

const cellWidth = 50
const cellHeight = 50

const Container = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	flex-direction:column;
`

const TopCard = styled.div`
  height: 135px;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding-bottom:26px;
  position:relative;

`
const Content = styled.div`
	display:flex;
  justify-content:center;
  align-items:center;
  margin-top:0px;
  flex-direction:column;
  width:100%;
  height:120px;
  position:absolute;
  top:0px;
  left:0px;
`

const Title = styled.div`
	font-size: 21px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:5px;  
`

const P = styled.p`
	font-size: 16px;
  line-height: 1.31;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  text-align:center;
  width:75%;
  margin-bottom:10px;
`

const Arrow = styled.span`
	font-size:50px;
	position:absolute;
	top:10px;
	left:${props=>props.position};
	cursor:pointer;
	user-select: none;
`

const Dot = styled.div`
  width: ${props=>props.selected? '8px' : '6px'};
  height: ${props=>props.selected? '8px' : '6px'};
  opacity: ${props=>props.selected? 1 : 0.6};
  background-color: #6a00ff;
  border-radius:${props=>props.selected? '4px' : '3px'};
  margin-right:6px;
`

const IMG = styled.img`
	margin-right:10px;
	width:${props=>props.selected==5?'18px':props.selected==3?'50px':'34px'};

`

const View = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  font-family: 'Red Hat Display', sans-serif;
  text-align: center;
`
const BottomContainer = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-evenly;
	margin-top:20px;
`

const ScoreCard = styled.div`
	width: ${props=>props.open?'162px':'80px'};
	height:100%;
	box-shadow: 0 4px 20px 0 #ececec;
	display:flex;
	align-items:center;
	flex-direction:column;
	transition:width 500ms;
`
const CommentCard = styled.div`
	width:calc(100% - 110px);
	height:100%;
	box-shadow: 0 2px 40px 0 #ececec;
	opacity:${props=>props.open?0.2:1};
`
const ScoreTitle = styled.div`
	font-size: 14px;
	font-family: 'Red Hat Display', sans-serif;
	text-align:center;
	margin-top:10px;
`

const Expand = styled.div`
	color:#6a00ff;
	font-size:12px;
	text-align:center;
	font-weight:bold;
	margin-top:10px;
`


const Box = styled.div`
	height:50px;
	width:50px;
	min-width:50px;
	min-height:50px;
	z-index:2;
	margin-bottom:1px;
	background-color:${props=> props.background };
	display:flex;
	align-items:center;
	justify-content:center;
`;

const YouBox = styled.div`
	height:65px;
	width:50px;
	min-width:50px;
	min-height:50px;
	z-index:2;
	margin-bottom:10px;
	background-color:${props=> props.background };
	display:flex;
	align-items:center;
	justify-content:center;
`;


const Score = styled.div`
	color:white;
	font-size: 23px;
  	font-weight: bold;
	transition:opacity 500ms,margin 300ms;
	font-family: 'Red Hat Display', sans-serif;
	text-align:center;
`

const Avatar = styled.img`
  height:40px;
  width:40px;
  border-radius:20px;
`

const ScoreBoxContainer = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-evenly;
	align-items:center;
`

const order=["Trust","Communication","Alignment","Resilience","Clarity","Impact"]
const colors = ['#e0d2fe','#9f73fb','#8d59fa','#7e44fa','#4f00f7']
const Images = [Trust,Communication,Alignment,Resilience,Clarity,Impact]

const ScoreBox = ({data,search,open,you}) =>{
	 const score = data.responses.find(({name}) => name === search).answer
	if(you){
		return (
		<ScoreBoxContainer>
			{open? <EmployeeAvatar 
                employee_id={data.employee_id}
                picSize='small'
                nonMemberHide={true}  /> :""}
			<YouBox background={colors[score-1]}>
				<Score>{score} <div style={{fontSize:12,marginTop:-4}}>YOU</div></Score>
		    </YouBox>
		</ScoreBoxContainer>
		)
	}

	return (
		<ScoreBoxContainer>
			{open? <EmployeeAvatar 
                employee_id={data.employee_id}
                picSize='small'
                nameHover={true}
                nonMemberHide={true}  /> :""}
			<Box background={colors[score-1]}>
				<Score>{score}</Score>
			</Box>
		</ScoreBoxContainer>
		)
}

const MobileCultureAnalytics = () =>{

	let {type} = useParams()
	const [selected,setSelected] = React.useState(type?order.indexOf(type):0)
	const [reverse,setReverse] = React.useState(false)
	const [direction,setDirection] = React.useState(1)
	const [expand,setExpand] = React.useState(false)
	const [trends,setTrends] = React.useState(false)
	const [loading,setLoading] = useState(true)
	let navigate = useNavigate();
	const [cultureData, setCultureData] = useState([]);

	const { culture_data, get_auth } = useSelector(
    (state) => ({
      culture_data: state.culture.culture,
      get_auth: state.auth,
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );

	useEffect(() => {
    if (culture_data[0]?.responses) {
      const _EmpResponses = []
      if (culture_data[0]?.responses.filter(re => re.employee_id == get_auth?.employee_id).length > 0)
      {
        _EmpResponses.push(culture_data[0]?.responses.filter(re => re.employee_id == get_auth?.employee_id)[0])
      }
      _EmpResponses.push(...culture_data[0]?.responses.filter(re => re.employee_id != get_auth?.employee_id))

      
      setCultureData(_EmpResponses);
    }
    else if(!loading){
      navigate("/app/culture");
    }
    setLoading(false)
  }, [culture_data, loading, navigate, get_auth]);


	const next = ()=>{
			setDirection(1)

	  	if(selected==5){
	  		setSelected(0)
	  		setReverse(false)
	  	}
	  	else{
	  		setSelected(selected+1)
	  		setReverse(false)
	}
  }

   const back=() =>{
   	setDirection(0)

  	if(selected==0){
  		setSelected(5)
	  	setReverse(true)
  	}

  	else {
  		setSelected(selected-1)
	  	setReverse(true)
  	}
  	
  }


	return (
		<Container>
			<TopCard>
			<CSSTransitionGroup
						transitionName={direction==1 ? "SlideRight" : "SlideLeft"}>
					<Content key={selected}>

							<Title><IMG src={Images[selected]} selected={selected}/>{culture_definitions[selected].type}</Title>
							<P>{culture_definitions[selected].definition}</P>
							<View onClick={()=>setTrends(!trends)}>{trends? 'Minimize trends':'View trends'}</View>

					</Content>
				</CSSTransitionGroup>

				<div style={{display:'flex',flexDirection:'row',justifyContent:'center',position:'absolute',bottom:0,width:'100%'}}>
					{[0,1,2,3,4,5,].map((item,i)=>{
						return <Dot selected={i==selected}/>
					})}
				</div>

				

				<Arrow position={'1%'} onClick={()=>back()}><Icon name='chevron left'  style={{fontSize:25,cursor:'pointer',zIndex:2,marginRight:0}}/></Arrow>
				<Arrow position={'90%'} onClick={()=>next()}>	<Icon name='chevron right'   style={{fontSize:25,cursor:'pointer',zIndex:2,marginRight:0}}/></Arrow>
				
				
			</TopCard>

			<MobileChart open={trends} culture_data={culture_data.slice(culture_data.length-5>0?culture_data.length-5:0,culture_data.length)} type={culture_definitions[selected].type}/>

			<BottomContainer>
				<ScoreCard open={expand}>
					<ScoreTitle>{culture_definitions[selected].type=="Communication"?'Communic..':culture_definitions[selected].type}<br/>Scores</ScoreTitle>
					

					<Expand onClick={()=>setExpand(!expand)}>
					{expand? 
						<><span style={{fontSize:30}}>&#8690;</span> <br/>
					MINIMIZE</>
						:<><span style={{fontSize:30}}>&#10530;</span> <br/>
					EXPAND</>}</Expand>

					{cultureData?.map((item,i)=>{

						return  <ScoreBox 
									data={item}
									search={culture_definitions[selected].type}
									you={item.employee_id==get_auth.employee_id} 
									open={expand}/>})}
				
				</ScoreCard> 

				<CommentCard open={expand}>
					<Comments type={culture_definitions[selected].type} key={selected}/>
				</CommentCard>
			</BottomContainer>
		</Container>
		)
}



export default MobileCultureAnalytics