import styled, { keyframes } from "styled-components";
import React from "react";
import {useDispatch,
  //  useSelector,  shallowEqual 
  } from "react-redux";

import { Link, useParams } from "react-router-dom";
import { Dimmer, Loader, Icon, Header } from "semantic-ui-react";

import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/Resilience.svg";
import Impact from "assets/images/Impact.svg";

import culture_definitions from "data/culture_definitions.json";

import CultureTable from "./CultureTable";
import Comments from "./Comments";

import {
  // SG_GET_MOODS,
  // SG_GET_CULTURE_DATA,
  LOAD_EMPLOYEES,
  // LOAD_TEAMS,
} from "constants/actions";

import { CSSTransitionGroup } from "react-transition-group";
import "./analytics.css";

const order = [
  "Trust",
  "Communication",
  "Alignment",
  "Resilience",
  "Clarity",
  "Impact",
];


const Images = [Trust, Communication, Alignment, Resilience, Clarity, Impact];

const CultureAnalytics = () => {
  const dispatch = useDispatch();
  let { type } = useParams();
  const [selected, setSelected] = React.useState(
    type ? order.indexOf(type) : 0
  );
  const [reverse, setReverse] = React.useState(false);
  const [direction, setDirection] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [loadingText, setLoadingText] = React.useState('Loading');

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch({ type: SG_GET_MOODS });
    dispatch({ type: LOAD_EMPLOYEES });
    // dispatch({ type: LOAD_TEAMS });
    // dispatch({ type: SG_GET_CULTURE_DATA });
  }, [dispatch]);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });

  const next = () => {
    setDirection(1);

    if (selected === 5) {
      setSelected(0);
      setReverse(false);
    } else {
      setSelected(selected + 1);
      setReverse(false);
    }
  };

  const back = () => {
    setDirection(0);

    if (selected === 0) {
      setSelected(5);
      setReverse(true);
    } else {
      setSelected(selected - 1);
      setReverse(true);
    }
  };

  
  return (
    <div style={{ width: "100%" }}>
      <Dimmer.Dimmable blurring dimmed={loading}>
        <TopCard>
          <CSSTransitionGroup
            transitionName={direction === 1 ? "SlideRight" : "SlideLeft"}
            transitionEnterTimeout={10}
            transitionLeaveTimeout={10}
          >
            <Content key={selected}>
              <Title>
                <IMG src={Images[selected]} />
                {culture_definitions[selected].type}
              </Title>
              <P>{culture_definitions[selected].definition}</P>
            </Content>
          </CSSTransitionGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "absolute",
              bottom: 20,
            }}
          >
            {[0, 1, 2, 3, 4, 5].map((item, i) => {
              return <Dot selected={i === selected} key={i} />;
            })}
          </div>

          <Arrow position={"20%"} onClick={() => back()}>
            <Icon
              name="chevron left"
              style={{ fontSize: 25, cursor: "pointer", zIndex: 2 }}
            />
          </Arrow>
          <Arrow position={"80%"} onClick={() => next()}>
            {" "}
            <Icon
              name="chevron right"
              style={{ fontSize: 25, cursor: "pointer", zIndex: 2 }}
            />
          </Arrow>
        </TopCard>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 20,
            position: "relative",
          }}
        >
          <CultureTable
            width={"70%"}
            position={selected}
            setPosition={(x) => setSelected(x)}
            reverse={reverse}
            setReverse={(x) => setReverse(x)}
          />

          <CSSTransitionGroup transitionName="card">
            <Comments
              type={culture_definitions[selected].type}
              key={selected}
            />
          </CSSTransitionGroup>
        </div>
        <Dimmer active={loading} onClickOutside={() => setLoading(!loading)}>
          {loadingText === "Loading" && <Loader />}{" "}
          <Header as="h2" icon inverted>
          {loadingText}
          </Header>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
};

export default CultureAnalytics;

const TopCard = styled.div`
  height: 234px;
  box-shadow: 0 4px 10px 0 rgba(189, 189, 189, 0.5);
  background-color: #ffffff;
  width: 130%;
  margin-left: -20%;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 26px;
  position: relative;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;

const P = styled.p`
  font-size: 16px;
  line-height: 1.31;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  flex-direction: column;
  width: 100%;
  height: 150px;
  position: absolute;
  top: 50px;
  left: 0px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  flex-direction: column;
  position: absolute;
`;

const Arrow = styled.span`
  font-size: 50px;
  position: absolute;
  top: 49%;
  left: ${(props) => props.position};
  cursor: pointer;
  user-select: none;
`;

const Dot = styled.div`
  width: ${(props) => (props.selected ? "8px" : "6px")};
  height: ${(props) => (props.selected ? "8px" : "6px")};
  opacity: ${(props) => (props.selected ? 1 : 0.6)};
  background-color: #6a00ff;
  border-radius: ${(props) => (props.selected ? "4px" : "3px")};
  margin-right: 6px;
`;

const IMG = styled.img`
  margin-right: 10px;
  width: 40px;
`;
