import React from "react";
import { View, Text, Image, StyleSheet, Page } from "@react-pdf/renderer";
import Table from "../components/Table";
import Footer from "../components/Footer";
import Side from '../components/Side'

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#3076B8",
    marginBottom: 15,
  },
  page: {
      padding: 60,
  },
  p: {
    fontSize: 12,
    marginBottom: 10,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#3076B8",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 20,
  },
});

const tableTemplate = [
  { title: "Question", width: "46%" },
  { title: "Factor", width: "15%" },
  { title: "Anchor", width: "15%" },
  { title: "Norm", width: "8%" },
  { title: "Change", width: "8%" },
  { title: "Score", width: "8%" },
];

const Risks = (props) => {
  const { data } = props;
  const riskData = data.risks.map((risk) => {
    return [
      `${risk.question}`,
      `${risk.factor}`,
      `${risk.anchorName} (${risk.anchorType})`,
      `--`,
      `--`,
      `${Math.floor(risk.average * 10)}%`,
    ];
  });

  const strengthData = data.strengths.map((strength) => {
    return [
      `${strength.question}`,
      `${strength.factor}`,
      `${strength.anchorName} (${strength.anchorType})`,
      `--`,
      `--`,
      `${Math.floor(strength.average * 10)}%`,
    ];
  });

  const risks = {
    rows: tableTemplate,
    data: riskData,
  };

  const strengths = {
    rows: tableTemplate,
    data: strengthData,
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.T2}>Key Risks & Strengths</Text>
      <View style={styles.line} />

      <Text style={styles.p}>
        This table contains overall performance metrics for the nuLogic
         assessment. The following information
        appears on all nuLogic reports and is not specific to this
        organization's individual results
      </Text>

      <View wrap={false}>
        <Text style={styles.title}>Top Risks</Text>
        <Text style={styles.description}>
          Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
          tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
          swine, pork chop porchetta picanha spare ribs. Chicken jerky short
          ribs, pastrami pork fatback ground round spare ribs cow t-bone filet
          mignon hamburger leberkas shank. Meatball burgdoggen pork ribeye.
        </Text>

        <Table data={risks} />
      </View>

      <View wrap={false}>
        <Text style={styles.title}>Top Strengths</Text>
        <Text style={styles.description}>
          Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
          tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
          swine, pork chop porchetta picanha spare ribs. Chicken jerky short
          ribs, pastrami pork fatback ground round spare ribs cow t-bone filet
          mignon hamburger leberkas shank. Meatball burgdoggen pork ribeye.
        </Text>

        <Table data={strengths} />
      </View>

      <Footer />
      <Side/>
    </Page>
  );
};

export default Risks;
