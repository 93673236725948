import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { BasicErrorMessage } from "utilities/ErrorMessages";
import { PERSONAS, PERSONA_OPTIONS } from "members/audit/Personas.js";

import styled from "styled-components";
import { Segment, Loader, Dimmer, Dropdown, Divider } from "semantic-ui-react";
import Tabs from "components/Tabs";
import { useToasts } from "react-toast-notifications";

import { LockIcon, SaveIcon, XIcon, CategoryIcon } from "../Icons";

import { useNavigate } from "react-router";

import {
  SG_REGISTER_EMPLOYEE_CULTURE, // new employee
  SG_GET_EMPLOYEE_ORG_PERMISSIONS,
} from "constants/actions";

import { PERMISSION_TEXT } from "../../../constants/PERMISSION_TEXT";

const VALID_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ToggleComponent = (props) => {
  const handleChange = () => {
    props.handleCheck(!props.checked);
  };

  return (
    <ToggleLabel disabled={props.disabled}>
      <ToggleInput
        type="checkbox"
        onChange={() => !props.disabled && handleChange()}
        value={props.checked}
        checked={props.checked}
      />
      <ToggleSlider isChecked={props.checked} />
    </ToggleLabel>
  );
};

const EmployeeProfile = ({
  employee,
  userEmployee,
  setCategoryUpdated,
  setCategoryUpload,
}) => {
  const dispatch = useDispatch();
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [selectedPrimary, setSelectedPrimary] = useState([]);
  const { addToast } = useToasts();

  // this is current logged is user
  const [pending, setPending] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  // Is the current user an admin?
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [secondaryCategory, setSecondaryCategory] = useState([]);
  const [selectedSecondary, setSelectedSecondary] = useState({});

  // Will the new employee be an admin?
  const [isUserAdmin, setIsAdmin] = useState(false);
  const [notEnoughPriv, setNotEnoughPriv] = useState(false);
  const [editUsers, setEditUsers] = useState(false);
  const [pdfEnabled, setPDFEnabled] = useState(false);
  const [otpToggle, setOtpToggle] = useState(false);
  const [otpPrimaryOptions, setOtpPrimaryOptions] = useState([]);
  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);

  const [disableCategory, setDisableCategory] = useState(false);
  const [orgCategory, setOrgCategory] = useState([]);
  const [employeeCategories, setEmployeeCategories] = useState();

  const [categoryTempLoad, setCategoryTempLoad] = useState([]);
  const [show, setShow] = useState(null);

  const [errorMessage, setErrorMessage] = useState(false);

  const [deactiveReason, setDeactiveReason] = useState({
    value: "",
    error: "",
  });

  const [newEmpCategory, setNewEmpCategory] = useState([]);
  const [nonSportOrg, setNonSportOrg] = useState(false);
  const navigate = useNavigate();

  const [employeeCategory, setEmployeeCategory] = useState([]);
  const [employeeRecord, setEmployeeRecord] = useState([]);
  const [employeeLog, setEmployeeLog] = useState([]);
  const [employeeRecValue, setEmployeeRecValue] = useState([]);
  const [team, setTeam] = useState([]);
  const [teamOptions, setTeamOptions] = useState();
  const [primaryApiOptions, setPrimaryApiOptions] = useState();

  // PERSONAS
  const [cwfView, setCwfView] = useState(false);
  // const [showCwfWarning, setShowCwfWarning] = useState(false);
  const [persona, setPersona] = useState();
  const [newUserPersona, setNewUserPersona] = useState();
  const [personaOptions, setPersonaOptions] = useState();

  const [selectRow, setSelectRow] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    enable_login: false,
    email_validated: 1,
    survey_status: 9,
    survey_schedule: 0,
    survey_add_users: 0,
    account_type: 9,
    role: 11,
  });

  const [valueUpdated, setValueUpdated] = useState(false);
  // Permissions
  const [participateSurvey, setParticipateSurvey] = useState(false);
  const [accessDatalytics, setAccessDatalytics] = useState(false);
  const [accessSubfactor, setAccessSubfactor] = useState(false);
  const [readFeedback, setReadFeedback] = useState(false);
  const [scheduleSurveys, setScheduleSurveys] = useState(false);
  const [hasLogin, setHasLogin] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [isOtp, setIsOtp] = useState(false);
  const [otpDualView, setOtpDualView] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState();

  const [selectedTab, setSelectedTab] = useState(0);

  // Fields
  const [email, setEmail] = useState();

  const [canChangeAdmin, setCanChangeAdmin] = useState(false);
  const [showSelfAdminError, setShowSelfAdminError] = useState(false);
  const [empCategoryList, setEmpCategoryList] = useState();

  // TABS
  const settingsTabs = ["Profile", "Permissions", "Category Options"];
  const [tabs, setTabs] = useState(settingsTabs);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [canAccessData, setCanAccessData] = useState();

  const {
    // get_teams,
    get_error,
    get_auth,
    get_employee_category,
    get_survey_structure,
    get_organizations,
    get_org_id,
    get_employee_records,
    get_employee,
    get_employee_log,
    get_employee_record_value,
    get_employee_user_category,
    employee_org_permission,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employee: state.employees,
      get_teams: state.teams.teams,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      get_employee_category: state.employee_category.employee_category,
      get_survey_structure: state.surveystructure.survey_structure,
      get_org_id: state.auth.organization_id,
      get_organizations: state.organizations,
      get_employee_records: state.employee_record.employee_record,
      get_employee_log: state.employee_log.employee_log,
      get_employee_record_value:
        state.employee_record_value.employee_record_value,
      employee_org_permission: state.employee_permission,
    }),
    shallowEqual
  );

  const handlePersonaSelection = (value) => {
    setNewUserPersona(value);
    const options = PERSONAS.find((p) => p.value === value).options;
    // Set options based on inclusion of option.
    const enableLogin = options.includes("enable_login");
    const editUsers = options.includes("edit_users");
    const scheduleSurveys = options.includes("schedule_survey");
    const participateSurvey = options.includes("participate_survey");
    const readFeedback = options.includes("read_feedback");
    const accessSubfactor = options.includes("access_subfactor");
    const accessDatalytics = options.includes("access_datalytics");
    const isAdminOption = options.includes("is_admin");
    const pdfGenerate = options.includes("pdf_enabled");
    const otpView = options.includes("otp_view");
    const isCWF = options.includes("is_cwf");

    setHasLogin(enableLogin);
    setEditUsers(editUsers);
    setScheduleSurveys(scheduleSurveys);
    setParticipateSurvey(participateSurvey);
    setReadFeedback(readFeedback);
    setAccessSubfactor(accessSubfactor);
    setAccessDatalytics(accessDatalytics);
    setIsAdmin(isAdminOption);
    setPDFEnabled(pdfGenerate);
    handleOtpView(otpView, isCWF);
    setCwfView(isCWF);
    // setShowCwfWarning(isCWF);
  };

  useEffect(() => {
    if (!personaOptions && isOtp && userIsAdmin) {
      setPersonaOptions(PERSONAS);
    }

    if (!personaOptions && isOtp && persona && PERSONAS && !userIsAdmin) {
      const personaFilter = PERSONAS.filter((p) => p.id > persona.id);
      setPersonaOptions(personaFilter);
    }
  }, [personaOptions, persona, isOtp, userIsAdmin]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_auth?.employee_id, get_employee_user_category]);

  useEffect(() => {
    const optionsArray = [];
    if (
      currentUser &&
      isOtp &&
      filteredCategories &&
      get_survey_structure?.[0] &&
      employee_org_permission
    ) {
      currentUser?.access_data_analytics === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_datalytics);
      currentUser?.access_subfactor_data === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_subfactor);
      currentUser?.survey_add_users === 1 &&
        optionsArray.push(PERSONA_OPTIONS.edit_users);
      currentUser?.account_type === 5 &&
        optionsArray.push(PERSONA_OPTIONS.is_admin);
      currentUser?.enable_login === true &&
        optionsArray.push(PERSONA_OPTIONS.enable_login);
      currentUser?.read_feedback === 1 &&
        optionsArray.push(PERSONA_OPTIONS.read_feedback);
      currentUser?.survey_schedule === 1 &&
        optionsArray.push(PERSONA_OPTIONS.schedule_survey);
      currentUser?.survey_status === 1 &&
        optionsArray.push(PERSONA_OPTIONS.participate_survey);

      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const newTeamArr = primary?.options.map((o) => o.name);

      if (
        newTeamArr?.length > 0 &&
        newTeamArr.every((v) => filteredCategories.includes(v))
      ) {
        optionsArray.push(PERSONA_OPTIONS.otp_view);
        if (filteredCategories.length > newTeamArr.length) {
          optionsArray.push(PERSONA_OPTIONS.is_cwf);
        }
      }

      if (
        employee_org_permission &&
        employee_org_permission?.employee_org_permission.length > 0
      ) {
        const currentPermissions =
          employee_org_permission?.employee_org_permission.find(
            (p) => p.employee_id === currentUser?.id
          );

        if (currentPermissions?.auth_details) {
          const exists = currentPermissions?.auth_details.find((p) => {
            if (p?.name === "pdf_enabled") {
              return true;
            }
            if (p.length > 0) {
              return p[0].name === "pdf_enabled";
            }
            return false;
          });

          const isCWF =
            currentPermissions?.auth_details?.find((p) => {
              if (p?.name?.toLowerCase() === "cwf") {
                return true;
              }
            }) ||
            currentPermissions?.extra_details?.persona?.toLowerCase() === "cwf";

          if (isCWF) {
            optionsArray.push(PERSONA_OPTIONS.is_cwf);
            optionsArray.push(PERSONA_OPTIONS.otp_view);
          }

          if (exists || isCWF) {
            setPDFEnabled(true);
            optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
          }
        } else {
          setPDFEnabled(false);
        }
      }

      const persona = PERSONAS.find((p) => {
        return p.options.sort().join(",") === optionsArray.sort().join(",");
      });

      if (persona) {
        setPersona(persona);
      }
    }
  }, [
    currentUser,
    isOtp,
    filteredCategories,
    get_survey_structure,
    employee_org_permission,
  ]);

  // Onload dispatch calls
  useEffect(() => {
    if (
      isOtp &&
      get_auth?.organization_id &&
      !employee_org_permission?.employee_org_permission?.length > 0
    ) {
      dispatch({
        type: SG_GET_EMPLOYEE_ORG_PERMISSIONS,
        payload: `organization=${Number(get_auth?.organization_id)}`,
      });
    }
  }, [dispatch, get_auth?.organization_id, isOtp]);

  // If isOtp, check to see if account is OTP view.
  useEffect(() => {
    if (
      employeeCategories &&
      employeeCategories?.category &&
      get_survey_structure.length > 0 &&
      isOtp
    ) {
      let otpView = true;
      const primaryList = get_survey_structure?.[0]?.categories.filter(
        (c) => c.priority === "primary"
      )?.[0]?.options;

      const empCategories = employeeCategories?.category.map((ci) => ci?.value);

      setEmpCategoryList(empCategories);

      primaryList.forEach((li) => {
        if (!empCategories.includes(li.name)) {
          otpView = false;
        }
      });

      if (otpView === true && empCategories.length > primaryList.length) {
        setOtpDualView(true);
      }
    }
  }, [employeeCategories, isOtp, get_survey_structure]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (userIsAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value_id);

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  useEffect(() => {
    if (cwfView) {
      let showCwf = true;
      team.forEach((t) => {
        if (teamOptions.find((to) => to.text === t)) {
          showCwf = false;
        }
      });
    }
  }, [cwfView, team, persona]);

  useEffect(() => {
    setEmployeeCategory(
      get_employee_category.filter((ec) => ec.employee === employee?.id)?.[0]
    );
    setCategoryTempLoad(
      get_employee_category
        .filter((ec) => ec.employee === employee?.id)?.[0]
        ?.category.filter((ct) => ct.priority !== "primary")
    );
  }, [get_employee_category, setEmployeeCategory, employee]);

  const handleAdminChange = (value) => {
    if (canChangeAdmin) {
      setIsAdmin(value);
    }
  };

  useEffect(() => {
    setCanChangeAdmin(false);
    setUserIsAdmin(false);
    // if (get_auth && get_employee) {
    //   const found = get_employee?.employees.find((emp) => {
    //     return emp.id === Number(get_auth?.employee_id);
    //   });

    // if (found) {
    setCurrentUser(get_employee?.userEmp);
    // }

    if (get_employee?.userEmp?.account_type === 5) {
      setCanChangeAdmin(true);
      setUserIsAdmin(true);
    }

    if (get_employee?.userEmp?.survey_add_users === 1) {
      setCanEdit(true);
    }

    if (get_employee.userEmp?.access_data_analytics === 1) {
      setCanAccessData(true);
    }
  }, [get_employee]);

  useEffect(() => {
    setEmployeeRecord(get_employee_records);
  }, [get_employee_records]);

  useEffect(() => {
    setEmployeeLog(get_employee_log);
  }, [get_employee_log]);

  useEffect(() => {
    setEmployeeRecValue(get_employee_record_value);
  }, [get_employee_record_value]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure.length > 0) {
      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const filteredPrimaryOptions =
        (!userIsAdmin &&
          primary.options.filter((po) => {
            return filteredCategories?.includes(po.id);
          })) ||
        primary.options;

      if (primary) {
        let options = [];
        let primaryApiOptions = [];
        let otpOptions = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((cat) => {
            if (!cat.subfactors || cat.subfactors.length === 0) {
              options.push({
                key: cat.name,
                text: cat.name,
                value: cat.id,
              });
            } else {
              otpOptions.push({
                name: primary.name,
                name_id: primary.id,
                value: cat.name,
                priority: "primary",
                value_id: cat.id,
                id: cat.id,
              });
            }
          });

          filteredPrimaryOptions.forEach((cat) => {
            if (!cat.subfactors || cat.subfactors.length === 0) {
              primaryApiOptions.push({
                name: primary.name,
                name_id: primary.id,
                value: cat.name,
                priority: "primary",
                value_id: cat.id,
                id: primary.id,
              });
            }
          });
        }

        let subfactors = [];
        let apiSubfactors = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
                apiSubfactors.push({
                  name: primary.name,
                  name_id: primary.id,
                  value: sf.name,
                  priority: "primary",
                  value_id: sf.id,
                  id: primary.id,
                  group: sf.name,
                  mainGroup: o.name,
                  main_group_id: o.id,
                });
              });
            }
          });
        } else {
          primary.options.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
                apiSubfactors.push({
                  name: primary.name,
                  name_id: primary.id,
                  value: sf.name,
                  priority: "primary",
                  value_id: sf.id,
                  id: primary.id,
                  group: sf.name,
                  mainGroup: o.name,
                  main_group_id: o.id,
                });
              });
            }
          });

          subfactors = subfactors.filter((sf) => {
            return filteredCategories?.includes(sf.value);
          });
          apiSubfactors = apiSubfactors.filter((sf) => {
            return filteredCategories?.includes(sf.value_id);
          });
        }

        if (subfactors.length > 0) {
          options.push(...subfactors);
        }
        if (apiSubfactors.length > 0) {
          primaryApiOptions.push(...apiSubfactors);
        }

        if (otpDualView && !userIsAdmin) {
          const otpValues = otpOptions.map((o) => o.value_id);
          const filterValues = filteredCategories?.filter(
            (o) => !otpValues.includes(o)
          );
          setTeamOptions(options.filter((o) => filterValues.includes(o.value)));
          setPrimaryApiOptions(
            primaryApiOptions.filter((o) => filterValues.includes(o.value_id))
          );
          setOtpPrimaryOptions(otpOptions);
        } else {
          setPrimaryApiOptions(primaryApiOptions);
          setOtpPrimaryOptions(otpOptions);
          setTeamOptions(options);
        }
      }
    }
  }, [get_survey_structure, filteredCategories, userIsAdmin, persona]);

  useEffect(() => {
    if (get_organizations?.[get_org_id]?.org_category) {
      const _sport = get_organizations?.[get_org_id]?.org_category?.sport;
      if (_sport === "yes") {
        setNonSportOrg(false);
        return;
      }
      if (_sport === "no") {
        setNonSportOrg(true);
        return;
      }
      if (_sport === false) {
        setNonSportOrg(true);
        return;
      }
      setNonSportOrg(true);
    }

    if (get_organizations) {
      const isOTPType = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;

      setIsOtp(isOTPType);
    }
  }, [get_organizations, get_org_id]);

  function UpdateDeactivateReason(e) {
    setDeactiveReason({
      value: e,
      error: "",
    });
  }

  useEffect(() => {
    if (get_survey_structure.length > 0) {
      const _primaryList =
        get_survey_structure?.[0]?.field_map?.field_name.find(
          (f) => f.priority === "primary"
        );
      const _primarySelectList = [];
      get_survey_structure?.[0]?.field_map?.field_name
        .find((f) => f.priority === "primary")
        ?.answers?.map((lst, i) => {
          if (userIsAdmin) {
            _primarySelectList.push({
              value: lst.name,
              index: i,
              label: lst.name,
              name: _primaryList?.original,
              priority: "primary",
            });
          } else {
            if (userEmployeeCategoryList?.includes(lst.name)) {
              _primarySelectList.push({
                value: lst.name,
                index: i,
                label: lst.name,
                name: _primaryList?.original,
                priority: "primary",
              });
            }
          }
          return null;
        });

      setPrimaryCategory({
        original: _primaryList?.original,
        answers: _primarySelectList,
      });

      const _secondaryList =
        get_survey_structure?.[0]?.field_map?.field_name.find(
          (f) => f.priority === "secondary"
        );

      const _secondarySelectList =
        get_survey_structure?.[0]?.field_map?.field_name
          .find((f) => f.priority === "secondary")
          ?.answers?.map((lst, i) => {
            return {
              index: i,
              value: lst.name,
              label: lst.name,
              name: _secondaryList?.original,
              priority: "secondary",
            };
          });

      setSecondaryCategory({
        original: _secondaryList?.original,
        answers: _secondarySelectList,
      });

      const _orgCategory = [];
      get_survey_structure?.[0]?.categories
        .filter((f) => f.priority !== "primary" && f?.visibility)
        .map((og) => {
          if (og?.visibility === "public") {
            _orgCategory.push(og);
          }
          return null;
        });

      setOrgCategory(_orgCategory);
    }
  }, [get_survey_structure, isUserAdmin, userEmployeeCategoryList]);

  useEffect(() => {
    let notFound = 0;
    setDisableCategory(false);
    if (userEmployee?.account_type !== 5) {
      employeeCategory?.category
        ?.filter((em) => em.priority === "primary")
        .map((item) => {
          // console.log(item)
          if (!userEmployeeCategoryList.includes(item?.value)) {
            notFound += 1;
          }
          return null;
        });
      if (notFound > 0) {
        setDisableCategory(true);
      }
    }
  }, [userEmployeeCategoryList, employeeCategory, userEmployee]);

  useEffect(() => {
    const _selectedPrimaryList = [];
    let _selectedRoleList = {};
    if (employeeCategory?.category) {
      employeeCategory?.category.map((cat) => {
        if (primaryCategory?.answers) {
          const PC = primaryCategory?.answers?.find(
            ({ value }) => value === cat?.value
          );
          if (PC) {
            _selectedPrimaryList.push(PC);
          }
        }
        if (secondaryCategory?.answers) {
          const RC = secondaryCategory?.answers?.find(
            ({ value }) => value === cat?.value
          );
          if (RC) {
            _selectedRoleList = RC;
          }
        }
        return null;
      });
    }
    setSelectedSecondary(_selectedRoleList);
    setSelectedPrimary(_selectedPrimaryList);
  }, [primaryCategory, secondaryCategory, employeeCategory, userEmployee]);

  useEffect(() => {
    const _Category = get_employee_category.filter(
      (ec) => ec.employee === Number(get_auth?.employee_id)
    )?.[0]?.category;

    setUserEmployeeCategoryList(
      _Category
        ?.filter((ct) => ct?.priority === "primary")
        ?.map((ct) => ct.value)
    );
  }, [get_employee_category, get_auth]);

  useEffect(() => {
    const UploadFile = [];
    if (selectedPrimary?.length > 0) {
      selectedPrimary.map((item) => {
        return UploadFile.push({
          name: item.name,
          value: item.value,
          priority: item.priority,
        });
      });
    }

    if (categoryTempLoad?.length > 0) {
      categoryTempLoad.map((item) => {
        return UploadFile.push({
          name: item.name,
          value: item.value,
          priority: item.priority,
        });
      });
    }

    if (UploadFile.length > 0) {
      setCategoryUpdated(true);
    }

    setCategoryUpload(UploadFile);
  }, [
    selectedPrimary,
    categoryTempLoad,
    setCategoryUpload,
    setCategoryUpdated,
  ]);

  function ChangePrimaryCategory(e) {
    setValueUpdated(true);
    if (!e) {
      setSelectedPrimary([]);
    } else {
      setSelectedPrimary(e);
    }
    setCategoryUpdated(true);
  }

  function ResetRecieveSurvey() {
    setValueUpdated(true);
    if (selectRow?.survey_status === 1) {
      setSelectRow({
        ...selectRow,
        survey_status: 2,
      });
    } else {
      setSelectRow({
        ...selectRow,
        survey_status: 1,
      });
    }
  }

  function ResetAccessSubfactorData() {
    setValueUpdated(true);
    if (selectRow?.access_subfactor_data === 1) {
      setSelectRow({
        ...selectRow,
        access_subfactor_data: 0,
      });
    } else {
      setSelectRow({
        ...selectRow,
        access_subfactor_data: 1,
      });
    }
  }

  function ResetAccessDataAnalytics() {
    setValueUpdated(true);
    if (selectRow?.access_data_analytics === 1) {
      setSelectRow({
        ...selectRow,
        access_data_analytics: 0,
      });
    } else {
      setSelectRow({
        ...selectRow,
        access_data_analytics: 1,
      });
    }
  }

  function ChangeCategoryTag(e, org) {
    setValueUpdated(true);
    setCategoryUpdated(true);
    const UploadFile =
      categoryTempLoad?.filter((cl) => cl.name !== org.name) || [];
    const selectionName = org?.options.find((o) => o.id === e);
    const selectionIndex = org?.options.findIndex((o) => o.id === e);
    if (selectionName) {
      UploadFile.push({
        index: selectionIndex,
        name: org?.name,
        name_id: org?.id,
        priority: org?.priority,
        value: selectionName?.name,
        value_id: selectionName?.id,
      });
      // categoryTempLoad
    }
    setCategoryOptions(UploadFile);
    setCategoryTempLoad(UploadFile);
  }

  function ResetLoginEnabled() {
    setValueUpdated(true);
    if (selectRow?.enable_login === true) {
      setSelectRow({
        ...selectRow,
        enable_login: false,
      });
    } else {
      setSelectRow({
        ...selectRow,
        enable_login: true,
      });
    }
  }

  function ResetSurveySchedule() {
    setValueUpdated(true);
    if (selectRow?.survey_schedule !== 1) {
      setSelectRow({
        ...selectRow,
        survey_schedule: 1,
      });
    } else {
      setSelectRow({
        ...selectRow,
        survey_schedule: 0,
      });
    }
  }

  function ResetCanReadFeedback() {
    setValueUpdated(true);
    if (selectRow?.read_feedback === 1) {
      setSelectRow({
        ...selectRow,
        read_feedback: 0,
      });
    } else {
      setSelectRow({
        ...selectRow,
        read_feedback: 1,
      });
    }
  }
  function ResetSurveyUser() {
    setValueUpdated(true);
    if (selectRow?.survey_add_users !== 1) {
      setSelectRow({
        ...selectRow,
        survey_add_users: 1,
      });
    } else {
      setSelectRow({
        ...selectRow,
        survey_add_users: 0,
      });
    }
  }

  function ResetAdmin() {
    setValueUpdated(true);
    if (selectRow?.account_type === 5) {
      setSelectRow({
        ...selectRow,
        account_type: 9,
      });
    } else {
      setSelectRow({
        ...selectRow,
        account_type: 5,
      });
    }
  }

  const searchEmail = (e) => {
    setValueUpdated(true);

    setSelectRow({
      ...selectRow,
      email: e.target.value,
    });
    setEmail(e.target.value);

    if (VALID_EMAIL.test(e.target.value)) {
      setErrorMessage(false);
    } else {
      // invalid email, maybe show an error to the user.
      setErrorMessage("Not valid email id");
    }
  };
  // please  use this object this has all the information on the emp

  const getAuditObj = () => {
    // editUsers,
    return {
      enable_login: hasLogin,
      first_name: selectRow?.first_name,
      last_name: selectRow?.last_name,
      email: selectRow?.email,
      survey_add_users: editUsers ? 1 : 0,
      account_type: isUserAdmin ? 5 : 9,
      survey_schedule: scheduleSurveys ? 1 : 0,
      survey_status: participateSurvey ? 1 : 2,
      read_feedback: readFeedback ? 1 : 0,
      access_subfactor_data: accessSubfactor ? 1 : 0,
      access_data_analytics: accessDatalytics ? 1 : 0,
      role: 11,
      email_status: 1,
    };
  };

  useEffect(() => {
    if (get_error?.errors.length > 0) {
      addToast("Error while updating user", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [get_error]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;
    if (!selectRow?.email) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter an email",
      };
      totalErrors += 1;
    } else {
      if (!VALID_EMAIL.test(selectRow?.email)) {
        setErrorMessage("");
        ErrorList["email"] = {
          error: true,
          message: "Not valid email id",
        };
        totalErrors += 1;
      }
    }

    ErrorList["errors"] = totalErrors;

    setFormErrors(ErrorList);
  }, [selectRow, pristine]);

  const createNewUser = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    const payloadData = getAuditObj();
    let extraDetails = {};
    if (newUserPersona) {
      extraDetails = {
        persona: newUserPersona,
      };
    }
    setPending(true);
    if (pdfEnabled) {
      dispatch({
        type: SG_REGISTER_EMPLOYEE_CULTURE,
        payload: {
          organization: get_org_id,
          ...payloadData,
          new_category: [...newEmpCategory, ...categoryOptions],
          new_permission: [
            {
              name_id: "pdf",
              name: "pdf_enabled",
              value_id: "pdf",
              value: "PDF - Enabled",
              text: "Enabled PDF",
            },
            extraDetails,
          ],
          new_extra_details: extraDetails,
        },
      });
    } else {
      dispatch({
        type: SG_REGISTER_EMPLOYEE_CULTURE,
        payload: {
          organization: get_org_id,
          ...payloadData,
          new_category: [...newEmpCategory, ...categoryOptions],
        },
        new_extra_details: extraDetails,
      });
    }
  };

  useEffect(() => {
    if (get_employee?.created?.id) {
      addToast("Added user!", { appearance: "success", autoDismiss: true });
      const timer1 = setTimeout(() => {
        setPending(get_employee?.pending);
        // console.log("created")
        // edit/5
        navigate(`/core/members/edit/${get_employee?.created?.id}`);
        // navigate("/core/members/list");
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [addToast, navigate, get_employee]);

  useEffect(() => {
    if (get_survey_structure[0]?.categories) {
      const otherCategories = get_survey_structure[0]?.categories?.filter(
        (c) =>
          c.priority !== "primary" &&
          c.visibility !== "private" &&
          c.visibility !== ""
      );

      if (otherCategories.length === 0) {
        setTabs(["Profile", "Permissions"]);
      }
    }
  }, [get_survey_structure]);

  useEffect(() => {
    if (employee?.id) {
      setSelectRow({
        id: employee?.id,
        first_name: employee?.first_name,
        last_name: employee?.last_name,
        email: employee?.email,
        enable_login: employee?.enable_login,
        survey_status: employee?.survey_status,
        survey_schedule: employee?.survey_schedule,
        survey_add_users: employee?.survey_add_users,
        access_subfactor_data: employee?.access_subfactor_data,
        access_data_analytics: employee?.access_data_analytics,
        account_type: employee?.account_type,
        read_feedback: employee?.read_feedback,
        // role: employee?.role,
      });
    } else {
      setSelectRow({
        first_name: "",
        last_name: "",
        email: "",
        enable_login: false,
        email_status: 1,
        survey_status: 9,
        survey_schedule: 0,
        survey_add_users: 0,
        read_feedback: 0,
        access_subfactor_data: 0,
        access_data_analytics: 0,
        account_type: 9,
        role: 11,
      });
    }
  }, [employee, setSelectRow]);

  function ChangeFirstNameValue(value) {
    setValueUpdated(true);
    setSelectRow({
      ...selectRow,
      first_name: value,
    });
  }

  function ChangeLastNameValue(value) {
    setValueUpdated(true);
    setSelectRow({
      ...selectRow,
      last_name: value,
    });
  }

  useEffect(() => {
    setNotEnoughPriv(false);
    if (userEmployee?.account_type > employee?.account_type) {
      setNotEnoughPriv(true);
    }
  }, [userEmployee, employee]);

  const handleTeamSelection = (value) => {
    const _newTeamList = [];
    let apiOptions = primaryApiOptions;
    value.map((v) => {
      return apiOptions
        .filter((t) => t.value_id === v)
        .map((p) => {
          return _newTeamList.push({
            name: p.name,
            value: p.value,
            name_id: p.name_id,
            priority: "primary",
            value_id: p.value_id,
            id: p.id,
            mainGroup: p?.mainGroup,
            main_group_id: p?.main_group_id,
          });
        });
    });

    if (otpToggle) {
      otpPrimaryOptions.forEach((p) => {
        return _newTeamList.push({
          name: p.name,
          value: p.value,
          name_id: p.name_id,
          priority: "primary",
          value_id: p.value_id,
          id: p.id,
          mainGroup: p?.mainGroup,
          main_group_id: p?.main_group_id,
        });
      });
    }

    setNewEmpCategory(_newTeamList);
    let newTeamArr = value
      .filter((v) => !!v)
      .map((v) => {
        return teamOptions.find((t) => t.value === v).text;
      });
    const teamsTemp = [...team];
    const tIndex = teamsTemp.findIndex((t) => t === newTeamArr);
    if (tIndex > -1) {
      teamsTemp.splice(tIndex, 1);
    } else {
      teamsTemp.push(...newTeamArr);
    }

    if (otpToggle) {
      const otpOptions = otpPrimaryOptions.map((o) => o.value);
      newTeamArr = [...newTeamArr, ...otpOptions];
    }
    setTeam(newTeamArr);
  };

  const handleOtpView = (s, cwf) => {
    const primary = get_survey_structure[0]?.categories?.find(
      (c) => c.priority === "primary"
    );

    const newTeamArr = primary?.options.map((o) => o.name);

    if (s) {
      if (cwf) {
        const newTeam = [...team, ...newTeamArr];
        setTeam(newTeam);
      } else {
        const newTeam = [...newTeamArr];
        setTeam(newTeam);
      }

      const _newTeamList = [];
      otpPrimaryOptions.forEach((p) => {
        return _newTeamList.push({
          name: p.name,
          value: p.value,
          name_id: p.name_id,
          priority: "primary",
          value_id: p.value_id,
          id: p.id,
          mainGroup: p?.mainGroup,
          main_group_id: p?.main_group_id,
        });
      });
      setNewEmpCategory(_newTeamList);
    } else {
      const newTeam = [...team].filter((t) => {
        return newTeamArr.some((ts) => ts !== t);
      });
      setTeam(newTeam);
    }
    setOtpToggle(s);
    if (cwfView && s) {
      // setShowCwfWarning(true);
    }
  };

  const addAllCategories = () => {
    const _newTeamList = [];
    primaryApiOptions.forEach((p) => {
      return _newTeamList.push({
        name: p.name,
        value: p.value,
        name_id: p.name_id,
        priority: "primary",
        value_id: p.value_id,
        id: p.id,
        mainGroup: p?.mainGroup,
        main_group_id: p?.main_group_id,
      });
    });

    setNewEmpCategory(_newTeamList);
    let newTeamArr = teamOptions.map((v) => {
      return v.text;
    });

    const teamsTemp = [...team];
    const tIndex = teamsTemp.findIndex((t) => t === newTeamArr);
    if (tIndex > -1) {
      teamsTemp.splice(tIndex, 1);
    } else {
      teamsTemp.push(...newTeamArr);
    }

    if (persona && persona === "CWF") {
      otpPrimaryOptions.forEach((p) => {
        return _newTeamList.push({
          name: p.name,
          value: p.value,
          name_id: p.name_id,
          priority: "primary",
          value_id: p.value_id,
          id: p.id,
          mainGroup: p?.mainGroup,
          main_group_id: p?.main_group_id,
        });
      });

      otpPrimaryOptions.forEach((p) => newTeamArr.push(p.value));
      setNewEmpCategory(_newTeamList);
    }
    setTeam(newTeamArr);
  };

  const removeAllCategories = () => {
    setNewEmpCategory();
    setTeam([]);
  };

  const handleCancel = () => {
    navigate("/core/members/list");
  };

  return (
    <Container>
      <TopSection>
        <FixedButtons>
          <SaveControls>
            <CancelButton onClick={() => handleCancel()}>
              <CancelButtonText>Cancel</CancelButtonText>
              <CancelButtonIcon>{XIcon()}</CancelButtonIcon>
            </CancelButton>
            <SaveButton
              // showCwfWarning={showCwfWarning}
              onClick={() => createNewUser()}
            >
              <SaveIconWrapper>{SaveIcon()}</SaveIconWrapper>
              <SaveText>Save</SaveText>
            </SaveButton>
          </SaveControls>
        </FixedButtons>
      </TopSection>
      <BasicErrorMessage />
      <Segment basic>
        <Dimmer active={pending}>
          <Loader content="saving ..." />
        </Dimmer>

        <FormContainer>
          <FormSection>
            <>
              <TopTitle>
                {employee?.id ? "Update Employee" : "Invite Member"}
              </TopTitle>
              <Divider />
              <TabsContainer>
                <Tabs
                  options={tabs}
                  onSelect={(item) => setSelectedTab(item)}
                  selected={selectedTab}
                  isOtp={isOtp}
                />
              </TabsContainer>
              <Divider />
            </>
            {tabs[selectedTab] === "Profile" && (
              <FieldsContainer>
                <RowInfo>
                  <RowLabel>First Name</RowLabel>
                  <RowDataNames
                    value={selectRow?.first_name || ""}
                    //placeholder="John"
                    onChange={(e) => ChangeFirstNameValue(e.target.value)}
                  />
                </RowInfo>
                <RowInfo>
                  <RowLabel>Last Name</RowLabel>
                  <RowDataNames
                    value={selectRow?.last_name || ""}
                    //placeholder="Smith"
                    onChange={(e) => ChangeLastNameValue(e.target.value)}
                  />
                </RowInfo>
                <RowInfo>
                  <RowLabel>Email</RowLabel>
                  <FlexRowContainer>
                    <RowData
                      value={selectRow?.email || ""}
                      //placeholder="johnsmith@example.com"
                      onChange={(e) => searchEmail(e)}
                    />
                    {formErrors.email?.error && !pristine && (
                      <ErrorLabel>{formErrors.email?.message}</ErrorLabel>
                    )}
                  </FlexRowContainer>
                </RowInfo>
                {(userIsAdmin || canEdit) && isOtp && personaOptions && (
                  // <RowInfo>
                  //   <RowLabel>{primaryApiOptions?.[0]?.name}</RowLabel>
                  //   <SelectionContainer>
                  //     <ToggleComponent
                  //       toggle
                  //       checked={otpToggle}
                  //       handleCheck={(s) => handleOtpView(s)}
                  //     />
                  //     <ControlDescription>
                  //       <DescriptionTitle>Enable OTP View</DescriptionTitle>
                  //       <DescriptionText>
                  //         Member will access all of the OTP Targets, rather than
                  //         sport specific.
                  //       </DescriptionText>
                  //     </ControlDescription>
                  //   </SelectionContainer>
                  // </RowInfo>
                  <RowInfo>
                    <RowLabel>Permissions Profile</RowLabel>
                    <SelectionContainer>
                      <Dropdown
                        placeholder={`Select a Permission`}
                        fluid
                        selection
                        options={personaOptions}
                        value={newUserPersona}
                        style={{
                          borderRadius: 10,
                          fontFamily: "Poppins",
                        }}
                        onChange={(e, t) => {
                          handlePersonaSelection(t.value);
                        }}
                      />
                    </SelectionContainer>
                  </RowInfo>
                )}
                {persona !== "OTP Admin" && (
                  <>
                    <RowInfo>
                      <RowLabel>{primaryCategory?.original}</RowLabel>
                      <SelectionContainer>
                        <Dropdown
                          placeholder={`Select ${primaryCategory?.original}`}
                          onChange={(e, t) => {
                            handleTeamSelection(t.value);
                          }}
                          value={team.map((t) => {
                            return teamOptions?.find((op) => {
                              return op.text === t;
                            })?.value;
                          })}
                          fluid
                          selection
                          multiple
                          options={teamOptions}
                          style={{
                            borderRadius: 10,
                            fontFamily: "Poppins",
                          }}
                        />
                      </SelectionContainer>
                      {/* {showCwfWarning && (
                      <ToolTipDesc>
                        <Popup
                          trigger={
                            <Icon circular color="red" name="warning sign" />
                          }
                          content="CWF Requires at least one team to be selected."
                          position="top center"
                          inverted
                        />
                      </ToolTipDesc>
                    )} */}
                    </RowInfo>
                    <AddAllControls>
                      <CancelButton onClick={() => removeAllCategories()}>
                        <CancelButtonText>Remove All</CancelButtonText>
                        <CancelButtonIcon>{XIcon()}</CancelButtonIcon>
                      </CancelButton>
                      <SaveButton onClick={() => addAllCategories()}>
                        <SaveText>Add All</SaveText>
                      </SaveButton>
                    </AddAllControls>
                  </>
                )}
              </FieldsContainer>
            )}
            {tabs[selectedTab] === "Permissions" && !isOtp && (
              <PermissionsSection>
                <PermissionsHeader>
                  <IconWrapper>{LockIcon()}</IconWrapper>
                  <PermissionsLabel>Permission Controls</PermissionsLabel>
                </PermissionsHeader>
                <PermissionsControls>
                  {/* Can participate in surveys */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={participateSurvey}
                      handleCheck={(s) => setParticipateSurvey(s)}
                    />
                      <ControlDescription>
                        <DescriptionTitle>{PERMISSION_TEXT.SURVEY_PARTICIPATION.TITLE}</DescriptionTitle>
                        <DescriptionText>{PERMISSION_TEXT.SURVEY_PARTICIPATION.DESC}</DescriptionText>
                      </ControlDescription>
                  </ControlWrapper>
                  {/* Has a Login Account */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={hasLogin}
                      handleCheck={(s) => setHasLogin(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>{PERMISSION_TEXT.LOGIN_TO_PORTAL.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.LOGIN_TO_PORTAL.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>

                  {/* Access Subfactor Data */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={accessDatalytics}
                      handleCheck={(s) => setAccessDatalytics(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>{PERMISSION_TEXT.DATA_ANALYTICS.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.DATA_ANALYTICS.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Access Data Analytics */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={accessSubfactor}
                      handleCheck={(s) => setAccessSubfactor(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>{PERMISSION_TEXT.SUBFACTOR_DATA.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.SUBFACTOR_DATA.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can Read Feedback */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={readFeedback}
                      handleCheck={(s) => setReadFeedback(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>{PERMISSION_TEXT.READ_FEEDBACK.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.READ_FEEDBACK.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can Edit Users */}
                  <ControlWrapper>
                    <ToggleComponent
                      checked={editUsers}
                      handleCheck={(s) => setEditUsers(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>{PERMISSION_TEXT.EDIT_USERS.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.EDIT_USERS.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can Schedule Surveys */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={scheduleSurveys}
                      handleCheck={(s) => setScheduleSurveys(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>{PERMISSION_TEXT.SCHEDULE_SURVEYS.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.SCHEDULE_SURVEYS.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Is an Account Administrator */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={isUserAdmin}
                      disabled={!canChangeAdmin}
                      handleCheck={(s) => handleAdminChange(s)}
                    />
                    <ControlDescription disable={showSelfAdminError}>
                      <DescriptionTitle>{PERMISSION_TEXT.ADMIN_ACCOUNT.TITLE}</DescriptionTitle>
                      <DescriptionText>{PERMISSION_TEXT.ADMIN_ACCOUNT.DESC}</DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {showSelfAdminError && (
                    <ErrorLabel>
                      You cannot remove your self as admin, doing so would
                      result in you being locked out of your account.
                    </ErrorLabel>
                  )}
                </PermissionsControls>
              </PermissionsSection>
            )}
            {((tabs[selectedTab] === "Category Options" &&
              orgCategory.length > 0) ||
              (tabs[selectedTab] !== "Profile" && isOtp)) && (
                <>
                  <CategorySection>
                    <CategoryHeader>
                      <IconWrapper>{CategoryIcon()}</IconWrapper>
                      <CategoryLabel>Category Tags</CategoryLabel>
                    </CategoryHeader>
                    <CategoryRowInfo>
                      <PermissionsControls>
                        {orgCategory &&
                          orgCategory.map((org, i) => {
                            let _selectedCategory = null;

                            const testVal = categoryTempLoad?.filter((cl) => {
                              return cl.name_id === org.id;
                            })?.[0];
                            if (testVal) {
                              const _selectedValue = categoryTempLoad.filter(
                                (cl) => cl.name_id === org.id
                              )?.[0]?.value_id;
                              _selectedCategory = org?.options.filter((op) => {
                                return op.id === _selectedValue;
                              });
                            }

                            const options = org?.options.map((o) => {
                              return {
                                key: o.name,
                                text: o.name,
                                value: o.id,
                              };
                            });

                            const selected = options.find((o) => {
                              return o.text === _selectedCategory?.[0]?.name;
                            })?.value;

                            return (
                              <ControlWrapper key={i}>
                                <CategoryTag>{org?.name}</CategoryTag>
                                <TableContent>
                                  <SelectionContainer>
                                    <Dropdown
                                      placeholder={`Select ${org?.name}`}
                                      fluid
                                      selection
                                      clearable
                                      onChange={(e, t) =>
                                        ChangeCategoryTag(t.value, org)
                                      }
                                      value={selected}
                                      isClearable={true}
                                      style={{
                                        borderRadius: 100,
                                        fontFamily: "Poppins",
                                      }}
                                      // isMulti
                                      options={options}
                                    />
                                  </SelectionContainer>
                                </TableContent>
                              </ControlWrapper>
                            );
                          })}
                      </PermissionsControls>
                    </CategoryRowInfo>
                  </CategorySection>
                </>
              )}
          </FormSection>
        </FormContainer>
      </Segment>
    </Container>
  );
};

export default EmployeeProfile;

const SelectionContainer = styled.div`
  display: flex;
  min-width: 376px;
  max-width: 768px;
`;

const SaveIconWrapper = styled.div`
  padding-right: 12px;
  cursor: pointer;
`;

const SaveText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 30px;
  margin-bottom: -16px;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FixedButtons = styled.div`
  bottom: 10px;
  padding-left: 30px;
  align-items: flex-end;
  right: 70px;
  position: absolute;
  top: 80px;
`;

const ToolTipDesc = styled.span`
  padding: 5px;
  cursor: pointer;
`;

const SaveControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AddAllControls = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 195px;
  align-items: center;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 45px;
  cursor: pointer;
`;

const CancelButtonIcon = styled.div`
  line-height: 15px;
  padding-top: 1px;
  padding-left: 5px;
  cursor: pointer;
`;

const CancelButtonText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #c0c0c0;
  cursor: pointer;
`;

const Container = styled.div`
  margin-left: 10px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const PermissionsSection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const PermissionsHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div``;

const PermissionsLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const PermissionsControls = styled.div``;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 5px;
`;

const SaveButton = styled.div`
  width: 120px;
  height: 30px;
  cursor: pointer;

  background: #476dfa;
  border-radius: 100px;
  /* Auto layout */
  opacity: ${(props) => (props.showCwfWarning ? 0.4 : 1)};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ControlDescription = styled.div`
  padding-left: 20px;
  width: 409px;
  height: 38px;
  opacity: ${(props) => (props.disable ? 0.4 : 1)};
`;

const DescriptionTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const ToggleSlider = styled.div`
  position: relative;
  width: 40px;
  height: 16px;
  background: #e7e7e7;
  border-radius: 26.6667px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 15px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${(props) => (props.isChecked ? "#66d686" : "#c0c0c0")};
    transform: translate(0, -50%);
  }
`;

const ToggleInput = styled.input`
  display: none;

  &:checked + ${ToggleSlider} {
    background: #e7e7e7;

    &:before {
      transform: translate(20px, -50%);
    }
  }
`;

const DescriptionText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #7e7e7e;
`;

const ErrorLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height */
  align-self: center;
  color: red;
`;

const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const FieldsContainer = styled.div`
  flex: 1;
  padding-bottom: 50px;
`;

const TopTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 83px;

  color: #414141;
`;

const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  padding-left: 83px;
  height: 100%;
`;

const RowLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: 150px;
  padding-top: 15px;
  /* identical to box height */
  color: #6d6d6d;
`;

const RowData = styled.input`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px 12px 32px;
  gap: 10px;
  width: 376px;
  height: 48px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;

  color: #9e9fa1;

  border: 1px solid #dfdfdf;
  border-radius: 99px;
`;

const RowDataNames = styled(RowData)`
  text-transform: capitalize;
`;


const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  margin-left: ${(props) => (props.inValid ? "10px" : "30px")};
  color: ${(props) => (props.inValid ? "#ff2d55" : "#484545")};
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  // font-family: "Barlow", sans-serif;
  color: #ff0000;
`;

const Warning = styled.div`
  margin-left: 30px;
  // padding-left: 20px;
`;

const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Barlow", sans-serif;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0px 200px 0px;
    width: 100%;
  }
`;

const FormSection1 = styled.div`
  margin-right: 60px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const FormSection = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const ShowArea = styled.div`
  max-height: ${(props) => (props.show ? "1000px" : 0)};
  transition: all 1s;
  overflow: ${(props) => (props.show ? "visible" : "hidden")};
  padding-bottom: ${(props) => (props.show ? "30px" : 0)};
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 10px;
`;

const CategorySection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

const CategoryLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const CategoryRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  width: 566px;
  justify-content: space-between;
`;

const CategoryTag = styled.div`
  width: 250px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const TableContent = styled.div`
  width: 75%;
  padding-left: 80px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-right: 150px;
  color: #7e7e7e;
`;
