import {
  ROOM_LOADING,
  CREATE_CHAT_ROOM,
  FETCH_CHAT_ROOM,
  FETCH_SUCCESS,
  GET_CHAT_ROOM,
  DELETE_CHAT_ROOM,
  CLEAR_DELETE_MESSAGE,
} from "../constants/actions";

const initialState = {
  chatData: null,
  isLoading: true,
  error: null,
  deleted: null,
  chat_topics: [],
  pending_chat_topic: null,
  chat_history: null,
};

export default function chatTopics(state = initialState, action) {
  switch (action.type) {
    case ROOM_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CHAT_ROOM:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        pending_chat_topic: null,
        error: null,
      };
    case CREATE_CHAT_ROOM:
      if (!action.payload.error) {
        const pendingTopic = {
          id: action.payload.id,
          name: action.payload.data,
          employee_id: action.payload.employee_id,
          created_at: new Date(),
        };
        return {
          ...state,
          created: action.payload,
          chat_topics: [...state.chat_topics, pendingTopic],
          pending_chat_topic: action.payload.id,
          isLoading: false,
          error: action.payload.error,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          pending_chat_topic: null,
          error: action.payload.error,
        };
      }
    case FETCH_CHAT_ROOM:
      return {
        ...state,
        chat_history: action.payload,
        pending_chat_topic: null,
        [action.payload.id]: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case DELETE_CHAT_ROOM:
      const deleted = state.chat_topics.filter(
        (topic) => topic.id !== action.payload.id
      );
      return {
        ...state,
        chat_topics: deleted,
        isLoading: false, deleted: action.payload
      };
    case CLEAR_DELETE_MESSAGE:
      return { ...state, isLoading: false, deleted: null };
    default:
      return state;
  }
}
