import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress,Dropdown } from 'semantic-ui-react'

import Chart from "react-apexcharts";

const CardData = ({data,color}) =>{
	const [series,setSeries] = useState([{'name':'data',data:data.distribution}])

	return (
		<Container>
			<Title>Score Statistics</Title>
			<Chart options={data2.options} series={series} type="bar" width={'100%'} />
			<div style={{width:'100%',marginTop:0}}>
				

{/*			<Values>
				<Val>	
					<Num>2.3</Num>
					<Sub>Stdev</Sub>
				</Val>
				<Val>	
					<Num>43rd</Num>
					<Sub>Canadian Norm</Sub>
				</Val>
				<Val>	
					<Num>66th</Num>
					<Sub>Sport Norm</Sub>
				</Val>
				<Val noBorder={true}>	
					<Num>85th</Num>
					<Sub>Role Norm</Sub>
				</Val>
			</Values>*/}


			<Progression>
				<Title>Standard Deviation</Title>
				<StyledProgressBar  color={color} progress='value' value={3.5} total={10} size="small" style={{margin:0,marginBottom:10}}/>

				<Title>Canadian Norm</Title>
				<StyledProgressBar  color={color} progress percent={50}  size="small"  style={{margin:0,marginBottom:10}}/>

				<Title>Sport Norm</Title>
				<StyledProgressBar  color={color} progress percent={75} size="small"  style={{margin:0,marginBottom:10}}/>

				<Title>Role Norm</Title>
				<StyledProgressBar  color={color} progress percent={30} size="small" style={{margin:0,marginBottom:10}}/>
			</Progression>
			</div>

		

		</Container>
		)
}


export const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || 'green'} !important;
  }
`;

export default CardData

const Container = styled.div`
	width:500px;
	padding:10px;

`

const Title = styled.div`
	font-size:10px;
	text-transform:uppercase;
	font-weight:bold;
`



const Legend = styled.div`
	align-items:center;
	width:800px;
	margin:20px;
	justify-content:space-between;
	display:${props=>props.show?'flex':'none'};
`

const Circle = styled.div`
	height:15px;
	width:15px;
	border-radius:7.5px;
	background-color:${props=>props.color};
	border:2px solid black;
`

const Text = styled.div`


`

const Row = styled.div`
	display:flex;
	width:100%;
`

const Values = styled.div`
	width:100%;
	border:1px solid black;
	display:flex;
`

const Val = styled.div`
	border-right:${props=>props.noBorder?'none':'1px solid black'};
	padding:5px;
	width:25%;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Sub = styled.div`
	font-size:10px;
`

const Num = styled.div`
	font-weight:bold;
	font-size:14px;
`

const Progression = styled.div`

`
const data2 = {
	series: [

              {
                name: "Scores",
                data: [0,0,0,0,1,2,8,3,2,0,1]
              }
            ],
            options: {
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: false,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left',
              	show:false
              },
              colors:['#0387EF','#FFC600','#FF0000',"#02AE5B"],
              fill:{
              	opacity:0.3
              },
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               dataLabels: {
			  enabled: false
			},
			stroke: {
			  curve: "smooth"
			},
              xaxis: {
                categories: ['0','1','2','3','4','5','6','7','8','9','10'],
              },
              yaxis: {
                title: {
                  text: 'Score frequency'
                },
                 decimalsInFloat: 0,
              },
            }
}