import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Message, Icon, Loader } from "semantic-ui-react";
// import ToolBox from './ToolBox'
// import Logo from 'assets/images/cog.png'
// import { Transition } from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import { Dimmer } from "semantic-ui-react";

import { pathList } from "routes/routeMap"


const OnBoarding = () => {
  let navigate = useNavigate();
  // const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoutes, setValidRoutes] = useState([])

  const { get_auth,
    get_organizations,
     get_selectedOrg,
     WhiteLabel } = useSelector(
      (state) => ({
        get_auth: state.auth,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
        WhiteLabel:state.white_label?.white_label
      }),
      shallowEqual
    );

  const AmbassadorId = useSelector((state) => Number(state.auth?.ambassador_id), shallowEqual)

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId])
    } else {
      setOrganization(false)
    }

  }, [organizationId, get_organizations]);

  useEffect(() => {

    const EnabledList = []
    pathList.map(path => {
      let Found = organization?.services_enabled?.filter(
        srv => srv?.name === path?.name && srv?.enabled === true
      )?.[0]
      if (Found?.id > 0) {
        EnabledList.push(path.name)
      }
      return null
    })

    setValidRoutes(EnabledList)

    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000)
    return () => clearTimeout(timer1)

  }, [organization]);

  // console.log(get_auth)
  // console.log(organizationId)
  // the routes for for followed top down
  useEffect(() => {
    if (!loading) {
      if(get_auth?.token === "undefined") {
        return navigate("/account/logout");
      }
      if (Number(AmbassadorId) > 0 && !organizationId) {
        return navigate("/ambassador");
      }
      if (validRoutes.includes("Teamtool")) {
        return navigate("/app/team/dashboard")
      }
      if (validRoutes.includes("Audit") && WhiteLabel?.survey_logo) {
        return navigate("/app/audit")
      }
      if (validRoutes.includes("Audit")) {
        return navigate("/app/audit/dashboard")
      }
      if (validRoutes.includes("EDI")) {
        return navigate("/app/edi/analytics")
      }
      if (validRoutes.includes("OTP")) {
        return navigate("/app/otp")
      }
    }
  }, [get_auth, loading, AmbassadorId, validRoutes, navigate, organizationId]);



  return (
    <>

      <Container
      ><TopBanner>
          <Left>
            <SubTitle>Please wait while we load your data ...</SubTitle>

          </Left>

        </TopBanner>

        <Dimmer.Dimmable blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>





        </Dimmer.Dimmable>
      </Container>

    </>
  )
}


export default OnBoarding


const Container = styled.div`
	width:100%;
  margin-top:30px;

   @media(max-width:768px){
    margin-top:0;
   }
`

const TopBanner = styled.div`
	width:100%;
	height:200px;
	display:flex;
	justify-content:center;
	flex-direction:row;
	font-family: 'Red Hat Display', sans-serif;
	position:relative;
	background-color:rgba(0, 176, 240,0.1);

 
    flex-wrap:wrap;
    height:auto;
  }

`

const Left = styled.div`
	width:50%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding:40px;

  @media(max-width:768px){
    width:100%;
  }
`

const Title = styled.div`
	font-size:30px;
	font-weight:bold;
  line-height:1;

  @media(max-width:768px){
    font-size:28px;
  }
`

const Description = styled.div`
	margin-top:30px;
	max-width:800px;
	font-size:18px;
	line-height:1.3;
`

const Row = styled.div`
	display:flex;
	margin-top:30px;
`

const Block = styled.div`
	background-color:#00B0F0;
	height:300px;
	width:200px;
	display:flex;
	align-items:center;
	justify-content:center;
`

const Text = styled.div`
	color:white;
	font-size:40px;
	line-height:1;
	text-align:center;

`

const Block2 = styled.div`
	height:300px;
	width:200px;

`

const BlockTitle = styled.div`
	font-size:20px;
	font-weight:bold;
`

const SubTitle = styled.div`
	color:#545454;
  margin-top:20px;
	margin-bottom:20px;
	font-size:16px;
`

const Image = styled.img`
	height:150px;
`

const Right = styled.div`
	width:50%;
	position:relative;
	display:flex;
	align-items:center;
	justify-content:center;

  @media(max-width:768px){
   display:none;
  }
`

const Upgrade = styled.span`
  display: flex;
  padding: 5px 10px 5px 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
  border-radius: 10px;
  font-weight: 900;
  margin-left: 30px;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.14em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  justify-content: space-between;
`;