import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon, Progress, Dimmer, Loader, Pagination } from "semantic-ui-react";
import Insight from "./Insight";
import Filter from "./Filter";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";

import { combo_effect_size_calculation, all_effect_size_calculations } from "../Analytics/calculations";
import { SURVEY_INFO } from "constants/surveys";
import Empty from "../Empty";
import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
} from "constants/actions";

const ES_levels = [0, 0.2, 0.5, 0.8, 1.2];

const Insights = (props) => {
  const [risk, setRisk] = useState(1);
  const [previousRisk, setPreviousRisk] = useState(null);
  const [selected, setSelected] = useState("Overall");
  const risks = ["None", "Modest", "Moderate", "High", "Very High"];
  const colors = ["grey", "yellow", "orange", "red", "red"];
  const [data, setData] = useState([]);
  const [dataRef, setRef] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [recentResponses, setRecentResponses] = useState();
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState();
  const [EDIQues, setEDIQues] = useState(null);
  const [questions, setQuestions] = useState();
  const [categories, setCategories] = useState();

  const responseSet = recentResponses?.filter((set) => {
    return set.responses.length > 0;
  });

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const {
    auth,
    surveyStructure,
    selectedOrg,
    ediResponses,
    surveyQuestions,
    get_organizations,
  } = useSelector(
    (state) => ({
      errors: state.errors,
      auth: state.auth,
      surveyQuestions: state.surveyquestions.survey_questions,
      surveyStructure: state.surveystructure.survey_structure,
      selectedOrg: state.selectedOrg,
      ediResponses: state.debrief_schedule.culture_audit_reports,
      get_organizations: state.organizations,
    }),
    shallowEqual
  );

  const getTertiary = (name, id) => {
    return surveyStructure[0]?.categories.filter((f) => f.name === name)[0]
      .options[id].name;
  };

  useEffect(() => {
    //Determine which survey is being used in the org data
    if (get_organizations && organizationId && !EDIQues) {
      const organization = get_organizations[organizationId];
      const ediQues =
        organization?.services_enabled &&
        organization?.services_enabled.filter((sc) => {
          return sc?.name === SURVEY_INFO.edi?.name;
        })?.[0]?.question_sort_order;
      if (ediQues) {
        setEDIQues(ediQues);
      } else setEDIQues(0);
    }
  }, [get_organizations, organizationId]);

  useEffect(() => {
    if (EDIQues && EDIQues > 0 && surveyQuestions[0] && !questions) {
      const SurveyQues = surveyQuestions.find((sq) => {
        return sq.sort_order === EDIQues;
      });
      setQuestions(SurveyQues.questions);
    } else if (surveyQuestions[0] && EDIQues === 0) {
      setQuestions(surveyQuestions[0].questions);
    }
  }, [surveyQuestions, EDIQues]);

  useEffect(() => {
    if (
      !recentResponses &&
      ediResponses.response &&
      ediResponses.response.length > 0
    ) {
      let responseList = [];

      const dataSet = ediResponses.response.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });

      dataSet.forEach((responseSet) => {
        if (responseSet.responses.length > 0) {
          const exists = responseList.findIndex(
            (response) => response.name === responseSet.name
          );
          if (exists === -1) {
            responseList.push(responseSet);
          }
          if (responseList.length === 0) {
            responseList.push(responseSet);
          }
        }
      });
      setRecentResponses(responseList);
      setLoading(false);
    }
  }, [ediResponses, recentResponses]);

  useEffect(() => {
    if (organizationId && !ediResponses[0] && surveyStructure.length === 0) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=7&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: "audit-type-id=4",
      });
    }
  }, [dispatch, organizationId, ediResponses]);

  useEffect(() => {
    if (Number(ambassadorId) > 0 && selectedOrg) {
      setOrganizationId(selectedOrg.organization?.id);
    } else {
      setOrganizationId(Number(auth.organization_id));
    }
  }, [selectedOrg, ambassadorId, auth]);

  useEffect(() => {
    if (
      data.length === 0 &&
      !loading &&
      surveyStructure &&
      surveyStructure.length > 0 &&
      questions &&
      responseSet &&
      responseSet.length > 0 &&
      risk !== previousRisk
    ) {

      all_effect_size_calculations( questions,
        surveyStructure[0]?.categories,
        responseSet,
        0)
      let _data = all_effect_size_calculations(
        questions,
        surveyStructure[0]?.categories,
        responseSet,
        0
      )
        .flat()
        .flat()
        .map((item) => {
          if (item && item.data) {
            return item.data.map((i) => {
              return i.ES.map((e, idx) => {
                return {
                  factor: item.factor,
                  primary: item.category,
                  secondary: item.category2,
                  risk: e.risk,
                  n: e.n,
                  tertiary: getTertiary(i.name, idx),
                  avg: e.avg,
                };
              });
            });
          }
        })
        .flat()
        .flat()
        .filter((i) => i && !isNaN(i.risk))
        .sort((a, b) => {
          return a.risk - b.risk;
        });
      setData(
        _data.filter(
          (f) => f.risk > ES_levels[risk] || f.risk < -1 * ES_levels[risk]
        )
      );
      setRef(_data);

      setTimeout(function () {
        setLoading(false);
      }, 1000);
    }
  }, [loading, selected, questions, responseSet, surveyStructure, data.length]);

  useEffect(() => {
    if (dataRef.length > 0) {
      let _data = dataRef;
      if (risk > 0) {
        _data = _data
          .filter(
            (f) => f.risk > ES_levels[risk] || f.risk < -1 * ES_levels[risk]
          )
          .sort((a, b) => {
            return a.risk - b.risk;
          });
        setData(_data);
        setPage(1);
      }
      if (selected !== "Overall") {
        _data = _data.filter((f) => f.primary === selected);
      }
      setData(_data);
      setPage(1);
      setPreviousRisk(risk);
    }
  }, [selected, dataRef, risk]);

  const handlePaginationChange = (e, { activePage }) => setPage(activePage);


  if (
    (ediResponses?.response?.length === 0 && data?.length === 0) ||
    (surveyStructure?.length === 0 && !responseSet) ||
    responseSet?.length === 0
  ) {
    return <Empty />;
  }

  if (!surveyQuestions || !surveyStructure || !questions) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Title>
        <Icon name="lightbulb" style={{ color: "#f4fa50" }} />
        Insights
      </Title>
      <Sub>
        nuLogic combs through your data in a very granular way to identify areas
        of risk. A risk flag indicates that the scores in this particular area
        are statistically different than those in the same cohort. Overall low
        grades may not flag as high risk as this search is designed to look for
        meaningful differences within larger groups.
      </Sub>
      <Filters>
        <RiskContainer>
          <T2>Set risk level search</T2>
          <div
            style={{
              display: "flex",
              width: 250,
              marginBottom: 10,
              justifyContent: "space-between",
            }}
          >
            Level of risk
            <Risk>{risks[risk]}</Risk>
          </div>
          <div style={{ display: "flex", width: 300 }}>
            <div style={{ width: "80%" }}>
              <Progress percent={risk * 25} color={colors[risk]} />
            </div>
            <Add onClick={() => setRisk(risk < 4 ? risk + 1 : risk)}>
              <Icon name="plus" />
            </Add>
            <Add onClick={() => setRisk(risk === 0 ? risk : risk - 1)}>
              <Icon name="minus" />
            </Add>
          </div>
        </RiskContainer>

        <div>
          <T2>Filter by Location</T2>
          <Filter
            structure={surveyStructure?.[0]?.categories}
            selected={selected}
            setSelected={(v) => {
              if (v === "") {
                setSelected("Overall");
              } else {
                setSelected(v);
              }
            }}
          />
        </div>
      </Filters>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 20,
        }}
      >
        <Pagination
          totalPages={Math.ceil(data.length / 9)}
          onPageChange={handlePaginationChange}
          activePage={page}
          size="mini"
        />
      </div>
      <Dimmer.Dimmable blurring dimmed={loading} style={{ width: "100%" }}>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <InsightContainer>
          {data.slice((page - 1) * 9, page * 9).map((item) => {
            return (
              <Insight
                title={`${item.factor} in ${item.tertiary? item.tertiary:""} ${
                  item.secondary === "Overall" ? "employees" : item.secondary ? item.secondary:""
                } ${item.primary === "Overall" ? "" : item.primary ? item.primary:""}`}
                risk={item.risk}
                n={item.n}
                link={item.factor}
                avg={item.avg}
                description={""}
              />
            );
          })}
        </InsightContainer>
      </Dimmer.Dimmable>
    </Container>
  );
};

export default Insights;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 50px;
  font-family: "Barlow Semi Condensed", sans-serif;
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Risk = styled.div`
  font-size: 20px;
`;

const Add = styled.div`
  cursor: pointer;
`;

const RiskContainer = styled.div``;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const InsightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Sub = styled.div`
  max-width: 600px;
  margin-bottom: 30px;
`;

const T2 = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;
