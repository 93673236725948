import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Pagination, Input, Dimmer, Loader, Message } from "semantic-ui-react";

import TableList from "./TableList";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_INACTIVE_EMPLOYEES,
} from "constants/actions";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

const MemberDirectory = () => {
  const dispatch = useDispatch();

  const [memberList, setMemberList] = useState([]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [selectTeam, setSelectTeam] = useState(false);

  const [organization, setOrganization] = useState({});

  const [loading, setLoading] = useState(true);
  // // // // //
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );

  const {
    // employees_data,
    get_organizations,
    get_employees,
    get_auth,
    // get_departments,
    get_selectedOrg,
    employees_pending,
    // get_error,
    // get_survey_structure,
    get_employee_categories,
  } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_auth: state.auth,
      get_employees: state.employees,
      get_organizations: state.organizations,
      // get_departments: state.departments,
      employees_pending: state.employees.pending,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({
      type: SG_INACTIVE_EMPLOYEES,
    });
  }, [dispatch]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch({ type: SG_GET_MOODS });
    if (Number(AmbassadorId) > 0) {
      // console.log("Ambassador Lead teams", AmbassadorId)
      const OrgId = get_selectedOrg?.organization?.id;
      if (OrgId) {
        dispatch({
          type: SG_GET_SURVEY_STRUCTURES,
          payload: `organization=${OrgId}`,
        });
        // dispatch({
        //     type: SG_GET_EMPLOYEE_CATEGORIES,
        //     payload: `organization=${OrgId}`,
        // });
      }
    } else {
      // console.log("Lead teams")
      dispatch({ type: SG_GET_SURVEY_STRUCTURES });
      // dispatch({ type: SG_GET_EMPLOYEE_CATEGORIES });
    }
  }, [dispatch, AmbassadorId, get_selectedOrg]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganization(get_organizations[get_selectedOrg?.organization?.id]);
    } else {
      setOrganization(get_organizations[get_auth?.organization_id]);
    }
  }, [get_organizations, get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    setMemberList(
      get_employees?.inactive.sort((a, b) => (a.id > b.id ? 1 : -1))
    );
  }, [get_employees]);

  // console.log(employees_pending)

  useEffect(() => {
    // if (members.length > 0) {
    setLoading(employees_pending);
    if (employees_pending === false) {
      const timer1 = setTimeout(() => {
        // console.log("I am here", loading)
        // if (setLoading) {
        setLoading(false);
        // }
      }, 1000);
      return () => clearTimeout(timer1);
    }
    // }
  }, [employees_pending]);

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  function SearchMembers(e) {
    setSearch(e);
    let emailLst = [];
    let firstList = [];
    let lastList = [];
    if (e.length > 0) {
      setPage(1);
      emailLst = memberList.filter((item) => item?.email?.includes(e));
      firstList = memberList.filter((item) => item?.first_name?.includes(e));
      lastList = memberList.filter((item) => item?.last_name?.includes(e));
      const _MemberList = emailLst.concat(firstList, lastList);
      setMemberList(_MemberList);
    } else {
      setMemberList(memberList);
    }
  }

  return (
    <>
      <Dimmer.Dimmable blurring dimmed={loading}>
        <SearchContainer>
          <Input
            icon="search"
            iconPosition="left"
            placeholder="Search..."
            size="small"
            style={{ width: "70%" }}
            value={search}
            onChange={(e) => SearchMembers(e.target.value)}
          />

          <Pagination
            activePage={page}
            onPageChange={handlePaginationChange}
            boundaryRange={1}
            size="mini"
            firstItem={null}
            lastItem={null}
            siblingRange={0}
            totalPages={Math.ceil(memberList?.length / 10)}
          />
        </SearchContainer>
        <TableList
          members={memberList}
          employee_categories={get_employee_categories}
        />

        <Dimmer active={loading}>
          <LoadingContainer>
            <p>Loading page</p>
            <Loader size="medium">Loading</Loader>
          </LoadingContainer>
        </Dimmer>
      </Dimmer.Dimmable>
    </>
  );
};

export default MemberDirectory;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
`;

const NavContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #d3dbdb;
`;

const NavTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const LoadingContainer = styled.div`
  min-height: 40vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;
