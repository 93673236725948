import styled, { keyframes } from "styled-components";
import React from "react";
import Empty from '404/Empty'
import { Icon, Popup } from 'semantic-ui-react'


const Cluster = ({ clusterData, clusterRank, DefaultPic }) => {

	if (clusterData && clusterData.length < 1) {
		return <Empty cluster />
	}

	const getArea = (data) => {
		let area = data[0]
		let val = Math.abs(data[1])
		let dir = data[1] > 0 ? true : false
		let words = ''

		if (val < 0.2) {
			words += 'Slightly '
		}
		else if (val < 0.5) {
			words += 'Moderately '
		}
		else {
			words += 'Significantly '
		}

		if (dir) {
			words += 'higher scores in '
		} else {
			words += 'lower scores in '
		}


		words += area

		return words

	}


	return (
		<Container>
			<Title>Team Clusters</Title>
			<Description>The team cluster analysis looks for trends in the team intelligence survey responses and identifies clusters of similarity within your team. These patterns are then analyzed for the most meaningful traits which make these team members similar in how they responded to their survey.</Description>

			<Title>How to use this analysis?</Title>
			<Description>Similarities will help inform you how these groupings of people are connected to one another. Look forward network connection patterns within these groupings to help identify a potential cause for the patterns.</Description>


			<ClusterContainer>
				{clusterData &&
					clusterData.sort(function (a, b) { return b.employees?.length - a.employees?.length }).map((cl, key) => {


						return <ACluster key={key}>

							<Number color={'#6a00ff'}>{cl.employees.length}</Number>
							<Content>
								<Line width={100} color={'#6a00ff'} />
								<ClusterTitle>Group {cl.group}</ClusterTitle>
								<AvatarContainer>

									{cl?.employees && cl.employees.map((emp) => {
										if (emp?.picture) {
											return <Popup
												key={emp?.first_name}
												header={emp?.first_name ? emp?.first_name + ' ' + emp?.last_name : emp?.email}
												trigger={<Avatar src={emp?.picture ? emp?.picture : DefaultPic} key={emp?.id} />}
											/>
										}
										else {
											return <Popup
												key={emp?.first_name}
												header={emp?.first_name ? emp?.first_name + ' ' + emp?.last_name : emp?.email}
												trigger={<Icon name="user circle" style={{ fontSize: 30, marginTop: 5 }} />}
											/>
										}

									})}


								</AvatarContainer>

								<ClusterDescription>Similarities:</ClusterDescription>
								<ul>
									{cl?.rank && cl?.rank.map((item, i) => {
										return <Li>{getArea(item)}</Li>
									})}

								</ul>

								<ClusterDescription>Keywords:</ClusterDescription>

								<ul>
									{cl?.keywords && cl?.keywords.map((kw) => {
										return <Li key={kw}>{kw}</Li>
									})}

								</ul>

							</Content>
						</ACluster>
					})}
			</ClusterContainer>
		</Container>
	)
}


export default Cluster


const Container = styled.div`
	width:100%;
	margin-left:20px;

	@media(max-width:1000px){
		width:100%;
		margin-top:20px;
		margin-left:0px;

	}
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:16px;
	font-weight:bold;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;
	max-width:800px;
`

const ACluster = styled.div`
	display:flex;
	width:calc(50% - 10px);
	margin-bottom:60px;

	@media(max-width:768px){
		width:100%;
		margin-bottom:20px;
	}
`

const Number = styled.div`
	height:50px;
	width:50px;
	border:${props => '1px solid ' + props.color};
	border-radius:25px;
	font-family: "Red Hat Display", sans-serif;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:24px;
	font-weight:bold;
	color:${props => props.color};
	margin-right:15px;
	min-width:50px;
`

const Content = styled.div`
	width:calc(100% - 75px);
`

const Line = styled.div`
	width:${props => 'calc(' + props.width + '% - 30px)'};
	height:5px;
	background-color:${props => props.color};
	margin-bottom:5px;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	border-radius:15px;
	margin-right:5px;
	margin-bottom:5px;
`

const AvatarContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
`

const ClusterContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
	width:100%;
	margin-top:40px;
`

const ClusterTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
	margin-bottom:5px;
`

const ClusterDescription = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top:15px;
	font-weight:bold;
`

const Li = styled.li`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	margin-bottom:5px;
`