import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
    Button,
    Icon,
    Table,
    Segment,
    Dimmer,
    Confirm,
    Popup,
    Loader,
    List,
} from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_FETCH_FILE_UPLOAD,
    SG_EDIT_FILE_UPLOAD,

} from "constants/actions";

import format from "date-fns/format";

import Update from "./Update";
import BasicErrorMessage from "utilities/ErrorMessages";

// import { EmployeeImport } from "data/datastructure/Import"

function elementCount(arr, element) {
    return arr.reduce((currentElement, arrElement) =>
        (arrElement == element ? currentElement + 1 : currentElement), 0);
};

function ValidateCheckEmail({ k, item, head,
    fieldValues, validFieldName, validEmailList,
    uploadedEmailList }) {

    if (head === "email") {
        if (!item?.email) {
            return (
                <Table.Cell key={k} negative>
                    <Popup
                        content="This field cannot be empty"
                        trigger={<Icon name="attention" />}
                    />
                    {item[head]}
                </Table.Cell>
            );
        }
        const FoundMultiple = (elementCount(uploadedEmailList, item.email))
        if (FoundMultiple > 1) {
            return (
                <Table.Cell key={k} negative>
                    <Popup
                        content="Multiple emails found"
                        trigger={<Icon name="attention" />}
                    />
                    {item[head]}
                </Table.Cell>
            );
        }
        const Found = validEmailList.includes(item?.email)
        if (Found) {
            return (
                <Table.Cell key={k} negative>
                    <Popup
                        content="This email id has been used"
                        trigger={<Icon name="attention" />}
                    />
                    {item[head]}
                </Table.Cell>
            );
        } else {
            return (
                <Table.Cell key={k} positive>
                    <Popup
                        content="This email can be used"
                        trigger={<Icon color="green" name="checkmark" />}
                    />
                    {item[head]}{" "}
                </Table.Cell>
            );

        }
    }
    if (head === "first_name" || head === "last_name") {

        return (
            <Table.Cell key={k} positive>
                <Popup
                    content="This is a valid choice"
                    trigger={<Icon color="green" name="checkmark" />}
                />
                {item[head]}{" "}
            </Table.Cell>
        );
    }
    if (validFieldName[head]) {
        if (validFieldName[head]?.priority === 'primary') {
            if (fieldValues?.[head]) {
                const Found1 = fieldValues[head].find(({ name }) => name === item[head])
                // console.log(Found1)
                if (!item[head]) {
                    return <Table.Cell key={k}>{item[head]} </Table.Cell>;
                }

                if (Found1) {
                    return (
                        <Table.Cell key={k} positive>
                            <Popup
                                content="This is a valid choice"
                                trigger={<Icon color="green" name="checkmark" />}
                            />
                            {item[head]}{" "}
                        </Table.Cell>
                    );
                } else {
                    return (
                        <Table.Cell key={k} negative>
                            <Popup
                                content="This is not a valid choice"
                                trigger={<Icon name="attention" />}
                            />
                            {item[head]}
                        </Table.Cell>
                    );
                }
            }
        } else {

            const Found1 = validFieldName[head]?.options.find(({ name }) => name === item[head])
            // console.log(Found1)
            if (!item[head]) {
                return <Table.Cell key={k}>{item[head]} </Table.Cell>;
            }

            if (Found1) {
                return (
                    <Table.Cell key={k} positive>
                        <Popup
                            content="This is a valid choice"
                            trigger={<Icon color="green" name="checkmark" />}
                        />
                        {item[head]}{" "}
                    </Table.Cell>
                );
            } else {
                return (
                    <Table.Cell key={k} negative>
                        <Popup
                            content="This is not a valid choice"
                            trigger={<Icon name="attention" />}
                        />
                        {item[head]}
                    </Table.Cell>
                );
            }
        }
    }


    return <Table.Cell key={k}>{item[head]} </Table.Cell>;
}

const DebriefScheduleDetail = ({ showReportId, get_survey_structure }) => {
    const dispatch = useDispatch();

    const [fileUpload, setfileUpload] = useState([]);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [validFields, setValidFields] = useState([])
    const [validatedValues, setValidatedValues] = useState([])
    const [controlledBy, setControlledBy] = useState({})
    const [fieldValues, setFieldValues] = useState({})
    const [parentField, setParentField] = useState({})
    const [jsonData, setJsonData] = useState([])
    const [fileUploadStatus, setFileUploadStatus] = useState(0)
    const [validFieldName, setValidFieldName] = useState([])

    const [uploadedEmailList, setUploadedEmailList] = useState([])
    const [showUploadLog, setShowUploadLog] = useState(false)
    const [uploadedLog, setUploadedLog] = useState([])

    const [rowInfo, setRowInfo] = useState([]);

    const [rowId, setRowId] = useState(false);
    const [openPortal, setopenPortal] = useState(false);

    const [validEmailList, setValidEmailList] = useState([])
    const [deleteId, setDeleteId] = useState(false);
    const [invalidRows, setInvalidRows] = useState(0);

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false)

    const {
        get_file_uploads,
        get_email_list
        // get_selectedOrg
    } = useSelector(
        (state) => ({
            get_file_uploads: state.fileupload,
            get_email_list: state.employees.allEmailList,

            // get_selectedOrg: state.selectedOrg
        }),
        shallowEqual
    );

    useEffect(() => {
        if (showReportId) {
            dispatch({
                type: SG_FETCH_FILE_UPLOAD,
                payload: { id: showReportId },
            });
        }
    }, [dispatch, showReportId, rowId, openPortal]);

    useEffect(() => {
        setValidEmailList(get_email_list?.response)
    }, [get_email_list]);

    useEffect(() => {
        setfileUpload(get_file_uploads?.[showReportId]);
    }, [showReportId, get_file_uploads]);

    useEffect(() => {
        setFileUploadStatus(fileUpload?.status)
    }, [fileUpload]);

    useEffect(() => {
        const _UserList = [];

        let _total = 0
        if (fileUpload?.review_data?.[0]) {
            if (fileUpload?.status > 2) {
                const _fileKeys = Object.keys(fileUpload?.review_data?.[0])
                const _index = _fileKeys.indexOf('category');
                delete _fileKeys[_index]
                const _index2 = _fileKeys.indexOf('employee_id');
                delete _fileKeys[_index2]
                setFileHeaders(_fileKeys)
                // upload Log get email, id and category information in one row
                const _log = fileUpload?.review_data.filter(up => {
                    return ({
                        emai: up?.email,
                        employee_id: up?.employee_id,
                        status: up?.status,
                        category: up?.category
                    })
                })
                setUploadedLog(_log)

            } else {
                setFileHeaders(Object.keys(fileUpload?.review_data?.[0]))
            }

            fileUpload?.review_data.map((fl, i) => {
                const Found = validEmailList.includes(fl?.email);
                if (Found) {
                    // console.log("Invalid")
                    _total += 1;
                }
                let Found2 = 0
                // let EmptyRow = 0

                validFields.filter(vf => vf !== 'first_name')
                    .filter(vf => vf !== 'email').filter(vf => vf !== 'last_name').map(fld => {
                        // console.log(fld, ":", fl[fld])
                        if (fl[fld] === '') {
                            // console.log("null return")
                            return null
                        }
                        // if (!fl[fld]) {
                        //     EmptyRow = 1;
                        //     return null
                        // }

                        // Found2 = validFieldName[fld]?.options.find(({ name }) => name === fl[fld])
                        // validFieldName[fld]?.options.filter()
                        // if (fieldValues?.[fld]?.length > 0) {
                        //     // console.log(fieldValues[fld])
                        //     Found2 = fieldValues?.[fld].find(({ name }) => name === fl[fld])
                        // }
                        return null
                    })

                // if (!Found2) {
                //     // console.log("Found2", _total)
                //     _total += 1;
                // }
                // if (EmptyRow) {
                //     // console.log("EmptyRow", _total)
                //     _total += 1;
                // }

                return _UserList.push({
                    ...fl,
                    valid: Found,
                });
            });
            if (fileUpload?.status > 2) {
                _total = 0;
            }
            if (fileUpload?.status > 3) {
                _total += 100;
            }
        }

        setUploadedEmailList(_UserList.map(ur => ur.email))
        setJsonData(_UserList)
        setInvalidRows(_total)


    }, [fileUpload, validEmailList, validFieldName, validFields, parentField, validatedValues]);

    // console.log(invalidRows)

    useEffect(() => {
        // const _primaryList = [];
        // const _secondaryList = [];
        if (get_survey_structure?.length > 0) {
            setValidFields(get_survey_structure?.[0]?.field_map?.fields)
            setControlledBy(get_survey_structure?.[0]?.field_map?.controlled_by)
            setFieldValues(get_survey_structure?.[0]?.field_map?.values)
            setValidatedValues(get_survey_structure?.[0]?.field_map?.validated_values)
            setParentField(get_survey_structure?.[0]?.field_map?.parent_field)
            setValidFieldName(get_survey_structure?.[0]?.field_map?.valid_field_name)
        }
    }, [get_survey_structure]);

    // useEffect(() => {
    //     if (loadingPage && !get_file_uploads?.pending) {
    //         const timer1 = setTimeout(() => {
    //             setLoadingPage(false);
    //         }, 1000);
    //         return () => clearTimeout(timer1);
    //     }

    // }, [loadingPage, get_file_uploads]);

    useEffect(() => {
        setLoadingPage(get_file_uploads?.pending);
    }, [get_file_uploads]);

    function GetDetails(item, id) {
        // setRowInfo(item)
        setopenPortal(true);
        setRowInfo(item);
        setRowId(id);
        // setRowData(Object.entries(item));
    }

    function DeleteRow(id) {
        // console.log(id)
        setDeleteId(id);
        setConfirmDelete(true);
    }
    function DeleteFileUpload() {
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: showReportId,
                delete_row: jsonData[deleteId]?.id,
            },
        });
        const _jsonData = jsonData;
        _jsonData.splice(deleteId, 1);
        setJsonData(_jsonData);

        setConfirmDelete(false);
        setDeleteId(null);
    }

    function ImportToDb() {
        setLoadingPage(true)
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: showReportId,
                import_to_db: 1,
                review_data: jsonData,
            },
        });
    }
    function ShowPortal(e) {
        setLoadingPage(e)
        setopenPortal(e)
    }


    return (
        <>
        {/* <BasicErrorMessage /> */}
            {openPortal && (
                <>
                    {/* to be shown when user clicks edit option */}
                    <Update
                        ShowPortal={ShowPortal}
                        openPortal={openPortal}
                        rowInfo={rowInfo}
                        setRowInfo={setRowInfo}
                        id={showReportId}
                        rowId={rowId}
                        setJsonData={setJsonData}
                        jsonData={jsonData}
                        fieldValues={fieldValues}
                        validFields={validFields}
                        validatedValues={validatedValues}
                        parentField={parentField}
                        controlledBy={controlledBy}
                        validFieldName={validFieldName}
                    />
                </>
            )}
            <Segment basic padded>
                <Dimmer active={loadingPage} />
                <Loader>Loading</Loader>
                <TopContainer>
                    <div>
                        {fileUpload?.created_at && (
                            <Information>
                                {fileUpload?.status_text} -{" "}
                                {format(
                                    new Date(
                                        fileUpload?.created_at?.toLocaleString()
                                    ),
                                    "EEEE, MMM do"
                                )}
                            </Information>
                        )}
                        <Information>
                            {invalidRows > 0 &&
                                `Invalid Data Found please fix them to complete the upload`}
                        </Information>
                    </div>
                    {fileUploadStatus < 3 && (
                        <StyledButton
                            disabled={invalidRows > 0}
                            onClick={ImportToDb}
                        >
                            Import Data Listed Below
                        </StyledButton>
                    )}
                    {fileUploadStatus > 2 &&
                        <UploadLogList>
                            <Button onClick={() => setShowUploadLog(!showUploadLog)}>{showUploadLog ? "Hide" : "Show"} Log</Button>
                        </UploadLogList>}
                </TopContainer>
                {showUploadLog &&
                    <LogHolder>
                            {uploadedLog && uploadedLog?.length > 0 && uploadedLog.map((ll, i) => {
                                return (

                                        <LogRow  key={i}>
                                        <LogCell>
                                               {i + 1} 
                                            </LogCell>
                                            <LogCell>
                                               {ll?.email} 
                                            </LogCell>
                                            <LogCell>
                                                {ll?.employee_id}
                                            </LogCell>
                                            <LogCell>
                                                {ll?.status}
                                            </LogCell>
                                        </LogRow>
                                        
                                )
                            })}
                       
                    </LogHolder>
                }

                <ScrollContainer>
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                {fileHeaders &&
                                    fileHeaders.map((head, i) => {

                                        const Result = validFields.includes(head);
                                        if (head === 'valid') {
                                            return null
                                        }
                                        if (head === 'id') {
                                            return null
                                        }

                                        if (Result) {
                                            return (
                                                <Table.HeaderCell key={i}>
                                                    {head}
                                                </Table.HeaderCell>
                                            );
                                        }

                                        return (
                                            <Table.HeaderCell key={i} error>
                                                <Popup
                                                    content="Unable to import data in this column"
                                                    trigger={
                                                        <Icon name="attention" />
                                                    }
                                                />
                                                {head}
                                            </Table.HeaderCell>
                                        );
                                    })}
                                {fileUploadStatus < 3 && (
                                    <Table.HeaderCell></Table.HeaderCell>
                                )}
                                {fileUploadStatus < 3 && (
                                    <Table.HeaderCell></Table.HeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {jsonData &&
                                jsonData.map((item, i) => {
                                    return (
                                        <Table.Row
                                            key={i}
                                            error={
                                                item.valid !== true &&
                                                fileUploadStatus < 3
                                            }
                                        >
                                            {fileHeaders &&
                                                fileHeaders.map((head, k) => {
                                                    const Result = validFields.includes(head);
                                                    if (head === 'valid') {
                                                        return null
                                                    }
                                                    if (head === 'id') {
                                                        return null
                                                    }
                                                    // file uploaded successfully start
                                                    if (
                                                        fileUploadStatus > 2
                                                    ) {
                                                        return (
                                                            <Table.Cell key={k}>
                                                                {item[head]}
                                                            </Table.Cell>
                                                        );
                                                    }
                                                    // file uploaded successfull end
                                                    if (Result) {
                                                        return <ValidateCheckEmail key={k}
                                                            item={item}
                                                            head={head}
                                                            uploadedEmailList={uploadedEmailList}
                                                            uploadStatus={fileUploadStatus}
                                                            fieldValues={fieldValues}
                                                            validEmailList={validEmailList}
                                                            validFieldName={validFieldName}

                                                        />
                                                    }
                                                    // console.log(validFields.includes(head))
                                                    // console.log(head)
                                                    return (
                                                        <Table.Cell
                                                            key={k}
                                                            error
                                                        >
                                                            <Popup
                                                                content="Unable to import this data, not supported field"
                                                                trigger={
                                                                    <Icon name="attention" />
                                                                }
                                                            />
                                                            {item[head]}
                                                        </Table.Cell>
                                                    );
                                                })}

                                            {fileUploadStatus < 3 && (
                                                <Table.Cell>
                                                    <Button
                                                        icon
                                                        onClick={() =>
                                                            GetDetails(item, i)
                                                        }
                                                    >
                                                        <Icon name="edit" />
                                                    </Button>
                                                </Table.Cell>
                                            )}
                                            {fileUploadStatus < 3 && (
                                                <Table.Cell>
                                                    <Button
                                                        icon
                                                        onClick={() =>
                                                            DeleteRow(i)
                                                        }
                                                    >
                                                        <Icon name="trash alternate" />
                                                    </Button>
                                                    <Confirm
                                                        open={confirmDelete}
                                                        onCancel={() =>
                                                            setConfirmDelete(
                                                                false
                                                            )
                                                        }
                                                        onConfirm={() =>
                                                            DeleteFileUpload(i)
                                                        }
                                                    />
                                                </Table.Cell>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>
                </ScrollContainer>
            </Segment>
        </>
    );
};

export default DebriefScheduleDetail;

const ScrollContainer = styled.div`
    overflow-x: auto;
    // display:flex;
    // width:100%;
    // align-items:center;
    // justify-content:space-between;
`;

const Headers = styled.div`
    font-family: "Barlow", sans-serif;
    font-weight: bold;
    font-size: 28px;
    margin-top: 40px;
    margin-bottom: 100px;
    padding-bottom: 50px;
`;

const LogHolder = styled.div`
    margin-top: 20px;
    margin-bottom: 100px;
`;
const InfoCentered = styled.div`
    text-align: center;
    font-family: "Barlow", sans-serif;
    font-size: 20px;
    margin-bottom: 25px;
`;

const Org = styled.div`
    font-family: "Barlow", sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 25px;
`;
const Information = styled.div`
    font-family: "Barlow", sans-serif;
    font-size: 20px;
`;

const UploadLogList = styled.div`
    display: flex;
    justify-content:  flex-end 

`

const LogRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

`

const LogCell = styled.div`
    margin: 5px;
    padding: 5px;
`

const StyledTable = styled.div`
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
`;

const TR = styled.div`
    display: flex;
    padding: 15px 10px;
    width: 100%;
`;

const TH = styled.div`
    width: ${(props) => props.width + "%"};
    font-weight: bold;
    border-bottom: 1px solid #9b9b9b;
    padding-bottom: 5px;
`;

const TD = styled.div`
    width: ${(props) => props.width + "%"};
    display: flex;
    align-items: center;
    position: relative;
`;

const T1 = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

const SmallImg = styled.img`
    width: 30px;
    margin-right: 10px;
`;
const RowView = styled.tr`
    cursor: pointer;
`;

const View = styled.div`
    padding: 5px 35px;
    background-color: #6a00ff;
    color: white;
    border-radius: 20px;
    font-weight: bold;
    position: absolute;
    right: 0;
    cursor: pointer;
`;

const Card = styled.div`
    max-width: 100%;
    padding: 30px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    //   position:relative;
    display: flex;
    justify-content: space-between;
    //   align-items:center;
`;

const TopContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
   
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.disabled ? "#606060" : "#2d50e2")};
    disabled: ${(props) => (props.disabled ? true : false)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-right: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;
