import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import BasicDropdown from "../PlanComponent/Dropdown";
import { Dropdown } from 'semantic-ui-react'


const getTemplateOptions = (plans) => {
  return plans?.map((plan, i) => {
    return {
      key: plan.title,
      text: plan.title,
      value: i,
    };
  });
};

const PlanComponent = ({ selected, setSelected, plans }) => {
  const [open, setOpen] = useState(false);
  const templateOptions = getTemplateOptions(plans);

  return (
    <Container>
      <Top onClick={() => setOpen(!open)}>
        <Title>Plan</Title>
        <Description>
          Please select a template to use for this action plan.
        </Description>
      </Top>

      <Dropdown
        options={templateOptions}
        fluid
        selection
        placeholder="Select a template"
        value={
          templateOptions?.find((t) => {
            return t.value === selected;
          })?.value || "Select a template"
        }
        onChange={(t, { value }) => {
          setSelected(value);
        }}
      />

      {/* <BasicDropdown
        selectedOption={
          templateOptions?.find((t) => t.value === selected)?.text ||
          "Select a template"
        }
        handleOptionChange={(t) => {
          setSelected(t);
        }}
        options={templateOptions}
      /> */}
    </Container>
  );
};

export default PlanComponent;

const Container = styled.div`
  width: 100%;

  font-family: "Raleway";
  border-radius: 3px;
  padding-bottom: 15px;
`;

const Top = styled.div`
  display: flex;
  flex-direction:column;
  margin-bottom: 10px;

  position: relative;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color:#2A3039;
  line-height: 21px;
  margin-right: 15px;
  margin-bottom:10px;
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 25px;
  color: #2A3039;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
`;

const Selected = styled.div``;
