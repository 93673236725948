import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { CSSTransitionGroup } from "react-transition-group";
import "./team.css";

import { useForm, Controller } from "react-hook-form";
import { Message } from "semantic-ui-react";
import Selection from './Selection'
import CreateEdit from './CreateEdit'
import Invite from './Invite'
import UserList from './userList'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  UPDATE_EMPLOYEE,
  LOAD_EMPLOYEES,
} from "constants/actions";


import {
  Text,
  SubText,
  Hide,
  TeamContainer,
  InvitedContainer,
} from "../styled-list";


const TeamCard = (props) => {
const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [leaderSent, setLeaderSent] = React.useState(false);
  const [team, setTeam] = React.useState(false);
  const [invite,setInvite] = React.useState(false)

  const [invited, setInvited] = React.useState([]);


  React.useEffect(() => {
  	if(team?.id){
  		dispatch({
      type: LOAD_EMPLOYEES,
      payload: {params: team? `&team=${team.id}` : null}
    });
  	}
   
  }, [dispatch, team]);

  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_employees?.employees) {
      setInvited(get_employees.employees);
      if(get_employees.employees.length){
      	props.complete()
      }
      
    }
  }, [get_employees]);


  return (
    <TeamContainer type={props.type}>
    	<Icon name="arrow left" size={"large"} style={{ color: "#4a4a4a",cursor:"pointer",marginLeft:-40,marginBottom:-50 }} onClick={()=>props.back()}/>
     	<CreateEdit visible={visible} setVisible={(val)=>setVisible(val)} setTeam={(id)=>setTeam(id)} team={team} department={false}/>

      <Hide visible={visible}>
        <SubText>{leaderSent ? "Team Leader" : "Add Team Leader"}</SubText>
        <Invite role="Leader" role_id={2} team_id={team?team.id:false} send={()=>setLeaderSent(true)} department={false}/>
        <UserList invited={invited.filter(i=>i.role==2&&i.team==team.id)}/>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Selection
            color={"#6a00ff"}
            selected={
              invite ? "linear-gradient(260deg, #6a00ff, #6a00ff)" : false
            }
            onClick={() => setInvite(!invite)}
          />
          <Text>Have leader invite team members?</Text>
        </div>

        <SubText>Add Team Member(s)</SubText>
        <Invite role="Member" role_id={1} team_id={team?team.id:false} department={false}/>
        <InvitedContainer>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            <UserList invited={invited.filter(i=>i.role==1&&i.team==team.id)} />
          </CSSTransitionGroup>
        </InvitedContainer>
      </Hide>
    </TeamContainer>
  );
};


export default TeamCard