import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Label } from "semantic-ui-react";
import GoogleMapReact from "google-map-react";
import Chart from "react-apexcharts";
import IndividualModal from "../IndividualModal/nonModal";
import Filter from "./Filter";

const AnyReactComponent = ({ text, open }) => (
	<NewMarker onClick={() => open()} text={text} />
);

const Area = (props) => {
	return (
		<div>
			<ATitle>
				{props.title}
				<Percent>{props.percent}%</Percent>
			</ATitle>
			<Progress percent={props.percent} color="grey" inverted size="tiny" />
		</div>
	);
};

const Active = (props) => {
	return (
		<AContainer>
			<H5>{props.title}</H5>
			<div style={{ display: "flex" }}>
				<Label
					color={
						props.risk == "High"
							? "red"
							: props.risk == "Moderate"
							? "orange"
							: "grey"
					}
					size="mini"
				>
					Risk: {props.risk}
				</Label>
			</div>
		</AContainer>
	);
};

const NewMarker = ({ text, onClick }) => {
	return (
		<Marker onClick={() => onClick()}>
			{text}
			<Down />
		</Marker>
	);
};

const MarkerContainer = styled.div`
	width: 100px;
	height: 30px;
	border-radius: 10px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Down = styled.div`
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid white;
	position: absolute;
	bottom: -11px;
	left: calc(50% - 10px);
`;

const Points = [
	{
		lat: 51.514458747308446,
		lng: -0.1270083459652734,
		text: "86%",
		location: "London",
	},
	{ lat: 44.65107, lng: -63.582687, text: "96%", location: "Halifax" },
	{
		lat: -33.91749856583468,
		lng: 18.417085729596675,
		text: "73%",
		location: "South Africa",
	},
	{
		lat: 36.730637522309905,
		lng: -4.4310606550807625,
		text: "73%",
		location: "Malaga",
	},
	{
		lat: 58.56645488897973,
		lng: 25.646071905861394,
		text: "73%",
		location: "Estonia",
	},
];

const Maps = () => {
	const [center, setCenter] = useState({
		lat: Points[0].lat,
		lng: Points[0].lng,
	});
	const [zoom, setZoom] = useState(0);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(0);

	const selection = (id) => {
		setOpen(true);
		setSelected(id);
		setCenter({ lat: Points[id].lat, lng: Points[id].lng });
	};

	return (
    <Container>
      <Top>
        <Title>Map Overview</Title>

        <Filter />
      </Top>

      <Dash>
        <MapContainer>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAdZuA2OaEPdXJeetk4VXzcxBso-m6nz1w",
            }}
            center={center}
            defaultZoom={zoom}
          >
            {Points.map((item, i) => {
              return (
                <AnyReactComponent
                  lat={item.lat}
                  lng={item.lng}
                  text={item.text}
                  open={() => selection(i)}
                />
              );
            })}
          </GoogleMapReact>
        </MapContainer>
        <SideMenu open={open}>
          <H2>
            {Points[selected].location}
            <Icon
              name="close"
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </H2>

          <Card>
            <H3>nuLogic Score</H3>
            <Chart
              options={data.options}
              series={data.series}
              type="radialBar"
              height={300}
            />
            <TextArea>
              <Change>Great</Change>
              <Text>
                This location's nuLogic score has{" "}
                <strong>increased by +10%</strong> this assessment.
              </Text>
            </TextArea>
          </Card>

          <Content>
            <H4>Department Breakdown</H4>
            <Area title={"Executives"} percent={94} />
            <Area title={"Developers"} percent={88} />
            <Area title={"Marketing"} percent={78} />
            <Area title={"Acquisitions"} percent={66} />
          </Content>

          <Activity>
            <div style={{ padding: "20px 20px 0px 20px" }}>
              <H4>Smart insights</H4>
            </div>
            <Active
              title={
                "Black Female employees have scored leadership 50% lower than the average"
              }
              priority={"High"}
              risk={"High"}
            />
            <Active
              title={
                "Remote workers have 10% lower levels of psychological safety "
              }
              priority={"High"}
              risk={"Moderate"}
            />
            <Active
              title={
                "Development department's overall nuLogic scores are 20% below location norms"
              }
              priority={"High"}
              risk={"Low"}
            />
          </Activity>
        </SideMenu>
      </Dash>
    </Container>
  );
};

export default Maps;

const Container = styled.div`
	height: calc(100vh - 300px);
	width: 100%;
	position: relative;
	overflow: hidden;
	justify-content: center;
	display: flex;
	flex-direction: column;
	font-family: "Barlow", sans-serif;
`;

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const Top = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 60px;
	padding-left: 30px;
	border-bottom: 1px solid #4a4a4a;
	margin-top: 10px;
	justify-content: space-between;
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 500;
`;

const Dash = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
`;

const SideMenu = styled.div`
	width: 350px;
	height: 100%;
	background-color: #f8f8f8;
	position: absolute;
	right: ${(props) => (props.open ? 0 : "-640px")};
	transition: all 500ms;
`;

const H2 = styled.div`
	font-size: 18px;
	padding: 20px;
	border-bottom: 1px solid #e9e9e9;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const H3 = styled.div`
	font-size: 12px;
`;

const Change = styled.div`
	font-size: 16px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
	color: #ab710a;
	margin-bottom: 5px;
`;

const Card = styled.div`
	padding: 10px;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: white;
`;

const Values = styled.div`
	position: absolute;
	width: 50px;
	height: 50px;
	left: calc(50% - 25px);
	top: calc(50% - 25px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Stat = styled.div`
	font-size: 25px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
`;

const TextArea = styled.div`
	width: 100%;
	padding: 10px;
	margin-top: -30px;
`;

const Text = styled.div``;

const Content = styled.div`
	padding: 20px;
	border-bottom: 1px solid #e9e9e9;
`;

const H4 = styled.div`
	font-size: 16px;
	color: #ab710a;
	font-weight: bold;
`;

const ATitle = styled.div`
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Percent = styled.div``;

const Activity = styled.div``;

const AContainer = styled.div`
	padding: 10px 20px;
	border-bottom: 1px solid #e9e9e9;
`;

const H5 = styled.div`
	font-weight: 400;
	font-size: 16px;
	font-family: "Barlow", sans-serif;
`;

const Dat = styled.div`
	margin-top: 10px;
	color: #4a4a4a;
	font-size: 12px;
`;

const Marker = styled.div`
	height: 60px;
	width: 60px;
	border-radius: 30px;
	border: 3px solid white;
	background-color: rgba(0, 176, 240, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Red Hat Display", sans-serif;
	color: white;
	font-size: 22px;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	transform: translate(-30px, -60px);

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 40px;
	}
`;

const data = {
	series: [80],
	options: {
		chart: {
			height: 350,
			type: "radialBar",
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: "50%",
				},
				dataLabels: {
					show: true,
					name: {
						offsetY: -10,
						show: true,
						color: "#888",
						fontSize: "17px",
					},
					value: {
						formatter: function (val) {
							return parseInt(val);
						},
						color: "black",
						fontSize: "36px",
						show: true,
					},
				},
			},
		},

		labels: ["Percentile"],
		fill: {
			colors: ["#b5b5b5"],
		},
	},
};
