import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  Icon,
  Progress,
  Dropdown,
  Tab,
  Table,
  Label,
  Pagination,
} from "semantic-ui-react";
import TeamIcon from 'assets/images/nav/team.svg'
import { CSVLink } from "react-csv";


var SentimentAnalysis = require("sentiment");

const createOptions = (structure) => {
  let options = [];
  options.push({ text: "Factors", value: { d: -1, f: -1 } });

  if (structure?.dimensions?.[0]?.factors) {
    structure.dimensions[0].factors.map((item, i) => {
      options.push({ text: item?.title || "Untitled", value: { d: 0, f: i } });
    });
  }

  if (structure?.dimensions?.[1]?.factors) {
    structure.dimensions[1].factors.map((item, i) => {
      options.push({ text: item?.title || "Untitled", value: { d: 1, f: i } });
    });
  }

  return options;
};

const Context = ({ data, categories, structure }) => {
  var sentiment = new SentimentAnalysis();
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState(createOptions(structure));
  const [selected, setSelected] = useState(options[0].value);

  const [sorted, setSorted] = useState(data);

  const get_risk = (score, resp) => {
    let sent = sentiment.analyze(resp);
    let sent_score =
      sent.calculation.length > 0
        ? sent.score / sent.calculation.length
        : sent.score;

    if (!score) {
      return (100 - (sent_score + 5) * 10).toFixed(0);
    }
    return Math.floor(50 - score * 5 + (sent_score * -1 + 5) * 5);
  };

  const getRisk = (risk) => {
    if (risk > 70) {
      return (
        <>
          <Sentiment>High - {risk}%</Sentiment>
          <StyledProgressBar percent={risk} size="tiny" color={"#E5657A"} />
        </>
      );
    }
    if (risk > 50) {
      return (
        <>
          <Sentiment>Moderate - {risk}%</Sentiment>
          <StyledProgressBar percent={risk} size="tiny" color={"#F3AB3C"} />
        </>
      );
    }
    return (
      <>
        <Sentiment>Low - {risk}%</Sentiment>
        <StyledProgressBar percent={risk} size="tiny" color={"#66D686"} />
      </>
    );
  };

  useEffect(() => {
    if (selected.d !== -1 && structure?.dimensions) {
      if (structure.dimensions.length === 1) {
        setSorted(
          data.filter((f) => Number(f.feedback?.factor) === selected.f)
        );
      } else {
        setSorted(
          data.filter(
            (f) =>
              f.feedback?.id === selected.d &&
              Number(f.feedback?.factor) === selected.f
          )
        );
      }
    } else {
      setSorted(data);
    }
  }, [selected, data, structure]);

  const getcsvData = () => {
    let new_data = [
      "Primary",
      "Factor",
      "Risk",
      "Question",
      "Feedback",
      "AI Follow Up",
      "AI Follow Up Feedback",
    ];

    let _data = sorted
      .filter((f) => f.feedback?.feedback)
      .map((item) => {
        let primary = item.categories.find(
          (f) => f.priority === "primary"
        )?.response;
        let primary_name =
          categories?.categories
            .find((f) => f.priority === "primary")
            ?.options?.find((f) => f.id === primary)?.name || "Unknown";

        let factor =
          structure?.dimensions?.[item.feedback?.id || 0]?.factors?.[
            item.feedback?.factor
          ]?.title || "Unknown";

        let follow_up_question = item.feedback?.follow_up_question || "";
        let follow_up_feedback = item.feedback?.follow_up
          ? item.feedback.follow_up.replace(/\n|\r/g, " ")
          : "";

        return [
          primary_name,
          factor,
          item.feedback?.risk,
          item.feedback?.question,
          item.feedback?.feedback.replace(/\n|\r/g, " "),
          follow_up_question,
          follow_up_feedback,
        ];
      });

    return [new_data, ..._data];
  };

  if (!structure?.dimensions) {
    return null;
  }

  return (
    <Container>
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "space-between",
          display: "flex",
          marginTop: 20,
        }}
      >
        <Title>Question Context</Title>
        <Download>
          <CSVLink data={getcsvData()}>
            <Icon name="file excel" />
            Download csv
          </CSVLink>
        </Download>
      </div>

      <div style={{ width: "100%", marginTop: 20 }}>
        <Header>
          <TH width={50}>Feedback</TH>
          <TH width={15}>
            {categories?.categories.find((f) => f.priority == "primary").name}
          </TH>

          <TH width={15}>
            <Dropdown
              inline
              options={options}
              value={selected}
              onChange={(e, d) => setSelected(d.value)}
            />
          </TH>

          <TH width={20}>Risk</TH>
        </Header>

        {sorted
          .filter((f) => f.feedback.feedback)
          .map((item) => {
            let _data = item;
            _data.feedback.risk = get_risk(
              item.feedback.response,
              item.feedback.feedback
            );
            return _data;
          })
          .filter((f) => f.feedback.feedback)
          .sort((a, b) => b.feedback.risk - a.feedback.risk)
          .slice((page - 1) * 10, page * 10)
          .map((item) => {
            let primary = item.categories.find(
              (f) => f.priority == "primary"
            ).response;
            if (
              (structure.dimensions.length == 1 && item.feedback.id != 1) ||
              structure.dimensions.length > 1
            ) {
              return (
                <TR>
                  <TD width={50}>
                    <Question>{item.feedback.question}</Question>
                    <Text>{item.feedback.feedback}</Text>

                    {item.feedback.follow_up_question &&
                    item.feedback.follow_up_question.length > 0 ? (
                      <div style={{ marginTop: 20 }}>
                        <Question>{item.feedback.follow_up_question}</Question>
                        <Text>{item.feedback.follow_up}</Text>
                      </div>
                    ) : (
                      ""
                    )}
                  </TD>

                  <TD width={15}>
                    <Team>
                      <Image src={TeamIcon} />
                      {
                        categories.categories
                          .find((f) => f.priority == "primary")
                          .options.find((f) => f.id == primary).name
                      }
                    </Team>
                  </TD>

                  <TD width={15}>
                    <Pillar>
                      {structure?.dimensions?.[item.feedback?.id || 0]
                        ?.factors?.[item.feedback?.factor]?.title ||
                        "Unknown Factor"}
                    </Pillar>
                  </TD>

                  <TD width={20}>{getRisk(item.feedback.risk)}</TD>
                </TR>
              );
            }
          })}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 5,
        }}
      >
        <Pagination
          activePage={page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          totalPages={Math.ceil(
            sorted.filter((f) => f.feedback.feedback).length / 10
          )}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          size="mini"
        />
      </div>
    </Container>
  );
};


export default Context


const Container = styled.div`
	width:100%;
  padding-left:58px;
`

const Title = styled.div`

  margin-bottom: 5px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
/* identical to box height */

display: flex;
align-items: center;

color: #414141;
`;

const Question = styled.div`
	font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #414141;
	margin-bottom:5px;
`

const Text = styled.div`
	font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;

color: #7E7E7E;
`

const Sentiment = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
/* identical to box height */


color: #7E7E7E;
`;


const Header = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display:flex;
  padding:14px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #414141;
`

const TH = styled.div`
  width:${props=>props.width+'%'};
`

const TR = styled.div`
  width:100%;
  padding:18px 27px;
  font-family: 'Poppins';
  display:flex;
   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom:5px;
  align-items:center;
`

const TD = styled.div`
  word-wrap:break-word;
  width:${props=>props.width+'%'};
  padding-right:10px;
`

const Image = styled.img`
  margin-right:10px;
`

const Team = styled.div`
  display:flex;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #7E7E7E;
align-items:center;
`

const Pillar = styled.div`
 padding:3px;
  display:flex;
  align-items:center;
  text-align:center;
  justify-content:center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 8px;
line-height: 12px;
padding:5px;
background-color:#615BF0;
margin:0px 5px;
color:white;border-radius: 100px;
`

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color:${props=>props.color+' !important'};
    border-radius:15px !important;
  }
   border-radius:15px !important;
`;

const Download = styled.div`
  margin-right:40px;
  font-size:12px;
  font-family: 'Poppins';

  a{
     color:#615BF0 !important;
  }
`