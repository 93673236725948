import React from "react";
import styled from "styled-components";
import { Icon, Popup } from "semantic-ui-react";

const StatusBox = ({ type, tokenStatus, email_status }) => {
	// console.log(email_status)
	// console.log(email_status === 4)
	
	const getColor = () => {
		
		if (email_status === 4) {
			return "Marked Spam";
		}
		if (email_status === 3) {
			return "Hard Bounce";
		}
		if (type === "Pending") {
			return "grey";
		}
		if (type === "Error" || type === "Removed") {
			return "red";
		}
		if (type === "No Response") {
			return "grey";
		}

		return "green";
	};

	const getContent = () => {
		if (email_status === 3) {
			return "Hard Bounce";
		}
		if (email_status === 4) {
			return "Marked Spam";
		}
		if (type=="Archived") {
			return "Archived";
		}
		if (type === "Pending") {
			return "Delivered";
		}

		if (type === "Completed") {
			return "Completed";
		}
		if (type === "No Response") {
			return "No Response";
		}

		if (type === "Removed") {
			return "Survey was removed for this address";
		}

		return "Unable to deliver to this email address";
	};

	return (
		<Popup
			trigger={
				<Container color={getColor()}>
					{getContent()}
					{tokenStatus?.id === 3 ? (
						<Check>
							<Icon
								name="check"
								style={{ margin: 0, marginTop: -7 }}
							/>
						</Check>
					) : (
						""
					)}
				</Container>
			}
			content={getContent()}
			inverted
			position="top center"
		/>
	);
};

/******** Styles ****************************************************/

const Container = styled.div`
	padding: 5px;
	font-family: "Barlow Semi Condensed", sans-serif;
	border-radius: 3px;
	width: 150px;
	border: ${(props) => "1px solid " + props.color};
	color: ${(props) => props.color};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const Check = styled.div`
	position: absolute;
	top: -7.5px;
	right: -7.5px;
	color: white;
	background-color: green;
	height: 20px;
	width: 20px;
	border-radius: 10px;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default StatusBox;
