import {
  GET_FILTERED_DATA,
  SET_FILTERED_DATA,
  SET_SELECTED_QUESTION,
  SET_DATA_DATES,
  SET_CATEGORY_DATA,
  SET_RAW_DATA,
  CLEAR_ALL,
  SET_QUESTIONS,
  SET_SUB_NAV,
  SET_NAVIGATION,
  SET_SELECTED_FILTERS,
  SET_DATA_ACNHOR,
  SET_FILTERED_LAST_DATA,
  SET_SURVEY_VERSION,
  SET_NEW_FILTERS
} from "constants/actions";

function isValidObject(obj) {
  // Check if the object is valid
  if (!obj || typeof obj !== "object") {
    return false;
  }

  // Iterate over each property of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // If the value is an object, recursively check its properties
      if (typeof value === "object" && value !== null) {
        if (!isValidObject(value)) {
          return false;
        }
      }
      // If the value is not undefined or null, it's a valid entry
      else if (value !== undefined && value !== null) {
        return true; // There's at least one valid property
      }
    }
  }

  // If all values are undefined or null
  return false;
}

const initialState = {
  filtered_data: [],
  filtered_last_data: [],
  question_structure: {},
  category_structure: {},
  selected: { id: 0 },
  dates: [],
  raw_data: [],
  sub: 1,
  filters: [],
  new_filters: [],
  anchor: null,
  nav: null,
  survey_version: null,
};

export default function Audit(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERED_DATA:
      return {
        ...state,
        filtered_data: action.payload,
      };
    case SET_NEW_FILTERS:
      return {
        ...state,
        new_filters: action.payload,
      };
    case SET_FILTERED_LAST_DATA:
      return {
        ...state,
        filtered_last_data: action.payload,
      };
    case SET_SELECTED_QUESTION:
      return {
        ...state,
        selected: action.payload,
      };
    case SET_SURVEY_VERSION:
      return {
        ...state,
        survey_version: action.payload,
      };
    case SET_DATA_DATES:
      return {
        ...state,
        dates: action.payload,
      };
    case SET_CATEGORY_DATA:
      return {
        ...state,
        category_structure: action.payload,
      };
    case SET_DATA_ACNHOR:
      return {
        ...state,
        anchor: action.payload,
      };
    case SET_RAW_DATA:
      return {
        ...state,
        raw_data: action.payload,
      };
    case SET_QUESTIONS:
      if (!isValidObject(action.payload)) {
        return state;
      }

      return {
        ...state,
        question_structure: action.payload,
      };
    case SET_SUB_NAV:
      return {
        ...state,
        sub: action.payload,
      };
    case SET_NAVIGATION:
      return {
        ...state,
        nav: action.payload,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        filtered_data: [],
        question_structure: {},
        category_structure: {},
        dates: [],
        raw_data: [],
        sub: 1,
        selected: { id: 0 },
        nav: {},
        filters: [],
        new_filters: [],
      };
    default:
      return state;
  }
}
