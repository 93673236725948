import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress } from "semantic-ui-react";
import questions_list from "./question_structure.json";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const top_labels = [
  "AV",
  "Technical Staff",
  "Coach",
  "Sport Science",
  "Athlete",
];
const colored = ["181, 181, 181", "201, 124, 12"];

const SingleTable = ({ data, questions, labels, color, q,language_hook,isFrench }) => {
  const secondary_list = new Set(data.map((i) => i.secondary));
  const column_length = secondary_list.size;
  
  return (
    <Container>
      <TR2>
        <TH width={25} borderLeft={false} borderRight={true} color={color}>
          {isFrench?questions.fr:questions.title} Dimension
        </TH>
        <TH
          width={75 / (column_length + 1)}
          borderRight={true}
          center={true}
          color={color}
        >
          {language_hook('Average')}
        </TH>
        {[...secondary_list].map((item, i) => {
          return (
            <TH
              width={75 / (column_length + 1)}
              borderRight={i == column_length - 1 ? false : true}
              center={true}
              color={color}
            >
              {isFrench?labels.find((f) => f.id == item).fr:labels.find((f) => f.id == item).name}
            </TH>
          );
        })}
      </TR2>

      {questions.factors.map((item, i) => {
        return (
          <TableRow
            title={isFrench? item.fr:item.title}
            labels={labels}
            data={data.filter((f) => Number(f.factor) == i)}
            color={color}
            column_length={column_length}
          />
        );
      })}
    </Container>
  );
};

const Table = ({ type, data, labels, color,language_hook,is_french }) => {
  const questions = useSelector(
    (state) => state.audit?.question_structure,
    shallowEqual
  );
  return (
    <SingleTable
      data={data.summary.filter((f) => f.dimension == type)}
      labels={labels}
      color={color}
      questions={questions.dimensions[type]}
      language_hook={language_hook}
      isFrench={is_french}
    />
  );
};

export default Table;

const getAverage = (data) => {
  let total = 0;
  let n = 0;
  data.map((i) => {
    total += i.average;
    n += 1;
  });

  return total/n;
};

const TableRow = ({ title, color, data, labels, column_length }) => {
  return (
    <TR>
      <TD width={25} borderLeft={false} borderRight={true}>
        <Name>{title}</Name>
      </TD>
      <TD2
        width={75 / (column_length + 1)}
        borderRight={true}
        center={true}
        bold={true}
      >
        {getAverage(data).toFixed(1)}
      </TD2>
      {data.map((item, i) => {
        return (
          <TD2
            width={75 / (column_length + 1)}
            borderRight={i == column_length - 1 ? false : true}
            center={true}
            bold={i == 0}
            color={colored.includes(i) ? "rgba(" + color + ",0.3)" : "white"}
          >
            {item.average.toFixed(1)}
          </TD2>
        );
      })}
    </TR>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
const TR = styled.div`
  width: 100%;
  padding:14px;
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom:3px;
`;

const TR2 = styled.div`
  width: 100%;
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding:14px;
  border-radius: 10px;
  margin-bottom:3px;
`;

const TH = styled.div`
  color: black;
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  padding: 3px 6px;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */


  color: #414141;
`;

const Name = styled.div`
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #414141
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  padding: 3px 6px;

`;

const TD2 = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  padding: 3px 6px;
  justify-content: center;
  background-color: ${(props) => props.color};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */


  color: #7E7E7E;
`;
