import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import Overview from '../user_explore/User/Overview'
import TeamHealth from '../team_health/index'
import NewEQ from 'reports/emotional_health/index'
import ClusterAnalysis from 'reports/cluster/index'
import Cluster from 'reports/cluster/Cluster'
import ThemeAnalytics from '../theme/ThemeAnalysis'
import ThemeOverview from '../theme/Overview'

import { useSelector, useDispatch, shallowEqual } from "react-redux";


import { ThemesList } from "data/themes"

import format from "date-fns/format";

const DefaultPic = 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7'

const TabMap = { 'Connections': 1, 'Clusters': 2, 'Sentiments': 3 }


const Profile = (props) => {
	const { selectedTeam, selectedTeamId } = props
	const [selectedTab, setSelectedTab] = useState(1)

	const [clusterRank, setClusterRank] = useState();
	const [clusterData, setClusterData] = useState([])

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const name = params.get('name') ? params.get('name') : "Connections";

	const [loading, setLoading] = React.useState(true)

	const [cultureData, setCultureData] = useState([]);

	const [team_type, setTeamType] = useState(10)


	const [themeList, setThemeList] = useState([]);
	const [themeHistory, setThemeHistory] = useState([]);

	const [themeAnalysis, setThemeAnalysis] = useState([]);

	const [dateList, setDateList] = useState([]);

	const [empCultureData, setEmpCultureData] = useState([])
	const [empMoodData, setEmpMoodData] = useState([])

	const [culComments, setculComments] = useState();

	const [pastDates, setPastDates] = useState();
	const [culOverview, setculOverview] = useState();
	const [moodOverview, setmoodOverview] = useState();

	// // // // // // //
	const EmployeeId = useSelector((state) => state.auth?.employee_id, shallowEqual)
	const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)

	const { culture_data, mood_data,
		employees_data, industry_benchmark_data,
		get_selectedOrg } = useSelector(
			(state) => ({
				mood_data: state.moods.moods,
				culture_data: state.culture.culture,
				employees_data: state.employees.employees,
				industry_benchmark_data: state.industry_benchmark.industry_benchmark,
				get_selectedOrg: state.selectedOrg
			}),
			shallowEqual
		);

	// console.log(mood_data)
	// console.log(culture_data)


	useEffect(() => {

		const overView = []
		const empList = []
		const _dateList = []
		const connectList = []
		const commentList = []
		const _graph_network = []
		// console.log(culture_data)
		culture_data.map(cd => {
			_dateList.push(format(
				new Date(cd?.on_date.toLocaleString()),
				"MMM do yyyy"
			))
			// console.log(cd)

			if (cd.summary) {
				overView.push(
					cd.summary?.culture_total_score)
			} else {
				overView.push(0)
			}
			// /survey_comments
			commentList.push({
				date: format(
					new Date(cd?.on_date.toLocaleString()),
					"MMM do yyyy"
				),
				survey_comments: cd.survey_comments
			}
			)
			_graph_network.push(cd.graph_network)
			return null
		})
		// setGraphNetwork(_graph_network)
		setPastDates(_dateList.reverse())
		setDateList(_dateList)
		setEmpCultureData(empList)
		setculOverview(overView.reverse())
		// setculConnections(connectList)
		setculComments(commentList)


	}, [culture_data]);

	useEffect(() => {
		// employee_comments

		const overView = []
		const empList = []
		mood_data.map(cd => {
			// console.log(cd)
			if (cd.employee_comments) {
				overView.push(cd?.mood_score
				)
			} else {
				overView.push(0)
			}

			empList.push({
				date: cd.on_date,
				responses: cd.employee_comments
			})
			return null
		})
		setEmpMoodData(empList)
		setmoodOverview(overView.reverse())


	}, [mood_data, dateList]);

	useEffect(() => {
		if (culture_data.length > 0) {
			setTeamType(culture_data[0].team_type)
			// console.log(culture_data[0].team_type)

			if (employees_data.length > 0) {
				// setCultureData(culture_data[0]?.connections);
				const newNodes = []
				const newEdges = []
				if (culture_data[0].connections.length > 0) {

					culture_data[0].connections.edges.map(en => {
						newEdges.push(
							{
								from: en.from,
								to: en.to,
								color: 'rgba(106, 0, 255,0.4)',
								dashes: true
							}
						)
					})

					Object.keys(culture_data[0].connections.nodes).map((key, k) => {
						//  console.log(key))
						const Employee = employees_data.filter(
							em => em.id === culture_data[0].connections.nodes[key].employee_id)[0]

						function FullName() {
							if (Employee.first_name) {
								return `${Employee.first_name} ${Employee.last_name}`
							}
							return Employee.email.split('@')[0]

						}
						// console.log(Employee)
						newNodes.push({
							id: Employee.id,
							value: culture_data[0].connections.nodes[key].value,
							shape: "circularImage", color: '#08c251',
							label: FullName(),
							image: Employee.picture ? Employee.picture : DefaultPic
						})
					})
				}
				// console.log(newNodes)

				setCultureData({ nodes: newNodes, edges: newEdges })
				setLoading(false)
			}

		}
	}, [culture_data, employees_data]);

	// console.log(culOverview)
	// console.log(moodOverview)

	useEffect(() => {
		setThemeList([])
		if (mood_data[0]?.team_theme_score) {
			const ThemeList1a = Object.values(mood_data[0]?.team_theme_score)
			// console.log(ThemeList1a);
			const FinalList = []
			ThemeList1a.filter(tt => tt?.category_name.length > 1).map(p => {
				FinalList.push(
					{
						name: p.category_name,
						importance: p.total_importance / p.nos,
						sentiment_compound: p.total_sentiment_compound / p.nos,
						sentiment_neg: p.total_sentiment_neg / p.nos,
						sentiment_neu: p.total_sentiment_neu / p.nos,
						sentiment_pos: p.total_sentiment_pos / p.nos,
						z_score: p.total_z_score / p.nos,
						keywords: p.keywords,
					}
				)
			})

			// console.log(FinalList)
			setThemeList(
				FinalList.sort((a, b) => parseFloat(b.importance) - parseFloat(a.importance))
			)

		}
		if (mood_data) {

			const _dateList = []
			const FinalList1 = []
			// const GetHistory = []

			mood_data.map(md => {
				_dateList.push(format(
					new Date(md?.on_date.toLocaleString()),
					"MMM do yyyy"
				))
				let FinalList2 = []
				const ThemeList1b = Object.values(md.team_theme_score)
				if (ThemeList1b.length > 0) {
					// console.log(ThemeList1b);

					ThemeList1b.filter(tt => tt?.category_name?.length > 1).map(p => {
						// console.log(p)
						FinalList2.push(
							{
								date:
									format(
										new Date(md?.on_date.toLocaleString()),
										"MMM do yyyy"
									),
								name: p.category_name,
								importance: p.total_importance / p.nos,
							}
						)
						// console.log(
						// 	FinalList2
						// )
					})

					FinalList1.push(
						FinalList2.sort((a, b) => parseFloat(b.importance) - parseFloat(a.importance))
					)
				}

			})

			setDateList(_dateList.reverse())
			// console.log(GetHistory)
			setThemeHistory(FinalList1)

			// const ResponseList = []
			const ResponseObject = {}
			const SortedObject = {}

			ThemesList.map(th => {
				// console.log(th.name)
				ResponseObject[th.name] = {
					'importance': [],
					'sentiment': []
				}
				SortedObject[th.name] = {
					'importance': [],
					'sentiment': []
				}
				// ResponseObject['dateList'] = []
				// const _dateList = [].reverse()
				// const ReversedMooData = mood_data.sort((a, b) => (b.on_date) - (a.on_date))
				// const ReverseMoodData = mood_data.reverse()
				const Importance = []
				const Sentiment = []

				mood_data.map(md => {
					if (md.team_theme_score) {
						const ThemeList1 = Object.values(md.team_theme_score)
						const ThemeFound = (ThemeList1.filter(fth => fth.category_name === th.name)[0])
						// console.log(ThemeFound)

						if (ThemeFound) {
							ResponseObject[th.name]['importance'].push(Math.round(ThemeFound.total_importance / ThemeFound.nos))
							ResponseObject[th.name]['sentiment'].push(Math.round((((ThemeFound.total_sentiment_compound / ThemeFound.nos) + 1) / 2) * 100))
							// Math.round(()+ 1) / 2) * 100))
							// console.log(Math.round((((ThemeFound.total_sentiment_compound / ThemeFound.nos)+1 ) / 2)))

							Importance.push(Math.round(ThemeFound.total_importance / ThemeFound.nos))
							Sentiment.push(Math.round((((ThemeFound.total_sentiment_compound / ThemeFound.nos) + 1) / 2) * 100))


						} else {
							ResponseObject[th.name]['importance'].push(0)
							ResponseObject[th.name]['sentiment'].push(0)

							Importance.push(0)
							Sentiment.push(0)

						}
					}
					return null
				})

				SortedObject[th.name]['importance'] = Importance.reverse()
				SortedObject[th.name]['sentiment'] = Sentiment.reverse()

			})
			setThemeAnalysis(SortedObject)
		}

	}, [mood_data, selectedTeam]);

	useEffect(() => {
		// 
		// selectedTeam
		const NewClusterData = []
		if (mood_data[0]?.team === selectedTeam?.id) {
			if (mood_data[0]?.cluster_score?.cluster) {

				for (const [key, value] of Object.entries(mood_data[0]?.cluster_score.cluster)) {
					const EmployeeList = []
					if (value?.employees) {
						value.employees.map(em => {
							EmployeeList.push(employees_data.filter(emp => emp.id === em)[0])
						})
					}
					NewClusterData.push(
						{
							group: key,
							employees: EmployeeList,
							keywords: value.keywords,
							rank: mood_data[0].cluster_score.rank[key]
						}
					)
				}
				setClusterRank(mood_data[0]?.cluster_score?.rank)
			}
		}

		setClusterData(NewClusterData)

	}, [employees_data, mood_data, selectedTeam]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (loading === true) {
				setLoading(false)
			}
		}, 2000);
		return () => clearTimeout(timer);
	}, [loading]);

	return (
		<Container hide={props.hide}>
			<Card>
				<UserContainer>
					<Name>{selectedTeam?.name}</Name>
				</UserContainer>

				<Tabs>
					<Tab selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>Overview</Tab>
					<Tab selected={selectedTab === 2} onClick={() => setSelectedTab(2)}>Team Health</Tab>
					<Tab selected={selectedTab === 3} onClick={() => setSelectedTab(3)}>Emotional Health</Tab>
					<Tab selected={selectedTab === 4} onClick={() => setSelectedTab(4)}>Clusters</Tab>
					<Tab selected={selectedTab === 5} onClick={() => setSelectedTab(5)}>Connections</Tab>
					<Tab selected={selectedTab === 6} onClick={() => setSelectedTab(6)}>Key Themes</Tab>
					<Tab selected={selectedTab === 7} onClick={() => setSelectedTab(7)}>Theme History</Tab>
				</Tabs>
			</Card>
			<Bottom>
				{selectedTab === 1 && <Overview
					culOverview={culOverview}
					moodOverview={moodOverview}
					pastDates={pastDates} />}

				{selectedTab === 2 && <TeamHealth
					// empCultureData={empCultureData}
					selectedTeam={selectedTeam}
					selectedTeamId={selectedTeamId}
				// selEmp={selEmp}
				// culComments={culComments} 
				/>}

				{selectedTab === 3 && <NewEQ
					// empMoodData={empMoodData}
					selectedTeam={selectedTeam}
					selectedTeamId={selectedTeamId}
					// selEmp={selEmp}
					// pastDates={pastDates}
					teamList={true}
				/>}

				{selectedTab === 4 && <Cluster
					clusterData={clusterData}
					clusterRank={clusterRank}
					DefaultPic={DefaultPic}
				/>}

				{selectedTab === 5 && <ClusterAnalysis
					cultureData={cultureData}
					culture_data={culture_data}
					employees_data={employees_data}
				/>}

				{selectedTab === 6 && <ThemeOverview
					themeList={themeList}
					themeHistory={themeHistory}
				/>}

				{selectedTab === 7 && <ThemeAnalytics
					themeAnalysis={themeAnalysis}
					dateList={dateList}
				/>}

			</Bottom>
		</Container>
	)
}


export default Profile

const Container = styled.div`
	width:${props => props.hide ? 'calc(100% - 70px)' : 'calc(100% - 270px)'};
	border-radius: 15px;
  	overflow:hidden;
  	transition:all 500ms;
`

const Card = styled.div`
	width:100%;
	padding-top:20px;
  	background-color: #ffffff;
`


const UserContainer = styled.div`
	display:flex;
	align-items:center;
	position:relative;
	margin-bottom:40px;
`

const Avatar = styled.img`
	width:60px;
	height:60px;
	border-radius:30px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:36px;
	font-weight:bold;
	color:#4a4a4a;
`

const Tabs = styled.div`
	display:flex;
`

const Tab = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px;
	font-size:16px;
	font-weight:${props => props.selected ? 'bold' : 400};
	border-bottom:${props => props.selected ? '2px solid #6a00ff' : 400};
	color:${props => props.selected ? 'black' : '#9b9b9b'};
	cursor:pointer;
`

const Bottom = styled.div`
	width:100%;
	background-color:white;

`
