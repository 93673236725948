import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { build_default_State } from "../data_navigation/calculate_data";

import { SET_NAVIGATION, SET_SELECTED_FILTERS } from "constants/actions";

const FilterContainer = ({structure,employee_categories}) =>{
	 const dispatch = useDispatch();
	const [muted, setMuted] = useState([]);
	const [access, setAccess] = useState([]);
	const [refresh, setRefresh] = useState(false);

	const selected = useSelector((state) => state.audit?.filters, shallowEqual);
  const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual
  );

  const survey_version = useSelector((state) => state.audit?.survey_version);




  const onSelect = (s) => {
    let check = selected.filter(
      (f) => f.id == s.id && f.subfactor == s.subfactor && f.option == s.option
    );

    let _selected;
    if (check.length > 0) {
      _selected = selected.filter((f) => {
        return (
          f.id != s.id || s.subfactor != f.subfactor || f.option != s.option
        );
      });
    } else {
      _selected = [...selected, s];
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected, structure);
    setMuted([]);
  };

   const mute = (s) => {
    if (!isMuted(s)) {
      let _muted = muted.filter(
        (f) =>
          f.id != s.id || f.subfactor != s.subfactor || f.option != s.option
      );
      return setMuted(_muted);
    }

    setMuted([...muted, s]);
  };

 const isMuted = (s) => {
	    return (
	      !muted.filter(
	        (f) =>
	          f.id == s.id && f.subfactor == s.subfactor && f.option == s.option
	      ).length > 0
	    );
	  };

	const set_nav = (new_selected) => {
	    let _selected = build_default_State(structure, employee_categories);
	    new_selected.map((item, i) => {
	      if (_selected[item.id].selected) {
	        _selected[item.id]["selected"] = false;
	        Object.keys(_selected[item.id]).map((s) => {
	          if (s != "selected" && s != "priority") {
	            _selected[item.id][s].selected = false;
	          }
	        });
	      }

	      if (item.subfactor != null) {
	        _selected[item.id][item.option].subfactors[
	          item.name.id
	        ].selected = true;
	        _selected[item.id][item.option].sub = true;
	      } else {
	        if (_selected[item.id][item.name.id]) {
	          _selected[item.id][item.name.id].selected = true;
	        } else {
	          onSelect(item);
	          _selected = build_default_State(structure, employee_categories);
	          setRefresh(true);
	        }
	      }
	    });

	    dispatch({
	      type: SET_NAVIGATION,
	      payload: _selected,
	    });
  };

useEffect(() => {
    if (muted.length > 0) {
      // dispatch({
      // 	type: SET_SELECTED_FILTERS,
      // 	payload: muted,
      // });

      set_nav(muted, structure);
    } else {
      // dispatch({
      // 	type: SET_SELECTED_FILTERS,
      // 	payload: selected,
      // });

      set_nav(selected, structure);
    }
  }, [muted]);


useEffect(()=>{
  if(employee_categories){
    setAccess(employee_categories.map(item=>item?.value_id))
  }
},[employee_categories])

useEffect(()=>{
  dispatch({
    type: SET_SELECTED_FILTERS,
    payload: [],
  });
  set_nav([])
},[survey_version])



	return (
     <LabelContainer selected={selected.length>0}>
        {selected.map((item, i) => {
          let checkMuted = isMuted(item) && muted.length > 0;
          let close = item.subfactor ? access.includes(item.subfactor) : false
          if (close && access.length===1) {
            return <Label key={i}>{item.name.name}</Label>;
          }
          return (
            <Label key={i} muted={checkMuted}>
              <div onClick={() => mute(item)}>{item.name.name}</div>
              {muted.length == 0 ? (
                <Icon
                  name="delete"
                  style={{ margin: 0, marginTop: -8, zIndex: 10 }}
                  onClick={() => onSelect(item)}
                  id={"label"}
                />
              ) : (
                <Icon
                  name="delete"
                  style={{ margin: 0, marginTop: -8, zIndex: 10 }}
                  id={"label"}
                />
              )}
            </Label>
          );
        })}
      </LabelContainer>
		)
}

export default FilterContainer



const Label = styled.div`
  font-family: "Inter", sans-serif;
  background-color: ${props=>props.muted?'476DFA':'#515151'};
  border-radius: 20px;
  transition: background 0.1s ease;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 22px;
  font-size:10px;
  padding:0px 10px;
  color:white;
  opacity: ${(props) => (props.muted ? 0.2 : 1)};
  zindex: -1;
  margin-right:5px;
  margin-top:5px;

  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
