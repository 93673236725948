import styled from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

import Overview from "./Overview";
import Analytics from "./Analytics/index";

import { Data_Generator } from "./testing/generate_data";
import structure from "./structure/survey_structure";
import questions from "./structure/question_structure";

const EDI = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const name = params.get("name") ? params.get("name") : false;

  const data = Data_Generator(5000, 1, structure, questions);
  if (name) {
    return <Analytics name={name.replace("?testing=1", "")} />;
  }
  return (
    <Overview responses={data} categories={structure} questions={questions} />
  );
};

export default EDI;
