import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import TeamCard from "./TeamCard";
import { Message } from "semantic-ui-react";

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width:768px){
    display:none;
  }
`;

const Linked = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const TeamTable = (props) => {
  const members = props.members;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
      }}
    >
      {props.data.length > 0 ? (
        <Table>
          <Headers>
            <TH width={40}>Name</TH>
            <TH width={30}>Completion</TH>
            <TH width={30}></TH>
          </Headers>
          {props.data.map((item, i) => {
            return (
              <TeamCard
                data={item}
                id={i}
                members={
                  members ? members.filter((mm) => mm.team === item.id) : null
                }
                onClick={() => props.selectTeam(item.id)}
                key={i}
              />
            );
          })}
        </Table>
      ) : props.search ? (
        ""
      ) : (
        <Message
          icon="plug"
          color="violet"
          size="big"
          header="Improve team member understanding "
          style={{ zIndex: 0 }}
          content={
            <div>
              <Linked>Invite a team</Linked> within nuLogic to the
              personality assessment hub.
            </div>
          }
        />
      )}
    </div>
  );
};

export default TeamTable;
