import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
// import { Progress } from 'semantic-ui-react'
// import Pic from 'assets/images/hardship.png'
// import ThemeData from 'data/themes.js'

import { Dropdown } from 'semantic-ui-react'

import { ThemesList } from "data/themes"

const options = {
		chart: {
			id: 'chart2',
			height: 350,
			type: 'area',
			toolbar: {
				autoSelected: 'pan',
				show: false
			},
		},
		colors: ['#00B0F0', '#F12DA2'],
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: 'smooth'
		},
		xaxis: {
			type: 'datetime',
			categories: ["2018-09-12T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-26T02:30:00.000Z", "2018-10-03T03:30:00.000Z", "2018-10-10T04:30:00.000Z", "2018-10-17T05:30:00.000Z", "2018-10-24T06:30:00.000Z"]
		},
		tooltip: {
			x: {
				format: 'dd/MM/yy HH:mm'
			},
		}
};

const series = [{
	name: 'Impact',
	data: [31, 40, 28, 51, 42, 66, 95]
}, {
	name: 'Sentiment',
	data: [11, 32, 88, 32, 34, 52, 41]
}]
const ThemeAnalysis = (props) => {

	const {themeAnalysis, dateList, pastDates} = props

	const [selectedTheme, setSelectedTheme] = useState(ThemesList[0]);
	const [chartOptions, setChartOptions] = useState(options);
	const [chartSeries, setChartSeries] = useState(series);

	function OnSelectChange(e, data) {
		// console.log(data);
		setSelectedTheme(ThemesList.filter(th => th.value === data.value)[0])
		// console.log("changed")
	}
	// console.log(props)
	useEffect(() => {
		if (dateList) {
			setChartOptions(
				{...options, 
				xaxis: {
				type: 'date',
				categories: dateList
			}})
		}
		
	}, [dateList]);

	useEffect(() => {
		if (themeAnalysis) {
			setChartSeries(
				[{
					name: 'Impact',
					data: themeAnalysis[selectedTheme.name]['importance']
				}, {
					name: 'Sentiment',
					data: themeAnalysis[selectedTheme.name]['sentiment']
				}])
		}
		
	}, [themeAnalysis, selectedTheme]);

	// console.log(themeAnalysis)
	// console.log(dateList)
	

	return (
		<CardContent>
			<Row>
				<ImageContainer>
					<Image src={selectedTheme.icon} />
				</ImageContainer>
				<div>
					<Title>

						<Dropdown
							onChange={OnSelectChange}
							//   inline
							options={ThemesList}
							defaultValue={ThemesList[0].name}
						/>



					</Title>
					<Sub>Sentiment Analytics</Sub>
					<P>{selectedTheme.details} </P>
				</div>
			</Row>

			<ChartArea>
				<ChartTitle>Historical Trends</ChartTitle>
				<Chart 
				options={chartOptions} 
				series={chartSeries} 
				type="area" height={350} />
			</ChartArea>

			{/* <ChartArea>
				<MiniTitle>Date Range</MiniTitle>
				<Chart options={data2.options} series={data2.series} type="area" height={130} />
			</ChartArea> */}

			{/* <Data> */}


			{/* <Card>
					<CardTitle>Most Impactful Keywords</CardTitle>
					<Description>These keywords best describe the most impactful comments on growth mindset</Description>
					<KeyWord> 
						<Word>season eager goal
						<Percent>88%</Percent>
						</Word>
						 <Progress percent={88}  color='violet'  size="tiny"/>
					</KeyWord>
					<KeyWord> 
						<Word>mentally losing games <Percent>86%</Percent></Word>
						
						 <Progress percent={66}  color='violet'  size="tiny"/>
					</KeyWord>
					<KeyWord> 
						<Word>frustrated person team
						<Percent>55%</Percent></Word>
						 <Progress percent={55}  color='violet'  size="tiny"/>
					</KeyWord>
					<KeyWord> 
						<Word> season trying win
						<Percent>84%</Percent></Word>
						 <Progress percent={45}  color='violet'  size="tiny"/>
					</KeyWord>
					<KeyWord> 
						<Word>great team performing
						<Percent>33%</Percent></Word>
						 <Progress percent={33}  color='violet'  size="tiny"/>
					</KeyWord>
					
				</Card> */}

			{/* </Data> */}
		</CardContent>
	)
}


export default ThemeAnalysis




const Card = styled.div`
	width:calc(30% - 20px);
	margin-left:20px;
	margin-top:30px;
`

const ChartCard = styled.div`
	width:60%;
`

const CardTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	font-weight:500;
	
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:25px;
	margin-top:5px;
`

const KeyWord = styled.div`
	display:flex;
	flex-direction:column;
	margin-bottom:0px;
`
const ChartTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	font-weight:500;
`

const Word = styled.div`
	font-size:16px;
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:3px;
	display:flex;
	justify-content:space-between;
	width:100%;
`

const Impact = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
`

const MiniTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
`

const Intro = styled.div`
	width:60%;
	display:flex;
	flex-direction:column;
`

const CardContent = styled.div`
	margin-top:20px;
	padding:20px;
	display:flex;
	flex-wrap:wrap;
`

const Dated = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	color:#4a4a4a;
	margin-top:5px;
`
const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:36px;
	font-weight:bold;
	width:100%;
`

const P = styled.p`
	font-family: "Red Hat Display", sans-serif;
	max-width:800px;
	margin-top:20px;
	font-size:16px;
`

const Sub = styled.div`
	font-family: "Red Hat Display", sans-serif;
	color:#9b9b9b;
	margin-top:5px;
`

const Percent = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;

`


const ImageContainer = styled.div`
   background-color:#7e24ea;
   padding:20px;
   border-radius:10px;
   margin-right:30px;

   @media(max-width:768px){
   	display:none;
   }
`

const Image = styled.img`
  height:100px;

  @media(max-width:768px){
   		height:25px;
   }
  
`

const Row = styled.div`
	display:flex;

`

const Data = styled.div`
	display:flex;
`

const ChartArea = styled.div`
	width:100%;
	margin-top:30px;
`



const data2 = {
	series: [{
		data: [31, 40, 28, 51, 42, 66, 95]
	}],
	options: {
		chart: {
			id: 'chart1',
			height: 130,
			type: 'area',
			brush: {
				target: 'chart2',
				enabled: true
			},
			events: {
				brushScrolled: function (chartContext, { xaxis, yaxis }) {
					//console.log(xaxis)
				}
			},
			selection: {
				enabled: true,
				xaxis: {
					min: new Date('2018-09-12T00:00:00.000Z').getTime(),
					max: new Date('2018-10-24T06:30:00.000Z').getTime()
				}
			},
		},
		colors: ['#008FFB'],
		fill: {
			type: 'gradient',
			gradient: {
				opacityFrom: 0.91,
				opacityTo: 0.1,
			}
		},
		xaxis: {
			type: 'datetime',
			categories: ["2018-09-12T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-26T02:30:00.000Z", "2018-10-03T03:30:00.000Z", "2018-10-10T04:30:00.000Z", "2018-10-17T05:30:00.000Z", "2018-10-24T06:30:00.000Z"],
			tooltip: {
				enabled: false
			}
		},
		yaxis: {
			tickAmount: 2,
			max: 100,
			min: 0
		}
	},

}