import React from "react";
import styled from "styled-components";

// import moment from "moment";
import { format, formatDistance, subDays } from "date-fns";
import getISODay from "date-fns/getISODay";
import formatISO from 'date-fns/formatISO'

import { useDispatch } from "react-redux";
import { Icon, Button, Modal, Dropdown } from "semantic-ui-react";
// import { SelectContainer } from "./styled_list";

import { SG_EDIT_SURVEY_COMPLIANCE } from "constants/actions";

const DayOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const SurveyDate = ({ onDay, setProcessing, scheduledAt, id }) => {
  const dispatch = useDispatch();
  const [weekDays, setWeekDays] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [selectedDate, setSelectedDate] = React.useState(false);

  React.useEffect(() => {
    let _weekdays = [];

    let numberOfDaysToAdd = 90;
    processArray(numberOfDaysToAdd);

    async function processArray(items) {
      for (let i = 0; i < items; i++) {
        await delayedLog(i);
      }
    }

    async function delayedLog(item) {
      // notice that we can await a function
      // that returns a promise
      let newDate = await delay(item);
      let someDate = new Date(newDate);
      if (someDate.getDay() === onDay + 1) {
        _weekdays.push({
          key: format(newDate, "yyyy-MM-dd"),
          value: format(newDate, "yyyy-MM-dd"),
          text: `${format(newDate, "yyyy-MM-dd")} 
          ${formatDistance(subDays(new Date(newDate), 0), new Date(), {
            addSuffix: true,
          })}`,
        });
      }
      // console.log(format(newDate, "yyyy-MM-dd"));
      // console.log(delay(item));
    }
    function delay(i) {
      let someDate = new Date();
      return someDate.setDate(someDate.getDate() + i);
    }
    setWeekDays(_weekdays);

    // return sundays;
  }, [onDay]);



  function UpdateActiveStatus() {
    if (id) {
      setProcessing(true);
      dispatch({
        type: SG_EDIT_SURVEY_COMPLIANCE,
        payload: {
          id: id,
          scheduled_at: formatISO(new Date(selectedDate))
        },
      });
    }
  }

  function sedDate(e, { value }) {
    console.log(value);
    setSelectedDate(value)
  }

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
          <Button>{`Next Survey: 
          ${scheduledAt && format(new Date(scheduledAt), "dd MMM yyyy")}
          ${DayOfWeek[getISODay(new Date(scheduledAt)) - 1]}`}</Button>
        }
      >
        <Modal.Header>Pick any {DayOfWeek[onDay]}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {/* <Header>Default Profile Image</Header> */}
            <ContainerCard>
              <Dropdown
                placeholder="Select next Date"
                selection
                // multiple={true}
                options={weekDays}
                onChange={sedDate}
                value={selectedDate}
              />
              
              <Button
                content="Set Date"
                labelPosition="right"
                icon={selectedDate? "checkmark": "close"}
                disabled={!selectedDate}
                onClick={() => {
                  UpdateActiveStatus();
                  setOpen(false);
                }}
                positive={selectedDate? true: false}
                
              />
            </ContainerCard>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default SurveyDate;

export const ContainerCard = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
`;
