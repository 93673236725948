import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React from "react";

import { Outlet, useLocation } from "react-router-dom";

import Help from "./Help";

import ErrorWrapper from "error/index";

import { DomainContext } from "layouts/LayoutContext";
import { DomainList } from "data/live/domain";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
const DesktopContainer = () => {
  return (
    <Media
      greaterThan="mobile"
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <SideBar /> */}
      {/* <TopBar /> */}

      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100vw",
            paddingLeft: 68,
            paddingTop: 37,
            paddingRight: 30,
          }}
        >
          <ErrorWrapper>
            <Outlet />
          </ErrorWrapper>
        </div>
      </div>
    </Media>
  );
};

const MobileContainer = () => {
  const location = useLocation();

  // This was added to satisfy the margin-top for the ROI report
  // I was unsure of the possible side effects of this change
  // so I targeted the ROI report specifically. -Ben
  const topMargin = location.pathname.includes("roi-report") ? "0px" : "100px";
  return (
    <Media at="mobile" style={{ position: "relative" }}>
      {/* <MobileNav /> */}
      <div
        style={{
          width: "100%",
          marginTop: topMargin,
          maxWidth: "100%",
          overflowX: "hidden",
          padding: 10,
        }}
      >
        <Outlet />
      </div>
      <Help />
    </Media>
  );
};

const ResponsiveContainer = ({ children }) => {
  /* Heads up!
   * For large applications it may not be best option to put all
   * page into these containers at
   * they will be rendered twice for SSR.
   */
  const domain = window.location.host;
  // console.log(domain)
  return (
    <MediaContextProvider>
      <DomainContext.Provider value={
        DomainList?.[domain]? DomainList?.[domain] : Object.values(DomainList)[0]}>
          <DesktopContainer>{children}</DesktopContainer>
          <MobileContainer>{children}</MobileContainer>
        </DomainContext.Provider>

    </MediaContextProvider>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;
