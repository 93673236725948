import styled from "styled-components";
import React from "react";


import OverviewGrid1D from "./OverviewGrid1D";

const Overview = ({
  nav,
  data,
  refresh,
  structure,
  WhiteLabel,
  rule,
  questions,
  pdf = false,
  overview = false,
  otpPdf = false,
  selected,
  standards,
  otpView = false,
  language,
}) => {
  const isOneDimensional = () => {
    return questions?.dimensions?.length === 1;
  };

  const selectOverview = () => {
    if (isOneDimensional()) {
      return (
        <OverviewGrid1D
          nav={nav}
          data={data}
          refresh={refresh}
          structure={structure}
          WhiteLabel={WhiteLabel}
          rule={rule}
          questions={questions}
          pdf={pdf}
          overviewReport={overview}
          selected={selected}
        />
      );
    }
  };

  return <Container>{selectOverview()}</Container>;
};

export default Overview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
