import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import Pic from "assets/images/empty/error.png";
import Pic2 from 'assets/images/empty/wrong.svg'

function ErrorFallback({ error, resetErrorBoundary }) {
  const safety = () => {
    resetErrorBoundary();
  };
  return (
    <Container>
      <Image src={Pic2} />
      <Title>
        It looks like the data we're trying to load is not available right now.
      </Title>
      <Text>This may be expected, or there may be data here that you want to access. Please try again, or contact someone at nuLogic to let them know how they can help.</Text>
      <Button onClick={() => safety()}>Try again</Button>
    </Container>
  );
}

const ErrorWrapper = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      resetErrorBoundary={() => props.retryFunction}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorWrapper;


const Container = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:100%;
	justify-content:center;

	margin-top:50px;
`

const Text = styled.div`
	margin-top:30px;
	text-align:center;
	max-width:400px;
	font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
color:#666D79;
`

const Title = styled.div`
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 600;
color:#2A3039;
`

const Image = styled.img`
	width:150px;
`

const Button = styled.div`
  width: 196px;
  height: 50px;
  border-radius: 10px;
  background-color: #2D70E2;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: Raleway;
font-size: 14px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-top:30px;

`