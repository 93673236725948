import React, { useState } from 'react';

import TeamMembers from "./Member/index"
import Teams from "./team/index"

const TeamHome = () => {
    const [selectedTeam, setSelectedTeam] = useState(false)
    return (
        <>
        {selectedTeam ? 
        <TeamMembers selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam} />
        :
        <Teams  setSelectedTeam={setSelectedTeam} />
        }
        </>
    );
};

export default TeamHome;