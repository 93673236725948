import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";


import {
  CHAT_GPT_PENDING,
  POST_CHAT_GPT,
  SG_POST_CHAT_GPT,
  AUTO_LOGIN_TO_ML,
  SG_AUTO_LOGIN_TO_ML,
  AUTO_LOGIN_TO_ML_ERROR,
  POST_ML_SERVER_V1,
  SG_POST_ML_SERVER_V1,
  POST_ML_SERVER_V1_XL,
  SG_POST_ML_SERVER_V1_XL,
  GET_CHAT_GPT,
  SG_GET_CHAT_GPT,
  ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
  yield put({ type: CHAT_GPT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.POST_CHAT_GPT, action.payload);
    yield put({ type: POST_CHAT_GPT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_POST_CHAT_GPT, loadmethods);
}

function* loginmethods(action) {
  yield put({ type: CHAT_GPT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.AUTO_LOGIN_TO_ML, action.payload);
    yield put({ type: AUTO_LOGIN_TO_ML, payload: json.data });

  } catch (e) {
    yield put({ type: AUTO_LOGIN_TO_ML_ERROR, payload: e.response });
  }
}

export function* methodLogin() {
  yield takeLatest(SG_AUTO_LOGIN_TO_ML, loginmethods);
}

function* loadRegularMl(action) {
  yield put({ type: CHAT_GPT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const token = yield call(api.AUTO_LOGIN_TO_ML);
    yield put({ type: AUTO_LOGIN_TO_ML, payload: token.data });
    const APIPAYLOAD = {request: action.payload, token: token.data?.token}
    try {
      const json = yield call(api.POST_ML_SERVER_V1, APIPAYLOAD);
      yield put({ type: POST_ML_SERVER_V1, payload: json.data });
  
    } catch (e) {
      yield put({ type: AUTO_LOGIN_TO_ML_ERROR, payload: e.response });
    }

  } catch (e) {
    yield put({ type: AUTO_LOGIN_TO_ML_ERROR, payload: e.response });
  }

  
}

export function* methodRegularMl() {
  yield takeLatest(SG_POST_ML_SERVER_V1, loadRegularMl);
}

function* loadRegularMlXl(action) {
  yield put({ type: CHAT_GPT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const token = yield call(api.AUTO_LOGIN_TO_ML);
    yield put({ type: AUTO_LOGIN_TO_ML, payload: token.data });
    const APIPAYLOAD = {request: action.payload, token: token.data?.token}
    try {
      const json = yield call(api.POST_ML_SERVER_V1_XL, APIPAYLOAD);
      yield put({ type: POST_ML_SERVER_V1_XL, payload: json.data });
  
    } catch (e) {
      yield put({ type: AUTO_LOGIN_TO_ML_ERROR, payload: e.response });
    }

  } catch (e) {
    yield put({ type: AUTO_LOGIN_TO_ML_ERROR, payload: e.response });
  }

}

export function* methodRegularMlXl() {
  yield takeLatest(SG_POST_ML_SERVER_V1_XL, loadRegularMlXl);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodLogin(),
    methodRegularMl(),
    methodRegularMlXl(),
    // methodGet(),
  ]);
}