import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import Phase1 from "./Planning/Phase1";
import Plan from "./Plan/index";
import Phase3 from "./Planning/Phase3";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";
import { SURVEY_INFO } from "constants/surveys";
import { useLocation } from "react-router";

const empty_selections = {
  metric: "",
  primary: "",
  plan: { id: null },
  planData: ["", "", "", "", "", ""],
  member: [],
  status: null,
};

const Planning = ({ open, close, plans, handlePlanCreation, questions }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState(empty_selections);
  const location = useLocation();

  useEffect(() => {
    setData({ ...empty_selections, planData: ["", "", "", "", "", ""] });
  }, []);

  const surveyInfo = location.pathname.includes("edi/action")
    ? {
        type: SURVEY_INFO.edi.question_type,
        auditType: SURVEY_INFO.edi.type,
      }
    : {
        type: SURVEY_INFO.audit.question_type,
        auditType: SURVEY_INFO.audit.type,
      };
  const { get_survey_structure, get_survey_questions } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_survey_questions: state.surveyquestions.survey_questions,
    }),
    shallowEqual
  );

  //Pull survey questions and categories from redux
  useEffect(() => {
    dispatch({ type: SG_GET_SURVEY_STRUCTURES });
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=${surveyInfo.type}&status=2`,
    });
  }, [dispatch]);

  const next = () => {
    if (selected === 0) {
      let check = false;

      if (
        data.metric &&
        data.question !== null &&
        data.primary &&
        data.plan !== null
      ) {
        check = true;
      }

      if (check) {
        setSelected(selected + 1);
      }
    }
    if (selected === 1) {
      setSelected(selected + 1);
    }
    if (selected === 2) {
      handlePlanCreation(data);
      setData({ ...empty_selections, planData: ["", "", "", "", "", ""] });
      setSelected(0);
      close();
    }
  };

  return (
    <Modal open={open} onClose={() => close()} size="small">
      <Modal.Header>
        <Title>Create an action plan</Title>
      </Modal.Header>
      <Modal.Content image scrolling>
        {get_survey_questions.length > 0 ? (
          selected === 0 ? (
            <Phase1
              categories={get_survey_structure[0]}
              questions={questions || get_survey_questions[0]}
              data={data}
              setData={(d) => setData(d)}
            />
          ) : selected === 1 ? (
            <Plan
              data={data}
              setData={(d) => setData(d)}
              questions={questions || get_survey_questions[0]}
            />
          ) : (
            <Phase3 data={data} setData={(d) => setData(d)} />
          )
        ) : (
          ""
        )}
      </Modal.Content>
      <Modal.Actions>
        <Actions>
          <Back onClick={() => setSelected(selected - 1)}>Back</Back>
          <StyledButton
            showDisabled={!isNaN(data.plan.id)}
            disabled={!isNaN(data.plan.id)}
            onClick={() => next()}
          >
            {selected == 2 ? "Save" : "Continue"}
          </StyledButton>
        </Actions>
      </Modal.Actions>
    </Modal>
  );
};

export default Planning;

const Container = styled.div`
  /* position: absolute; */
  min-width: 300px;
  width: 60vw;
  /* right: ${(props) => (props.open ? "-25px" : "-70vw")}; */
  top: -20px;
  height: calc(100vh - 50px);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 99;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  padding: 10px;
  overflow: hidden; ;
`;

const ScrollContainer = styled.div`
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;

  &:hover {
    background-color: rgba(9, 30, 66, 0.2);
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-left: 55px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.showDisabled ? "default" : "pointer")};
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  opacity: ${(props) => (props.showDisabled ? "0.6" : "1")};

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: ${(props) =>
      props.showDisabled ? "#2d50e2" : "rgba(0, 0, 0, 0)"};
    border: ${(props) =>
      props.showDisabled ? "1px solid white" : "1px solid #2d50e2"};
    color: ${(props) => (props.showDisabled ? "white" : "#2d50e2")};
  }
`;

const Back = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #7f7f7f;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
