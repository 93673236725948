import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Pagination,
  Input,
  Dimmer,
  Loader,
  Message,
  Divider,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { PERSONAS, PERSONA_OPTIONS } from "members/audit/Personas.js";

import TableList from "./TableList";

import {
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_SET_MEMBER_SEARCH_FILTERS,
} from "constants/actions";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { PlusIcon, UploadGroupIcon, RefreshIcon } from "./Icons";
import Filter from "./Filter";

const MemberDirectory = (props) => {
  const dispatch = useDispatch();

  const {
    setTeamUpdate,
    userAdmin,
    userEmployee,
    changesMade,
    setChangesMade,
  } = props;
  const [members, setMembers] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [teamLeader, setTeamLeader] = useState(false);
  const [administrator, setAdministrator] = useState(false);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [selectTeam, setSelectTeam] = useState(false);

  const [organization, setOrganization] = useState({});
  const [auditEnabled, setAuditEnabled] = useState(false);
  const [teamToolEnabled, setTeamToolEnabled] = useState(false);

  const createdTeam = useSelector((state) => state.teams.created, shallowEqual);

  const [addUploadUsers, setAddUploadUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterCategories, setFilterCategories] = useState([]);
  const [filteredCategoriesSelected, setFilteredCategoriesSelected] = useState(
    []
  );
  const [employeeCategories, setEmployeeCategories] = useState([]);
  const [userFilteredCategories, setUserFilteredCategories] = useState();
  const [filteredPermissions, setFilteredPermissions] = useState([]);
  const [missingCategoriesFilter, setMissingCategoriesFilter] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canAccessData, setCanAccessData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [membersFiltered, setMembersFiltered] = useState(false);
  const [hideMembers, setHideMembers] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [pdfEnabled, setPDFEnabled] = useState(false);
  const [persona, setPersona] = useState();
  const [timeoutReached, setTimeoutReached] = useState(false);

  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );

  const memberFilters = useSelector(
    (state) => state.member_filters,
    shallowEqual
  );

  const {
    get_organizations,
    get_employees,
    get_auth,
    get_teams,
    get_selectedOrg,
    employees_pending,
    get_survey_structure,
    get_employee_org_category,
    employee_org_permission,
    categoriesFetched,
  } = useSelector(
    (state) => ({
      get_error: state.errors,
      employees_data: state.employees.employees,
      get_auth: state.auth,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      get_employees: state.employees,
      get_organizations: state.organizations,
      get_teams: state.teams,
      get_departments: state.departments,
      employees_pending: state.employees.pending,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_org_category: state.employee_category?.employee_org_category,
      categoriesFetched: state.employee_category?.fetched,
      get_org_category: state.employee_category,
      employee_org_permission: state.employee_permission,
    }),
    shallowEqual
  );

  // Dispatch Calls
  useEffect(() => {
    if (get_auth?.organization_id) {
      dispatch({
        type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
        payload: `organization=${get_auth?.organization_id}`,
      });
    } else {
      dispatch({ type: SG_GET_EMPLOYEE_CATEGORIES });
    }
  }, [dispatch, get_auth, get_employee_org_category]);

  useEffect(() => {
    if (
      memberFilters?.search &&
      memberFilters?.search !== "" &&
      memberFilters?.search !== search &&
      ((memberList.length > 0 && memberList) || members) &&
      !isTyping
    ) {
      setTimeout(() => {
        setSearch(memberFilters?.search);
        searchMembers(memberFilters?.search);
      }, [200]);
    }
  }, [memberFilters, search]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_org_category?.length > 0) {
        const currentCategories = get_employee_org_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_employee_org_category, get_auth?.employee_id]);

  useEffect(() => {
    const optionsArray = [];
    if (
      currentUser &&
      isOtp &&
      userFilteredCategories &&
      get_survey_structure?.[0] &&
      employee_org_permission
    ) {
      currentUser?.access_data_analytics === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_datalytics);
      currentUser?.access_subfactor_data === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_subfactor);
      currentUser?.survey_add_users === 1 &&
        optionsArray.push(PERSONA_OPTIONS.edit_users);
      currentUser?.account_type === 5 &&
        optionsArray.push(PERSONA_OPTIONS.is_admin);
      currentUser?.enable_login === true &&
        optionsArray.push(PERSONA_OPTIONS.enable_login);
      currentUser?.read_feedback === 1 &&
        optionsArray.push(PERSONA_OPTIONS.read_feedback);
      currentUser?.survey_schedule === 1 &&
        optionsArray.push(PERSONA_OPTIONS.schedule_survey);
      currentUser?.survey_status === 1 &&
        optionsArray.push(PERSONA_OPTIONS.participate_survey);

      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const newTeamArr = primary?.options.map((o) => {
        return o.id;
      });

      if (
        newTeamArr?.length > 0 &&
        newTeamArr.every((v) => userFilteredCategories.includes(v))
      ) {
        optionsArray.push(PERSONA_OPTIONS.otp_view);
        if (userFilteredCategories.length > newTeamArr.length) {
          optionsArray.push(PERSONA_OPTIONS.is_cwf);
        }
      }

      if (
        employee_org_permission &&
        employee_org_permission?.employee_org_permission.length > 0
      ) {
        const currentPermissions =
          employee_org_permission?.employee_org_permission.find(
            (p) => p.employee_id === currentUser?.id
          );

        if (currentPermissions?.auth_details) {
          const exists = currentPermissions?.auth_details.find((p) => {
            if (p?.name === "pdf_enabled") {
              return true;
            }
            if (p.length > 0) {
              return p[0].name === "pdf_enabled";
            }
            return false;
          });

          if (exists) {
            setPDFEnabled(true);
            optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
          }
        } else {
          setPDFEnabled(false);
        }
      }

      const persona = PERSONAS.find((p) => {
        return p.options.sort().join(",") === optionsArray.sort().join(",");
      });

      if (persona) {
        setPersona(persona);
      }
    }
  }, [
    currentUser,
    isOtp,
    userFilteredCategories,
    get_survey_structure,
    employee_org_permission,
  ]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !userFilteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setUserFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value_id);

        setUserFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganization(get_organizations[get_selectedOrg?.organization?.id]);
    } else {
      setOrganization(get_organizations[get_auth?.organization_id]);
    }

    if (get_organizations) {
      const isOTPType = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;

      setIsOtp(isOTPType);
    }
  }, [get_organizations, get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    if (organization?.services_enabled) {
      let audit = false;

      organization?.services_enabled.map((i) => {
        if (["Audit", "OTP", "EDI"].includes(i.name) && i.enabled) {
          audit = true;
        }
        return null;
      });
      setAuditEnabled(audit);
      setTeamToolEnabled(
        organization.services_enabled.find((f) => f.name === "Teamtool")
          ?.enabled
      );
    }
  }, [organization]);

  useEffect(() => {
    let selectedTeam = selectTeam ? selectTeam : false;
    if (userAdmin) {
      setAdministrator(true);
    }
    if (Number(EmployeeId) > 0) {
      // setEmployee(get_employees[Number(EmployeeId)]);
      // team leader
      if (get_employees[Number(EmployeeId)]?.role < 7) {
        setTeamLeader(true);

        // console.log("I was here set as team leader")
        if (get_employees[Number(EmployeeId)]?.team) {
          setSelectTeam(get_employees[Number(EmployeeId)]?.team);
        }
      }
      // admin
      if (get_employees[Number(EmployeeId)]?.account_type < 7) {
        // setTeamLeader(true);
        setAdministrator(true);
        setSelectTeam(selectedTeam);
      }
    }
    if (Number(AmbassadorId) > 0) {
      // set role and account
      setTeamLeader(true);
      setAdministrator(true);
      setSelectTeam(selectedTeam);
      // console.log("I am here")
    }
  }, [
    userAdmin,
    get_teams,
    get_employees,
    AmbassadorId,
    EmployeeId,
    selectTeam,
  ]);

  useEffect(() => {
    const activeMembers = get_employees?.employees.filter(
      (e) => e.status !== 99
    );
    setMembers(activeMembers.sort((a, b) => (a.id > b.id ? 1 : -1)));
    setMemberList(activeMembers.sort((a, b) => (a.id > b.id ? 1 : -1)));
  }, [get_employees]);

  useEffect(() => {
    if (get_employees) {
      setCurrentUser(get_employees?.userEmp);
    }
  }, [get_employees]);

  useEffect(() => {
    if (currentUser?.account_type === 5) {
      setIsAdmin(true);
    }
    if (currentUser?.survey_add_users === 1) {
      setCanEdit(true);
    }
    if (currentUser?.access_data_analytics === 1) {
      setCanAccessData(true);
    }
  }, [currentUser]);

  useEffect(() => {
    // if (members.length > 0) {
    if (!employees_pending) {
      const timer1 = setTimeout(() => {
        // if (setLoading) {
        // setLoading(false);
        // }
      }, 1000);
      return () => clearTimeout(timer1);
    }
    // }
  }, [members, employees_pending, loading]);

  useEffect(() => {
    if (
      filteredCategoriesSelected.length === 0 ||
      filteredPermissions.length === 0 ||
      !missingCategoriesFilter
    ) {
      setMemberList(members);
      // Re-run search to retain old list
      searchMembers(search, true);
    }

    if (
      filteredCategoriesSelected.length > 0 &&
      get_employee_org_category
      // &&
      // used to keep loading spinner in cases where the get_employee_org_category is empty
      //memberList.length <= get_employee_org_category.length
    ) {
      // members
      // memberList
      const listToFilter =
        (memberList.length > 0 && search?.length > 0 && memberList) || members;
      const filteredMemberList = listToFilter.filter((member) => {
        let isValidMember = true;
        const employeeCategories = get_employee_org_category.find(
          (emp) => emp.employee === member.id
        );

        filteredCategoriesSelected.forEach((category) => {
          // Reset on each
          const categoryName = filterCategories
            ?.find((fg) => {
              return fg?.priority === "primary";
            })
            ?.options.find((fc) => {
              return fc.id === category;
            });

          const empCats = employeeCategories?.category?.map((cat) => {
            return cat?.value_id;
          });

          if (isValidMember) {
            isValidMember =
              empCats?.includes(category) ||
              empCats?.includes(categoryName?.text);
          }
          // employeeCategories?.category?.forEach((cat) => {
          //   if (!isValidMember) {
          //     isValidMember =
          //       category === cat?.value_id ||
          //       categoryName?.text === cat?.value_id;
          //   }
          // });
        });
        return isValidMember;
      });
      setMemberList(filteredMemberList);
    }

    if (filteredPermissions.length > 0) {
      const listToFilter =
        filteredCategoriesSelected.length > 0 || search.length > 0
          ? memberList
          : members;
      const filteredMemberList = listToFilter.filter((member) => {
        let isValidMember = false;
        filteredPermissions.forEach((perm) => {
          // Account types have diff value than 1
          if (perm === "account_type") {
            isValidMember = member[perm] === 5;
          } else if (perm === "enable_login") {
            isValidMember = member[perm];
          } else {
            isValidMember = member[perm] === 1;
          }
        });
        return isValidMember;
      });
      setMemberList(filteredMemberList);
    }

    if (missingCategoriesFilter) {
      const listToFilter =
        filteredCategoriesSelected.length > 0 || search.length > 0
          ? memberList
          : members;

      const filteredMemberList = listToFilter.filter((item, i) => {
        let missingFields = [];
        filterCategories.forEach((cat) => {
          const userCategory = get_employee_org_category.find(
            (c) => c?.employee === item.id
          );
          if (userCategory && cat?.visibility !== "private") {
            if (cat?.priority === "primary" || cat?.priority === "secondary") {
              const hasField = userCategory?.category.find(
                (uc) => uc?.priority === cat?.priority
              );

              if (
                (!hasField && !missingFields.includes(cat?.category)) ||
                (hasField &&
                  !cat?.options.find((op) => op.text === hasField?.value))
              ) {
                missingFields.push(cat?.category);
              }
            } else {
              const hasField = userCategory?.category.find(
                (uc) => uc?.name === cat?.category
              );
              if (!hasField && !missingFields.includes(cat?.category)) {
                missingFields.push(cat?.category);
              }
            }
          }
        });

        if (!item.email) {
          missingFields.push("Email");
        }

        if (!item.first_name) {
          missingFields.push("First Name");
        }

        if (!item.last_name) {
          missingFields.push("Last Name");
        }

        missingFields = missingFields.filter((f) => !!f);
        return missingFields.length > 0;
      });
      setMemberList(filteredMemberList);
      if (hideMembers) {
        const newMembersList = memberList.filter(
          (member) => member?.id === currentUser?.id
        );
        setMemberList(newMembersList);
        setMembers(newMembersList);
      }
    }
    setPage(1);
  }, [
    filteredCategoriesSelected,
    filteredPermissions,
    missingCategoriesFilter,
  ]);

  useEffect(() => {
    if (
      persona &&
      persona?.text === "CWF" &&
      memberList?.length > 0 &&
      !membersFiltered
    ) {
      let showMembers = false;
      if (
        currentUser &&
        currentUser?.employee_employee_category?.category?.length > 0
      ) {
        currentUser?.employee_employee_category?.category?.forEach(
          (category) => {
            if (category?.priority === "primary" && category?.group) {
              showMembers = true;
            }
          }
        );
      }
      if (!showMembers) {
        setHideMembers(true);
      }
      setMembersFiltered(true);
    }
  }, [memberList, persona, currentUser]);

  useEffect(() => {
    let selectedTeam = selectTeam ? selectTeam : false;
    if (userAdmin) {
      setAdministrator(true);
    }
    if (Number(EmployeeId) > 0) {
      // setEmployee(get_employees[Number(EmployeeId)]);
      // team leader
      if (get_employees[Number(EmployeeId)]?.role < 7) {
        setTeamLeader(true);

        // console.log("I am here set as team leader")
        if (get_employees[Number(EmployeeId)]?.team) {
          setSelectTeam(get_employees[Number(EmployeeId)]?.team);
        }
      }
      // admin
      if (get_employees[Number(EmployeeId)]?.account_type < 7) {
        // setTeamLeader(true);
        setAdministrator(true);
        setSelectTeam(selectedTeam);
      }
    }
    if (Number(AmbassadorId) > 0) {
      // set role and account
      setTeamLeader(true);
      setAdministrator(true);
      setSelectTeam(selectedTeam);
    }
  }, [
    userAdmin,
    get_teams,
    get_employees,
    AmbassadorId,
    EmployeeId,
    selectTeam,
  ]);

  useEffect(() => {
    const activeMembers = get_employees?.employees.filter(
      (e) => e.status !== 99
    );
    setMembers(activeMembers.sort((a, b) => (a.id > b.id ? 1 : -1)));
    setMemberList(activeMembers.sort((a, b) => (a.id > b.id ? 1 : -1)));
  }, [get_employees]);

  // console.log(employees_pending)

  useEffect(() => {
    // if (members.length > 0) {
    const timer1 = setTimeout(() => {
      if (setLoading) {
        setLoading(false);
        setTimeoutReached(true);
      }
    }, 30000);
    return () => clearTimeout(timer1);
    // }
  }, [members, employees_pending]);

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  useEffect(() => {
    if (get_employees?.updated && setTeamUpdate) {
      setTeamUpdate(get_employees?.update?.id);
    }
  }, [get_employees, setTeamUpdate]);

  useEffect(() => {
    if (
      get_employees &&
      get_survey_structure?.[0] &&
      get_employee_org_category &&
      get_employee_org_category.length > 0 &&
      filterCategories &&
      (memberList.length <= get_employee_org_category.length ||
        get_employee_org_category.length > 1)
    ) {
      setLoading(false);
    } else {
      if (!categoriesFetched) {
        setLoading(true);
      }
      if (categoriesFetched || timeoutReached) {
        setLoading(false);
      }
    }
  }, [
    get_employees,
    employees_pending,
    get_survey_structure,
    get_employee_org_category,
    filterCategories,
  ]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure[0]) {
      const filterOptions = get_survey_structure[0]?.categories?.map((cat) => {
        const categoryName = cat.name;
        const categoryOptions = [];
        cat.options.forEach((op) => {
          if (op?.subfactors?.length > 0) {
            op?.subfactors.forEach((sf) => {
              categoryOptions.push({
                text: sf.name,
                value: sf.name,
                id: sf.id,
                subfactorId: sf.id,
                isSubfactor: true,
              });
            });
          } else {
            categoryOptions.push({
              text: op.name,
              value: op.name,
              id: op.id,
            });
          }
        });

        // Only allow if visibility is not private
        if (cat.visibility !== "private") {
          return {
            category: categoryName,
            priority: cat.priority,
            options: [...categoryOptions],
          };
        }
      });
      setFilterCategories(filterOptions);
    }
  }, [get_survey_structure, userFilteredCategories, isAdmin]);

  useEffect(() => {
    setAddUploadUsers(
      userEmployee?.account_type === 5 || userEmployee?.survey_add_users === 1
    );
  }, [userEmployee]);

  function searchMembers(e, useList) {
    setSearch(e);
    setIsTyping(true);
    let emailLst = [];
    let firstList = [];
    let lastList = [];
    const listToFilter =
      (memberList.length > 0 && !useList && memberList) || members;
    if (e.length > 0) {
      setPage(1);
      emailLst = listToFilter.filter((item) =>
        item?.email?.toLowerCase().includes(e.toLowerCase())
      );
      firstList = listToFilter.filter((item) => {
        const name =
          item?.first_name?.toLowerCase() +
          " " +
          item?.last_name?.toLowerCase();
        return name.includes(e.toLowerCase());
      });
      const _MemberList = emailLst.concat(firstList);
      const newList = _MemberList.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      setMemberList(newList);
      if (memberFilters?.search !== e) {
        dispatch({
          type: SG_SET_MEMBER_SEARCH_FILTERS,
          payload: {
            search: e,
          },
        });
      }
    } else {
      setMemberList(listToFilter);
    }
  }

  const newMembersList = memberList?.filter(
    (member) => member?.id === currentUser?.id
  );

  return (
    <Dimmer.Dimmable blurring dimmed={loading}>
      {changesMade && (
        <Message
          onDismiss={() => {
            setChangesMade(false);
          }}
          color={changesMade?.color}
        >
          {changesMade?.message}
        </Message>
      )}

      <TopTitle>
        <Title>Member Management</Title>
      </TopTitle>
      {((canEdit || isAdmin) && (
        <TopSection>
          <div style={{ display: "flex" }}>
            <Link
              to={`/core/members/new-member`}
              style={{
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <MemberInviteButton>
                <IconWrapper>{PlusIcon()}</IconWrapper>
                <InviteText>Invite Member</InviteText>
              </MemberInviteButton>
            </Link>
            <Link
              to={`/core/members/inactive`}
              style={{
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <Archive>
                <InviteText>Archived</InviteText>
              </Archive>
            </Link>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link
              to={`/core/members/upload`}
              style={{
                alignSelf: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <UploadCsvWrapper>
                <UploadIcon>{UploadGroupIcon()}</UploadIcon>
                <UploadCsvText>Upload</UploadCsvText>
              </UploadCsvWrapper>
            </Link>
            <Link
              to={`/core/members/update`}
              style={{
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <UploadCsvWrapper>
                <UploadIcon>{RefreshIcon()}</UploadIcon>
                <UploadCsvText>Update</UploadCsvText>
              </UploadCsvWrapper>
            </Link>
          </div>
        </TopSection>
      )) || <Divider />}
      <Container>
        <SearchContainer>
          <Input
            icon="search"
            iconPosition="left"
            placeholder="Search..."
            size="small"
            style={{ width: "70%" }}
            value={search}
            onChange={(e) => searchMembers(e.target.value, false)}
          />
          <Filter
            setFilterCategories={(f) => setFilteredCategoriesSelected(f)}
            setFilterPermissions={(f) => setFilteredPermissions(f)}
            setMissingCategoriesFilter={(f) => setMissingCategoriesFilter(f)}
            categories={filterCategories}
            resetMemberList={() => {
              setMemberList([]);
              setFilteredPermissions([]);
              setFilteredCategoriesSelected([]);
              setMissingCategoriesFilter(false);
            }}
          />
        </SearchContainer>
        <TableWrapper>
          <TableList
            members={
              hideMembers
                ? newMembersList
                : filteredCategoriesSelected.length > 0 ||
                  filteredPermissions.length > 0 ||
                  missingCategoriesFilter ||
                  search.length > 0
                  ? memberList?.slice((page - 1) * 10, page * 10)
                  : members?.slice((page - 1) * 10, page * 10)
            }
            categories={get_employee_org_category}
            filterCategories={filterCategories}
            setLoadingCb={(l) => setLoading(l)}
            structure={get_survey_structure[0]}
          />
        </TableWrapper>
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: 10,
          }}
        >
          <Pagination
            activePage={page}
            onPageChange={handlePaginationChange}
            boundaryRange={1}
            size="mini"
            firstItem={null}
            lastItem={null}
            siblingRange={0}
            totalPages={
              hideMembers
                ? 1
                : (search && search.length > 0) ||
                  filteredCategoriesSelected.length > 0 ||
                  filteredPermissions.length > 0 ||
                  missingCategoriesFilter
                  ? Math.ceil(memberList?.length / 10)
                  : Math.ceil(members?.length / 10)
            }
          />
        </div>
      </Container>

      <Dimmer active={loading}>
        <LoadingContainer>
          <p>Loading page</p>
          <Loader size="medium">Loading</Loader>
        </LoadingContainer>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default MemberDirectory;

const Container = styled.div`
  padding-left: 58px;
  margin-bottom: 50px;
`;

const TopTitle = styled.div`
  width: 100%;
  padding-left: 58px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  margin-top: -20px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Image = styled.img`
  margin-right: 8px;
  margin-left: 19px;
`;

const Sub = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #7e7e7e;
`;

const TableWrapper = styled.div`
  z-index: 20;
  position: relative;
`;

const IconWrapper = styled.div`
  line-height: 21.86px;
  margin-right: 5px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const InviteText = styled.label`
  font-family: "Inter";
  font-style: normal;
  justify-content: center;
  font-weight: 300;
  font-size: 10px;
  line-height: 21.86px;
  cursor: pointer;
  color: white;
`;

const MemberInviteButton = styled.div`
  display: flex;
  width: 99px;
  height: 21.86px;
  cursor: pointer;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background-color: #476dfa;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #ffffff;
`;

const Archive = styled.div`
  display: flex;
  width: 81px;
  height: 21.86px;
  cursor: pointer;
  border-radius: 30px;
  align-self: center;
  justify-content: center;
  background-color: #515151;
  margin-left: 10px;
  border: 1px solid #414141;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

const TopSection = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px;
  border-bottom: 1px;
  border-color: #dfdfdf;
  border-style: solid;
  border-right: 0px;
  border-left: 0px;
  height: 58px;
  justify-content: space-between;
  padding-left: 58px;
  padding-right: 10px;
`;

const UploadCsvWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-self: center;
`;

const UploadCsvText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  height: 20px;
  align-self: center;
  color: #7e7e7e;
  cursor: pointer;
`;

const UploadIcon = styled.div`
  margin-right: 5px;
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
`;

const NavContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #d3dbdb;
`;

const NavTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: relative;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const LoadingContainer = styled.div`
  min-height: 40vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;
