import React from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { CLEAR_PASSWORD_DATA } from "constants/actions";

import LoginForm from "account/LoginForm";

const Login = (props) => {
  const dispatch = useDispatch();
  const { get_auth, get_error } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_error: state.errors,
      get_authenticated: state.auth.token ? true : false,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_error?.errors?.status === 401) {
      if (get_auth.token) {
        dispatch({
          type: CLEAR_PASSWORD_DATA,
        });
      }
    }
  });
  //  admin/dashboard


  return (
    <div style={{ height: "100%" }}>
      <LoginForm props={props} />
    </div>
  );
};

export default Login;
