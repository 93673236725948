import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import ModelCard from './ModelCard'
import Chart from "react-apexcharts";
import Modal from './Modal'
import VIF from './VIF'

import Model from 'assets/images/nav/model.svg'
import Factor from 'assets/images/nav/factor.svg'
import Opp from 'assets/images/nav/opportunity.svg'


const Prediction = () =>{

	return (
    <Container>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Title>Global turnover</Title>
        <Modal
          trigger={<Button>Make a Prediction</Button>}
          title={"Team trust scores and turnover optimization"}
          text={""}
        />
      </div>

      <CardArea>
        <Card width={70}>
          <TableTitle>
            <Image src={Opp} />
            Opportunity for Optimization
          </TableTitle>
          <Sub>
            Based on company and industry norms, there is an opportunity to
            reduce the turn over rate of this organization by up to 30%
          </Sub>

          <Chart
            options={data3.options}
            series={data3.series}
            type="area"
            width="100%"
            height={400}
          />
        </Card>

        <Card width={30}>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Model Characteristics
          </TableTitle>

          <Table>
            <Row>
              <Feature>Accuracy</Feature>

              <Num>80%</Num>
            </Row>

            <Row>
              <Feature>Samples</Feature>

              <Num>1000</Num>
            </Row>

            <Row>
              <Feature>Outcome variable</Feature>

              <Num>Turnover</Num>
            </Row>

            <Row>
              <Feature>Unit of analysis</Feature>

              <Num>Individuals</Num>
            </Row>
          </Table>
        </Card>

        <Card width={50}>
          <TableTitle>
            <Image src={Factor} />
            Factor Importance
          </TableTitle>

          <Table>
            <Row>
              <Feature>Trust</Feature>

              <Bar>
                <Color value={40} />
              </Bar>
              <Num>40%</Num>
            </Row>
            <Row>
              <Feature>Team extraversion</Feature>
              <Bar>
                <Color value={25} />
              </Bar>
              <Num>25%</Num>
            </Row>
            <Row>
              <Feature>Performance review</Feature>
              <Bar>
                <Color value={12} />
              </Bar>
              <Num>12%</Num>
            </Row>
            <Row>
              <Feature>Cohesion</Feature>
              <Bar>
                <Color value={6} />
              </Bar>
              <Num>6%</Num>
            </Row>
            <Row>
              <Feature>Payroll raise</Feature>
              <Bar>
                <Color value={5} />
              </Bar>
              <Num>5%</Num>
            </Row>
            <Row>
              <Feature>Leadership</Feature>
              <Bar>
                <Color value={5} />
              </Bar>
              <Num>5%</Num>
            </Row>
            <Row>
              <Feature>Individual Agreeableness</Feature>
              <Bar>
                <Color value={3} />
              </Bar>
              <Num>3%</Num>
            </Row>
            <Row>
              <Feature>Autonomy</Feature>
              <Bar>
                <Color value={3} />
              </Bar>
              <Num>3%</Num>
            </Row>
            <Row>
              <Feature>Drive</Feature>
              <Bar>
                <Color value={1} />
              </Bar>
              <Num>1%</Num>
            </Row>
          </Table>
        </Card>

        <Card width={50}>
          <VIF />
        </Card>
      </CardArea>
    </Container>
  );
}


export default Prediction



const Container = styled.div`
  font-family: "Poppins", sans-serif;
  padding: 30px 0px 0px 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;


const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
  font-weight:500;
  color: #414141;

`;


const T2 = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
margin-bottom:30px;
/* identical to box height */


color: #414141
`

const Content = styled.div`
	width:100%;
	display:flex;

`

const ModelArea = styled.div`
	width:calc(100% - 400px);
	padding:0px 27px 18px 27px;
`

const TableTitle = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-bottom:5px;
/* identical to box height */
display:flex;
align-items:center;

color: #414141;
`

const Table = styled.div`
	width:100%;
	margin-bottom:30px;
`

const Row = styled.div`
	display:flex;
	width:100%;
	border-top: 1px solid #DFDFDF;
	align-items:center;
	justify-content:space-between;
	padding:11px 17px;
`

const Feature = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */

color: #7E7E7E;
width:200px;


`

const Bar = styled.div`
	width:calc(100% - 200px);
	border-radius:10px;
	height:12px;
	background: #C0C0C0;
	overflow:hidden;
`

const Color  = styled.div`
	width:${props=>props.value+'%'};
	height:100%;
	background-color:#5167DD;
	border-radius:4px;
`

const Num = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: right;

color: #7E7E7E;
margin-left:5px;
`

const Sub = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
margin-bottom:20px;
color: #7E7E7E;
max-width:500px;
padding-left:33px;
`

const Button = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size:10px;
line-height: 12px;
padding:5px 16px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;
border-radius:20px;
color: #476DFA;
border:1px solid #476DFA;
position:absolute;
right:0px;
top:5px;
cursor:pointer;
`

const ChartArea = styled.div`
	width:100%;
	height:300px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:-40px;
`

const ChartText = styled.div`
	position:absolute;
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 45px;
/* identical to box height */

text-align: center;

color: #414141;
`

const Card = styled.div`
	width:${props=>'calc('+props.width+'% - 10px)'};
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding:24px;
	margin-right:10px;
`

const CardArea = styled.div`
	width:100%;
	display:flex;
	flex-wrap:wrap;
`
const Image = styled.img`
	width:20px;
	margin-right:10px;
`

const data3 = {
	series: [
		{
			name: "Turn Over Rate",
			type: "area",
			data: [80, 83, 75, 75, null, null],
		},
		
		{
			name: "Optimized",
			type: "area",
			data: [null, null, null, 75, 70, 60],
		},
		{
			name: "Predicted",
			type: "area",
			data: [null, null, null, 75, 85, 95],
		},
	],
	options: {
		chart: {
			type: "line",
			width:'100%',
			toolbar: {
				show: false,
			},
		},
		annotations: {
			points: [
				{
					x: "Q4 2022",
					y: 60,
					marker: {
						size: 8,
						fillColor: "#3260a8",
					},
					label: {
						text: "Optimized",
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
					},
				},
				{
					x: "Q4 2022",
					y: 95,
					marker: {
						size: 8,
						fillColor: "#32a852",
					},
					label: {
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
						text: "Predicted",
					},
				},
			],
		},

		dataLabels: {
			enabled: false,
		},
		colors: ["#b5b5b5", "#3260a8", "#32a852"],
		stroke: {
			dashArray: [0, 4, 4],
			curve: "smooth",
			width: [0, 3, 3],
		},
		fill: {
			type: ["solid", "dashed", "dashed"],
			opacity: [0.3, 0.4, 0.2],
		},
		yaxis: {
			min: 0,
			max: 100,
			labels: {
				formatter: function (value) {
					return value;
				},
			},
		},
		grid: {
			padding: {
				right: 50,
			},
		},
		xaxis: {
			categories: [
				"Q3 2020",
				"Q4 2020",
				"Q1 2021",
				"Q2 2021",
				"Q3 2021",
				"Q4 2022",
			],
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	},
};