// Libraries
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { erf } from "mathjs";
// Calculations
import {
  filter_data,
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  get_by_id,
  change_data,
} from "reports/Audit/data_navigation/calculate_data";

// Actions
import {
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SET_QUESTIONS,
  SG_GET_SURVEY_STANDARD,
} from "constants/actions";
import { SURVEY_INFO } from "constants/surveys";

function isValidObject(obj) {
  // Check if the object is valid
  if (!obj || typeof obj !== "object") {
    return false;
  }

  // Iterate over each property of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // If the value is an object, recursively check its properties
      if (typeof value === "object" && value !== null) {
        if (!isValidObject(value)) {
          return false;
        }
      }
      // If the value is not undefined or null, it's a valid entry
      else if (value !== undefined && value !== null) {
        return true; // There's at least one valid property
      }
    }
  }

  // If all values are undefined or null
  return false;
}

/**
 * ? Since this hook is specific to Audit's, some of these params can likely be changed.
 * @param {auditTypeId: int, surveyType: int } params
 * @returns
 */
function useGetSurveyUtils(params) {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const [cultureAuditQues, setCultureAuditQues] = useState();
  const [questionStructure, setQuestionStructure] = useState(null);
  const [comments, setComments] = useState(false);
  const [filterData, setFilterData] = useState();
  const [servicesEnabled, setServicesEnabled] = useState();
  const [questionsList, setQuestionsList] = useState();

  const check_comments = (resp) => {
    let comm = false;
    if (params.get_employee) {
      let user_info = params.get_employee?.userEmp;

      if (
        !user_info ||
        (!user_info.read_feedback && user_info.account_type_text != "Admin")
      ) {
        return false;
      }

      // if(questionStructure){
      //   if(!("comments" in questionStructure)){
      //     return false
      //   }
      //   else if(questionStructure.comments.length<1){
      //     return false
      //   }
      // }

      for (var i = 0; i < resp.responses.length; i++) {
        if (
          "comments" in resp.responses[i] ||
          "feedback" in resp.responses[i]
        ) {
          comm = true;
          break;
        }
      }
    }

    return comm;
  };

  useEffect(() => {
    if (params.get_survey_structure && params.get_survey_structure[0]) {
      const DefState = build_default_State(params.get_survey_structure[0]);
      dispatch({
        type: SET_NAVIGATION,
        payload: DefState,
      });
      if (params.get_culture_audit_reports.response) {
        if (params.get_culture_audit_reports.response.length > 0) {
          let sorted_responses = params.get_culture_audit_reports.response.sort(
            (a, b) => b.id - a.id
          );

          if (params.survey_version) {
            sorted_responses = sorted_responses.filter(
              (f) => f.question.sort_order == params.survey_version.sort_order
            );
          }
          if (params.dateStart) {
            setReportData([]);
          }

          if (checkPersonality(params.get_organizations)) {
            //Modify the results to include personality as a categorical variable
            sorted_responses =
              convert_personality_to_categories(sorted_responses);
          }

          if (params.surveyType === 7) {
            sorted_responses = convert_DEI(sorted_responses);
          }

          // change_data(sorted_responses[0], 10);

          if (params.sportsFiltered?.length > 0) {
            sorted_responses = sorted_responses.filter((responseGroup) => {
              return params.sportsFiltered.includes(
                responseGroup.name.replace(/[ \t]+(\r?$)/, "")
              );
            });
          }

          // change_data(sorted_responses[0],10)
          let most_recent;
          if (params.reportId && sorted_responses) {
            most_recent = get_by_id(sorted_responses, params.reportId);
            sorted_responses
              .filter((responseGroup) => responseGroup.id === params.reportId)
              ?.map((i) => i.responses)
              .flat()
              .map((i) => i.response)
              .filter((f) => !Array.isArray(f));
          } else if (params.dateStart && params.dateEnd && sorted_responses) {
            sorted_responses = sorted_responses.filter(
              (responseGroup) => responseGroup.responses.length > 0
            );

            sorted_responses = sorted_responses.filter(
              (responseGroup) =>
                new Date(responseGroup.date) <= new Date(params.dateEnd) &&
                new Date(responseGroup.date) >= new Date(params.dateStart)
            );

            // If not admin, filter categories which user does not have access to.

            most_recent = get_most_recent(sorted_responses);
          } else {
            // if (!params.isAdmin) {
            //   console.log(params.filteredCategories)
            //   sorted_responses = sorted_responses.filter((rg) => {
            //     return params.filteredCategories?.includes(rg.name);
            //   });
            // }

            most_recent = get_most_recent(sorted_responses);
          }
          // most_recent.responses = most_recent.responses.filter(f=>parseInt(f.employee_records[0]?.Payroll)<250000)

          // let blob= ''
          // let text = most_recent.responses.filter(f=>f.categories.find(f=>f.priority=='secondary').response=='f0fb167d-6db5-47ce-8974-aa99c3e93615').map(item=>item.feedback).flat().map(item=>{
          //   if(item.feedback){
          //     blob=blob+'. '+item.feedback
          //   }
          //   })
          // console.log(blob)

          let total = {};
          most_recent.responses?.map((r) => {
            let n = r.categories.length.toString();
            if (!(n in total)) {
              total[n] = 1;
            } else {
              total[n] += 1;
            }
          });

          most_recent["last"] = get_last_responses(sorted_responses);

          if (sorted_responses.length > 0 && most_recent.responses) {
            let historical_data = sort_historical_data(
              sorted_responses.reverse(),
              0
            );

            setReportData([most_recent, ...historical_data]);

            setComments(check_comments(most_recent));

            dispatch({
              type: SET_RAW_DATA,
              payload: [most_recent, ...historical_data],
            });

            dispatch({
              type: SET_DATA_DATES,
              payload: get_dates(historical_data),
            });
          }
        }
      }
    }
  }, [
    dispatch,
    params.get_survey_structure,
    params.get_culture_audit_reports,
    params.dateEnd,
    params.dateStart,
    // params.sportsFiltered,
    params.reportId,
    params.get_organizations,
    params.refetch,
    params.survey_version,
  ]);

  useEffect(() => {
    if (params.get_auth && params.get_organizations) {
      const orgServices =
        params.get_organizations?.[params.get_auth?.organization_id]
          ?.services_enabled;

      if (orgServices) {
        setServicesEnabled(orgServices);
      }
    }
  }, [params.get_auth, params.get_organizations]);

  useEffect(() => {
    if (organizationId) {
      const organization = params.get_organizations?.[organizationId];
      let isOTP = organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;

      let survey_type =
        params.surveyType === 7
          ? SURVEY_INFO.edi.name
          : isOTP
          ? SURVEY_INFO.otp.name
          : SURVEY_INFO.audit.name;

      setCultureAuditQues(
        organization?.services_enabled &&
          organization?.services_enabled.filter(
            (sc) => sc?.name === survey_type
          )?.[0]?.question_sort_order
      );
    }
  }, [params.get_organizations, organizationId]);

  useEffect(() => {
    if (Number(params.ambassadorId) < 0) {
      setOrganizationId(params.get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(params.get_auth?.organization_id));
    }
  }, [params.get_auth, params.get_selectedOrg]);

  useEffect(() => {
    if (cultureAuditQues > 0) {
      if (
        params.get_survey_questions &&
        params.get_survey_questions.length > 0
      ) {
        let SurveyQues = {
          ...params.get_survey_questions.filter(
            (sq) => sq.sort_order === cultureAuditQues
          )?.[0],
        };
        if (params.survey_version) {
          SurveyQues = {
            ...params.get_survey_questions.filter(
              (sq) => sq.sort_order === params.survey_version.sort_order
            )?.[0],
          };

          if (Object.keys(SurveyQues).length === 0 && cultureAuditQues) {
            SurveyQues = params.survey_version;
          }

          // If SurveyQues is an empty object, and params.get_survey_questions has 1 item, then set SurveyQues to that item.
          if (
            Object.keys(SurveyQues).length === 0 &&
            params.get_survey_questions.length === 1
          ) {
            SurveyQues = { ...params.get_survey_questions[0] };
          }
        }

        if (params.surveyType === 7 && SurveyQues && SurveyQues?.questions) {
          SurveyQues = restructure_Questions(SurveyQues);
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };

        setQuestionStructure(QuesStruc);
        if (isValidObject(QuesStruc)) {
          dispatch({
            type: SET_QUESTIONS,
            payload: QuesStruc,
          });
        }
      }
    } else if (params.get_survey_questions?.[0]) {
      let SurveyQues = { ...params.get_survey_questions[0] };

      if (params.survey_version) {
        SurveyQues = {
          ...params.get_survey_questions.filter(
            (sq) => sq.sort_order === params.survey_version.sort_order
          )?.[0],
        };
      }

      if (params.surveyType === 7 && SurveyQues && SurveyQues?.questions) {
        SurveyQues = restructure_Questions(SurveyQues);
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      if (isValidObject(QuesStruc)) {
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    }
  }, [
    dispatch,
    params.get_survey_questions,
    cultureAuditQues,
    params.survey_version,
  ]);

  return {
    reportData,
    cultureAuditQues,
    questionStructure,
    surveyComments: comments,
    servicesEnabled,
    organizationId,
    questionsList,
  };
}

const convert_DEI = (resp) => {
  resp.map((item) => {
    item.responses = item.responses.map((r) => {
      r.response.questions = r.response.questions.map((q) => {
        q.id = 0;
        return q;
      });
      return r;
    });
    return item;
  });
  return resp;
};

const restructure_Questions = (questions) => {
  const questionsCopy = JSON.parse(JSON.stringify(questions));
  let d1 = {
    id: 1,
    title: "nuLogic Factors",
    factors: questionsCopy?.questions?.factors,
  };
  questionsCopy.questions.dimensions = [d1];

  return questionsCopy;
};

const checkPersonality = (org) => {
  return org.organization.services_enabled.find((f) => f.id === 21)?.enabled;
};

const convert_personality_to_categories = (resp) => {
  resp.map((item) => {
    item.responses.map((r) => {
      if (Object.keys(r.response.survey_personality).length > 0) {
        let personality = calculate_personality_categories(
          r.response.survey_personality
        );
        r.response.categories.push(...personality);
      }

      return r;
    });
  });

  return resp;
};

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const personality_names = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Neuroticism",
  "Openess",
];

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};
const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

const calculate_personality_categories = (data) => {
  let pers = personality_norms.map((item, i) => calculate_personality(data, i));
  return pers.map((item, i) => {
    let response = item < 33 ? "0" : item < 67 ? "1" : "2";
    return { id: personality_names[i], priority: "personality", response };
  });
};

export default useGetSurveyUtils;
