import Settings from "assets/images/nav/settings.svg";
import Home from "assets/images/nav/home.svg";
import Management from "assets/images/nav/management.svg";
import L360 from "assets/images/nav/360.svg";
import Report from "assets/images/nav/report.svg";
import PDFIcon from "assets/images/nav/pdf-regular-white.svg";

import Culture from "assets/images/nav/culture.svg";
import Mood from "assets/images/nav/mood.svg";
import Personality from "assets/images/nav/personality.svg";

import Leadership from "assets/images/nav/leadership.svg";
import Admin from "assets/images/nav/admin.svg";
import MyMood from "assets/images/group-12.svg";
import Survey from "assets/images/nav/survey.svg";

import Data from "assets/images/nav/data.svg";

import Calendar from "assets/images/nav/calendar.png";
import Action from "assets/images/nav/action.png";

import {
  TeamIcon,
  reportIcon,
  actionIcon,
  cultureIcon,
  calendarIcon,
  dataScienceIcon,
  groupIcon,
  recommendationIcon,
  outcomesIcon,
  aiIcon,
  dashboardIcon,
} from "./Icons";

// before you add things here check if the route is valid
// checking this data is done in sidebar.js
// use the name property to loop through
export const CommonNav = [
  {
    icon: "address book",
    link: "/core/management",
    title: "Team Management",
    image: Management,
    role: 7,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
];
export const OtpNav = [];

export const NavData = [
  {
    // icon: "home",
    link: "/app/edi/analytics",
    title: "Dashboard",
    image: Calendar,
    svg: dashboardIcon,
    role: 9,
    paid: false,
    team: false,
    name: "EDI",
    group: "EDI",
    employee_permission:true,
    innerlogic_only:true,
    no_subfactor:true,
  }
  ,
  {
    header: "nuLogic",
    role: 9,
    paid: true,
    team: false,
    name: "EDI",
    group: "EDI",
  },
  {
    icon: "address book",
    link: "/app/edi",
    title: "Calendar",
    image: Calendar,
    svg: calendarIcon,
    role: 9,
    paid: true,
    team: false,
    name: "EDI",
    group: "EDI",
  },
  {
    icon: "address book",
    link: "/app/edi/report",
    title: "Analytics",
    svg: cultureIcon,
    image: L360,
    role: 9,
    paid: true,
    team: false,
    name: "EDI",
    group: "EDI",
  },
  // {
  //   icon: "address book",
  //   link: "/app/edi/recommendations",
  //   title: "Recommendations",
  //   image: Report,
  //   svg: recommendationIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: false,
  //   name: "EDI",
  //   group: "EDI",
  // },
  {
    icon: "address book",
    link: "/app/edi/AI",
    title: "Copilot",
    image: Report,
    svg: aiIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "AI",
    group: "EDI",
  },
  {
    icon: "dollar",
    link: "/app/edi/outcomes",
    title: "Outcomes",
    svg: outcomesIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "EDI",
  },
  {
    icon: "dollar",
    link: "/app/edi/group",
    title: "Group Analytics",
    svg: groupIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "EDI",
    group: "EDI",
    demo: false,
  },
  {
    icon: "dollar",
    link: "/app/edi/data_science",
    title: "Data Science",
    svg: dataScienceIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: false,
    name: "EDI",
    group: "EDI",
    demo: true,
  },
  {
    icon: "address book",
    link: "/app/edi/rec",
    title: "Recommendations",
    image: Report,
    svg: recommendationIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    require_feedback_access:true,
    name: "EDI",
    group: "EDI",
    employee_permission:true,
  },
  // {
  //   icon: "address book",
  //   link: "/app/edi/action",
  //   title: "Action Plan",
  //   image: Action,
  //   svg: actionIcon,
  //   role: 9,
  //   paid: false,
  //   team: false,
  //   name: "EDI",
  //   group: "EDI",
  // },
  // {
  //   icon: "address book",
  //   link: "/app/edi/pdf",
  //   title: "PDF Report",
  //   image: PDFIcon,
  //   svg: reportIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   name: "EDI",
  //   group: "EDI",
  // },
  // common links
  {
    header: "Settings",
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
  {
    icon: "address book",
    link: "/core/members/list",
    title: "Team Management",
    fr: "Gestion de l'équipe",
    image: Management,
    svg: TeamIcon,
    border: true,
    role: 6,
    paid: true,
    team: false,
    name: null,
    require_add_users: true,
    group: "Common",
  },
];

export default NavData;
