import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import "./home.css"


const PageShell = (Page,pageRef) => {


  return props =>
    {
      return (<>
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionAppearTimeout={600}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={200}
        transitionName={props.match.path ? 'SlideIn' : 'SlideOut'}
      >
        <Page {...props} pageRef={pageRef}/>
      </ReactCSSTransitionGroup>
    </>)
    }
};

export default PageShell;