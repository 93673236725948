import React from "react";
import styled from "styled-components";
import { Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import Settings from "assets/images/nav/cog.svg";
import Logout from "assets/images/nav/logout.svg";
//import LanguageMenu from "./LanguageMenu"; // TP: used for language localizaton control, to be expanded below.

const TopBar = ({ open, setOpen, setHelp }) => {
  const [employee, setEmployee] = React.useState(false);
  const [paymentAlert, setPaymentAlert] = React.useState(false);
  const [organization, setOrganization] = React.useState(false);
  const [checkForStaff, setCheckForStaff] = React.useState(false);

  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );
  const IsStaff = useSelector((state) => state.auth?.is_staff, shallowEqual);
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );

  React.useEffect(() => {
    if (typeof IsStaff == "boolean") {
      if (IsStaff) {
        setCheckForStaff(true);
      }
    }
    if (typeof IsStaff == "string") {
      if (IsStaff === "true") {
        setCheckForStaff(true);
      }
    }
  }, [IsStaff]);

  const { get_employee, get_auth, get_organizations, get_SelectedOrg } =
    useSelector(
      (state) => ({
        get_employee: state.employees,
        get_auth: state.auth,
        get_organizations: state.organizations,
        get_SelectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  React.useEffect(() => {
    if (get_employee) {
      setEmployee(get_employee[get_auth?.employee_id]);
    }

    if (get_auth?.token) {
      setOrganization(get_organizations[get_auth.organization_id]);
    }
  }, [get_employee, get_auth, get_organizations]);

  return (
    <Container>
      {WhiteLabel?.logo ? <Logo src={WhiteLabel.logo} open={open} /> : ""}
      <div>{employee?.account_type === 5 && paymentAlert?.header}</div>
      <div>
        {get_SelectedOrg?.organization?.name}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          right: 10,
        }}
      >
        <Line />

        {/* Todo: TP Localization is roughed out, but incomplete. Building this out could be valueable. */}
        {/*   <LanguageMenu />   */}

        <Link to="/settings/member">
          <Popup
            content="Profile Settings"
            trigger={<Image src={Settings} />}
          />
        </Link>

        <Link to="/account/logout">
          <Popup content="Log Out" trigger={<Image src={Logout} />} />
        </Link>
      </div>
    </Container>
  );
};

export default TopBar;

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  height: 50px;
  z-index: 998;
  background-color: white;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 70px;
  font-size: 20px;

  a {
    color: #a5a5a5;
  }
`;

const Line = styled.div`
  height: 100%;
  width: 1px;
  background-color: #a5a5a5;
  margin-right: 20px;
`;

const Image = styled.img`
  height: 19px;
  width: 19px;
  margin-right: 20px;
`;

const Logo = styled.img`
  height: 15px;
  margin-left: ${(props) => (props.open ? "120px" : 0)};
  transition: all 0.5s;
`;
