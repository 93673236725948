import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Anchor from "../Audit/Anchor";
import Tabs from "./components/Tabs";
import Predictions from "./Predictions";
import Correlations from "./Correlations"
import {
  primaryColor,
  inactiveColor,
  fontSizes,
  sectionTabs,
  barContainerBg,
  defaultText,
  iconColor,
  scoresComponent,
} from "./styleConfig";
import { Dimmer, Loader } from "semantic-ui-react";
import { StarIcon, BoltIcon, CloseIcon } from "./Icons";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_OUTCOME_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
} from "constants/actions";
import {
  getDataByQuarter,
  getAllDataByQuarter,
  getAnchorAveragesByQuestion,
  getAnchorOptions,
  calculate_correlations,
} from "./calculations";

import useGetOrgData from "hooks/useGetOrgData";
import useGetSurveyData from "hooks/useGetSurveyData";
import Empty from "./empty";
import NavBar2 from "../Audit/NavBar2";
import { useLocation } from "react-router-dom";
import useGetUserSettings from "hooks/useGetUserSettings";

import ErrorWrapper from 'error/component_error'

const getChartOptions = (labels) => {
  // Get the next quarter and year based on the most recent label
  return {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      fontFamily: "Helvetica, Arial, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    markers: {
      size: 8,
      radius: 100,
    },
    colors: [primaryColor, "#b5b5b5"],
    grid: {
      padding: {
        left: 10,
        right: 25,
        bottom: 20,
        top: 10,
      },
    },
    stroke: {
      width: [2, 2],
      curve: "smooth",
      dashArray: [0, 8],
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -10,
      style: {
        fontSize: fontSizes.xsmall,
        fontWeight: 400,
        colors: [inactiveColor, "#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 15,
        borderRadius: 10,
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          fontWeight: 400,
        },
      },
      min: 0,
      max: 10,
      decimalsInFloat: 1,
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["rgba(63, 134, 254, 0.06)", "rgba(112, 51, 255, 0)"], // optional, if not defined - uses the shades of same color in series
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [],
      },
    },
  };
};

// Loop through the locationData starting from the reverse
// For each locationData add the object to a new array, only if the option hasn't already been added.
const getUniqueLocationData = (data) => {
  const uniqueLocationData = [];
  for (let i = data.length - 1; i >= 0; i--) {
      const locationData = data[i].locationData;
      for (let j = locationData.length - 1; j >= 0; j--) {
          const option = locationData[j].option;
          const optionExists = uniqueLocationData.some(location => location.option === option);
          if (!optionExists && locationData[j]?.average !== 0) {
              uniqueLocationData.push(locationData[j]);
          }
      }
  }

  // Order list from highest average to lowest.
  uniqueLocationData.sort((a, b) => b.average - a.average);

  return uniqueLocationData;
}

const RiskElement = ({
  title,
  description,
  risk,
  selected,
  open,
  onSelect,
}) => {
  if (!open) {
    return (
      <ClosedContainer onClick={() => onSelect()} selected={selected}>
        <CategoryText>{title}</CategoryText>
      </ClosedContainer>
    );
  }

  return (
    <RiskContainer selected={selected}>
      <RiskTitle>{title}</RiskTitle>
      <div style={{ width: "100%", display: "flex" }}>
        <Row>
          <Chart
            options={data.options}
            series={data.series}
            type="line"
            height={50}
          />
          <Value>85%</Value>
        </Row>
        <Line />

        <LabelContainer>
          <Num>+6%</Num>

          <Label2>Change</Label2>
        </LabelContainer>
        <Line />
        <LabelContainer>
          <Num>+12%</Num>

          <Label2>Predicted impact</Label2>
        </LabelContainer>
      </div>
    </RiskContainer>
  );
};

// TODO: Get background/primary colors from theme.
const getScoreColor = (score) => {
  if (score < 50) {
    return ["#FF0000", "#FF9991"];
  } else if (score < 80) {
    return ["#FFA500", "#FFC222"];
  }

  return [iconColor.good, iconColor.goodBg];
};

const Models = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sub, setSub] = useState(0);
  const [scoreCol, setScoreCol] = useState();
  const [selected, setSelected] = useState(0);
  const [cats, setCats] = useState([]);
  const [quarterlyData, setQuarterlyData] = useState([]);
  const [calculatedData, setCalculatedData] = useState([]);
  const [sortOrder, setSortOrder] = useState();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [questionsList, setQuestionsList] = useState([]);
  const [outcomesData, setOutcomesData] = useState([]);
  const [predictionOutcomes, setPredictionOutcomes] = useState();
  const [fetchingData, setFetchingData] = useState(true);
  const [category_access, setAccess] = useState([]);
  const [subfactor_access, setSubAccess] = useState(false);
  const [filteredReports, setFilteredReports] = useState();
  const [correlationData, setCorrelations] = useState([]);
  const [orgQuestions, setOrgQuestions] = useState(null);
  const [scoresBy, setScoresBy] = useState();
  const [loadingCorrelations, setLoadingCorrelations] = useState(true);
  const [rule, setRule] = useState(2);
  const { filteredCategories, isAdmin } = useGetUserSettings();
  // I don't like this code, but it's a hack to get the anchor data to load.
  const [anchorHack, setAnchorHack] = useState();
  const location = useLocation();

  const isDEI = location.pathname.includes("edi");
  const auditTypeId = isDEI ? 4 : 3;
  const auditSurveyType = isDEI ? 7 : 6;
  const surveyType = 10; // Survey type is 10 for outcomes.
  const { organizationData } = useGetOrgData();
  const { orgSurveyQuestions, WhiteLabel, nav_state } = useGetSurveyData({
    surveyType: auditSurveyType,
    auditTypeId: auditTypeId,
    filteredCategories,
    isAdmin,
  });

  const { get_auth, get_employee_categories } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  const reports = useSelector((state) => {
    return state.debrief_schedule.culture_audit_reports;
  });

  const surveyQuestions = useSelector((state) => {
    return state.surveyquestions.outcome_questions;
  });

  const surveyVersion = useSelector((state) => {
    return state.audit.survey_version;
  });

  const surveyStructure = useSelector((state) => {
    return state.surveystructure.survey_structure;
  });

  const get_employee = useSelector((state) => state.employees, shallowEqual);

  const anchor = useSelector((state) => state.audit?.anchor);

  useEffect(() => {
    if (
      questionsList &&
      questionsList.length > 0 &&
      !isNaN(selected) &&
      outcomesData.length > 0
    ) {
      const idName = questionsList[selected].name;
      const outcomes = outcomesData.find((outcome) => outcome.id === idName);
      setPredictionOutcomes(outcomes);
    }
  }, [questionsList, selected, outcomesData]);

  useEffect(() => {
    if (Object.keys(WhiteLabel).includes("rule")) {
      setRule(Number(WhiteLabel.rule));
    }
  }, [WhiteLabel]);

  useEffect(() => {
    if (organizationData?.services_enabled?.length > 0) {
      const serviceObject = organizationData?.services_enabled?.find(
        (service) => service?.name === "Audit"
      );
      const sortOrder = serviceObject.question_sort_order;

      if (orgSurveyQuestions.length > 0 && sortOrder) {
        const questions = orgSurveyQuestions.find(
          (question) => question.sort_order === sortOrder
        );

        if (questions?.questions?.outcomes) {
          setOutcomesData(questions?.questions?.outcomes);
        }
      }
    }
  }, [orgSurveyQuestions, organizationData]);

  useEffect(() => {
    if (!anchor && surveyStructure?.length > 0) {
      const tempAnchor = surveyStructure?.[0]?.categories.find(
        (f) => f.priority === "primary"
      ).id;

      setAnchorHack(tempAnchor);
    }
  }, [surveyStructure, anchor]);

  useEffect(() => {
    if (surveyQuestions?.length === 0) {
      dispatch({
        type: SG_GET_OUTCOME_QUESTIONS,
        payload: `survey_type=${surveyType}&status=2`,
      });
    }
  }, [surveyQuestions]);

  // Get the sort order for the outcome questions.
  useEffect(() => {
    if (organizationData?.styling) {
      const sortOrder = organizationData?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;
      setSortOrder(sortOrder);
    }
  }, [organizationData]);

  useEffect(() => {
    if (nav_state && reports && surveyStructure?.length > 0) {
      let primary = [];
      let prime =
        nav_state[
          Object.keys(nav_state).find(
            (f) => nav_state[f].priority === "primary"
          )
        ];

      if (prime && Object.keys(prime)?.length > 0) {
        Object.keys(prime)
          .filter((f) => f != "selected" || f != "priority")
          .map((item, i) => {
            let items = prime[item];

            if (items.selected) {
              primary.push(item);
            }
          });
        const primaryOptions = surveyStructure[0]?.categories?.filter(
          (cat) => cat.priority === "primary"
        );

        if (primary?.length > 0) {
          const selectedPrimaries = primary?.map(
            (p) => primaryOptions[0]?.options?.find((po) => po.id === p)?.name
          );

          const filteredReports = reports?.response?.filter((report) =>
            selectedPrimaries?.includes(report?.name)
          );

          setFilteredReports({ response: filteredReports });
        }
      }
    }
  }, [reports, nav_state, surveyStructure]);

  // Get the survey questions for the outcome survey.
  useEffect(() => {
    setFetchingData(true);
    if (surveyQuestions?.length > 0 && sortOrder) {
      const questions = surveyQuestions?.find(
        (item) => item.sort_order === sortOrder
      )?.questions;
      if (questions?.length > 0) {
        setQuestionsList(questions);
      }
    }
  }, [surveyQuestions, sortOrder]);

  // Organize data once we have reports & a selected anchor.
  // Update the data when the anchor changes.
  useEffect(() => {
    if (filteredReports?.response?.length > 0) {
      let data = getAllDataByQuarter(filteredReports?.response);
      setQuarterlyData(data);
    }
  }, [filteredReports, selected]);

  useEffect(() => {
    if (filteredReports?.response?.length > 0 && questionsList.length > 0) {
      setLoadingCorrelations(true);
      const qId = questionsList[selected].id;
      if (surveyVersion) {
        let filtered_survey_data = filteredReports?.response.filter(
          (f) => f.question_sort_order === surveyVersion.sort_order
        );
        setCorrelations(calculate_correlations(filtered_survey_data, qId));
        setLoadingCorrelations(false);
      } else {
        setLoadingCorrelations(true);

        setCorrelations(calculate_correlations(filteredReports?.response, qId));
        setLoadingCorrelations(false);
      }

      setTimeout(() => {
        setLoadingCorrelations(false);
      }, 5000);
    }
  }, [filteredReports, selected, surveyVersion]);

  // When we have quarterly data, calculate the averages for the selected question.
  useEffect(() => {
    if (quarterlyData.length > 0 && questionsList?.length > 0 && anchor) {
      const qId = questionsList[selected].id;
      let data = getAnchorAveragesByQuestion(
        quarterlyData,
        anchor,
        qId,
        surveyStructure[0],
        rule
      );

      data[0]?.anchorData?.sort((a, b) => a.average - b.average)?.reverse();

      const uniqueLocationData = getUniqueLocationData(data);
      const labels = data?.map(
        (item) => `${item?.quarter.toUpperCase()} ${item?.year}`
      );
      const options = getChartOptions(labels);
      setOptions(options);
      setCalculatedData(data);
      setScoresBy(uniqueLocationData);
      setLoading(false);
    }

    if (quarterlyData.length > 0 && fetchingData) {
      setTimeout(() => {
        // Using a set timeout to prevent the empty screen from showing instantly.
        setLoading(false);
      }, 1000);
    }
  }, [
    quarterlyData,
    anchor,
    surveyQuestions,
    questionsList,
    selected,
    surveyStructure,
    rule,
  ]);

  useEffect(() => {
    if (reports.length < 1) {
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: `audit-type-id=${auditTypeId}`,
      });
    }
  }, [reports]);

  useEffect(() => {
    if (get_employee.length < 1) {
      return;
    }
    let role = get_employee?.userEmp?.account_type_text == "Admin";
    if (
      get_employee.userEmp.employee_employee_category?.category.length > 0 &&
      !role
    ) {
      setAccess(get_employee.userEmp.employee_employee_category.category);
    }

    setSubAccess(get_employee.userEmp.access_subfactor_data);
  }, [get_auth, get_employee_categories]);

  const getLongTermTrends = (selected, options) => {
    const usesPrimary =
      surveyStructure[0]?.categories?.find((cat) => cat.id === anchor)
        ?.priority === "primary";
    const anchorData = getAnchorOptions(surveyStructure[0], anchor);
    let chartData = [];

    if (selected && anchorData) {
      const selectedOption = anchorData.options.find(
        (item) => item.id === selected
      );
      const trendData = [];
      calculatedData.forEach((quarter) => {
        const anchorData = usesPrimary
          ? quarter.primaryData.filter(
              (set) =>
                set.option === selectedOption?.id ||
                set.option === selectedOption?.option
            )
          : quarter.locationData.filter(
              (set) =>
                set.option === selectedOption?.id ||
                set.option === selectedOption?.option
            );
        trendData.push(anchorData);
      });
      chartData = trendData.flat().map((plot) => plot.average);
    } else {
      chartData = calculatedData.map((plot) => plot.overallAverage);
    }

    const series = [{ name: "Average Score", data: chartData }];

    return (
      <>
        <SectionTitle>Long term trends</SectionTitle>
        <ChartWrapper>
          <Chart options={options} series={series} type="area" height={400} />
        </ChartWrapper>
      </>
    );
  };

  const scoresByChart =
    calculatedData?.[0]?.anchorData?.length > 0 ? (
      scoresBy?.map((item, i) => {
        if (item?.average > 0) {
          return (
            <BarGraphItem
              onClick={() => {
                setScoreCol(item.option);
              }}
              selected={item.option === scoreCol}
            >
              <BarGraphTitle>{item.optionLabel}</BarGraphTitle>
              <BarGraphItemBar>
                <BarGraphFillContainer>
                  <BarGraphItemBarInner
                    width={Number(item.average) * 10}
                    selected={item.option === scoreCol}
                  />
                </BarGraphFillContainer>
                <BarGraphPercent selected={item.option === scoreCol}>
                  {Number(item.average) * 10}%
                </BarGraphPercent>
              </BarGraphItemBar>
            </BarGraphItem>
          );
        }
      })
    ) : (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          No data available
        </div>
      </div>
    );

  if (calculatedData.length === 0 && !loading) {
    return <Empty />;
  }

  return (
    <Dimmer.Dimmable blurring dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Container>
        <Left open={open}>
          <Title>
            CRITICAL OUTCOMES
            {/* <Icon name={open?"chevron left":"chevron right"} style={{cursor:"pointer"}} onClick={()=>setOpen(!open)}/> */}
          </Title>
          {questionsList?.map((item, i) => (
            <RiskElement
              title={item.name}
              onSelect={() => setSelected(i)}
              description={item?.description || "No description available."}
              selected={i === selected}
              risk={"0.8"}
              open={open}
            />
          ))}
        </Left>
        <Right open={open}>
          <TopSection>
            <LeftPart>
              <CategoryTitle>
                {questionsList?.[selected]?.name?.toUpperCase()}
              </CategoryTitle>
              <D1>
                {questionsList?.[selected]?.description ||
                  "No description available."}
              </D1>
            </LeftPart>

            <OverallStats>
              <ScoreContainer>
                <IconWrapper backgroundColor={getScoreColor(95)[1]}>
                  {StarIcon(getScoreColor(95)[0])}
                </IconWrapper>
                <ScoreWrapper>
                  <Score>
                    {(calculatedData?.[calculatedData?.length - 1]
                      ?.overallAverage &&
                      Math.round(
                        calculatedData?.[calculatedData?.length - 1]
                          ?.overallAverage * 10
                      )) ||
                      "--"}
                  </Score>
                  <T3>Overall Score</T3>
                </ScoreWrapper>
              </ScoreContainer>
              <ScoreContainer>
                <IconWrapper backgroundColor={getScoreColor(95)[1]}>
                  {BoltIcon(getScoreColor(95)[0])}
                </IconWrapper>
                <ScoreWrapper>
                  <Score>
                    {(calculatedData?.[calculatedData?.length - 1]
                      ?.overallAverage &&
                      (Math.round(
                        calculatedData?.[calculatedData?.length - 1]
                          ?.overallAverage *
                          1.22 *
                          10
                      ) < 100
                        ? Math.round(
                            calculatedData?.[calculatedData?.length - 1]
                              ?.overallAverage *
                              1.22 *
                              10
                          )
                        : 100)) ||
                      "--"}
                  </Score>
                  <T3 style={{ minWidth: 165 }}>Potential for optimization</T3>
                </ScoreWrapper>
              </ScoreContainer>
            </OverallStats>
          </TopSection>
          <Tabs
            options={sectionTabs}
            selected={sub}
            onSelect={(s) => setSub(s)}
          />
          {sub === 1 ? (
            <Correlations
              data={correlationData}
              questions={surveyVersion}
              outcome_title={questionsList?.[selected]?.name}
              loading={loadingCorrelations}
            />
          ) : (
            <>
              <BarGraphArea>
                <AnchorArea>
                  <SectionWrapper>
                    <SectionTitle>Scores by</SectionTitle>
                    {surveyStructure && surveyStructure?.length > 0 && (
                      <NavBarWrapper>
                        <NavBar2
                          structure={surveyStructure[0]}
                          subfactor_access={false}
                          showOnlyPrimary={true}
                          employee_categories={category_access}
                          isAdmin={
                            get_employee.userEmp.account_type_text === "Admin"
                          }
                        />
                      </NavBarWrapper>
                    )}
                  </SectionWrapper>

                  <ControlWrapper>
                    {scoreCol && (
                      <ClearButton
                        onClick={() => {
                          setScoreCol();
                        }}
                      >
                        {CloseIcon()}&nbsp;Clear
                      </ClearButton>
                    )}
                    <AnchorHider>
                      <Anchor selectedAnchor={anchorHack} />
                    </AnchorHider>
                  </ControlWrapper>
                </AnchorArea>

                {scoresByChart}
              </BarGraphArea>

              {calculatedData &&
                calculatedData?.[0]?.anchorData.length > 0 &&
                getLongTermTrends(scoreCol, options)}
            </>
          )}
        </Right>
      </Container>
    </Dimmer.Dimmable>
  );
};

export default Models;

const RiskContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 10px;
  background-color: ${(props) => (props.selected ? "lightgrey" : "white")};
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
  }
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 32px;
`;

const ClosedContainer = styled.div`
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  color: ${(props) => (props.selected ? primaryColor : inactiveColor)};
  padding: 5px;
  display: flex;
  font-size: ${fontSizes.small};
  justify-content: space-between;
  border-radius: 5px;
  padding-right: 40px;
  padding-left: 20px;
  padding-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  width: 32%;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 80px;
`;

const RiskTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 200px;
  line-height: 1.4;
`;

const Line = styled.div`
  width: 1px;
  height: 35px;
  background-color: lightgrey;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ClearButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
  font-size: ${fontSizes.regular};
  color: ${inactiveColor};
`;

const D1 = styled.div`
font-style: normal;
font-weight: 400;
font-size: ${fontSizes.small}};
line-height: 140%;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-top: 3px;
  margin-right: 12px;
`;

const Score = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.large};
  line-height: 140%;
  color: ${defaultText};
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const OverallStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  color: darkblue;
  cursor: pointer;
`;

const Value = styled.div`
  border-radius: 10px;
  background-color: lightgrey;
  padding: 2px 10px;
  position: absolute;
  top: -7px;
  left: calc(100% + 10px);
  font-weight: bold;
`;

const Num = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Label2 = styled.div`
  font-size: 11px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
`;

const Container = styled.div`
  padding-left: 15px;
  margin-top: 30px;
  display: flex;
  height: 90vh;
  font-family: "Raleway";
`;

const Left = styled.div`
  height: calc(100vh - 80px);
  border-right: 1px solid lightgrey;
  padding: ${(props) => (props.open ? "30px 20px" : "30px 0px")};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 265px;
`;

const CategoryTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  font-size: ${fontSizes.xlarge};
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: ${fontSizes.xlarge};
  font-weight: 700;
  margin-bottom: 44px;
  width: 250px;
  justify-content: flex-start;
`;

const T3 = styled.div`
font-style: normal;
font-weight: 400;
font-size: ${fontSizes.small}};
line-height: 128%;
min-width: 90px;
color: ${inactiveColor};
`;

const Right = styled.div`
  width: ${(props) =>
    props.open ? "calc(100vw - 600px)" : "calc(100vw - 250px)"};
  padding: 30px 40px;
`;

const CategoryText = styled.div`
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
  display: flex;
  width: 230px;
`;

const SectionTitle = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.xlarge};
  line-height: 128%;
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

const NavBarWrapper = styled.div`
  margin-top: 5px;
`;

const BarGraphArea = styled.div`
  margin-bottom: 50px;
  margin-top: 10px;
`;

const BarGraphTitle = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.medium};
  color: ${inactiveColor};
  overflow-wrap: break-word;
  width: 170px;
  padding-left: 15px;
`;

const BarGraphItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 8px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? scoresComponent.activeBackground : "white"};
`;

const BarGraphItemBar = styled.div`
  width: calc(100% - 150px);
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

const BarGraphPercent = styled.div`
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  font-size: ${fontSizes.medium};
  line-height: 140%;
  color: ${(props) => (props.selected ? primaryColor : inactiveColor)};
`;

const BarGraphFillContainer = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: ${barContainerBg};
  margin-right: 16px;
`;

const BarGraphItemBarInner = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(270deg, #3F86FE 0%, rgba(63, 134, 254, 0.3) 100%);"
      : "linear-gradient(270deg, rgba(102, 109, 121, 0.5) 0%, rgba(102, 109, 121, 0.2) 100%)"} !important;
  border-radius: 5px;
  margin-right: 3px;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AnchorArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const ChartWrapper = styled.div`
  overflow: visible;
  width: 100%;
  z-index: 1;
`;

const AnchorHider = styled.div``;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const data = {
  series: [
    {
      name: "Optimized",
      data: [60, 55, 75],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#476DFA", "#3260a8", "#32a852"],
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    fill: {
      type: ["solid", "dashed", "dashed"],
      opacity: [1, 0.4, 0.2],
    },
  },
};

const data2 = {
  series: [
    {
      name: "Average Score",
      data: [8.2, 8.5, 9, 7.6, 8.2, 8.5, 6, 5.6],
    },
  ],
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -10,
      style: {
        fontSize: fontSizes.xsmall,
        fontWeight: 400,
        colors: [inactiveColor, "#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 15,
        borderRadius: 10,
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: inactiveColor,
        },
      },
    },
    colors: ["#E3B01F", "#b5b5b5"],
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },
    stroke: {
      width: [5, 2],
      curve: "smooth",
      dashArray: [0, 8],
    },
    xaxis: {
      categories: [
        "Q1 2020",
        "Q2 2020",
        "Q3 2020",
        "Q4 2020",
        "Q1 2021",
        "Q2 2021",
        "Q3 2021",
        "Q4 2021",
      ],
    },
    yaxis: {
      title: {
        text: "Score",
      },
      min: 0,
      max: 10,
      decimalsInFloat: 0,
    },
  },
};

const data3 = {
  series: [
    {
      name: "Average Score",
    },
  ],
  options: {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      fontFamily: "Helvetica, Arial, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    markers: {
      size: 8,
      radius: 100,
    },
    colors: [primaryColor, "#b5b5b5"],
    grid: {
      padding: {
        left: 10,
        right: 25,
        bottom: 20,
        top: 10,
      },
    },
    stroke: {
      width: [2, 2],
      curve: "smooth",
      dashArray: [0, 8],
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -10,
      style: {
        fontSize: fontSizes.xsmall,
        fontWeight: 400,
        colors: [inactiveColor, "#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 15,
        borderRadius: 10,
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    xaxis: {
      type: "category",
      title: {
        text: "Survey Date",
        style: {
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          fontWeight: 400,
        },
      },
      min: 0,
      max: 10,
      decimalsInFloat: 1,
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["rgba(63, 134, 254, 0.06)", "rgba(112, 51, 255, 0)"], // optional, if not defined - uses the shades of same color in series
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [],
      },
    },
  },
};
