import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dimmer, Loader, Modal, Checkbox } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import DialScore from "./components/DialScore";
import ScoreCard from "./components/ScoreCard";

import Down from "assets/images/UI/down-small.svg";
import Up from "assets/images/UI/up-small.svg";
import {
  ideaIcon,
  flagIcon,
  heartIcon,
  starIcon,
  closeIcon,
} from "./common/icons";
import CultureMap from "./components/CultureMap";
import { format } from "date-fns";
import LogAnimation from "./LogAnimation";
import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  get_by_id,
  change_data,
} from "reports/Audit/data_navigation/calculate_data";
import Anchor from "reports/Audit/Anchor";
import {
  getDataByQuarter,
  getAnchorAveragesByQuestion,
} from "../Outcomes/calculations";
import Chart from "react-apexcharts";
import Filters from "./components/Filters";
import {
  filter_data,
  get_overall_average,
  getOverallSpread,
} from "reports/Tag/calculations";
import Empty from "./Empty";
// Calculations
import {
  calculate_table_data,
  getCategoryFromOption,
  retrievePillarData,
  // change_data,
  getPrimaryId,
  getSecondaryId,
  getOptionName,
  getLastFactorAverage,
  getSortOrders,
  restructure_Questions,
  getCultureAverages,
  convert_personality_to_categories,
  checkPersonality,
  convert_DEI,
  check_comments,
} from "reports/Dashboard/calculations.js";

import {
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_OUTCOME_QUESTIONS,
  SG_GET_DEBRIEF_SCHEDULES,
  SET_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SET_FILTERED_DATA,
  SET_FILTERED_LAST_DATA,
} from "constants/actions";

const personality_names = [
  ["Extraversion", "Extraverted"],
  ["Conscientiousness", "Conscientiousness"],
  ["Agreeableness", "Agreeable"],
  ["Neuroticism", "Neurotic"],
  ["Openess", "Open"],
];

const personality_categories = personality_names.map((item) => {
  return {
    id: item[0],
    name: item[0],
    options: [
      { id: "0", name: "Least " + item[1], color: "blue" },
      { id: "1", name: "Moderately " + item[1], color: "red" },
      { id: "2", name: "Most " + item[1], color: "green" },
    ],
    priority: "personality",
    subtitle: item,
    demographic: true,
  };
});

const colorScheme = [
  "#F15062",
  "#86E5CB",
  "#2D70E2",
  "#C293FF",
  "#F7E68F",
  "#FF9066",
  "#C4DAFF",
  "#FEA711",
  "#FFD3B6",
  "#B5EAD7",
  "#FFB6B9",
];

function getPosition(id, data) {
  return data.findIndex((item) => item.id === id) + 1;
}

const options = {
  chart: {
    type: "bar",
    fontFamily: "Poppins, sans-serif",
    foreColor: "#7E7E7E",
    toolbar: {
      show: false,
    },
    width: 600,
    height: 400,
    events: {},
  },
  tooltip: {
    enabled: true,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return "<div></div>";
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    show: false,
    markers: {
      fillColors: ["#468ADE", "#E6E6E6"],
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
    },
  },
  colors: ["#2D70E2", "#C7CFDB", "#E6E6E6"],
  fill: {
    type: ["solid"],
  },
  xaxis: {
    categories: ["Your Team", "Your Organization"],
    tickPlacement: "on",
    labels: {
      style: {
        fontSize: "9px",
      },
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    forceNiceScale: true,
    decimalsInFloat: 0,
    title: {
      text: "nuLogic Score",
      style: {
        fontWeight: 400,
      },
    },
  },
};

// Utility function to get the reversed score
function getReverseScore(score) {
  return 10 - score + 1; // Assuming the scores range from 1-10
}

// Using the given outcome id, get a list of the outcome scores along with the labels, sort them by highest to lowest, and return both the highest and lowest, along with their labels
// also get the overall score for the outcome
const getOutcomeScores = (data, outcomeId) => {
  const outcomeScores = data.map((item) => {
    const outcome = item.outcomesCalculations.find(
      (outcome) => outcome.id === outcomeId
    );
    return {
      label: item.label,
      score: outcome.overallScore,
    };
  });

  const sortedOutcomeScores = outcomeScores.sort((a, b) => b.score - a.score);

  const highest = sortedOutcomeScores[0];
  const lowest = sortedOutcomeScores[sortedOutcomeScores.length - 1];

  // Get the overall score for that particular outcome, regardless of the anchor option
  const overallScore =
    outcomeScores.reduce((acc, curr) => acc + curr.score, 0) /
    outcomeScores.length;

  return {
    highest,
    lowest,
    overallScore,
  };
};

const calculateScreenData = (data, structure, anchor, outcmesQuestions) => {
  // First, we need to get the options for the selected anchor.
  // This is done by traversing through structure[0]?.categories
  // and finding the ID that matches the anchor.
  const anchorOptions = structure[0]?.categories?.find(
    (cat) => cat.id === anchor
  )?.options;

  // Next we need to group the objects within filteredLastData
  // so that they are grouped by the corresponding anchor option.
  // This is done by looping through the anchor options to get each option
  // And then filtering the filteredLastData array based on categories, the id should match the anchor, and the response should match the anchor option.
  const groupedData = anchorOptions.map((option) => {
    return {
      option,
      data: data.filter(
        (data) =>
          data.categories?.find((cat) => cat.id === anchor)?.response ===
          option.id
      ),
    };
  });

  // Now we have the data grouped by the anchor options.
  // We will create a new array that will contain objects with the following structure:
  // {
  //    option: 8329e99d-6020-4213-8bc5-5af4da4f9508
  //    label: "Label",
  //    outcomesCalculations: [
  //      {id: '1', outcomeLabel: 'Psychological Safety', overallScore: 4.5},
  //      {id: '2', outcomeLabel: 'Trust', overallScore: 3.5},
  //    ],
  //    overallScore: 4.0
  // }
  // The label will be the name of the anchor option.
  // The outcomesCalculations will be an array of objects that contain the outcomeLabel and overallScore for each unique question
  // which is calculated by summing the scores of the employee_outcomes?.responses and dividing by the number of outcomes.
  // The overallScore is calculated by summing the responses from the questions array and dividing by the number of questions.
  // outcomesQuestions will be an array of objects that contain the outcomeLabel and the questions that are associated with that outcome.
  // If a question has reverse set to true, we will use the getReverseScore function to get the reverse value.
  // Fillter any groups that have less than 3 responses
  const filteredGroups = groupedData.filter((group) => group.data.length > 2);

  const finalData = filteredGroups.map((group) => {
    const outcomesCalculations = [];

    const outcomesData = group.data.flatMap((data) => data.employee_outcomes);
    // ensure employee_outcomes is not an empty object
    if (outcomesData.length === 0) {
      return;
    }
    const outcomesQuestions = outcmesQuestions?.questions.map((outcome) => {
      return {
        id: outcome.id,
        outcomeLabel: outcome.name,
      };
    });

    outcomesQuestions?.forEach((outcome) => {
      const filteredOutcomes = outcomesData?.flatMap((data) =>
        data.responses?.filter((d) => Number(d.q) === Number(outcome.id))
      );
      // Calculate the overallScore manually, DO NOT USE get_overall_average
      const totalSum = filteredOutcomes?.reduce((acc, curr) => {
        if (curr?.response) {
          return acc + curr?.response;
        } else {
          return acc;
        }
      }, 0);

      const overallScore = filteredOutcomes?.length
        ? totalSum / filteredOutcomes.length
        : 0;
      outcomesCalculations.push({
        id: outcome.id,
        outcomeLabel: outcome.outcomeLabel,
        overallScore,
      });
    });

    const overallScore = get_overall_average(group.data);
    const groupLength = group?.data?.length;

    return {
      option: group.option,
      label: group.option.name,
      overallScore,
      outcomesCalculations,
      overallSpread: groupLength > 4 ? groupLength * 1.2 : 5,
      groupSize: groupLength,
    };
  });

  const preFilteredData = groupedData.map((group) => {
    const outcomesCalculations = [];

    const outcomesData = group.data.flatMap((data) => data.employee_outcomes);
    // ensure employee_outcomes is not an empty object
    if (outcomesData.length === 0) {
      return;
    }
    const outcomesQuestions = outcmesQuestions?.questions.map((outcome) => {
      return {
        id: outcome.id,
        outcomeLabel: outcome.name,
      };
    });

    outcomesQuestions.forEach((outcome) => {
      const filteredOutcomes = outcomesData.flatMap((data) =>
        data.responses?.filter((d) => Number(d.q) === Number(outcome.id))
      );
      // Calculate the overallScore manually, DO NOT USE get_overall_average
      const totalSum = filteredOutcomes.reduce((acc, curr) => {
        if (curr?.response) {
          return acc + curr.response;
        } else {
          return acc;
        }
      }, 0);
      const overallScore = filteredOutcomes.length
        ? totalSum / filteredOutcomes.length
        : 0;
      outcomesCalculations.push({
        id: outcome.id,
        outcomeLabel: outcome.outcomeLabel,
        overallScore,
      });
    });

    const overallScore = get_overall_average(group.data);
    // Get the total respondents for the group
    const totalRespondents = group.data.length;

    return {
      option: group.option,
      label: group.option.name,
      overallScore,
      outcomesCalculations,
      totalRespondents: totalRespondents,
    };
  });

  return { finalData, preFilteredData };
};

// Create a array for a particular outcome id, this will be used to get the rank.
function createOutcomesArray(data, outcomeId) {
  const newArray = [];
  data.forEach((item) => {
    item.outcomesCalculations.forEach((outcome) => {
      if (outcome.id === outcomeId) {
        const newObj = {
          label: item.label,
          id: item.option.id,
          outcome: outcome.outcomeLabel,
          outcomeId: outcome.id,
          overallScore: outcome.overallScore,
        };
        newArray.push(newObj);
      }
    });
  });

  newArray.sort((a, b) => b.overallScore - a.overallScore);

  return newArray;
}

function getRankAndTotal(newArray, id) {
  const rank = newArray.findIndex((item) => item.id === id) + 1;
  const total = newArray.length;
  return {
    rank,
    total,
  };
}

function getChange(curr, prev, currId) {
  const prevItem = prev.find((item) => item.option.id === currId);
  const outcomesCalculations = prevItem?.outcomesCalculations;
  const outcome = outcomesCalculations?.find(
    (outcome) => outcome.id === curr.id
  );
  const change = curr.overallScore - outcome?.overallScore;
  if (outcome?.overallScore === 0) return 0;

  return change;
}

// Creates chart data using finalData
const restructureData = (data, prevData) => {
  return data.map((item, index) => {
    const outcomes = item.outcomesCalculations.map((outcome) => {
      const outcomesSorted = createOutcomesArray(data, outcome?.id);
      const rankInfo = getRankAndTotal(outcomesSorted, item?.option?.id);
      return {
        label: outcome.outcomeLabel,
        average: Math.round(outcome.overallScore * 10),
        totalGroups: rankInfo?.total,
        groupRank: rankInfo?.rank,
        anchorOption: item.label,
        outcomeChange: getChange(outcome, prevData, item.option.id),
      };
    });

    // Get the overall change from previous
    const prevItem = prevData.filter(
      (prevItem) => prevItem.option.id === item.option.id
    );

    const overallChange = item?.overallScore - prevItem?.[0]?.overallScore;

    return {
      category: item.label,
      x: Math.round(item.overallScore * 10),
      y: !isNaN(overallChange) ? overallChange * 10 : 0,
      id: item.option.id,
      // Spread is now length of data
      size: Math.abs(item?.overallSpread) || 5,
      totalRespondents: item?.groupSize,
      color: colorScheme[index],
      outcomes,
    };
  });
};

// Using the id of an outcome. Get the overall average. Use the totalRespondents to calculate the average.
const getOverallAverage = (data, id) => {
  let total = 0;
  let count = 0;
  data.forEach((item) => {
    if (item && item.outcomesCalculations) {
      item.outcomesCalculations.forEach((outcome) => {
        if (outcome.id === id) {
          total += outcome.overallScore * item.totalRespondents;
          count += item.totalRespondents;
        }
      });
    }
  });
  return total / count;
};

// Loop through the locationData starting from the reverse
// For each locationData add the object to a new array, only if the option hasn't already been added.
const getUniqueLocationData = (data) => {
  const uniqueLocationData = [];
  for (let i = data.length - 1; i >= 0; i--) {
    const locationData = data[i].locationData;
    for (let j = locationData.length - 1; j >= 0; j--) {
      const option = locationData[j].option;
      const optionExists = uniqueLocationData.some(
        (location) => location.option === option
      );
      if (!optionExists && locationData[j]?.average !== 0) {
        uniqueLocationData.push(locationData[j]);
      }
    }
  }

  // Order list from highest average to lowest.
  uniqueLocationData.sort((a, b) => b.average - a.average);

  return uniqueLocationData;
};

// Using prioritiesToFilterStructure and surveyStructureFiltered
// find the matching priorities and from the surveyStructureFiltered?.categories?.id
// and filter out any of the options that are not in the prioritiesToFilterStructure?.values array.
// If the prioritiesToFilterStructure?.values array is empty, then return the surveyStructureFiltered
// as is.
const filterSurveyStructure = (
  surveyStructureFiltered,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return surveyStructureFiltered;
  }
  const filteredCategories = surveyStructureFiltered?.[0]?.categories?.map(
    (category) => {
      const permissionsToFilter = prioritiesToFilterStructure?.find(
        (priority) => {
          return priority?.priority === category?.id;
        }
      );
      if (!permissionsToFilter) {
        return category;
      }

      const filteredOptions = category?.options?.filter((option) => {
        return permissionsToFilter?.values?.includes(option?.id);
      });

      const updatedCategory = { ...category };
      updatedCategory.options = filteredOptions;
      return {
        ...category,
        options: filteredOptions,
      };
    }
  );

  const newSurveyStructureFiltered = {
    ...surveyStructureFiltered[0],
    categories: filteredCategories,
  };

  return [newSurveyStructureFiltered];
};

const getIcon = (factor) => {
  switch (factor) {
    case "Engagement":
      return ideaIcon();
    case "Emotional Intelligence":
      return heartIcon();
    case "Psychological Safety":
      return starIcon();
    default:
      return ideaIcon();
  }
};

// Uses get_culture_audit_reports,  go through reportData?.response and filter out the responses that do not meet the prioritiesToFilterStructure
// the responses represent a survey respondant and the goal is to filter out any respondents that do not meet the prioritiesToFilterStructure
export const filterReportResponses = (
  reportData,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return reportData;
  }

  const filteredSurveys = reportData?.response?.map((survey) => {
    const filteredResponses = survey?.responses?.filter((response) => {
      return prioritiesToFilterStructure?.every((priority) => {
        const priorityId = priority?.priority;
        const priorityValues = priority?.values;
        const matchingResponse = response?.response?.categories?.find(
          (category) => {
            return (
              category?.id === priorityId &&
              priorityValues?.includes(category?.response)
            );
          }
        );
        return matchingResponse;
      });
    });
    const updatedSurvey = { ...survey };
    updatedSurvey.responses = filteredResponses;
    return updatedSurvey;
  });
  reportData.response = filteredSurveys;
  return reportData;
};

const Analytics = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading");
  const [reportData, setReportData] = useState();
  const [filterArray, setFilterArray] = useState([]);
  const [sortOrderList, setSortOrderList] = useState();
  const [selectedSortOrder, setSortOrder] = useState();
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysList, setSurveysList] = useState();
  const [cultureScore, setCultureScore] = useState();
  const [cultureChange, setCultureChange] = useState();
  const [quarterlyData, setQuarterlyData] = useState();
  const [calculated, setCalculated] = useState();
  const [outcomesData, setOutcomesData] = useState();
  const [calculatedOutcomes, setCalculatedOutcomes] = useState();
  const [outcomesQuestions, setOutcomesQuestions] = useState();
  const [selectedChartItem, setSelectedChartItem] = useState();
  const [calculatedChartData, setCalculatedChartData] = useState();
  const [barChartData, setBarChartData] = useState();
  const [useBarGraph, setUseBarGraph] = useState(false);
  const [latestData, setLatestData] = useState();
  const [filteredLatestData, setFilteredLatestData] = useState();
  const [filteredLastData, setFilteredLastData] = useState();
  const [lastData, setLastData] = useState();
  const [screenData, setScreenData] = useState();
  const [lastScreenData, setLastScreenData] = useState();

  const surveyType = 10;

  const {
    anchor,
    get_debrief_schedules,
    get_survey_structure,
    ediResponses,
    organization,
    outcomesQuestionsList,
    WhiteLabel,
    get_survey_questions,
    selectedFilters,
  } = useSelector(
    (state) => ({
      anchor: state.audit?.anchor,
      get_survey_structure: state.surveystructure.survey_structure,
      get_debrief_schedules: state.debrief_schedule,
      organization: state.organizations,
      outcomesQuestionsList: state.surveyquestions.outcome_questions,
      ediResponses: state.debrief_schedule.culture_audit_reports,
      WhiteLabel: state.white_label?.white_label,
      get_survey_questions: state.surveyquestions.survey_questions,
      selectedFilters: state.audit?.new_filters,
    }),
    shallowEqual
  );

  const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : 2;

  useEffect(() => {
    if (sortOrderList && surveysList && !selectedSurvey) {
      const selectedSortOrder = sortOrderList[0];

      const selected = surveysList?.find((survey) => {
        return selectedSortOrder === survey?.sort_order;
      });

      if (selected) {
        setSelectedSurvey(selected);
        dispatch({
          type: SET_QUESTIONS,
          payload: selected,
        });
      }
    }
  }, [sortOrderList, surveysList]);

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      const filteredLatestData = filter_data(latestData, selectedFilters);
      const filteredLastData = filter_data(lastData, selectedFilters);

      setFilteredLastData(filteredLastData);
      setFilteredLatestData(filteredLatestData);

      dispatch({
        type: SET_FILTERED_DATA,
        payload: filteredLatestData.filter_data,
      });

      dispatch({
        type: SET_FILTERED_LAST_DATA,
        payload: filteredLastData.filter_data_last,
      });
    }
  }, [selectedFilters, reportData]);

  useEffect(() => {
    if (ediResponses?.response?.length > 0) {
      let data = getDataByQuarter(ediResponses?.response);
      setQuarterlyData(data);
    } else {
      setQuarterlyData([]);
    }
  }, [ediResponses]);

  useEffect(() => {
    if (
      quarterlyData?.length > 0 &&
      outcomesQuestions?.questions?.length > 0 &&
      anchor
    ) {
      let usedAnchor = anchor;
      const anchorOptions = get_survey_structure?.[0]?.categories?.find(
        (cat) => cat.id === anchor
      )?.options;

      if (!anchorOptions) {
        usedAnchor = get_survey_structure?.[0]?.categories?.find(
          (cat) => cat.priority === "primary"
        )?.id;
      }

      const data = outcomesQuestions?.questions?.map((question) => {
        let calcData = getAnchorAveragesByQuestion(
          quarterlyData,
          usedAnchor,
          question?.id,
          get_survey_structure[0],
          orgRule
        );

        calcData?.[0]?.anchorData
          ?.sort((a, b) => a.average - b.average)
          ?.reverse();
        const uniqueLocationData = getUniqueLocationData(calcData);

        return {
          id: question?.id,
          data: calcData,
          label: question?.name,
          uniqueLocationData: uniqueLocationData,
        };
      });

      setCalculatedOutcomes(data);
    }
  }, [quarterlyData, outcomesQuestions, anchor]);

  useEffect(() => {
    if (
      filteredLatestData &&
      filteredLatestData.length > 0 &&
      anchor &&
      get_survey_structure?.[0] &&
      outcomesQuestions
    ) {
      const data = calculateScreenData(
        filteredLatestData,
        get_survey_structure,
        anchor,
        outcomesQuestions
      );

      const lastData = calculateScreenData(
        filteredLastData,
        get_survey_structure,
        anchor,
        outcomesQuestions
      );

      setScreenData(data);
      setLastScreenData(lastData);

      const chartData = restructureData(data.finalData, lastData.finalData);
      // sort the chartData by highest to lowest
      chartData.sort((a, b) => b.x - a.x);

      // Loop through the above data and create a new array containing only the x values
      const xValues = chartData.map((item) => item.x);

      // Loop through the above data and create a new array containing only the category values
      const categoryValues = chartData.map((item) => item.category);

      // Loop through the above data and create a new array containing only the colors values
      const colorValues = chartData.map((item) => item.color);

      const series = [
        {
          name: categoryValues,
          data: xValues,
        },
      ];

      options.colors = colorValues;
      options.xaxis.categories = categoryValues;
      options.chart.events = {
        click: function (event, chartContext, config) {
          setSelectedChartItem(chartData[config?.dataPointIndex]);
        },
      };
      setBarChartData({
        series: series,
        options: options,
      });

      setCalculatedChartData(chartData);
    }
  }, [filteredLatestData, anchor, get_survey_structure, outcomesQuestions]);

  useEffect(() => {
    if (
      get_debrief_schedules?.debrief_schedule?.results?.length > 0 &&
      get_survey_questions?.length > 0
    ) {
      const sortOrders = getSortOrders(
        get_debrief_schedules,
        get_survey_questions
      );

      setSortOrderList(sortOrders);
    } else {
      if (organization?.styling) {
        const sortOrder = organization?.services_enabled?.find(
          (item) => item.name === "Audit"
        )?.question_sort_order;
        setSortOrderList([sortOrder]);
      }
    }
  }, [get_debrief_schedules, get_survey_questions, organization]);

  useEffect(() => {
    if (organization?.organization?.styling) {
      const sortOrder =
        organization?.organization?.styling?.survey_sequence?.find(
          (item) => item.value === "outcome_question"
        )?.question?.sort_order;

      if (outcomesQuestionsList.length > 0 && sortOrder) {
        const questions = outcomesQuestionsList.find(
          (question) => question.sort_order === sortOrder
        );

        if (questions) {
          setOutcomesQuestions(questions);
          setOutcomesData(questions?.questions);
        } else {
          setOutcomesData([]);
        }
      }
    }
  }, [outcomesQuestionsList, organization]);

  useEffect(() => {
    if (
      sortOrderList &&
      get_survey_questions &&
      get_survey_questions.length > 0
    ) {
      const surveyQuestionsCopy = JSON.parse(
        JSON.stringify(get_survey_questions)
      );

      const mappedList = sortOrderList.map((i) => {
        if (i == 0) {
          return;
        }

        let survey = JSON.parse(
          JSON.stringify(
            surveyQuestionsCopy.filter((sq) => sq.sort_order === i)?.[0]
          )
        );

        survey = restructure_Questions(survey);

        const surveyStructure = {
          title: survey?.questions?.title,
          dimensions: survey?.questions?.dimensions,
          type: survey?.questions?.type,
          type_name: survey?.questions?.type_name,
          comments: survey?.questions?.comments,
          sort_order: survey?.sort_order,
        };

        return surveyStructure;
      });
      setSurveysList(mappedList.filter((f) => f));
    }
  }, [sortOrderList, get_survey_questions]);

  // Get the sort order for the outcome questions.
  useEffect(() => {
    if (organization?.styling) {
      const sortOrder = organization?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;
      setSortOrder(sortOrder);
    }
  }, [sortOrderList]);

  useEffect(() => {
    dispatch({
      type: SG_GET_OUTCOME_QUESTIONS,
      payload: `survey_type=${surveyType}&status=2`,
    });
  }, []);

  useEffect(() => {
    if (organization?.organization?.id) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=7&status=2`,
      });
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: "audit-type-id=4",
      });
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page_size=1000&type_of=4&organization=${organization?.organization?.id}`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organization?.organization?.id}`,
      });
    }
  }, [organization]);

  useEffect(() => {
    const DefState = build_default_State(get_survey_structure[0]);
    dispatch({
      type: SET_NAVIGATION,
      payload: DefState,
    });
  }, []);

  useEffect(() => {
    if (
      get_survey_structure &&
      get_survey_structure[0] &&
      ediResponses?.response
    ) {
      if (ediResponses.response.length > 0) {
        let sorted_responses = ediResponses.response.sort(
          (a, b) => b.id - a.id
        );

        if (checkPersonality(organization)) {
          //Modify the results to include personality as a categorical variable
          sorted_responses =
            convert_personality_to_categories(sorted_responses);
        }

        sorted_responses = convert_DEI(sorted_responses);

        change_data(sorted_responses[0], 10);

        let most_recent;
        most_recent = get_most_recent(sorted_responses);
        setLatestData(most_recent.responses);

        let total = {};
        most_recent.responses?.map((r) => {
          let n = r.categories.length.toString();
          if (!(n in total)) {
            total[n] = 1;
          } else {
            total[n] += 1;
          }
        });

        most_recent["last"] = get_last_responses(sorted_responses);
        setLastData(most_recent["last"]);

        if (sorted_responses.length > 0 && most_recent.responses) {
          let historical_data = sort_historical_data(
            sorted_responses.reverse(),
            0
          );

          setReportData([most_recent, ...historical_data]);

          dispatch({
            type: SET_RAW_DATA,
            payload: [most_recent, ...historical_data],
          });

          dispatch({
            type: SET_DATA_DATES,
            payload: get_dates(historical_data),
          });
        }
      }
    }
  }, [dispatch, get_survey_structure, ediResponses, organization]);

  useEffect(() => {
    if (filteredLatestData && filteredLastData) {
      const latest = get_overall_average(filteredLatestData);
      const last = get_overall_average(filteredLastData);
      if (last && latest) {
        setCultureChange(((latest - last) * 10).toFixed(1));
      }
      setCultureScore(Math.round(latest * 10));
    } else {
      setCultureScore(0);
    }
  }, [filteredLatestData, filteredLastData]);

  useEffect(() => {
    if (reportData && get_survey_structure[0] && anchor && selectedSurvey) {
      const calculatedData = calculate_table_data(
        reportData,
        filterArray,
        get_survey_structure[0],
        anchor,
        orgRule,
        selectedSurvey
      );

      setCalculated(calculatedData);
    }
  }, [reportData, get_survey_structure, anchor, selectedSurvey]);

  const getScoreCardSection = (data) => {
    return data
      ?.reduce((acc, curr, index) => {
        if (index % 2 === 0) {
          acc.push(calculatedOutcomes.slice(index, index + 2));
        }
        return acc;
      }, [])
      .map((group, index) => {
        return (
          <CardGroups key={index}>
            {group.map((outcome) => {
              const icon = getIcon(outcome?.label);
              const highLow = getOutcomeScores(
                screenData?.finalData,
                outcome?.id
              );

              // const overallScore = Math.round(
              //   getOverallAverage(screenData?.preFilteredData, outcome?.id) * 10
              // );
              const overallScore =
                Number(outcome?.data?.[0]?.overallAverage) * 10;
              const change =
                overallScore -
                Math.round(
                  getOverallAverage(
                    lastScreenData?.preFilteredData,
                    outcome?.id
                  ) * 10
                );

              if (isNaN(overallScore)) {
                return null;
              }

              return (
                <ScoreCard
                  score={overallScore}
                  lowest={highLow?.lowest?.label}
                  highest={highLow?.highest?.label}
                  factor={outcome?.label}
                  icon={icon}
                  change={change !== overallScore && change}
                />
              );
            })}
          </CardGroups>
        );
      });
  };

  useEffect(() => {
    if (calculatedChartData && calculatedOutcomes) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [anchor, calculatedChartData, calculatedOutcomes]);

  if (!screenData && !calculatedChartData && !calculatedOutcomes && loading) {
    return (
      <Dimmer active={loading} inverted>
        <div style={{ display: "none" }}>
          <Anchor anchorLabel=" " />
        </div>
      </Dimmer>
    );
  }

  const hasNoData =
    (((!calculatedOutcomes && !calculatedChartData) ||
      (calculatedChartData && calculatedChartData?.length === 0)) &&
      !loading) ||
    (filteredLatestData?.length === 0 && !loading);

  return (
    <Container>
      <div style={{ display: "none" }}>
        <Anchor anchorLabel=" " />
      </div>
      <HeadingSection>
        <CultureLabel>nuLogic Assessment</CultureLabel>
        <PageTitle>{organization?.organization?.name}</PageTitle>
      </HeadingSection>
      {hasNoData && !screenData && calculatedOutcomes.length === 0 && <Empty />}
      {screenData && calculatedOutcomes && calculatedOutcomes.length > 0 && (
        <>
          <FilterSection>
            <Filters />
          </FilterSection>
          <DialSection>
            <DialScore score={cultureScore} change={cultureChange} />
            <ScoreCardSection>
              {
                // split the calculatedOutcomes into 2 groups of 2 (if there are more than 2 outcomes) then add those to an array
                // then map through the array and create a CardGroup for each group of 2
                getScoreCardSection(calculatedOutcomes)
              }
            </ScoreCardSection>
          </DialSection>

          <ChartSection>
            <HeaderSection>
              <ChartHeader>
                {(useBarGraph && "Bar Chart") || "nuLogic Map"}
              </ChartHeader>
              <Anchor anchorLabel=" " />
              <BarGraphToggle>
                <BarGraphTextToggleText>Use Bar Chart</BarGraphTextToggleText>
                <Checkbox
                  toggle
                  checked={useBarGraph}
                  onChange={() => setUseBarGraph(!useBarGraph)}
                />
              </BarGraphToggle>
            </HeaderSection>
            {!loading && !hasNoData ? (
              <>
                {calculatedChartData &&
                  calculatedChartData?.length > 0 &&
                  !useBarGraph && (
                    <CultureMap
                      data={calculatedChartData}
                      outcomesData={calculatedOutcomes}
                      onItemClick={setSelectedChartItem}
                    />
                  )}
                {calculatedChartData &&
                  calculatedChartData?.length > 0 &&
                  barChartData &&
                  useBarGraph && (
                    <Chart
                      width={"100%"}
                      options={barChartData?.options}
                      series={barChartData?.series}
                      type="bar"
                      height={350}
                    />
                  )}
              </>
            ) : (
              <Dimmer active={loading} inverted>
                <Loader content={loadingText} />
              </Dimmer>
            )}
          </ChartSection>
        </>
      )}
      {hasNoData && <Empty />}
      {!hasNoData && selectedChartItem && (
        <Modal
          onClose={() => setSelectedChartItem(null)}
          open={selectedChartItem}
          size="tiny"
        >
          <ModalContent>
            <ModalHeading>
              {anchor &&
                get_survey_structure?.length > 0 &&
                get_survey_structure[0]?.categories
                  ?.find((category) => category.id === anchor)
                  ?.name?.toUpperCase()}
              <CloseButton
                onClick={() => {
                  setSelectedChartItem(null);
                }}
              >
                {closeIcon()}
              </CloseButton>
            </ModalHeading>
            <DepartmentLabelSection>
              <ColoredCircle color={selectedChartItem?.color} />
              <DepartmentLabel>{selectedChartItem?.category}</DepartmentLabel>
            </DepartmentLabelSection>
            <ModalSubSection>
              <NumRespondents>
                {selectedChartItem?.totalRespondents} respondents
              </NumRespondents>
            </ModalSubSection>
            <ModalCardSection>
              {/* Manually add Card */}
              <ModalOutcomeCard>
                <ModalOutcomeIcon>{ideaIcon()}</ModalOutcomeIcon>
                <ModalOutcomeLabelScore>
                  <ModalOutcomeLabel>nuLogic Score</ModalOutcomeLabel>
                  <ScoreRankSection>
                    <ModalOutcomeScore>
                      {selectedChartItem?.x}
                    </ModalOutcomeScore>
                    <RankChangeSection>
                      <ChangeContainer>
                        {!isNaN(selectedChartItem?.y) &&
                          selectedChartItem?.y !== 0 && (
                            <>
                              <Pic src={selectedChartItem?.y < 0 ? Down : Up} />
                              <ChangeText
                                changeColor={selectedChartItem?.y > 0}
                              >
                                {selectedChartItem?.y > 0 ? "+" : ""}
                                {selectedChartItem?.y.toFixed(2)}%
                              </ChangeText>
                            </>
                          )}

                        {selectedChartItem?.y === 0 ||
                        isNaN(selectedChartItem?.y)
                          ? "--"
                          : ""}
                      </ChangeContainer>
                      <ModalOutcomeRank>
                        <ModalBoldedRank>
                          {getPosition(
                            selectedChartItem?.id,
                            calculatedChartData
                          )}
                        </ModalBoldedRank>
                        &nbsp;out of&nbsp;
                        <ModalBoldedRank>
                          {calculatedChartData?.length}
                        </ModalBoldedRank>
                      </ModalOutcomeRank>
                    </RankChangeSection>
                  </ScoreRankSection>
                </ModalOutcomeLabelScore>
              </ModalOutcomeCard>
              {selectedChartItem?.outcomes?.map((outcome) => {
                return (
                  <ModalOutcomeCard>
                    <ModalOutcomeIcon>
                      {getIcon(outcome?.label)}
                    </ModalOutcomeIcon>
                    <ModalOutcomeLabelScore>
                      <ModalOutcomeLabel>{outcome?.label}</ModalOutcomeLabel>
                      <ScoreRankSection>
                        <ModalOutcomeScore>
                          {outcome?.average}%
                        </ModalOutcomeScore>
                        <RankChangeSection>
                          <ChangeContainer>
                            {outcome?.outcomeChange !== 0 &&
                              !isNaN(outcome?.outcomeChange) && (
                                <>
                                  <Pic
                                    src={outcome?.outcomeChange < 0 ? Down : Up}
                                  />
                                  <ChangeText
                                    changeColor={outcome?.outcomeChange > 0}
                                  >
                                    {outcome?.outcomeChange > 0 ? "+" : ""}
                                    {outcome?.outcomeChange.toFixed(2)}%
                                  </ChangeText>
                                </>
                              )}
                            {(outcome?.outcomeChange === 0 ||
                              isNaN(outcome?.outcomeChange)) &&
                              "--"}
                          </ChangeContainer>
                          <ModalOutcomeRank>
                            <ModalBoldedRank>
                              {outcome?.groupRank}
                            </ModalBoldedRank>
                            &nbsp;out of&nbsp;
                            <ModalBoldedRank>
                              {outcome?.totalGroups}
                            </ModalBoldedRank>
                          </ModalOutcomeRank>
                        </RankChangeSection>
                      </ScoreRankSection>
                    </ModalOutcomeLabelScore>
                  </ModalOutcomeCard>
                );
              })}
            </ModalCardSection>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};
export default Analytics;

const ModalOutcomeCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 10px;
  height: 75px;
  width: 230px;
  margin-bottom: 10px;
  background-color: #f8faff;
`;

const RankChangeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
`;

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const Pic = styled.img`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 3px;
`;

const ChangeText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.changeColor ? "#1D9A7D" : "#F15062")};
`;

const ModalOutcomeIcon = styled.div`
  padding: 6px;
  border-radius: 8px;
  background-color: #ebf1fd;
  height: 35px;
  width: 35px;
  margin-right: 10px;
`;

const ModalSubSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const NumRespondents = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666d79;

  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
`;

const ModalOutcomeLabelScore = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BarGraphToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 100px;
`;

const BarGraphTextToggleText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666d79;
  margin-right: 10px;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const ModalOutcomeLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2d70e2;
  margin-bottom: 13px;
`;

const ScoreRankSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalOutcomeScore = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #2a3039;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
`;

const ModalOutcomeRank = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  /* identical to box height, or 15px */
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const ModalBoldedRank = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
`;

const DepartmentLabelSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ColoredCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const DepartmentLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #2a3039;
`;

const ModalCardSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ModalHeading = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
  margin-bottom: 20px;
  color: #2a3039;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: auto;
  margin-top: -5px;
`;

const Container = styled.div`
  font-family: "Raleway";
  padding: 10px 0px;
  @media (max-width: 600px) {
    display: none;
  }
  margin-left: 30px;
`;

const PageTitle = styled.h1`
  display: flex;
  align-items: flex-start;
`;

const ScoreCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CultureLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;

  /* Primary/500 */
  color: #2d70e2;

  display: flex;
  align-items: flex-start;
  margin-bottom: -15px;
`;

const FilterSection = styled.div``;

const ChartHeader = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-right: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
  margin-bottom: 20px;
  color: #2a3039;
`;

const ChartDropdown = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const DialSection = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ChartSection = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
`;

// Stack the cards in rows of 2 and 2
const CardGroups = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
