import React,{useState,useEffect} from 'react'
import { Header, Message, Modal } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components';


const CancelButton = styled.div`
  cursor:pointer;
  margin-left:10px;
  font-size:16px;
  color:#4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
`
const P = styled.p`
font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:2px;
  margin-top:10px;
`

const Button = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:7px 24px 7px 24px;
  border: none;
    outline:none;
    margin-left:10px;
`

const SubText = styled.div`
  font-size: 13px;
  font-family: 'Red Hat Display', sans-serif;
   color:#4a4a4a;
   margin-bottom:20px;
`


function LeaderSetup(props) {
  const [open, setOpen] = useState(false)

  const yes = () =>{
    props.onChange(true)
    props.onSubmit()
  }

  const no =() =>{
    props.onChange(false)
    props.onSubmit()
  }

  useEffect(()=>{
    setOpen(props.open)
  },[props])


  return (
    <Modal
      onClose={() => props.close()}
      open={open}
      size={'tiny'}
    >
      <Modal.Header>Would you like the leader to setup their own team?</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <P>A team leader is able to invite team members to their own team. By flagging this leader to invite their team members you can save yourself the task of inviting each member yourself.</P>     
          <P>Both you and the team lead will always have the ability to invite/add/remove team members from this team at any time.</P>
          <SubText></SubText>
           
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
           <CancelButton onClick={()=>no()}>
            I'd prefer to invite the members myself
          </CancelButton>
          <Button onClick={()=>yes()}>Yes, let the leader invite their team members </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default LeaderSetup
