import {
  GET_PAYMENTS,
  FETCH_PAYMENT,
  DELETE_PAYMENT,
  ADD_PAYMENT,
  EDIT_PAYMENT,
  CLEAR_PAYMENT,
  CREATE_PAYMENT_INTENT,
  GET_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  payments: [],
  payment_intent: [],
  payment_methods: [],
  pending: false,
};

export default function Payments (state = initialState, action) {
  switch (action.type) {

    case GET_PAYMENT_METHODS:
      return {
        ...state,
        payment_methods: action.payload,
        pending: false,
      };
    
    case CREATE_PAYMENT_METHOD:
      return {
        ...state,
        payment_methods: [...state.payment_methods, action.payload],
      };
    
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        payment_methods: state.payment_methods.filter((row) => row.id !== action.payload),
      };

    case CREATE_PAYMENT_INTENT:
      return {
        ...state,
        payment_intent: action.payload,
        pending: false,
      };

    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
        pending: false,
      };
    
    case FETCH_PAYMENT:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_PAYMENT:
      return { ...state, [action.payload.id]: action.payload };

    case DELETE_PAYMENT:
      return {
        ...state,
        payments: state.payments.filter((row) => row.id !== action.payload),
      };

    case ADD_PAYMENT:
      return {
        ...state,
        payments: [...state.payments, action.payload],
      };

    case CLEAR_PAYMENT:
      return {
        ...state,
        payments: [],
        payment_intent: [],
        payment_methods: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        payments: [],
        payment_intent: [],
        payment_methods: [],
      };

    default:
      return state;
  }
}
