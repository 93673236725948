import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Icon, Message, Breadcrumb } from 'semantic-ui-react'
import { Link, useParams } from "react-router-dom"


import RemoveTeam from '../TeamSettings/removeTeam'
import AddMember from '../TeamSettings/addMember'
import InviteMember from '../TeamSettings/inviteMember'
import MemberCard from '../TeamSettings/memberCard'
import Edit from '../TeamSettings/Edit'
import {
	LOAD_TEAMS,
	LOAD_EMPLOYEES,
	LOAD_DEPARTMENTS,
	RESET_CREATED_TEAM,
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import {
	CSSTransition,
	CSSTransitionGroup,
} from 'react-transition-group';

import 'settings/setting.css';

const TableHeaders = [['Email', 40], ['Status', 30], ['+ Add team member', 30]]

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;

  @media (max-width:768px){
  	font-size:20px;
  }
`
const Button = styled.div`
	width: 102px;
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-left:64px;
`

const Remove = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: #ff2d55;
  cursor:pointer;
  margin-left:24px;
`

const Table = styled.div`
	width:100%;
`
const Headers = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	border-bottom:1px solid #e9e9e9;
`

const TH = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  color: #4a4a4a;
  width:${props => props.width + '%'};
  padding:0 0 10px 15px;
  display:flex;
  flex-direction:row;

  @media (max-width:768px){
  	display:${props => props.mobile ? 'flex' : 'none'};
  	width:100%;
  }
`
const View = styled.span`
	font-size: 13px;
  font-family: 'Red Hat Display', sans-serif;
  color: white;
  background-color:#7500ff;
  cursor:pointer;
  display:flex;
  justify-content:center;
  margin-right:10px;
  padding:4px 8px 4px 8px;
  border-radius:10px;
`



const Linked = styled.span`
	text-decoration:underline;
	cursor:pointer;
`

const Container = styled.div`
	width:100%;
	padding:71px 86px 0 50px;
	height:90vh;
	position:relative;

	@media (max-width:768px){
		padding:0;
	}
`


const EditContainer = styled.div`
	position:absolute;
	top:71px;
	right:86px;

	@media (max-width:768px){
		right:10px;
		top:0;
	}
`



const TeamSettings = (props) => {
	const apiDispatch = useDispatch();
	const [addMember, setAddMember] = React.useState(false)
	const [invitedMember, setInviteMember] = React.useState(false)
	const [role, setRole] = React.useState(0)
	const [members, setMembers] = useState([]);

	const selectDept = (dept) => {
		props.back()
		props.selectDepartment(dept)

	}

	const inviteLeader = () => {
		setRole(1)
		setAddMember(true)
	}

	const inviteMember = () => {
		setRole(0)
		setAddMember(false)
		setInviteMember(true)
	}

	const addMembers = () => {
		setRole(0)
		setInviteMember(false)
		setAddMember(true)
	}

	React.useEffect(() => {
		// if the response is ok 200
		apiDispatch({
			type: LOAD_EMPLOYEES,
			payload: {},
		});
	}, [apiDispatch]);

	const { get_employees, get_error, get_auth } = useSelector(
		(state) => ({
			get_employees: state.employees,
			get_error: state.errors,
			get_auth: state.auth,
		}),
		shallowEqual
	);

	useEffect(() => {
		setMembers(get_employees.employees.sort((a, b) => (a.id > b.id) ? 1 : -1));
	}, [
		get_employees.employees,
		get_auth
	]);


	return (
		<Container>
			<Title>  Settings</Title>
			<EditContainer>

			</EditContainer>

			{members.filter(m => m.role == 1).length > 0 ? "" : <Message
				icon='envelope outline'
				color='violet'
				header='This team does not have any members'
				style={{ zIndex: 0 }}
				size="big"
				content={<div><Linked onClick={() => inviteMember()}>Invite members</Linked> to this team in order to start using the nuLogic process.</div>}
			/>}
			{members.filter(m => m.role == 2).length > 0 ? "" : <Message
				icon='bullhorn'
				color='orange'
				header='This team does not have a leader'
				style={{ zIndex: 0 }}
				size="big"
				content={<div><Linked onClick={() => inviteLeader()}>Invite a leader</Linked> to this team, or promote a member's role to team leader from within the team.</div>}
			/>}

			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 60 }}>

				<Table>
					<Headers>
						<TH width={40} mobile={false}>Email</TH>
						<TH width={30} mobile={false}>Status</TH>
						<TH width={30} mobile={true}>
							<View onClick={() => addMembers()}>Add member</View>
							<View onClick={() => inviteMember()}>Invite member</View>
						</TH>

					</Headers>
					<AddMember
						visible={addMember}
						cancel={() => setAddMember(false)}
						team={props.id}
						role={role}
						organization={""} />
					<InviteMember
						visible={invitedMember}
						cancel={() => setInviteMember(false)}
						team={props.id}
						role={role}
						organization={""} />
					<CSSTransitionGroup
						transitionName="example"
						transitionEnterTimeout={500}
						transitionLeaveTimeout={300}>
						{

							members.map((item, i) => {
								return <MemberCard data={item} id={item.email + i} />
							})

						}
					</CSSTransitionGroup>

				</Table>

			</div>

		</Container>
	)
}


export default TeamSettings