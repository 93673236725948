import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import question_structure from "../question_structure_2.json";

const Target = ({ metric, setQuestion }) => {
	const [selected, setSelected] = useState(null);

	const select = (id, q) => {
		setSelected(id);
		setQuestion(q);
	};
	return (
		<Container>
			<Title>{metric.title} Questions</Title>
			<Description>Please select a question to target</Description>
			<Table>
				<Headers>
					<TH width={60}>Questions</TH>
					<TH width={20}>Score</TH>
					<TH width={20}>Actions</TH>
				</Headers>
				{question_structure.dimension
					.map((i) => i.factors)
					.flat()
					.find((f) => f.title == metric.title)
					.questions.map((item, i) => {
						return (
							<TargetCard
								question={item}
								selected={selected == i}
								setSelected={() => select(i, item)}
							/>
						);
					})}
			</Table>
		</Container>
	);
};

const TargetCard = ({ question, selected, setSelected }) => {
	const [score, setScore] = useState(Math.floor(Math.random() * 20));
	const [direction, setDirection] = useState(Math.random());

	return (
		<TargetContainer>
			<Q>{question}</Q>
			<TH width={20}>
				<Score>
					<Row>
						{Math.floor(Math.random() * 70) / 10}
						<IconContainer
							color={direction > 0.5 ? "red" : "green"}
						>
							<Icon
								name={
									direction > 0.5
										? "arrow alternate circle down"
										: "arrow alternate circle up"
								}
							/>
							{score}%
						</IconContainer>
					</Row>
				</Score>
			</TH>
			<TH width={20}>
				<StyledButton onClick={() => setSelected()} selected={selected}>
					Select
				</StyledButton>
			</TH>
		</TargetContainer>
	);
};

export default Target;

const Container = styled.div`
	width: 100%;
	padding: 20px;
	margin-top: 30px;
	font-family: "Barlow", sans-serif;
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 5px;
`;

const TargetContainer = styled.div`
	width: 100%;
	padding: 20px 10px;
	display: flex;
	border: 1px solid #e9e9e9;
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
	background-color: ${(props) =>
		props.selected ? "rgba(0, 0, 0, 0.1)" : "none"};
`;

const Table = styled.div`
	width: 100%;
`;
const Headers = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #e9e9e9;
	padding-top: 10px;
	background-color: #fafafa;
`;

const TH = styled.div`
	font-size: 14px;
	font-family: "Barlow", sans-serif;
	width: ${(props) => props.width + "%"};
	padding: 0 0 10px 15px;
	font-weight: bold;

	@media (max-width: 768px) {
		display: none;
	}
`;

const Q = styled.div`
	width: 60%;
	font-size: 16px;
`;

const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: ${(props) => (props.selected ? "#2d50e2" : "#7f7f7f")};
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: ${(props) =>
			props.selected ? "#2d50e2" : "rgba(0, 0, 0, 0)"};
		border: 1px solid #2d50e2;
		color: ${(props) => (props.selected ? "white" : "#2d50e2")};
	}
`;

const Score = styled.div`
	font-size: 28px;
	font-family: "Barlow Semi Condensed", sans-serif;
	line-height: 1;
	width: 150px;
`;

const Top = styled.div`
	font-size: 12px;
	color: grey;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const IconContainer = styled.div`
	font-size: 22px;
	margin-top: 5px;
	margin-left: 10px;
	color: ${(props) => props.color};
`;

const Description = styled.div`
	margin-bottom: 30px;
`;
