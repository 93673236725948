import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import DatePick from "../DatePicker";
import Metric from "../Metric";
import People from "../People";
import PlanComponent from "../PlanComponent/index";
import Progress from "../Progress";
import Anchor from "./Anchor";
import Sport from "./Sport";


const Phase1 = ({ categories, questions, data, setData }) => {
  const setFactor = (d) => {
    const _data = Object.assign({}, data);
    _data.metric = d;
    setData(_data);
  };

  const setQ = (q) => {
    const _data = Object.assign({}, data);
    _data.question = q === -1 ? [] : [q?.value];
    setData(_data);
  };

  const setPrimary = (d) => {
    const _data = Object.assign({}, data);
    _data.primary = d;
    setData(_data);
  };

  const setPlan = (d) => {
    const _data = Object.assign({}, data);
    _data.plan = d;
    setData(_data);
  };

  return (
    <Container>
      <Label>Plan components</Label>
      <Metric
        questions={questions}
        selectedFactor={data.metric}
        selected2={data.question}
        setSelected={(d) => setFactor(d)}
        setSelected2={(d) => setQ(d)}
        disableDropdown={data?.plan?.id === 3}
      />
      <Sport
        categories={categories}
        selected={data.primary.id}
        setSelected={(d) => setPrimary(d)}
      />
      {/* <PlanComponent
        plans={planningTemplatesOld}
        selected={data.plan.id}
        setSelected={(d) => setPlan(d)}
      /> */}
    </Container>
  );
};

export default Phase1;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 150px;
  padding-left: 55px;
  padding-right: 55px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Barlow Semi Condensed", sans-serif;
`;

const Input = styled.input`
  border-radius: 5px;
  height: 30px;
  width: 300px;
  outline: none;
  border: ${(props) =>
    props.error ? "1px solid #ff2d55" : "1px solid #9b9b9b"};
  margin-top: 5px;
  font-family: "Poppins";
  padding-left: 15px;
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #414141;
`;
const TextArea = styled.textarea`
  height: 150px;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
`;
