import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Pending from './Pending'
import RecommendationPDF from "./RecommendationPDF";
import LogAnimation from "./LogAnimation";
import NewRecommendation from './NewRecommendation'

function MainContent({
    latest_data,
    pillar_scores,
    pendingRecs,
    selectedRec,
    organization,
    setHasPendingRec,
    forcedLoading,
    setForcedLoading
}) {
    // ... other states and logic
    
    let content;

    if (forcedLoading) {
        content = <CenteredLoading><LogAnimation /></CenteredLoading>;
    } else if (selectedRec) {
        if (!Object.keys(selectedRec.recommendation).length) {
            content = <Pending />;
        } else if (
            !selectedRec?.survey_data?.scores ||
            selectedRec?.survey_data?.scores?.length === 0 ||
            selectedRec?.recommendation?.error
        ) {
            content = <>Recommendation has encountered an error</>;
        } else {
            content = <RecommendationPDF data={selectedRec} orgName={organization?.organization?.name} />;
        }
    } else {
        content = (
            <NewRecommendation
                data={latest_data}
                pillar_scores={pillar_scores}
                setPending={() => {
                    setForcedLoading(true);
                    setTimeout(() => {
                        setHasPendingRec(true);
                    }, 500);
                }}
                pendingRecs={pendingRecs}
            />
        );
    }
    return (
        <Content>
            {/* other parts of your component */}
            {content}
            {/* other parts of your component */}
        </Content>
    );
}


export default MainContent;

const CenteredLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
`;

const Content = styled.div`
  padding: 20px;
  padding-left: 350px;
  width: 100%;
  height: calc(100vh - 70px);
`;