
// Libraries
import { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "styled-components";
import { Select } from "semantic-ui-react";
import { SET_SURVEY_VERSION } from "constants/actions";
import { set } from "date-fns";
import { de } from "date-fns/locale";


const SurveyVersions = ({label=false,hidden=false}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();
  const [questionList, setQuestionList] = useState();
  const [defaultValue, setDefaultValue] = useState();


  // Get the survey responses, sort them by id (which is a proxy for date)
  // and then get a list of the survey questions that have been used.
  const get_culture_audit_reports = useSelector(
      state => state.debrief_schedule.culture_audit_reports, 
      shallowEqual
    );

    

    useEffect(() => {
      if(get_culture_audit_reports && !questionList){
          let sorted_responses = get_culture_audit_reports?.response?.sort(
              (a, b) => b.id - a.id
            );

          if(sorted_responses){
              let surveys = [];
              sorted_responses.map((item)=>{
                  if(!surveys.find(f=>f.id==item.question.sort_order)){
                      surveys.push({id:item.question.sort_order,value:item.question,text:item.question.name})
                  }
              })

              setQuestionList(surveys)
              setSelected(surveys?.[0]?.value);
          }
      }

    }, [get_culture_audit_reports])


    useEffect(() => {
        dispatch({
            type: SET_SURVEY_VERSION,
            payload: selected,
            });
    }, [selected])


    if(questionList?.length>1){


    return (
      <Container hidden={hidden}>
        {label? <Label>Survey Version</Label>:""}
        <StyledSelect
        placeholder="Select Survey Type"
        options={questionList}
        value={selected}
        id="anchors"
        onChange={(e, t) => setSelected(t.value)}
      />
      </Container>
    )
    }

    return <div/>


}

export default SurveyVersions;



const StyledSelect = styled(Select)`
font-family: "Inter", sans-serif !important;
border-radius: 20px !important;
border:none;
font-size: 10px !important;
color: #7e7e7e;

.item {
  font-size: 10px !important;
  color: #7e7e7e;
  font-family: "Inter", sans-serif !important;
}
`;

const Label = styled.div`
font-size: 10px;
color: #7e7e7e;
font-family: "Raleway", sans-serif !important;
margin-bottom:2px;
margin-left:5px;
font-weight:400;
`

const Container = styled.div`
display: ${props=>props.hidden?'none':'flex'};
flex-direction: column;
`
