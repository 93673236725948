import { SaveIcon, XIcon, ButtonTrashIcon } from "components/Icons";
import styled from "styled-components";
import PropTypes from "prop-types";

// Shared StyledButton component with props for dynamic customization
const StyledButton = ({ label, color = 'gray', icon, width, onClick }) => {
    return (
        <StyledButtonStyles variant={color} onClick={onClick} width={width}>
            {icon && <ButtonIcon>{icon}</ButtonIcon>}
            <ButtonText>{label || "Button"}</ButtonText>
        </StyledButtonStyles>
    );
};

StyledButton.propTypes = {
    label: PropTypes.string,
    color: PropTypes.oneOf(['blue', 'gray', 'orange', 'green', 'red']),
    icon: PropTypes.element, // Ensure it's an icon component
    width: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export { StyledButton };

// Specialized Buttons using the generic StyledButton
const StyledSaveButton = ({ onClick }) => (
    <StyledButton label="Save" color="blue" icon={<SaveIcon />} onClick={onClick} />
);

const StyledCancelButton = ({ onClick }) => (
    <StyledButton label="Cancel" color="gray" icon={<XIcon />} onClick={onClick} />
);

const StyledArchiveButton = ({ onClick }) => (
    <StyledButton label="Archive" color="orange" icon={<ButtonTrashIcon />} onClick={onClick} />
);

const StyledReactivateButton = ({ onClick }) => (
    <StyledButton label="Re-Activate" color="red" onClick={onClick} />
);

export { StyledSaveButton, StyledCancelButton, StyledArchiveButton, StyledReactivateButton };

// Simplified Styled Components

const colorMap = {
    blue: '#476dfa',
    gray: '#bbbbbb',
    orange: '#f3aa3d',
    green: '#28a745',
    red: '#e5657a',
    default: '#bbbbbb',
};

const hoverColorMap = {
    blue: '#6b8aff',
    gray: '#dddddd',
    orange: '#f6bd65',
    green: '#5ecf73',
    red: '#f28a9b',
    default: '#dddddd',
};

const StyledButtonStyles = styled.div`
  padding: 3px 20px;
  height: 30px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: ${(props) => props.width || 'auto'};

  background: ${(props) => colorMap[props.variant] || colorMap.default};

  &:hover {
    background-color: ${(props) => hoverColorMap[props.variant] || hoverColorMap.default};
  }
`;

const ButtonIcon = styled.div`
  padding-right: 8px;
`;

const ButtonText = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;
