import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import styled from "styled-components";
import {
    SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
} from "constants/actions";

import Select from "react-select";


import { Message } from "semantic-ui-react";


const SelectOrg = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [email, setEmail] = useState('')

    const [orgList, setOrgList] = useState([])
    const [selectedOrg, setSelectedOrg] = useState(false)

    const [formErrors, setFormErrors] = useState([]);
    const [pristine, setPristine] = useState(true);
    const [clicked, setClicked] = useState(false)



    const { get_auth } = useSelector(
        (state) => ({
            get_auth: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        setOrgList(get_auth?.organization_list)
        setEmail(get_auth?.user?.email)
    }, [get_auth]);


    useEffect(() => {
        const ErrorList = [];
        let totalErrors = 0

        if (orgList.length > 0) {
            if (!selectedOrg.AccountID) {
                ErrorList["organization"] =
                {
                    error: true,
                    message: "Please select an Organization"
                };
                totalErrors += 1
            }
        }
        ErrorList['errors'] = totalErrors
        setFormErrors(ErrorList);
    }, [
        orgList, selectedOrg, pristine
    ]);


    const onSubmit = () => {
        if (formErrors?.errors > 0) {
            setPristine(false);
            return;
        }

        dispatch({
            type: SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
            payload: {
                email: email,
                account_id: selectedOrg?.AccountID,
                employee_id: selectedOrg?.EmployeeID
            },
        });
        setPristine(true);
        setClicked(true)

    };

    useEffect(() => {
        if (clicked) {
            if (!get_auth?.pending) {
                return navigate("/app/dashboard");
            }
        }
    }, [clicked, get_auth, navigate]);


    return (
        <Container>
            <FormContainer>
                {formErrors.length > 0 && !pristine && (
                    <Message
                        negative
                        //   onDismiss={clearErrors}
                        header="We need these details!"
                        list={formErrors}
                    />
                )}
                <Box>

                    <Select
                        name="organization"
                        options={orgList}
                        onChange={setSelectedOrg}
                        getOptionLabel={(option) => `${option.Name} (${option.OrganizationID})`}
                        getOptionValue={(option) => `${option.OrganizationID}`}
                        value={selectedOrg}
                    />

                </Box>
                {orgList?.length > 1 &&
                 <Button onClick={onSubmit} id="sign-in">
                    Change
                </Button>
                }
               

            </FormContainer>
        </Container >
    );
};

export default SelectOrg;

const Button = styled.button`
  border-radius: 4px;
  background-color: #2d50e2;
  color: white;
  height: 36px;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #2d50e2;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`;

const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    // padding: 20px 20px 0 20px;
  }
  @media (max-height: 1000px) {
    // padding-top: 100px;
  }
`;
