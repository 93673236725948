import React from 'react';
import styled from 'styled-components';
import { ChevronIcon } from "./Icons";

const getHRScoreColor = (score) => {
  if (score > 0) {
    return ["#FEF3F4", "#F15062"]
  }

  return ["#EAFBF7", "#1D9A7D"];
};

const getFeedbackScoreColor = (score) => {
  if (score > 8) {
    return ["#EAFBF7", "#1D9A7D"];
  }
  if (score > 6) {
    return ["#EBF1FD", "#2D70E2"];
  }
  if (score > 2) {
    return ["#FFFAF1", "#FEA711"];
  }
  return ["#FEF3F4", "#F15062"];
};

const getSentimentScoreColor = (sentiment) => {
  switch (sentiment) {
    case "Very Positive":
      return ["#EAFBF7", "#1D9A7D"];
    case "Moderately Positive":
      return ["#EAFBF7", "#1D9A7D"];
    case "Slightly Positive":
      return ["#EAFBF7", "#1D9A7D"];
    case "Neutral":
      return ["#EBF1FD", "#2D70E2"];  
    case "Slightly Negative":
      return ["#FEF3F4", "#F15062"];
    case "Moderately Negative":
      return ["#FEF3F4", "#F15062"];
    default:
      return ["#FEF3F4", "#F15062"];
  }
};

const average = (data) =>{
  return data.reduce((a, b) => a + b, 0) / data.length;
}

const sentiments = ["Negative", "Moderate", "Positive", "Very Positive"];

const get_sentiment_words = (score) => {
  if (score > 0.7) {
    return "Very Positive";
  }
  if(score>0.5){
    return "Moderately Positive"
  }
  if (score > 0.2) {
    return "Slightly Positive";
  }
  if (score > -0.2) {
    return "Neutral";
  }
  if (score > -0.5) {
    return "Slightly Negative";
  }
  if(score > -0.7){
    return "Moderately Negative"
  }

  return "Very Negative";

}

const get_average_sentiment = (data) => {
  let average_sentiment = [];
  if (data?.length > 0) {
    data.map((f) => {
      average_sentiment.push(f.conversation_analysis.sentiment[0].compound);
    });
    return get_sentiment_words(average(average_sentiment));
  }
}

const get_total_flags = (data) => {
  let flag_count = 0;
  if (data?.length > 0) {
    data.map((f) => {
      flag_count += f.conversation_analysis.hr_flag[0];
    });
    return flag_count;
  }
}

const get_average_quality = (data) => {
  let average_quality = [];
  if (data?.length > 0) {
    data.map((f) => {
      average_quality.push(f.conversation_analysis.quality[0]);
    });
    return average(average_quality);
  }
}

function extractTitleAndSummary(str) {
  const titleRegex = /##\s*Title:\s*(.+)/i;
  const summaryRegex = /##\s*(?:TL;DR|tldr):\s*(.+)/i;

  const titleMatch = str.match(titleRegex);
  const summaryMatch = str.match(summaryRegex);

  const title = titleMatch ? titleMatch[1] : null;
  const summary = summaryMatch ? summaryMatch[1] : null;

  return { title, summary };
}

const FeedbackCard = ({ data, onClick }) => {
  const hrScore = Math.round(get_total_flags(data.ml_feedback.feedback));
  const hrColors = getHRScoreColor(hrScore);
  const sentiment = get_average_sentiment(data.ml_feedback.feedback);
  const sentimentColors = getSentimentScoreColor(sentiment);
  const feedbackScore = Math.round(get_average_quality(data.ml_feedback.feedback));
  const feedbackColors = getFeedbackScoreColor(feedbackScore);
  const { title, summary } = extractTitleAndSummary(data.ml_feedback.feedback_summary);

  return (
    <Card onClick={() => onClick()}>
      <CardContent>
        <TopSection>
          <TitleWrapper>
            <ReadReceipt read={true} />
            <T2>{title}</T2>
          </TitleWrapper>
          {/* <CreatedStamp>Created Jan 24 2022</CreatedStamp> */}
        </TopSection>

        {/* <SummaryText>
         {summary}
        </SummaryText> */}
        <ScoresSection>
          <Score
            style={{
              background: hrColors[0],
              color: hrColors[1],
            }}
          >
            <ScoreTitle>HR flag:&nbsp;</ScoreTitle>
            <ScoreValue>{hrScore}</ScoreValue>
          </Score>
          <Score
            style={{
              background: sentimentColors[0],
              color: sentimentColors[1],
            }}
          >
            <ScoreTitle>Sentiment:&nbsp;</ScoreTitle>
            <ScoreValue>{sentiment}</ScoreValue>
          </Score>
          <Score
            style={{
              background: feedbackColors[0],
              color: feedbackColors[1],
            }}
          >
            <ScoreTitle>Feedback Quality:&nbsp;</ScoreTitle>
            <ScoreValue>{feedbackScore}/10</ScoreValue>
          </Score>
        </ScoresSection>
      </CardContent>
      <CaretWrapper>{ChevronIcon()}</CaretWrapper>
    </Card>
  );
};

export default FeedbackCard;

const TitleWrapper = styled.div`
  display: flex;


`;

const TopSection = styled.div`
  padding-bottom: 16px;
`;

const ScoresSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const CardContent = styled.div``;

const CreatedStamp = styled.div`
  color: #666d79;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

const SummaryText = styled.p`
  color: #666d79;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const ReadReceipt = styled.div`
  min-width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #27cda7;
  margin-right: 10px;
  margin-top:8px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
`;

const CaretWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrimaryLabel = styled.div`
  color: #1d9a7d;
  background-color: #eafbf7;
  border-radius: 10px;
  padding: 5px 10px;
  margin-left: 12px;
  font-size: 10px;
`;

const T2 = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #2a3039;
`;

const Score = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;

  border-radius: 8px;
  margin-right: 3px;
`;

const ScoreTitle = styled.div`
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`;

const ScoreValue = styled.div`
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;