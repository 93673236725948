import Logo from "assets/images/logo.png";
import NuWave from "assets/images/nav/nuwave_black.png";

export const Domain = {
    id: 1,
    name: "nuLogic",
    url: "app.nulogic.co",
    image: Logo
}

export const DomainList = {
    "app.nulogic.co": {
        id: 3,
        name: "nuLogic",
        url: "app.nulogic.co",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Sign in to nuLogic",
        allowRegister: true,
        registerText: "Do not have a login",
        notRegisterText: "",
        showGoogleSso: false,
        debug: false,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://portal.nulogic.co/terms.html",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    }
}