import styled from "styled-components";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from 'semantic-ui-react'
import PlusImg from 'assets/images/nav/plus.svg'
import QuestionDrop from "./QuestionDrop2";
import Filters from './Filters'

const IntroScreen = ({feedback_length,raw_feedback}) => {
    const [selectedTemplate, setSelectedTemplate] = useState("Culture Factor Report");


    return (
        <Container>
            <Title>nuLogic Copilot</Title>
            <Description>
                Ask questions and generate insights about your culture and engagement. 
                Start by selecting which areas of your culture you'd like to address, 
                and then simply engage with the copilot.
            </Description>

            <SmallLabel>This chat topic is focused on:</SmallLabel>
            <FactorContainer>
            <QuestionDrop raw_feedback={raw_feedback}/>
            

            </FactorContainer>

            <SmallLabel>And on the following categories:</SmallLabel>
            <FactorContainer>
            <Filters/>

            </FactorContainer>

            <T2>Data Points</T2>
            <Description>
                <strong>{feedback_length}</strong> data points have been identified for this chat topic.
            </Description>

            
          
        </Container>
    )
}


export default IntroScreen;

const reportTemplates = [
    { key: 0, text: 'Thematic Analysis', value: 'Thematic Analysis' },
    { key: 1, text: 'Outcome Analysis', value: 'Outcome Analysis' },
    { key: 2, text: 'Culture Factor Report', value: 'Culture Factor Report' }
]

const Container = styled.div`
    margin-bottom:20px;
`


const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
color: #2A3039;
margin-bottom:10px;
`
const Factor = styled.div`

font-size: 16px;
font-style: normal;
font-weight: 600;
`

const FactorContainer = styled.div`
display:flex;
align-items:center;
color:#2D70E2;
font-family: Raleway;
margin-bottom:20px;
`

const Description = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #666D79;
margin-bottom:20px;
line-height:1.6;
`

const ActionList = styled.div`
    margin-top:32px;
`


const Label = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 125%;
color: #666D79;
margin-bottom:8px;
`

const SmallLabel = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 125%;
color: #666D79;
margin-bottom:12px;
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: bold;
font-size: 16px;
color: #2A3039;
margin-bottom:8px;
`

const IconImg2 = styled.img`
    width:20px;
    margin-left:20px;
`
