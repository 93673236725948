import {
  SET_TRACKED_STATE,
  COMPLETE_ANALYTICS
} from "constants/actions";

const initialState = {
  analytics: {
    completed: false,
    refreshed: 0,
    steps: [
      {
        title: "Take the nuLogic analytics tour",
        tracked: [],
        completed: false,
      },
      {
        title: "Explore each nuLogic analytics tab",
        tracked: [],
        completed: false,
      },
      {
        title: "Explore different data anchors",
        tracked: [],
        completed: false,
      },
      {
        title: "Investigate a low scoring question",
        tracked: [],
        completed: false,
      },
      {
        title: "Explore a data pillar",
        tracked: [],
        completed: false,
      },
    ],
  },
  home: {
    completed: false,
    steps: [
      {
        title: "Watch the welcome video",
        tracked: [],
        completed: false,
      },
      {
        title: "Check out the help section tutorials",
        tracked: [],
        completed: false,
      },
      {
        title: "Explore the data analytics page",
        tracked: [],
        completed: false,
      },
      {
        title: "Create an action plan",
        tracked: [],
        completed: false,
      },
    ],
  },
};

export default function checklist (state = initialState, action) {
  switch (action.type) {
    case SET_TRACKED_STATE:
      const newAnalytics = state.analytics
      newAnalytics.steps[action.id].tracked = [...newAnalytics.steps[0].tracked,action.payload]
      newAnalytics.refreshed+=1
      return {
        ...state,
        analytics:newAnalytics,
      };
    case COMPLETE_ANALYTICS:
      const newState = state.analytics
      newState.steps[action.payload].completed = true
      return {
        ...state,
        analytics:newState
      };
      default:
            return state;
    }
}