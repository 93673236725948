import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Message, Icon, Loader } from "semantic-ui-react";
import ToolBox from './ToolBox'
import Logo from 'assets/images/cog.png'
// import { Transition } from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
// import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import { Dimmer } from "semantic-ui-react";

import ValidateEmail from "./ValidateEmail";


const TeamToolHome = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [countdown, setCountdown] = useState(moment().add(5, "days"));
  const [employee, setEmployee] = useState(false);
  const [employeeId, setEmployeeId] = React.useState("");
  const [blurred, setBlurred] = useState(false);
  const [loading, setLoading] = useState(true)
  const [organization, setOrganization] = useState(false);
  // const [updated, setUpdated] = useState(false)

  const [paymentAlert, setPaymentAlert] = useState(false)
  const { get_auth, get_employees,
    get_organizations, get_error } = useSelector(
      (state) => ({
        get_error: state.errors,
        get_auth: state.auth,
        get_employees: state.employees,
        get_organizations: state.organizations
      }),
      shallowEqual
    );
  // const organization_id = useSelector((state) => state.auth.organization_id);
  const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)
  // console.log(organization)

  React.useEffect(() => {
    if (get_auth?.token) {
      setEmployeeId(get_auth.employee_id);
      setOrganization(get_organizations[get_auth.organization_id])

    }

  }, [get_auth, get_organizations]);

  useEffect(() => {
    if (organization?.payment_status < 2) {
      setPaymentAlert({
        header: <Header>
          {organization?.days_for_trial > 0 ? organization?.days_for_trial : 0} days left in trial
          <Link to="/get-payment">
            <Upgrade>Upgrade</Upgrade>
          </Link>
        </Header>,
        content: <div>
          Your free trial allows access to both the team health,
          and emotional health survey's. To continue your nuLogic
          experience, please upgrade your account in the billing section.
        </div>
      })
    }

    if (organization?.payment_status > 3) {
      setPaymentAlert({
        header: <Header>
          Payment Failed
          <Link to="/get-payment">
            <Upgrade>Retry</Upgrade>
          </Link>
        </Header>,
        content: <div>
          You may not be able access to team health,
          and emotional health survey's any longer. To continue your nuLogic
          experience, please complete your payment in the billing section.
        </div>
      })
    }
  }, [organization])



  React.useEffect(() => {
    if (employeeId) {
      setEmployee(get_employees[employeeId]);
      setBlurred(get_employees[employeeId]?.email_status === 9);
      setTimeout(() => {
        // console.log("I was here")
        setLoading(false);
      }, 2000);
      // setLoading(false)
    }
  }, [get_employees, employeeId]);

  if (employee?.email_status === 9) {
    return (
      <Container>
        <ValidateEmail />
      </Container>
    )
  }
  if (Number(AmbassadorId) > 0) {
    navigate("/ambassador");
  }

  return (
    <>

      <Container>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>


          <TopBanner>
            <Left>
              <SubTitle>Welcome, {employee && `${employee.first_name}`}!</SubTitle>
              <Title>Your Innerlogic ToolBox</Title>
              <Description>{employee && `${employee.first_name}`}, we're excited to have you join the platform designed to help you and your team thrive and work better together</Description>
            </Left>
            <Right>
              <Image src={Logo} />
            </Right>
          </TopBanner>

          <ToolBox loading={loading} role={employee ? employee.role : false} />
        </Dimmer.Dimmable>
      </Container>

    </>
  )
}


export default TeamToolHome


const Container = styled.div`
	width:100%;
  margin-top:30px;

   @media(max-width:768px){
    margin-top:0;
   }
`

const TopBanner = styled.div`
	width:100%;
	height:200px;
	display:flex;
	justify-content:center;
	flex-direction:row;
	font-family: 'Red Hat Display', sans-serif;
	position:relative;
	background-color:rgba(0, 176, 240,0.1);

 
    flex-wrap:wrap;
    height:auto;
  }

`

const Left = styled.div`
	width:50%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding:40px;

  @media(max-width:768px){
    width:100%;
  }
`

const Title = styled.div`
	font-size:30px;
	font-weight:bold;
  line-height:1;

  @media(max-width:768px){
    font-size:28px;
  }
`

const Description = styled.div`
	margin-top:30px;
	max-width:800px;
	font-size:18px;
	line-height:1.3;
`

const Row = styled.div`
	display:flex;
	margin-top:30px;
`

const Block = styled.div`
	background-color:#00B0F0;
	height:300px;
	width:200px;
	display:flex;
	align-items:center;
	justify-content:center;
`

const Text = styled.div`
	color:white;
	font-size:40px;
	line-height:1;
	text-align:center;

`

const Block2 = styled.div`
	height:300px;
	width:200px;

`

const BlockTitle = styled.div`
	font-size:20px;
	font-weight:bold;
`

const SubTitle = styled.div`
	color:#545454;
	margin-bottom:20px;
	font-size:16px;
`

const Image = styled.img`
	height:150px;
`

const Right = styled.div`
	width:50%;
	position:relative;
	display:flex;
	align-items:center;
	justify-content:center;

  @media(max-width:768px){
   display:none;
  }
`

const Upgrade = styled.span`
  display: flex;
  padding: 5px 10px 5px 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
  border-radius: 10px;
  font-weight: 900;
  margin-left: 30px;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.14em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  justify-content: space-between;
`;