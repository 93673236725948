import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  SubText,
  Input2,
  InviteFormButton,
} from "../styled-list";
import { Message } from "semantic-ui-react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SAVE_TEAM,
  RESET_CREATED_TEAM
} from "constants/actions";

const CreateEdit = ({visible,setVisible,team,setTeam,department,i}) =>{
	const apiDispatch = useDispatch();
	const methods = useForm();
	const { register, handleSubmit, control, reset, errors } = methods;

	const org_id = useSelector((state) => state.auth.organization_id)

	const onSubmit = (data) => {
	    setVisible(true);
	    if(department){
	    	apiDispatch({
		      type: SAVE_TEAM,
		      payload:{name:data.team_name,organization:org_id,department:department.id}
		    });
	    }else{
	    	apiDispatch({
		      type: SAVE_TEAM,
		      payload:{name:data.team_name,organization:org_id}
		    });
	    }
	    
	  };

	const createdTeam = useSelector((state) => state.teams.created,shallowEqual)

  React.useEffect(() => {
  		if(createdTeam?.id && !team?.id){
  			setTeam(createdTeam) 
  			apiDispatch({
  				type:RESET_CREATED_TEAM
  			})
  		}
  }, [createdTeam]);

return (
	<div>
	{visible ? <SubText>{team.name} {team.id} Team</SubText>
	      : 
	        <div>
	          <form onSubmit={handleSubmit(onSubmit)}>
	            <Input2
	              // value={team}
	              placeholder={"Team Name"}
	              control={control}
	              name="team_name"
	              ref={register({
	                required: "Please enter Team Name",
	              })}
	            />
	            {errors.team_name && (
	              <Message
	                error
	                // header='Action Forbidden'
	                content={errors.team_name.message}
	              />
	            )}
	            <InviteFormButton type="submit">Create Team</InviteFormButton>
	            {/* onClick={() =>}  */}
	          </form>
	        </div>
	    }
	 </div>
	    )

}

export default CreateEdit