import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled, { keyframes } from "styled-components";
import {
  addIcon,
  chatBubble,
  elipseDot,
  toolTip,
  removeIcon,
  deleteIcon,
} from "./Icons";

import { Loader } from "semantic-ui-react";

import {
  SG_GET_CHAT_ROOM,
  SG_FETCH_CHAT_ROOM,
  SET_WEB_CHAT_ID,
} from "constants/actions";
import { differenceInCalendarDays } from "date-fns";

const shortenString = (str, maxLength) => {
  if (str?.length <= maxLength) {
    return str;
  }
  return `${str?.slice(0, maxLength)}...`;
};

const getDaysAgo = (date) => {
  const dateFormatted = new Date(date);
  const currentDate = new Date();

  // const daysAgo = Math.ceil(
  //   (new Date() - dateFormatted) / (1000 * 60 * 60 * 24)
  // );
  const daysAgo = differenceInCalendarDays(currentDate, dateFormatted);
  return daysAgo;
};

const sameDay = (d1, d2) => {
  const dateOne = new Date(d1);
  const dateTwo = new Date(d2);
  // return isSameDay(dateOne, dateTwo);
  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
  );
};

const groupConversationsByDate = (conversations) => {
  return conversations.reduce((acc, conversation) => {
    const date = conversation.updated_at;

    const conversationDate = acc.find((item) => {
      return sameDay(item.updated_at, date);
    });

    if (conversationDate) {
      conversationDate.conversations.push(conversation);
    } else {
      acc.push({
        updated_at: date,
        conversations: [conversation],
      });
    }
    return acc;
  }, []);
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function ChatHistory(props) {
  const {
    selectedChatRoom,
    setSelectedChatRoom,
    setTopicCreated,
    topicCreated,
    selectedConversation,
    setChatHistory,
    setNewChannel,
    setDeleteQueue,
    setSelectedConversation,
    showModal,
    chatLoading,
    loadingChatHistory,
  } = props;
  const dispatch = useDispatch();
  const [selectedRoom, setSelectedRoom] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [sortedConversation, setSortedConversation] = useState();
  const [deleteIndex, setDeleteIndex] = useState();
  const [totalChats, setTotalChats] = useState();
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(1);
  const [tempLoad, setTempLoad] = useState(false);
  const { get_chat_topics } = useSelector(
    (state) => ({
      get_chat_topics: state.ai_chat,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      get_chat_topics &&
      get_chat_topics?.total_records &&
      Number(get_chat_topics?.total_records) > 0
    ) {
      setTotalChats(Number(get_chat_topics?.total_records));
    }
  }, [get_chat_topics]);

  useEffect(() => {
    setPageSize(page * 12);
  }, [page]);

  useEffect(() => {
    setChatHistory(get_chat_topics?.[selectedChatRoom?.id]);
    if (!selectedChatRoom) {
      setSelectedRoom();
    }
  }, [get_chat_topics, selectedChatRoom, setChatHistory]);

  useEffect(() => {
    if (!sortedConversation) {
      const timer1 = setTimeout(() => {
        dispatch({
          type: SG_GET_CHAT_ROOM,
          payload: `per_page=${pageSize}`,
        });
      }, 2000);
      return () => clearTimeout(timer1);
    } else {
      const timer1 = setTimeout(() => {
        dispatch({
          type: SG_GET_CHAT_ROOM,
          payload: `per_page=${pageSize}`,
        });
      }, 2000);
      const timer2 = setTimeout(() => {
        dispatch({
          type: SG_GET_CHAT_ROOM,
          payload: `per_page=${pageSize}`,
        });
      }, 5000);
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [dispatch, topicCreated, pageSize]);

  useEffect(() => {
    // if (sortedConversation && sortedConversation?.length === 0) {

    if (get_chat_topics?.chat_topics?.length > 0) {
      // if (!sortedConversation || loadingMore) {
      const sorted = groupConversationsByDate(get_chat_topics?.chat_topics);
      setSortedConversation(sorted);
      setLoadingMore(false);
      // }
    } else {
      setSortedConversation([]);
    }
    // }
  }, [get_chat_topics]);

  const handleConversationSelect = (conversation) => {
    // setSelectedChatRoom(conversation);
    // if (!chatLoading) {
    setSelectedChatRoom({
      id: conversation?.id,
      name: conversation?.name,
      params: conversation?.params,
    });
    setSelectedRoom(conversation);
    setNewChannel(conversation.id);

    dispatch({
      type: SET_WEB_CHAT_ID,
      payload: {
        id: conversation.id,
        name: conversation.name,
      },
    });
    dispatch({
      type: SG_FETCH_CHAT_ROOM,
      payload: { id: conversation.id },
    });
    // }
  };

  return (
    <Conversations activeConversation={selectedChatRoom}>
      {loadingChatHistory &&
        !chatLoading &&
        !selectedChatRoom &&
        sortedConversation?.length === 0 && (
          <LoadingHistoryText>Loading</LoadingHistoryText>
        )}
      {sortedConversation?.length > 0 &&
        sortedConversation
          ?.sort((a, b) => {
            if (isNaN(getDaysAgo(a.updated_at))) {
              return -1;
            }
            return new Date(b.updated_at) - new Date(a.updated_at);
          })
          ?.map((item, index) => {
            const days = getDaysAgo(item.updated_at);
            const isToday = days === 0;
            const daysText = isToday
              ? "Today"
              : days === 1
              ? `${days} day ago`
              : `${days} days ago`;
            const showPending = daysText?.includes("NaN");

            if (showPending) {
              return (
                <DaySection key={item?.updated_at} delay={index * 0.1}>
                  <DaysAgo>Just now</DaysAgo>
                  {item?.conversations?.map((conversation, c) => {
                    const isHovered = deleteIndex === conversation.id;
                    const color =
                      isHovered || conversation.id === selectedRoom?.id
                        ? "#2d70e2"
                        : "#666D79";

                    return (
                      <ConversationSnippet
                        key={conversation.id}
                        onMouseOver={() => setDeleteIndex(conversation.id)}
                        onMouseLeave={() => setDeleteIndex()}
                        isHovered={true}
                        onClick={() => {
                          handleConversationSelect(conversation);
                        }}
                      >
                        <AddIconWrapper>{chatBubble(color)}</AddIconWrapper>
                        <ConversationName color={color}>
                          <LoadingText>Creating new chatroom</LoadingText>
                        </ConversationName>
                        {isHovered && (
                          <DeleteIconWrapper
                            onClick={() => {
                              setDeleteQueue(conversation.id);
                            }}
                          >
                            {deleteIcon(color)}
                          </DeleteIconWrapper>
                        )}
                      </ConversationSnippet>
                    );
                  })}
                </DaySection>
              );
            }

            return (
              <DaySection key={item?.updated_at} delay={index * 0.1}>
                <DaysAgo>{daysText}</DaysAgo>
                {item?.conversations.map((conversation, c) => {
                  const isHovered = deleteIndex === conversation.id;
                  const color =
                    isHovered || conversation.id === selectedChatRoom?.id
                      ? "#2d70e2"
                      : "#666D79";

                  const itemName = shortenString(
                    conversation?.name?.replaceAll("_", " "),
                    32
                  );
                  return (
                    <ConversationSnippet
                      key={conversation.id}
                      onMouseOver={() => setDeleteIndex(conversation.id)}
                      onMouseLeave={() => setDeleteIndex()}
                      isHovered={isHovered}
                      onClick={() => {
                        handleConversationSelect(conversation);
                      }}
                    >
                      {/* <AddIconWrapper>{chatBubble(color)}</AddIconWrapper> */}
                      <ConversationName color={color}>
                        {capitalizeFirstLetter(itemName)}
                      </ConversationName>
                      {isHovered && (
                        <DeleteIconWrapper
                          onClick={() => {
                            setDeleteQueue(conversation.id);
                          }}
                        >
                          {deleteIcon(color)}
                        </DeleteIconWrapper>
                      )}
                    </ConversationSnippet>
                  );
                })}
              </DaySection>
            );
          })}
      {pageSize < totalChats && !loadingMore && (
        <LoadMoreButton
          onClick={() => {
            setPage(page + 1);
            setLoadingMore(true);
          }}
        >
          Load more
        </LoadMoreButton>
      )}
      {loadingMore && (
        <div style={{ width: 220, display: "flex", justifyContent: "center" }}>
          <Loader active inline />
        </div>
      )}
    </Conversations>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LoadMoreButton = styled.div`
  cursor: pointer;
  bottom: 120px;
  color: #2d70e2;
  border: 1px solid #2d70e2;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  z-index: 100;
  text-align: center;
  margin-bottom:20px;
`;

const LoadingText = styled.div`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const Conversations = styled.div`
  flex-direction: column;
  padding-top: 23.5px;
  padding-left: 20px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  height: calc(100vh - 100px);
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DaySection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state

  // 3. Adjust delay for each sample
  animation-delay: ${({ delay }) => delay}s;
`;

const DaysAgo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: 22px;
  color: #666d79;
`;

const LoadingHistoryText = styled.div`
  padding-right: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: 22px;
  color: #666d79;

  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const EmptyText = styled.div`
  padding-right: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: 22px;
  color: #666d79;
`;

const ConversationName = styled.div`
  color: ${({ color }) => color};
`;

const ConversationSnippet = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Raleway;
  font-size: 12px;
  cursor: pointer;
  background: ${({ isHovered }) => (isHovered ? "#F8FAFF" : "white")};
  color: ${({ isHovered }) => (isHovered ? "#2d70e2" : "#2A3039")};
  font-weight: ${({ isHovered }) => (isHovered ? "600" : "400")};
  border-radius: 8px;
  align-items: center;
  padding: 2px 10px;
  margin-bottom: 8px;
  margin-left: -15px;
  height: 25px;
`;

const AddIconWrapper = styled.div`
  margin-right: 10px;
`;

const DeleteIconWrapper = styled.div`
  margin-left: 6px;
  align-self: center;
`;
