import React, { useState } from "react";
import { Form, Loader, Checkbox, Message, Dimmer } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  REGISTER_AMBASSADOR,
  FETCH_ORGANIZATION,
  FETCH_AMBASSADOR,
  SG_VALIDATE_TOKEN,
} from "constants/actions";

import { useToasts } from "react-toast-notifications";

import styled, { keyframes } from "styled-components";
import Input from "./Input";
import Logo from "assets/images/logo.jpg";
import Background from "assets/images/login.jpg";


// function getEmailId(registeredUser) {
//   let first_name = "";
//   if (registeredUser?.first_name) {
//     first_name = registeredUser.first_name.toLowerCase();
//   }
//   let organization = "";
//   if (registeredUser?.organization) {
//     organization = registeredUser.organization
//       .toLowerCase()
//       .replace(/[&\\#,+()$~%.'":*?<>{} ]/g, "")
//       .trim();
//   }
//   return `${first_name}@${organization}.ca`;
// }

// const email_validation = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    errors,
    setValue,
  } = useForm();
  const [usrToken, setUsrToken] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [invalidToken, setInvalidToken] = React.useState(false);
  const [registerError, setRegisterError] = React.useState(false);
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const token = params.get("token");

  const { token } = useParams();
  const { addToast } = useToasts();

  const onSubmit = (data) => {
    // console.log({ ...data, terms: checked });
    dispatch({
      type: REGISTER_AMBASSADOR,
      payload: { ...data,
        user_token: usrToken.token, 
        email: usrToken.email },
    });

    if (!get_error?.errors) {
      navigate(`/account/login`);
    }
  };

  React.useEffect(() => {
    if (token) {
      dispatch({
        type: SG_VALIDATE_TOKEN,
        payload: `token=${token}`,
      });
    }
  }, [token, dispatch]);

  const { get_auth, get_token_validation, get_error } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_token_validation: state.validateEmail.validateToken,
      get_error: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_error?.errors?.data) {
      if (Array.isArray(get_error?.errors?.data)) {
        setRegisterError(get_error?.errors?.data.join());
      }
      addToast("Unable to Register", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setRegisterError(false);
    }
  }, [get_error, addToast]);

  React.useEffect(() => {
    if (get_token_validation.code === "200") {
      setUsrToken(get_token_validation);
    } 
    else {
      setMessage(get_token_validation.message);
    }

    if (get_error?.msg?.data?.token) {
      setInvalidToken(get_error?.msg?.data?.token?.[0]);
    }
  }, [get_token_validation, get_error]);

  // console.log(get_token_validation)

  React.useEffect(() => {
    if (get_error) {
      setErrorMsg(get_error);
    }
  }, [get_error]);

  React.useEffect(() => {
    if (get_auth?.ambassador_id) {
      dispatch({
        type: FETCH_AMBASSADOR,
        payload: {
          id: get_auth.ambassador_id,
          first_name: get_auth.first_name,
          last_name: get_auth.last_name,
          email: get_auth.email,
        },
      });
      setSuccess("Successfuly Registered redirecting to Login Page");
      // addToast("Successfuly Registered", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });
      // navigate(`/account/login`);
      setTimeout(() => navigate(`/account/login`), 4 * 1000);
    }

    if (get_auth?.organization_id) {
      dispatch({
        type: FETCH_ORGANIZATION,
        payload: { id: get_auth.organization_id, name: get_auth.company_name },
      });

      setTimeout(() => navigate(`/app/dashboard`), 4 * 1000);
    }
  }, [get_auth, dispatch, navigate, get_error]);

  let pwd = watch("password");

  React.useEffect(() => {
    if (checked) {
      register({ name: "terms" }, { required: false });
    } else {
      register(
        { name: "terms" },
        { required: "Please agree to terms and conditions" }
      );
    }
  }, [checked, register]);

  React.useEffect(() => {
    if (usrToken) {
      setValue("user_token", usrToken.token);
      setValue("email", usrToken.email);
    }
  }, [usrToken, setValue]);
  
// console.log(get_error?.msg?.data?.token)
// console.log(token)

  return (
    <Container>
      <ImgContainer>
        <SideImg src={Background} />
      </ImgContainer>

      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Box>
            <IMG src={Logo} />

            <Title>Create Password</Title>
            <P>
              You have been invited to create an Ambassador login, please create your
              password to get started.
            </P>

            {success && <Loader active inline="centered" />}
            {registerError && (
              <>
                <Message
                  error
                  // header='Action Forbidden'
                  content={registerError}
                />
              </>
            )}

            {success && (
              <>
                <Loader />
                <Message
                  success
                  // header='Action Forbidden'
                  content={success}
                />
              </>
            )}

            {/* <Controller
            as={<Input disabled defaultValue={usrToken ? usrToken.token : ""} />}
            name="user_token"
            control={control}
            placeholder="Token"
            rules={{ required: "Please enter Token" }}
            error={errors.user_token ? errors.user_token.message : ""}
          />
          <Error>
            {get_error && get_error?.user_token }
            {message && message}
          </Error> */}
            <Dimmer.Dimmable blurring dimmed={success ? true : false}>
              
              
              <Controller
                as={Input}
                name="password"
                placeholder="Password"
                control={control}
                type="password"
                defaultValue=""
                rules={{
                  required: "Please enter Password",
                  validate: (value) =>
                    value.length > 7 ||
                    "The min length of password is 8 characters",
                }}
                error={errors.password ? errors.password.message : ""}
              />
              <Controller
                as={Input}
                name="password_2"
                placeholder="Repeat Password"
                control={control}
                type="password"
                defaultValue=""
                rules={{
                  required: "Please enter Password Again",
                  validate: (value) =>
                    value === pwd || "The passwords do not match",
                }}
                error={errors.password_2 ? errors.password_2.message : ""}
              />

              <Checkbox
                onChange={() => setChecked(!checked)}
                checked={checked}
                name="terms"
                control={control}
                label={{
                  children: (
                    <a target="_blank"
                    rel="noopener noreferrer"
                    href={"https://portal.nulogic.co/terms.html"}>
                      I agree to the terms and conditions
                    </a>
                  ),
                }}
                style={{ borderColor: "black" }}
              />
              {!token &&
              <Message
              error
              // header='Action Forbidden'
              content="Invalid URL or missing Token"
            />
              }

            {invalidToken &&
              <Message
              error
              // header='Action Forbidden'
              content="Invalid Token"
            />
              }

            {errors.terms && !checked && (
                <Message
                  error
                  // header='Action Forbidden'
                  content={errors.terms.message}
                />
              )}
            {token &&
              <Button disabled={message ? true : false} type="submit">
                Get Started Now
              </Button> }
            </Dimmer.Dimmable>
          </Box>
        </form>
      </FormContainer>
    </Container>
  );
};

export default Register;

const Title = styled.div`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 40px;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 40px;
`;
const Button = styled.button`
  width: 200px;
  height: 55px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 18px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const IMG = styled.img`
  width: 160px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 30px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 50px;
  margin-top: 10px;
`;

const P = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4;
`;

const Error = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: -20px;
  line-height: 1.4;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Red Hat Display", sans-serif;
  justify-content: space-between;

  a {
    color: #6a00ff;
    font-weight: bold;
  }
`;

const SideImg = styled.img`
  min-height: 100%;
`;

const ImgContainer = styled.div`
  width: calc(100% - 500px);
  max-width: 900px;
  height: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 500px;
  height: 100%;
  padding: 100px 30px 0 30px;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 20px;
  }
`;
