import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Icon, Confirm } from "semantic-ui-react";
import { Pagination, Dimmer, Loader, Popup } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Select from "react-select";

import {
  SG_GET_DEBRIEF_SCHEDULES,
  ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  SG_DELETE_DEBRIEF_SCHEDULE,
  SG_GET_CULTURE_AUDIT_REPORTS,
} from "constants/actions";

import format from "date-fns/format";
import SurveyStatus from "components/CultureAudit/SurveyStatus";
import Anchor from "reports/Audit/Anchor";
import ErrorBoundary from "ErrorBoundary";

// used only for editSurvey.index.js
const StatusPendingHistoryList = ({
  organizationId,
  briefId,
  setBriefId,
  fetchAgain,
  setFetchAgain,
  showErrors,
  setOpen,
  showItems,
  surveyType,
  setAlertMessage,
  setShowMenu,
  pdfConfig,
  primaryCategory,
  primarySecondaryName,
  tab,
}) => {
  const dispatch = useDispatch();

  const [totalPages, setTotalPages] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [debriefSchedules, setDebriefSchedules] = useState([]);
  const [filteredDebriefs, setFilteredDebriefs] = useState();
  const [deleteId, setDeleteId] = useState(false);
  const [deletedRecord, setDeletedRecord] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showSurveyStatus, setShowSurveyStatus] = useState(0);

  const [selectedPrimary, setSelectedPrimary] = useState([]);

  const [nonHistory, setNonHistory] = useState(true);

  const [currStatusPage, setCurrStatusPage] = useState("");

  const [filteredCategories, setFilteredCategories] = useState();
  const [isAdmin, setIsAdmin] = useState();
  // this has been set in django if you use non basic pagination
  // this page using basic pagination
  const defaultPageSize = 12;

  const {
    get_culture_audit_reports,
    get_survey_structure,
    get_debrief_schedules,
    get_employees,
  } = useSelector(
    (state) => ({
      get_debrief_schedules: state.debrief_schedule,
      get_survey_structure: state.surveystructure.survey_structure,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_employees: state.employees,
    }),
    shallowEqual
  );

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  useEffect(() => {
    setFilteredCategories(
      get_survey_structure?.[0]?.categories.filter(
        (c) => c.priority === "primary"
      )?.[0]?.options
    );
  }, [get_survey_structure]);

  useEffect(() => {
    setIsAdmin(false);

    if (get_employees) {
      // setUserEmployee(get_employees?.userEmp);
      // check for admin
      if (get_employees.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }
    }
  }, [get_employees]);

  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  useEffect(() => {
    // console.log(tab)
    setDebriefSchedules(get_debrief_schedules.debrief_schedule.results);

    if (get_debrief_schedules.activePage > 0) {
      if (get_debrief_schedules.activePage !== activePage) {
        setActivePage(get_debrief_schedules.activePage);
      }
    }
  }, [get_debrief_schedules, activePage, tab]);

  useEffect(() => {
    setTotalPages(
      Math.ceil(get_debrief_schedules.debrief_schedule?.count / defaultPageSize)
    );
  }, [activePage, get_debrief_schedules]);

  // console.log(get_debrief_schedules)
  // console.log(get_debrief_schedules.debrief_schedule.results)
  // console.log(debriefSchedules)
  // console.log(tab)

  useEffect(() => {
    if (organizationId && activePage) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${activePage}&type_of=${surveyType}&organization=${organizationId}${
          showItems ? `&curr-status=${showItems}` : ""
        }${
          selectedPrimary?.value
            ? `&name=${selectedPrimary?.value?.trim()}`
            : ""
        }`,
      });
      if (fetchAgain) {
        setFetchAgain(false);
      }
      if (deletedRecord) {
        setDeletedRecord(false);
      }
    }
  }, [
    dispatch,
    organizationId,
    activePage,
    fetchAgain,
    setFetchAgain,
    deletedRecord,
    surveyType,
    showItems,
    selectedPrimary,
  ]);

  useEffect(() => {
    if (!get_culture_audit_reports?.response && surveyType) {
      if (ambassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${surveyType}&organization=${organizationId}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${surveyType}`,
        });
      }
    }
  }, [
    dispatch,
    surveyType,
    ambassadorId,
    get_culture_audit_reports.response,
    organizationId,
  ]);

  useEffect(() => {
    if (showItems === "history") {
      setNonHistory(false);
    }
  }, [showItems]);

  useEffect(() => {
    if (showItems !== currStatusPage) {
      // console.log('reset page no')
      setCurrStatusPage(showItems);
      setActivePage(1);
      dispatch({
        type: ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
        payload: 1,
      });
    }
  }, [showItems, dispatch, currStatusPage]);

  function ChangedPage(e, { activePage }) {
    setActivePage(activePage);
    dispatch({
      type: ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
      payload: activePage,
    });
  }

  useEffect(() => {
    setPageLoading(true);
    const timer1 = setTimeout(() => {
      setPageLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [dispatch, fetchAgain, deletedRecord, selectedPrimary]);

  useEffect(() => {
    setFilteredDebriefs(debriefSchedules);
  }, [debriefSchedules]);
  // const filter =

  //   pdfConfig?.[0].data.introTemplate === 2 ? (
  //     <FilterWrapper>
  //       <NavBar2 structure={get_survey_structure[0]} />
  //     </FilterWrapper>
  //   ) : null;
  // console.log(filteredDebriefs)

  const anchorWidth = pdfConfig?.[0].data.introTemplate === 2 ? "50" : "100";
  const hasAnchor = anchor || surveyType === 4;

  function Paginate() {
    return (
      <Pagination
        activePage={activePage}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        prevItem={filteredDebriefs?.links?.previous ? true : null}
        nextItem={filteredDebriefs?.links?.next ? true : null}
        totalPages={totalPages}
        onPageChange={ChangedPage}
      />
    );
  }

  function clearErrors() {
    setFetchAgain(false);
    setDeletedRecord(false);
  }

  function DeleteDebriefSchedule() {
    if (deleteId) {
      dispatch({
        type: SG_DELETE_DEBRIEF_SCHEDULE,
        payload: { id: deleteId },
      });
    }
    setDeletedRecord(deleteId);
    setConfirmDelete(false);
    setBriefId(false);
    setDeleteId(false);
  }

  function SelectDeleteRow(id) {
    setDeleteId(id);
    setConfirmDelete(true);
  }

  function EditSurvey(params) {
    setOpen(true);
    setBriefId(params);
  }

  function SurveyDetails(params) {
    setShowSurveyStatus(params);
    setBriefId(params);
    setShowMenu(false);
  }

  if (showSurveyStatus) {
    return (
      <SurveyStatus
        setShowSurveyStatus={setShowSurveyStatus}
        briefId={briefId}
        setAlertMessage={setAlertMessage}
        setShowMenu={setShowMenu}
        setBriefId={setBriefId}
        nonHistory={nonHistory} // for historic responses
      />
    );
  }

  return (
    <>
      {!showErrors && fetchAgain > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Schedule Added!"
          content="You have successfully added a new schedule!"
        />
      )}

      {!showErrors && deletedRecord > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Schedule Removed!"
          content="You have successfully removed a schedule!"
        />
      )}
      {/* <Dimmer.Dimmable as={Segment} dimmed={pageLoading}> */}

      <TopSection>
        <FormContainer>
          <div style={{ width: 300 }}>
            <Label>Filter {primarySecondaryName?.primary}</Label>
            <Select
              placeholder={`Select ${primarySecondaryName?.primary}`}
              onChange={(e) => setSelectedPrimary(e)}
              value={selectedPrimary}
              // isMulti
              isClearable
              options={primaryCategory}
              // getOptionLabel={(option) => option.name}
              // getOptionValue={(option) => option.role}
            />
          </div>
        </FormContainer>

        {surveyType === 3 ? (
          <>
            {/* {filter} */}
            <AnchorWrapper width={anchorWidth}>
              <Anchor anchorLabel="PDF Data Anchor" pdf={true} />
            </AnchorWrapper>
          </>
        ) : null}
      </TopSection>
      <Dimmer.Dimmable dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Table>
          <TR>
            <TH width={15}>Primary Factor</TH>
            <TH width={25}>Date &amp; Time (Local)</TH>
            <TH width={25}>Status</TH>
            <TH width={15}>Responses</TH>
            <TH width={20}>Actions</TH>
          </TR>

          {filteredDebriefs &&
            !pageLoading &&
            filteredDebriefs.map((brief, i) => {
              return (
                <TR key={i}>
                  <TD width={15}>{brief?.name}</TD>
                  <TD width={25}>
                    <TableRows>
                      <div>
                        <strong>Start # </strong>{" "}
                        {format(
                          new Date(brief?.scheduled_for?.toLocaleString()),
                          "EEEE, MMM do h:mm a"
                        )}
                      </div>
                      <div>
                        {showItems === "pending" && (
                          <>
                            <strong>Close #</strong>{" "}
                            {format(
                              new Date(brief?.close_at?.toLocaleString()),
                              "EEEE, MMM do h:mm a"
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <strong>Timezone #</strong> {brief?.time_zone}
                      </div>
                    </TableRows>
                  </TD>
                  <TD width={25}>{brief?.status_text}</TD>
                  <TD width={15}>
                    {nonHistory ? (
                      brief.status > 2 ? (
                        <div>
                          {brief?.total_responses} / {brief?.total_requests}
                        </div>
                      ) : (
                        "Pending"
                      )
                    ) : (
                      <div>
                        {Math.floor(
                          Number.isNaN(
                            brief?.total_responses / brief?.total_requests
                          )
                        )
                          ? 0
                          : Math.floor(
                              (brief?.total_responses / brief?.total_requests) *
                                100
                            )}
                        %
                        <br />
                        <small>
                          {brief?.total_responses} / {brief?.total_requests}
                        </small>
                      </div>
                    )}
                  </TD>
                  <TD width={20}>
                    {(currStatusPage === "history" || brief?.status === 21) && (
                      <Popup
                        content="Status"
                        trigger={
                          <Icon
                            name="history"
                            style={{
                              color: "#9b9b9b",
                              cursor: "pointer",
                              fontSize: 20,
                              marginRight: 10,
                            }}
                            onClick={() => SurveyDetails(brief?.id)}
                          />
                        }
                      />
                    )}
                    {brief?.status < 4 ? (
                      <GridAdjustment>
                        <Popup
                          content="Status"
                          trigger={
                            <Icon
                              name="history"
                              style={{
                                color: "#9b9b9b",
                                cursor: "pointer",
                                fontSize: 20,
                                marginRight: 10,
                              }}
                              onClick={() => SurveyDetails(brief?.id)}
                            />
                          }
                        />
                        {brief?.status < 3 && (
                          <>
                            <Popup
                              content="Delete Survey"
                              trigger={
                                <Icon
                                  name="trash alternate"
                                  style={{
                                    color: "#9b9b9b",
                                    cursor: "pointer",
                                    fontSize: 20,
                                    marginRight: 10,
                                  }}
                                  onClick={() => SelectDeleteRow(brief?.id)}
                                />
                              }
                            />
                            <Confirm
                              open={confirmDelete}
                              onCancel={() => setConfirmDelete(false)}
                              onConfirm={() => DeleteDebriefSchedule(brief?.id)}
                            />
                            <Popup
                              content="Edit"
                              trigger={
                                <Icon
                                  name="edit"
                                  style={{
                                    color: "#9b9b9b",
                                    cursor: "pointer",
                                    fontSize: 20,
                                  }}
                                  onClick={() => EditSurvey(brief?.id)}
                                />
                              }
                            />
                          </>
                        )}
                      </GridAdjustment>
                    ) : (
                      ""
                    )}
                  </TD>
                </TR>
              );
            })}
        </Table>

        <Pages>
          <Paginate />
        </Pages>
      </Dimmer.Dimmable>
    </>
  );
};

export default StatusPendingHistoryList;

/******** Styles ****************************************************/

const GridAdjustment = styled.div`
  left: ${(props) => (props.pdfConfig?.length > 0 ? "-15px" : "0px")};
  position: ${(props) =>
    props.pdfConfig?.length > 0 ? "absolute" : "relative"};
`;

const Table = styled.div`
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  font-family: "Barlow", sans-serif;
`;

const TR = styled.div`
  display: flex;
  padding: 15px 10px;
  width: 100%;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  font-weight: bold;
  border-bottom: 1px solid #9b9b9b;
  padding: 10px;
  background-color: #fafafa;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
`;

const ErrorWrapper = styled.div`
  width: 110px;
  position: absolute;
  left: 0;
  top: 0;
  color: red;
`;

const Pages = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const AnchorWrapper = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const FilterWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
`;

const TopSection = styled.div`
  flex-direction: row;
  display: flex;
`;

const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
  margin-top: -15px;
  margin-bottom: -3px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

const TableRows = styled.div`
  display: flex;
  flex-direction: column;
`;
