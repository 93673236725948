// Libraries
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Loader } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetEDISurveyUtils from "hooks/useGetEDISurveyUtils";

// Components
import PDFInstance from "./components/PDFInstance";
import DownloadButton from "./components/DownloadButton";

// Constants
import { SURVEY_INFO } from "constants/surveys";
import { GET_CULTURE_AUDIT_REPORTS } from "constants/actions";

// Dummy Data
import { Data_Generator } from "reports/EDI/Analytics/calculations";

const DEIPDF = (props) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState();
  const [pdfLoading, setPDFLoading] = useState(true);
  const [generatePdf, setGeneratePDF] = useState(false);
  const [generatingPdf, setGeneratingPDF] = useState(false);
  const { data } = props.config?.[0];
  const { addToast, removeAllToasts } = useToasts();
  const [filteredData, setFilteredData] = useState();
  const [calculated, setCalculated] = useState();
  const [dummyData, setDummyData] = useState();

  let [searchParams, setSearchParams] = useSearchParams();
  let testing = searchParams.get("testing");

  // TODO: Update rule to default to 2
  const rule = 2;

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  //Get redux data
  const {
    auth,
    surveyStructure,
    selectedOrg,
    ediResponses,
    surveyQuestions,
    get_organizations,
  } = useSelector(
    (state) => ({
      errors: state.errors,
      auth: state.auth,
      surveyQuestions: state.surveyquestions.survey_questions,
      surveyStructure: state.surveystructure.survey_structure,
      selectedOrg: state.selectedOrg,
      ediResponses: state.debrief_schedule.culture_audit_reports,
      get_organizations: state.organizations,
    }),
    shallowEqual
  );

  /***********  Helper Functions  *********/
  const generate_test_data_set = () => {
    setPDFLoading(true);
    const DataGenerated = Data_Generator(
      1000,
      5,
      get_survey_structure[0],
      questions
    );

    dispatch({
      type: GET_CULTURE_AUDIT_REPORTS,
      payload: { response: DataGenerated },
    });

    setTimeout(() => setPDFLoading(false), 100);
  };

  /***********  Custom Hooks  *************/
  const {
    get_survey_structure,
    get_culture_audit_reports,
    get_survey_questions,
    selectedReport,
    get_auth,
  } = useGetSurveyData({
    surveyType: SURVEY_INFO.edi.question_type,
    auditTypeId: SURVEY_INFO.edi.type,
    reportId: props.reportId || null,
    dateStart: props.options?.startDate || null,
    edi: true,
    dateEnd: props.options?.endDate || null,
    sports: props.options?.sports || null,
  });

  const { questions, selectedResponses, filteredResponses } =
    useGetEDISurveyUtils({
      ediResponses: get_culture_audit_reports,
      reportId: props.reportId || null,
      selectedReport: selectedReport,
      auth: get_auth,
      get_organizations: get_organizations,
      surveyStructure: get_survey_structure,
      organizationId: organizationId,
      surveyQuestions: get_survey_questions,
      pdfLoading: pdfLoading,
    });

  /***********  useEffect Hooks  *************/
  useEffect(() => {
    if (surveyStructure.length > 0) {
      setPDFLoading(false);
    }
  }, [surveyStructure]);

  useEffect(() => {
    if (Number(ambassadorId) > 0 && selectedOrg) {
      setOrganizationId(selectedOrg.organization?.id);
    } else {
      setOrganizationId(Number(auth.organization_id));
    }
  }, [selectedOrg, ambassadorId, auth]);

  /***********  Render Functions  *************/
  const pdfInstance = useMemo(
    () =>
      !pdfLoading &&
      surveyQuestions &&
      surveyStructure &&
      ediResponses && (
        <PDFInstance
          overviewReport={props.overviewReport}
          surveyQuestions={surveyQuestions}
          surveyStructure={surveyStructure}
          responseData={selectedResponses || filteredResponses}
          setPdfGenerating={(isGenerating) => setGeneratingPDF(isGenerating)}
        />
      ),
    [
      surveyQuestions,
      surveyStructure,
      pdfLoading,
      ediResponses,
      selectedResponses,
    ]
  );

  const testingControl = testing ? (
    <Test
      overview={props.overview}
      onClick={() => generate_test_data_set()}
      id={"test"}
    >
      Generate Data
    </Test>
  ) : null;

  if (pdfLoading) {
    return (
      <div
        style={{
          width: 110,
          position: "relative",
          left: "0px",
        }}
      >
        <Loader inline active size="small" />
      </div>
    );
  }

  const showControl =
    (surveyStructure?.length > 0 &&
      props.overviewReport &&
      filteredResponses?.length > 0) ||
    (surveyStructure?.length > 0 && selectedResponses?.length > 0);

  const downloadButton = (
    <DownloadButton
      overviewReport={props.overviewReport}
      btnAction={() => setGeneratePDF(true)}
      loading={pdfLoading}
      showControl={showControl}
      setPdfGenerating={(isGenerating) => setGeneratingPDF(isGenerating)}
    />
  );

  return (
    <div>
      {testing && testingControl}
      {downloadButton}
      {generatePdf && pdfInstance}
    </div>
  );
};

const Test = styled.button`
  width: 80px;
  height: 35px;
  position: absolute;
  margin-top: ${(props) => (props.overview ? "-50px" : "-20px")};
  margin-left: ${(props) => (props.overview ? "15px" : "0px")};
`;

export default DEIPDF;
