import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Icon, Confirm, Checkbox,Popup } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  SG_EDIT_DEBRIEF_TOKEN,
  REMOVE_EMPLOYEE
} from "constants/actions";
import StatusBox from "./StatusBox";


const StatusCard = ({ data, editSurvey, }) => {
  const dispatch = useDispatch();
  const { addToast, removeAllToasts } = useToasts();
  // const [changedRow, setChangedRow] = useState(false)
  const [tokenStatus, setTokenStatus] = useState({
    id: 2,
    message: "Pending",
  });




  const [showConfirm, setShowConfirm] = useState(false);

  function CloseSurvey() {
    if (data?.id) {
      dispatch({
        type: SG_EDIT_DEBRIEF_TOKEN,
        payload: {
          id: data?.id,
          close_survey: data?.id,
        },
      });

      addToast(
        "Survey access successfully removed",
        { appearance: "success" }
      );
    }
  }

  function Reminder() {
    if (data?.id) {
      setTimeLeft(330);
      dispatch({
        type: SG_EDIT_DEBRIEF_TOKEN,
        payload: {
          id: data?.id,
          remind: data?.id,
        },
      });
    }
  }


  function DeleteEmployee(employeeId) {

    if (employeeId) {
      setTimeLeft(330);
      dispatch({
        type: REMOVE_EMPLOYEE,
        payload: {
          id: employeeId
        },
      });
    }
  }

  const [timeLeft, setTimeLeft] = useState(data?.difference);

  useEffect(() => {
    if (data?.difference > 0) {
      setTimeLeft(data?.difference);
    }
  }, [data?.difference]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer1 = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timer1);
    }
  });

  useEffect(() => {
    // console.log(data)
    setTokenStatus({
      id: 2,
      message: "Pending",
    });
    if (data?.failed === true) {

      setTokenStatus({
        id: 4,
        message: "Removed",
      });
      return;
    }

    if (data?.to_delete === true) {
      setTokenStatus({
        id: 3,
        message: "Completed",
      });
      return;
    }

    if (data?.status === "Email id bounced or marked spam") {
      setTokenStatus({
        id: 1,
        message: "Email id bounced or marked spam",
      });
      return;
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const getType = (message) => {
    if (message === "Email id bounced or marked spam") {
      return "Error";
    }

    return message;
  };

  //

  return (
    <MemberContainer>
     

      <RowContainer width={20} mobile={false}>
        <Name>
          {" "}
          {data?.first_name ? data?.first_name + " " + data?.last_name : ""}
        </Name>
      </RowContainer>

      <RowContainer width={30} mobile={false}>
        <ColumnRows>
          {(data?.email_status === 3 || data?.email_status === 4) ?
          <Popup content={`Marked ${data?.email_status_text}`} trigger={
            <Text status={true}>{data ? data.email : ""}</Text>
          } /> 
          :
          <Text status={false}>{data ? data.email : ""}</Text>}



         
          <SubTitleText>
            {/* {data?.email_status === 3 &&
              <StyledButton color="#2d50e2" size="mini"
                onClick={() => DeleteEmployee(data?.id)}>
                Delete
              </StyledButton>} */}

          </SubTitleText>
        </ColumnRows>

      </RowContainer>
      <RowContainer width={10} mobile={false}>
        <Text>
          {data?.reminded}
          {/* {timeLeft} */}
        </Text>
      </RowContainer>
      <RowContainer width={30} mobile={false}>
      {data?.employee_status === 1 ?
        <StatusBox
          type={getType(tokenStatus?.message ? tokenStatus?.message : '')}
          data={tokenStatus?.message}
          tokenStatus={tokenStatus}
          email_status={data?.email_status}
        />
        :
        <StatusBox
          type={'Archived'}
          data={tokenStatus?.message}
          tokenStatus={tokenStatus}
          email_status={data?.email_status}
        />
      }
      </RowContainer>
      <RowContainer width={20} mobile={false}>
        {data?.employee_status === 1 ? tokenStatus?.id === 2 && (
          <>
            {data?.difference > 0 && timeLeft ? (
              <WaitText>
                <Icon loading name="asterisk" />{" "}
                {`${Math.floor((timeLeft / 60) % 60)}:${Math.floor(
                  timeLeft % 60
                )}s`}{" "}
              </WaitText>
            ) : (
              editSurvey && (

                <StyledButton color="#2d50e2" size="mini"
                  disabled={data?.email_status === 3}
                  onClick={Reminder}>
                  Remind
                </StyledButton>
              )
            )}

            {editSurvey && (
              <>
                <StyledButton
                  color="#ff5168"
                  size="mini"
                  onClick={() => setShowConfirm(true)}
                >
                  Remove
                </StyledButton>
                <Confirm
                  open={showConfirm}
                  header="Remove User from Survey"
                  content="Would you like to remove this user from the survey? This action will disable their survey link and cannot be undone."
                  onCancel={() => setShowConfirm(false)}
                  onConfirm={CloseSurvey}
                />
              </>
            )}
          </>
        ): ''}
      </RowContainer>
    </MemberContainer>
  );
};

/******** Styles ****************************************************/

const MemberContainer = styled.div`
  width: 100%;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
`;

const Name = styled.div`
  font-size: 14px;
`;

const Text = styled.div`
  font-size: 12px;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => (props.status ? "red" : "black")};
`;

const SubTitleText = styled.p`

  font-size: 12px;
  font-family: "Red Hat Display", sans-serif;
  color: red;
`;

const WaitText = styled.div`
  font-size: 12px;
  width: 75px;
  color: red;
  font-family: "Red Hat Display", sans-serif;
`;

const ColumnRows = styled.div`
  display:flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 0px 20px;
  height: 30px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-right: 10px;
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
  user-select: none;
  border: none;
  border: 1px solid white;
  outline: none;

  &:hover {
    background-color: white;
    color: ${(props) => props.color};
    border: ${(props) => "1px solid " + props.color};
  }
`;

export default StatusCard;
