import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Select, Checkbox } from "semantic-ui-react";

const Track = ({ setTime }) => {
	const [selected, setSelected] = useState(null);

	const change = (v) => {
		setSelected(v);
		setTime(anchor_list.find((i) => i.value == v).text);
	};
	return (
		<Container>
			<Title>Track your progress </Title>

			<T1>Evaluation period</T1>
			<Item>
				<Description>
					When do you want to remeasure this area (i.e., how long do
					you believe the plan will take to have a measurable impact)?
				</Description>
				<Select
					placeholder="Select timeframe"
					options={anchor_list}
					value={selected}
					onChange={(e, t) => change(t.value)}
				/>
			</Item>
		</Container>
	);
};

export default Track;

const Container = styled.div`
	width: 100%;
	padding: 20px;
	margin-top: 10px;
	font-family: "Barlow", sans-serif;
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 50px;
`;

const Description = styled.div`
	font-weight: 300;
	max-width: 600px;
`;

const T1 = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
`;

const Item = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;

const anchor_list = [
	{ value: 0, text: "1 week" },
	{ value: 1, text: "2 weeks" },
	{ value: 2, text: "1 month" },
	{ value: 3, text: "3 month" },
];
